import Tooltip from './Tooltip'
import copyToClipboard from '../../utils/copyToClipboard'
import styled from 'styled-components'

interface ConditionalTooltipI {
  text: string
  maxLength: number
  render?: any
}

const ConditionalTooltip: React.FC<ConditionalTooltipI> = ({
  text,
  maxLength,
  render: Component,
}) => {
  function handleClick(e: any) {
    copyToClipboard(text)
  }

  const postfix = text?.length > maxLength ? '...' : ''
  const updatedText = postfix ? text?.slice(0, maxLength) + postfix : text

  const WrappedComponent =
    text?.length > maxLength ? (
      <Tooltip enterDelay={0} enterNextDelay={0} title={<span>{text}</span>}>
        <ComponentContainer onClick={handleClick} style={{ maxWidth: maxLength }}>
          {!!Component ? Component(updatedText) : updatedText}
        </ComponentContainer>
      </Tooltip>
    ) : !!Component ? (
      Component(text)
    ) : (
      text
    )

  return WrappedComponent
}

const ComponentContainer = styled.span`
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default ConditionalTooltip
