import BasicSelect from '../../../../../components/BasicSelect'

export default function CleansingSelector({
  value,
  changeHandler,
  locked,
  options,
}: any) {
  const cleansingOptions = options.map(
    (n: { value: string; display_value: string }) => ({
      value: n.value,
      text: n.display_value,
    })
  )

  return (
    <BasicSelect
      disabled={locked}
      selectValue={value}
      items={cleansingOptions}
      onChange={changeHandler}
    />
  )
}
