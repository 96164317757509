import gtm from 'react-gtm-module'
import lf from 'localforage'
import { CustomEvent } from '../types'

gtm.initialize({
  gtmId: 'GTM-P6P2RTV',
})

declare global {
  interface Window {
    dataLayer: Array<any>
  }
}

let userData = {} // in-memory storage of user data

const flattenUserData = (data: any) => {
  const {
    id,
    user_hash,
    admin,
    email,
    first_name,
    last_name,
    credits,
    created,
    account,
  } = data
  return {
    account_type: account.type,
    account_stage: account.stage,
    account_signup_source: account.signup_source,
    account_creation_date: account.created.timestamp.raw,
    account_credit_refresh_period: account.credit_schedule.interval,
    account_credits_remaining: account.credits,
    account_expiration: account.expires.raw,
    account_id: account.id,
    account_last_credit_refresh_date: account.credit_schedule.last.raw,
    account_name: account.name,
    account_next_credit_refresh_date: account.credit_schedule.next.raw,
    account_record_ownership_period: account.record_ownership_retention_days,
    account_unlimited_credits: account.credits_unlimited,
    account_unlimited_credits_anonymized: account.credits_unlimited_anonymized,
    user_creation_date: created.timestamp.raw,
    user_credits_remaining: credits,
    user_email: email,
    user_first_name: first_name,
    user_id: id,
    user_hash: user_hash,
    user_last_name: last_name,
    user_role: admin ? 'admin' : 'staff',
  }
}

function capturePageview(location: any) {
  const pageviewCallback = () => {
    window.dataLayer.push({
      event: 'pageview',
      page: location,
      ...userData,
    })
  }

  if (!Object.keys(userData).length) {
    setTimeout(pageviewCallback, 5 * 1000) // Data not yet loaded, need to delay trigger
  } else {
    pageviewCallback()
  }
}

function captureUserData(data: any) {
  if (!Object.keys(data).length) {
    return
  }
  userData = flattenUserData(data)
  window.dataLayer.push({
    event: 'user_data_update',
    ...userData,
  })
}

function captureLogin(data: any, eventType: CustomEvent = 'login') {
  // TODO this is currently called on each jwt token refresh, not just actual login
  // TODO unfortunately the base data here is used for the pageview call, so untying from token refresh isn't as easy
  if (!Object.keys(data).length) {
    return
  }

  userData = flattenUserData(data)
  window.dataLayer.push({
    event: eventType,
    ...userData,
  })
}

function captureCustomEvent(eventName: CustomEvent, data: any = {}) {
  window.dataLayer.push({
    event: eventName,
    ...data,
  })
}

/* Some events may need to be queued, only to be triggered at a later, undetermined date.
   This may even be after a user has closed the browser and returned. To do this, 
   we queue events associated with a given trigger (when the event should be fired). 
   This key=value pair will be used later when the action occurs (see fireQueuedEvents) */
type EventTrigger = { trigger: string; event: CustomEvent }
async function queueEvent({ trigger = 'login', event }: EventTrigger) {
  const existingEvents: Array<CustomEvent> = (await lf.getItem(trigger)) || []
  const updatedEvents = [...existingEvents, event]
  await lf.setItem(trigger, updatedEvents)
}

/* Events that are queued will eventually be triggered by an action */
async function fireQueuedEvents(trigger: string = 'login') {
  const events: Array<CustomEvent> = (await lf.getItem(trigger)) || []
  events.forEach((event) => captureCustomEvent(event))
  await lf.setItem(trigger, [])
}

const captureFunctions = {
  captureLogin,
  capturePageview,
  captureUserData,
  captureCustomEvent,
  queueEvent,
  fireQueuedEvents,
}

export default captureFunctions
