import styled, { css, keyframes } from 'styled-components'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown'

const rotate = keyframes`
  from {
      transform: rotateX(0deg);
    }
    to {
      transform: rotateX(-180deg);
    }
`
/*
 * Not sure why animation-direction: reverse isn't working as I expect. Can
 * revisit at a later date.
 */
// revisit this at a later date.
const rotateBack = keyframes`
  from {
      transform: rotateX(180deg);
    }
    to {
      transform: rotateX(0deg);
    }
`

const StyledArrowDown = styled(ArrowDown)<{ animate: any; duration: any }>`
  animation: ${(props) =>
      props.animate
        ? css`
            ${rotate}
          `
        : css`
            ${rotateBack}
          `}
    ${(props) => `${props.duration}`} linear forwards;
`

export default function DropdownChevron({
  isAnimating,
  animationDuration = '100ms',
}: any) {
  return (
    <StyledArrowDown animate={isAnimating ? 1 : 0} duration={animationDuration} />
  )
}
