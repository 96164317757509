import styled from 'styled-components'
import { colors } from '../../../../../../context/theme-context'
import Skeleton from 'react-loading-skeleton'

export default function CountWidget({ credits, records, ...rest }: any) {
  return (
    <OuterContainer {...rest}>
      <InnerContainer>
        <Count data-cy="recordCount">
          {records?.toLocaleString() || <Skeleton width={105} height={30} />}
        </Count>
        <Label>records</Label>
      </InnerContainer>
      <InnerContainer>
        <Count data-cy="creditsCount">
          {credits?.toLocaleString() || <Skeleton width={105} height={30} />}
        </Count>
        <Label>credits</Label>
      </InnerContainer>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  display: flex;
  column-gap: 0.875rem;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 0.25rem;
`

const Count = styled.div`
  font-size: 1.375rem;
  line-height: 1.5rem;
  color: ${colors.gray12};
  font-weight: 500;
`

const Label = styled.div`
  font-size: 0.875rem;
  color: ${colors.gray8};
`
