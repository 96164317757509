// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import isEqual from 'lodash.isequal'

export default function filterDiff(
  selFilters: any,
  appFilters: any,
  sqlIsVerified: any
) {
  const selectedKeys = Object.keys(selFilters)
  const appliedKeys = Object.keys(appFilters)
  const allKeys = new Set([...selectedKeys, ...appliedKeys])

  let selectedValues
  let appliedValues
  let emailDiff = 0
  let diff = 0

  const diffCollection = {}
  const allDataMode = selFilters['allDataMode'] || appFilters['allDataMode']

  allKeys.forEach((key) => {
    selectedValues = selFilters[key]
    appliedValues = appFilters[key]

    if (key === 'chosen_files' && isEqual(selectedValues, appliedValues)) {
      return
    }

    /************ SPECIAL CASES DUE TO BUSINESS LOGIC ************/
    if (key === 'privacy') {
      return
    }

    if (['good_only', 'good_catch_all', 'b2b_email'].includes(key)) {
      if (!!selectedValues === !!appliedValues || selectedValues.length === 0) {
        return
      }
      emailDiff += 1
      return
    }

    if (['contact_location_country', 'company_location_country'].includes(key)) {
      if (allDataMode) {
        if (JSON.stringify(selectedValues) === JSON.stringify(appliedValues)) {
          return
        }
      }
    }
    /*************************************************************/

    if ([typeof selectedValues, typeof appliedValues].includes('undefined')) {
      if (!!selectedValues === !!appliedValues) {
        return
      }
      if (
        selectedValues instanceof Array &&
        !!selectedValues.length === !!appliedValues
      ) {
        return
      }
    }

    if (JSON.stringify(selectedValues) === JSON.stringify(appliedValues)) {
      return
    }

    if (key === 'sql_editor') {
      if (!(selectedValues === '' && appliedValues !== '')) {
        if (!sqlIsVerified) {
          return
        }
        if (!appliedValues && !selectedValues) {
          return
        }
      }
    }

    if (key === 'allDataMode') {
      // HOW DO WE WANT allDataMode to behave?
      // Instantly update the setting, or is it something that the user needs to accept?
      return
    }

    diff += 1
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    diffCollection[key] = [selectedValues, appliedValues]
  })
  if (!!emailDiff) {
    // treat these all as 1 diff
    diff += 1
  }

  return diff
}
