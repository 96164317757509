import styled from 'styled-components'
import { colors } from '../../../../../../context/theme-context'
import { toTitleCase } from '../../../../../../utils/caseHelpers'
import filterCategories from './filterCategories'

const stackOptions = {
  'And in': 'And In (Intersection)',
  'Or in': 'Or In (Union)',
  'And not in': 'And Not In (Exclude)',
}

function SubCategory({ title, filters }: any) {
  // filters = [JSX]
  return (
    <SubCategoryContainer>
      <SubCategoryTitle>{toTitleCase(title)}:&nbsp;</SubCategoryTitle>
      {filters.map((v: any) => v)}
    </SubCategoryContainer>
  )
}

function SectionGrouping({ category, data }: any) {
  // data = {key: [values], ...}
  return (
    <Group>
      <GroupTitle>
        <div>{toTitleCase(category)}</div>
        <HorizontalLine />
      </GroupTitle>
      <CategoryContainer>
        {Object.entries(data).map(([k, v]: [string, any]) => {
          if (v?.length > 0) {
            return <SubCategory key={k} title={k} filters={v} />
          }
          return undefined
        })}
      </CategoryContainer>
    </Group>
  )
}

function ValueSpan({ value, isTag = false, isLast = false }: any) {
  const valueFmt = toTitleCase(String(value)) + (!isLast ? ', ' : '')
  return isTag ? (
    <TagSpan value={value}>{valueFmt}</TagSpan>
  ) : (
    <span>{valueFmt}</span>
  )
}

export default function Filters({
  appliedFilters = { chosen_files: null, chosen_audiences: null },
}) {
  const { company, contact, advanced } = filterCategories
  function getExtract(properties: any) {
    let extract = {}
    for (let name of properties) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!!appliedFilters[name]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        extract[name] = appliedFilters[name]
      }
    }
    return extract
  }

  function makeTags(fields: any, title = null, extract = null) {
    let data = extract
    if (!extract) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '[string, unknown][]' is not assignable to ty... Remove this comment to see the full error message
      data = Object.entries(getExtract(fields))
    }

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    return data.map(([k, v], i: any) => {
      if (v?.length > 0) {
        return [
          <br key={k + i} />,
          <span key={v + i}>{title || toTitleCase(k)}</span>,
          ' (',
          ...v.map((item: any, idx: any) => (
            <ValueSpan
              key={i + idx}
              value={item}
              isTag={true}
              isLast={idx === v.length - 1}
            />
          )),
          ')',
        ]
      } else {
        return null
      }
    })
  }

  function convertCheckboxes(fields: any, isArray = false) {
    let newArr = []
    if (isArray) {
      newArr = Object.values(getExtract(fields)).flat()
    } else {
      newArr = Object.keys(getExtract(fields)).flat()
    }

    if (newArr.length > 0) {
      return newArr.map((v, idx) => (
        <ValueSpan key={idx} value={v} isLast={idx === newArr.length - 1} />
      ))
    }
    return []
  }

  let companyData = {
    required_firmographics: null,
    domains_and_keywords: null,
    industry: null,
    revenue: null,
    company_size: null,
    company_location: null,
  }
  let contactData = {
    contact_points: null,
    keywords: null,
    job_function: null,
    seniority: null,
    location_selection: null,
  }
  let advancedData = {
    advanced_required_fields: null,
    file_match: null,
    custom_sql: null,
  }

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element[]' is not assignable to type 'null'.
  companyData.required_firmographics = convertCheckboxes(
    company.required_firmographics
  )
  companyData.domains_and_keywords = makeTags(company.domains_and_keywords).filter(
    (item: any) => item !== null
  )
  companyData.industry = makeTags(company.industry).filter(
    (item: any) => item !== null
  )
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element[]' is not assignable to type 'null'.
  companyData.revenue = convertCheckboxes(company.revenue, true)
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element[]' is not assignable to type 'null'.
  companyData.company_size = convertCheckboxes(company.company_size, true)
  companyData.company_location = makeTags(company.company_location).filter(
    (item: any) => item !== null
  )

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element[]' is not assignable to type 'null'.
  contactData.contact_points = convertCheckboxes(contact.contact_points)
  contactData.keywords = makeTags(contact.keywords).filter(
    (item: any) => item !== null
  )

  /***** Job Functions *****/
  let jobFunctions: any = {}
  const jobFunctionData = {
    b2b_job_sub_functions: [],
    ...getExtract(contact.job_function),
  }

  jobFunctionData.b2b_job_sub_functions.forEach((item: any) => {
    const [key, value] = item
    if (jobFunctions[key]) {
      jobFunctions[key]?.push(value)
    } else {
      jobFunctions[key] = [value]
    }
  })
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'null'.
  contactData.job_function = Object.entries(jobFunctions).map((item) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '[string, unknown][]' is not assi... Remove this comment to see the full error message
    return makeTags(null, null, [item])
  })
  /***** End Job Functions *****/

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element[]' is not assignable to type 'null'.
  contactData.seniority = convertCheckboxes(contact.seniority, true)
  contactData.location_selection = makeTags(contact.location_selection).filter(
    (item: any) => !!item
  )

  advancedData.advanced_required_fields = makeTags(advanced.advanced_required_fields)

  if (appliedFilters.chosen_files) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element[][]' is not assignable to type 'null... Remove this comment to see the full error message
    advancedData.file_match = {
      chosen_files: [],
      ...getExtract(advanced.left_match),
    }.chosen_files.map((item) => {
      const { joins, filter_exclude_matches, file_name } = item
      return [
        <div key={file_name}>
          Input File ({file_name || 'FILE NAME IS MISSING'})
        </div>,
        <div key={filter_exclude_matches}>
          File Match Type ({filter_exclude_matches})
        </div>,
        <div key={(joins[0] as any).file_field}>
          Join Field ({(joins as any).map((item: any) => item.file_field).join(', ')}
          )
        </div>,
      ]
    })
  }
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown[]' is not assignable to type 'null'.
  advancedData.custom_sql = Object.values(getExtract(advanced.custom_sql))

  const { chosen_audiences } = appliedFilters
  return (
    <FiltersContainer>
      <SectionHeader>Audience Filters</SectionHeader>
      <SectionGrouping category={'company'} data={companyData} />
      <SectionGrouping category={'contact'} data={contactData} />
      <SectionGrouping category={'advanced'} data={advancedData} />
      {(chosen_audiences as any)?.length > 0 && (
        <SectionHeader>Audience Layers</SectionHeader>
      )}
      {(chosen_audiences as any)?.map(
        (
          layer: {
            audience: { name: string }
            stacked_type: 'And in' | 'Or in' | 'And not in'
          },
          i: any
        ) => {
          return (
            <SectionGrouping
              category={'Layer ' + (i + 1)}
              data={{
                [layer.audience.name]: [stackOptions[layer.stacked_type]],
              }}
            />
          )
        }
      )}
    </FiltersContainer>
  )
}

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid ${colors.gray6};
  row-gap: 1rem;
`
const SectionHeader = styled.div`
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 500;
`
const Group = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-right: 1rem;
`
const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${colors.gray11};
`
const HorizontalLine = styled.div`
  display: flex;
  flex: 1;
  margin: 0rem 0rem 0rem 1rem;
  border-bottom: 1px solid ${colors.gray6};
`
const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  font-size: 0.75rem;
  color: ${colors.gray8};
`
const SubCategoryContainer = styled.div`
  font-size: 0.75rem;
  color: ${colors.gray8};
`
const SubCategoryTitle = styled.span`
  font-weight: 500;
`
const TagSpan = styled.span<{ value: any }>`
  color: ${(props) => (props.value[0] === '-' ? colors.red7 : colors.green7)};
`
