import React, { useState, useRef } from 'react'
import ChipBasket from './ChipFilter/ChipBasket'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { InputContainer, StyledInput } from './TagInput/Css'
import { AutocompleteLabel } from './StandardAutocomplete/Css'
import { useAlerts } from '../context/alert-context'

interface Props {
  title?: any
  selectedTags?: Array<any>
  addTag?: any
  deleteTag?: any
  placeholder?: any
  tagSuggestions?: any
  LeftAdornment?: any
  RightAdornment?: any
  id?: any
  testId?: any
}

const TagInput: React.FC<Props> = ({
  title = null,
  selectedTags = [],
  addTag,
  deleteTag,
  placeholder = null,
  tagSuggestions = [],
  LeftAdornment = null,
  RightAdornment = null,
  id,
  testId = '',
}) => {
  const [tagValue, setTagValue] = useState('')
  const firstMatchRef = useRef(null)
  const inputRef = useRef(null)
  const { sendAlert } = useAlerts()

  function addAndReset(tag: any, keywordLimit = 100) {
    const _tag = Array.isArray(tag) ? tag : [tag]

    if ([...selectedTags, ..._tag].length > keywordLimit) {
      sendAlert({
        message:
          `Keyword limit of ${keywordLimit} reached, ` +
          `please use a file match for larger data sets.`,
      })
      return
    }
    addTag(_tag)
    setTagValue('')
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    inputRef.current.focus()
  }

  function handleChange(e: any) {
    const _value = e.target.value
    setTagValue(_value)
  }

  function handlePaste(e: any) {
    const pastedTags = e.clipboardData.getData('text')
    const splitTags = pastedTags.split('\n')
    addAndReset(splitTags)
    e.preventDefault()
  }

  function handleKeyDown(e: any) {
    if (e.keyCode === 13 && tagValue) {
      addAndReset(tagValue)
    } else if (e.keyCode === 9 && firstMatchRef.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      firstMatchRef.current.focus()
      e.preventDefault()
    }
  }

  function addRefValue(e: any, tag: any) {
    if (e.keyCode === 13) {
      addAndReset(tag)
    }
    e.preventDefault()
  }

  const MenuItems = tagSuggestions
    .filter((tag: string) => {
      if (tagValue) {
        const regex = new RegExp(tagValue, 'i')
        return !!tag.match(regex)
      }
      return false
    })
    .slice(0, 5)
    .map((tag: string, i: number) => {
      return i === 0 ? (
        <MenuItem
          onClick={() => addAndReset(tag)}
          onKeyDown={(e) => addRefValue(e, tag)}
          ref={firstMatchRef}
          key={i}
        >
          {tag}
        </MenuItem>
      ) : (
        <MenuItem onClick={() => addAndReset(tag)} key={i}>
          {tag}
        </MenuItem>
      )
    })

  return (
    <div style={{ width: '100%' }}>
      {title?.length && <AutocompleteLabel>{title}</AutocompleteLabel>}
      <InputContainer>
        {LeftAdornment && (
          <LeftAdornment style={{ margin: '0rem 0rem 0rem 0.5rem' }} />
        )}
        <StyledInput
          placeholder={placeholder}
          style={{ width: '100%' }}
          ref={inputRef}
          value={tagValue}
          onChange={handleChange}
          onPaste={handlePaste}
          onKeyDown={(e) => handleKeyDown(e)}
          type="search"
          data-cy={testId}
        />
        {RightAdornment && <RightAdornment />}
      </InputContainer>
      {selectedTags?.length > 0 && (
        <ChipBasket handleChipDelete={deleteTag} selectedOptions={selectedTags} />
      )}
      <MenuList disablePadding>{MenuItems}</MenuList>
    </div>
  )
}

export default TagInput
