import NavItem from './NavItem'
import NavButton from './NavButton'
import { ReactComponent as Logo } from '../../../../icons/24px/Layers.svg'
import { colors, typography } from '../../../../context/theme-context/'
import styled from 'styled-components'
const { big } = typography.bodyRegular

const Label = styled.span`
  font-size: ${big.fontSize};
  line-height: ${big.lineHeight};
  font-weight: ${big.fontWeight};
`

export default function AudiencesNav({ selected, smallScreen }: any) {
  const isSelected = ['exports', 'layouts'].includes(selected.top)

  const Content = (
    <NavButton
      selected={isSelected}
      Icon={Logo}
      Label={<Label>Exports</Label>}
      textColor={colors.blue8}
    />
  )

  return (
    <NavItem
      smallScreen={smallScreen}
      to={'/exports'}
      NavContent={Content}
      subNav={[
        { name: 'Recently Exported Files', link: '/exports' },
        {
          name: 'Saved Export Configurations',
          link: '/exports?action=to_saved_exports',
        },
        {
          name: 'Export Configuration',
          link: '/exports?action=create_new_export',
          id: 'exports_upload_new',
        },
        { name: 'New Layout', link: '/layouts' },
        { name: 'Saved Layouts', link: '/layouts?action=to_saved_layouts' },
      ]}
      id="exports"
    />
  )
}
