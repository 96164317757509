import { ListAccordion } from '../../../../../components/Accordion/'
import StandardAutocomplete from '../../../../../components/StandardAutocomplete'
import TagInput from '../../../../../components/TagInput'
import { useAuth } from '../../../../../context/auth-context'

export default function LocationSelection({
  personal_country,
  personal_state,
  updateFilter,
  contact_location_country,
  personal_states,
  contact_location_zip_code,
  divOnly = false,
}: any) {
  const { allDataMode } = useAuth()

  const count =
    (personal_states?.length || 0) + (contact_location_zip_code?.length || 0)

  return (
    <ListAccordion
      bottomBorder={false}
      title="Location"
      data-cy="contactLocation"
      count={count || ''}
      divOnly={divOnly}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <StandardAutocomplete
          label="Country"
          id="contact_country"
          testId="contactLocationCountry"
          placeholder={!allDataMode ? 'United States' : undefined}
          value={contact_location_country}
          tagSuggestions={personal_country}
          disabled={!allDataMode}
          onChange={(_: any, value: any) =>
            updateFilter({ contact_location_country: value })
          }
        />
        <StandardAutocomplete
          label="State"
          id="contact_state"
          testId="contactLocationState"
          value={personal_states}
          placeholder={'Select State'}
          tagSuggestions={personal_state || []}
          onChange={(_: any, value: any) => updateFilter({ personal_states: value })}
        />
        <TagInput
          title="Zip Code"
          placeholder="Enter Zip Code"
          id="contactLocationZipCode"
          testId="contactLocationZipCode"
          selectedTags={contact_location_zip_code}
          addTag={(tag: any) =>
            updateFilter({
              contact_location_zip_code: [
                ...(contact_location_zip_code || []),
                ...tag,
              ],
            })
          }
          deleteTag={(tag: any) =>
            updateFilter({
              contact_location_zip_code: contact_location_zip_code.filter(
                (state: any) => state !== tag
              ),
            })
          }
        />
      </div>
    </ListAccordion>
  )
}
