import React from 'react'
import { useAuth } from './auth-context'

const UserContext = React.createContext(null)

const UserProvider = (props: any) => {
  const { user } = useAuth()
  return <UserContext.Provider value={user} {...props} />
}

interface User {
  name: string
}

// @ts-expect-error ts-migrate(2352) FIXME: Conversion of type 'null' to type 'User' may be a ... Remove this comment to see the full error message
const useUser = (): User => React.useContext(UserContext) as User

export { UserProvider, useUser }
