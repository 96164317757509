import styled, { css } from 'styled-components'
import { typography, colors } from '../../context/theme-context/'

const { bodyRegular } = typography

const CheckboxContainer = styled.span<{ disabled?: boolean; status?: string }>`
  display: flex;
  align-content: center;
  font-size: ${bodyRegular.medium.fontSize};
  line-height: ${bodyRegular.medium.lineHeight};
  font-weight: ${bodyRegular.medium.fontWeight};
  cursor: pointer;
  column-gap: 0.25rem;
  ${(props) => {
    if (!props.disabled) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      if ([false, 'off'].includes(props.status)) {
        return css`
          color: ${colors.gray8};
          :hover {
            color: ${colors.gray9};
            svg > rect {
              stroke: ${colors.gray8};
            }
          }
        `
      } else {
        return css`
          color: ${colors.gray11};
          :hover {
            svg > rect {
              fill: ${[true, 'on'].includes(
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                props.status
              ) && colors.blue7};
            }
            ${props.status === 'partial' &&
            css`
              svg > g > rect {
                fill: ${colors.blue7};
              }
              svg > g > path {
                fill: ${colors.blue3};
              }
            `}
          }
        `
      }
    } else {
      return css`
        pointer-events: none;
        color: ${colors.gray6};
        svg > rect {
          fill: ${[true, 'on'].includes(
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            props.status
          ) && colors.gray6};
        }
        svg > g > path {
          fill: ${colors.gray7};
        }
        ${props.status === 'partial' &&
        css`
          svg > g > rect {
            fill: ${colors.gray7};
          }
          svg > g > path:nth-child(2) {
            fill: ${colors.gray6};
          }
        `};
      `
    }
  }}
`

export { CheckboxContainer }
