import SavedLayouts from './Layouts/SavedLayoutTable'
import LayoutMaker from './Layouts/LayoutMaker'
import { useEffect, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useAuth } from 'context/auth-context'
import { useLocation } from 'react-router-dom'
import { colors } from 'context/theme-context/'
import StandardDialog from 'components/StandardDialog'
import Button from 'components/Button'
import { toTitleCase } from 'utils/caseHelpers'
import { ReactComponent as Logo } from 'icons/24px/Upload.svg'
import UploadLayoutDialog from './Layouts/UploadLayoutDialog'
import { useAlerts } from 'context/alert-context'

export default function Layouts() {
  const { sendAlert } = useAlerts()
  const { client } = useAuth()
  const { layoutsIdAPI } = client.endpoints
  const [initialLayout, setInitialLayout] = useState()
  const [currentLayout, setCurrentLayout] = useState({
    hashed: '',
    id: null,
    fields: null,
    chosen_revenue_share: false,
    name: null,
    chosen_field_package: 'all',
    privacy: 'team_view',
    editable: false,
  })
  const [isOpen, setIsOpen] = useState(false)
  const [isUploadLayoutOpen, setIsUploadLayoutOpen] = useState(false)
  const [saveIsOpen, setSaveIsOpen] = useState(false)
  const [renderLayoutPage, setRenderLayoutsPage] = useState(false)
  const [tableData, setTableData] = useState(Array<any>())

  const location = useLocation()
  const parser = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])

  useEffect(() => {
    const action = parser.get('action')
    if (action === 'to_saved_layouts') {
      setIsOpen(true)
    }

    async function getInitialLayout(hash: any) {
      const { responseData: savedLayout } = await client.get({
        endpoint: layoutsIdAPI(hash),
      })
      setInitialLayout(savedLayout.results)
    }

    const hash = parser.get('layout')
    if (hash) {
      getInitialLayout(hash)
    }
  }, [location, client, layoutsIdAPI, parser])

  useEffect(() => {
    if (renderLayoutPage) {
      setRenderLayoutsPage(false)
    }
  }, [renderLayoutPage])

  const Content = (
    <div style={{ width: '800px' }}>
      <SavedLayouts handleClose={() => setIsOpen(false)} />
    </div>
  )

  const { name, fields, privacy } = currentLayout
  const LayoutName = name
    ? `${name} (${(fields as any)?.total_count} Fields, ${toTitleCase(privacy)})`
    : 'Untitled Layout'

  const validateSave = () => {
    // We want to make sure that the user has added at least one field to their layout
    tableData.length === 0
      ? sendAlert({ message: 'Please add at least one field to your layout' })
      : setSaveIsOpen(true)
  }
  return (
    <LayoutsContainer>
      <Header>
        <div id="layout_name" data-cy="layoutName">
          {LayoutName}
        </div>
        <div>
          <Button
            onClick={validateSave}
            text="Save"
            variant="secondary"
            size="medium"
            id="save"
            testId="saveLayout"
          />
        </div>
        <ButtonContainer>
          <a href="/layouts" style={{ textDecoration: 'none' }}>
            <LRButton left={true} data-cy="newLayout">
              New
            </LRButton>
          </a>
          <LRButton
            id="open_saved_button"
            onClick={() => setIsOpen(true)}
            middle={true}
            data-cy="openSavedLayout"
          >
            Open Saved
          </LRButton>
          <LRButton
            id="upload_layout_file"
            right={true}
            onClick={() => setIsUploadLayoutOpen(true)}
            style={{ display: 'flex', padding: '0.36rem 0.5rem' }}
            data-cy="uploadLayoutFile"
          >
            <Logo style={{ width: '20px', height: '20px' }} />
          </LRButton>
        </ButtonContainer>
      </Header>
      <LayoutMaker
        currentLayout={currentLayout}
        setCurrentLayout={setCurrentLayout}
        initialLayout={initialLayout}
        saveIsOpen={saveIsOpen}
        setSaveIsOpen={setSaveIsOpen}
        setTableData={setTableData}
        tableData={tableData}
      />
      <StandardDialog
        isOpen={isOpen}
        Content={Content}
        title="Saved Layouts"
        handleClose={() => setIsOpen(false)}
      />
      <UploadLayoutDialog
        isOpen={isUploadLayoutOpen}
        setIsOpen={setIsUploadLayoutOpen}
      />
    </LayoutsContainer>
  )
}

const LayoutsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  overflow-y: scroll;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  cursor: pointer;
  column-gap: 0.75rem;
  padding: 0.75rem 0rem 0rem;
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${colors.blue8};
  align-items: center;
`

const ButtonContainer = styled.div`
  color: ${colors.blue9};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
`
const LRButton = styled.div<{ left?: boolean; middle?: boolean; right?: boolean }>`
  padding: 0.25rem 0.5rem;
  color: ${colors.gray8};
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  white-space: nowrap;
  cursor: pointer;
  :hover {
    color: ${colors.blue8};
    background-color: ${colors.blue2};
    border: 1px solid ${colors.blue3};
  }
  :active {
    background-color: ${colors.blue3};
    border: 1px solid ${colors.blue4};
  }
  ${(props) =>
    props.left &&
    css`
      border-radius: 0.25rem 0rem 0rem 0.25rem;
      border-right: 0;
      :hover {
        border-right: 0;
      }
    `};
  ${(props) =>
    props.middle &&
    css`
      border-right: 0;
      :hover {
        border-right: 0;
      }
    `};
  ${(props) =>
    props.right &&
    css`
      border-radius: 0rem 0.25rem 0.25rem 0rem;
    `};
`
