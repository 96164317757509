import React from 'react'
import Alert from 'components/Alert'
import analytics from 'analytics/TagManager'
import Authentication from 'App/Authentication'
import Footer from 'App/SmartHub/Footer'
import SmartHub from 'App/SmartHub'
import styles from './App/App.module.css'
import { useAuth } from 'context/auth-context'
import { useLocation } from 'react-router-dom'
import { useAlerts } from 'context/alert-context'

const App = () => {
  const location = useLocation()
  const { userData, loggedIn } = useAuth() as any
  const { state: alertState, close } = useAlerts()

  React.useEffect(() => {
    analytics.capturePageview(location.pathname)
  }, [location.pathname])

  React.useEffect(() => {
    analytics.captureUserData(userData)
  }, [userData])

  const Component = loggedIn ? <SmartHub /> : <Authentication />
  return (
    <div className={styles.appContainer}>
      <div className={styles.bodyContainer}>{Component}</div>
      <div className={styles.footerContainer}>
        <Footer />
      </div>
      <Alert
        message={alertState.message}
        alertIsOpen={alertState.alertIsOpen}
        handleClose={close}
        handleOk={alertState?.handleOk}
      />
    </div>
  )
}

export default App
