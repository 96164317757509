// The D&B Password Standard requires the following:
// Not be the same as or contain the user ID
// Contain a minimum of 10 (ten) characters
// Contain at least 3 (three) of the following 4 (four) attributes
//  - Alphabetic Characters
//  - Numeric Characters
//  - Combination of UPPER and lower case letters
//  - Special Characters (e.g. £!&)
// -------------------------------------------------------------------------------------
// Passwords must be changed periodically
// - The period should be defined based on the sensitivity of the data being protected
// - Interactive internal user passwords must expire at least every ninety (90) days.
// - Passwords should be validated against the last 10 previous passwords
// -------------------------------------------------------------------------------------

export type Compliance = {
  compliant: boolean
  reason: string
}

type PasswordSet = {
  username?: string
  password: string
}

const doesNotContainUsername = ({
  username,
  password,
}: {
  username: string
  password: string
}): Compliance => {
  const regex = new RegExp(username, 'i')
  return {
    compliant: !regex.test(password),
    reason: 'Username / ID must not be found in password',
  }
}

const hasTenCharactersMinimum = (password: string): Compliance => ({
  compliant: /.{10,}/.test(password),
  reason: 'Password must contain at least 10 characters',
})

const hasAlphabeticCharacters = (password: string): Compliance => ({
  compliant: /[aA-zZ]/.test(password),
  reason: 'Password must contain alphabetical characters',
})

const hasNumericCharacters = (password: string): Compliance => ({
  compliant: /[0-9]/.test(password),
  reason: 'Password must contain numerical characters',
})

const hasUpperAndLowerCase = (password: string): Compliance => {
  const result = /(?=.*[A-Z])(?=.*[a-z])/.test(password)
  return {
    compliant: result,
    reason: 'Password must have both upper and lower-case letters',
  }
}

const hasSpecialCharacters = (password: string): Compliance => ({
  compliant: /[^aA-zZ0-9\s:]/.test(password),
  reason: 'Password must contain at least 1 special character',
})

function passwordIsCompliant({ username, password }: PasswordSet): Compliance {
  // Username may not be present when checking password.
  // Such is the case in ResetPasswordScreen when a user is setting a password for the first time.
  if (username) {
    const usernameCheck = doesNotContainUsername({ username, password })
    if (!usernameCheck.compliant) {
      return {
        compliant: usernameCheck.compliant,
        reason: usernameCheck.reason,
      }
    }
  }

  const lengthCheck = hasTenCharactersMinimum(password)
  if (!lengthCheck.compliant) {
    return {
      compliant: lengthCheck.compliant,
      reason: lengthCheck.reason,
    }
  }

  const checks = [
    hasAlphabeticCharacters,
    hasNumericCharacters,
    hasUpperAndLowerCase,
    hasSpecialCharacters,
  ]

  let failingChecks = []
  for (let check of checks) {
    const completedCheck = check(password)
    if (!completedCheck.compliant) {
      failingChecks.push(completedCheck)
    }
  }

  if (failingChecks.length > 1) {
    return {
      compliant: false,
      reason: failingChecks[0].reason,
    }
  }

  return {
    compliant: true,
    reason: 'Password compliant',
  }
}

export default passwordIsCompliant
export {
  hasAlphabeticCharacters,
  hasNumericCharacters,
  hasSpecialCharacters,
  hasTenCharactersMinimum,
  hasUpperAndLowerCase,
}
