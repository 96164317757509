export function preventTextInput(
  incomingText: string,
  forbiddenCharacters: Array<string> | RegExp,
  textUpdateFunction: () => any
) {
  // Track whether forbidden characters are present
  let containsForbiddenCharacters = false

  // If the forbidden characters are an array
  if (Array.isArray(forbiddenCharacters)) {
    const hasChars = forbiddenCharacters.reduce(
      (pv, cv): boolean => incomingText.includes(cv) || pv,
      false
    )
    containsForbiddenCharacters = hasChars
  }

  // If the forbidden characters are a regex
  else {
    containsForbiddenCharacters = forbiddenCharacters.test(incomingText)
  }

  // If a forbidden character is detected, return.
  // Else, execute the function
  if (containsForbiddenCharacters) return
  else textUpdateFunction()
}
