/***********************************************************/
/* Description: Note which represents an addable DB entry */
/*    ---------------- 
/* () - Example Note - 
/*    ---------------- 
/***********************************************************/
import React from 'react'
import ExpandingButton from 'components/ExpandingButton'
import { StyledTextareaAutosize } from 'components/TextareaAutosize'
import { colors } from 'context/theme-context'

interface Props {
  text: string
  updateText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  submit: () => void
}
export default function AddableNote(props: Props) {
  const inputRef = React.useRef(null)
  let textColor = colors.green5

  if (props.text.length > (256 * 0.75)) textColor = colors.orange5
  if (props.text.length > (256 * 0.90)) textColor = colors.red5

  function handleClick() {
    if (props.text) {
      props.submit()
      if (inputRef.current) {
        const elem = (inputRef.current as unknown) as HTMLTextAreaElement
        elem.value = ''
      }
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '1rem',
        margin: '1rem',
        minHeight: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <ExpandingButton
          text="SUBMIT"
          backgroundColor={colors.green4}
          hoverBackgroundColor={'white'}
          activeBackgroundColor={colors.green5}
          borderColor={colors.green6}
          onClick={handleClick}
          disableExpand={true}
          data-cy="addNote"
        />
      </div>
      <div style={{ display: 'flex', flex: 10 }}>
        <div>
          <StyledTextareaAutosize
            ref={inputRef}
            value={props.text}
            placeholder={'Write note here'}
            onChange={props.updateText}
            style={{ width: 600, height: 200 }}
            data-cy="note"
          />
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <span style={{ color: textColor }}>{props.text.length}/256</span>
          </div>
        </div>
      </div>
    </div >
  )
}
