import { useState, useEffect } from 'react'
import Accordion from '../../../components/Accordion'
import RemoteDataTable from '../../../components/RemoteDataTable'
import makeTableTheme from '../../../components/DataTable/theme'
import { useAuth } from '../../../context/auth-context'
import Button from '../../../components/Button'
import BasicSelect from '../../../components/BasicSelect'
import UploadButton from './ExactMatch/UploadButton'
import { colors } from '../../../context/theme-context'
import { ReactComponent as Logo } from '../../../icons/24px/People.svg'
import { BiGroup, BiShow, BiEditAlt, BiLockAlt } from 'react-icons/bi'
import { numericHumanReadable } from '../../../utils/numericHelpers'
import { ConditionalTooltip } from '../../../components/Tooltip'
import { useAlerts } from '../../../context/alert-context'
import { useSnackbar } from 'context/snackbar-context'

const MAX_CELL_WIDTH = 35
const [theme, disabledTheme] = makeTableTheme({ maxCellWidth: 'none' })

export default function UploadedFiles({
  setPreviewFileId,
  makeInitialMatchState,
  refreshCounter,
  setIsOpen,
  uploadsExpanded,
}: any) {
  const { client, adminMode } = useAuth()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage } = useSnackbar()
  const { filesAPI, filesIdAPI } = client.endpoints
  const [, setRefreshCount] = useState(0)

  useEffect(() => {
    async function incrementRefreshCount() {
      setRefreshCount((state) => state + 1)
    }
    incrementRefreshCount()
  }, [refreshCounter, setRefreshCount])

  const TextWithIcon = (text: any, icon: any) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '0.25rem',
        }}
      >
        {icon}
        {text}
      </div>
    )
  }

  const customRowRender = (data: any) => {
    const customData = data.map((row: any) => {
      const {
        status,
        formatted_name,
        rows: row_count,
        editable,
        privacy,
        hashed,
        id,
        created: {
          timestamp: { local: created_date },
          user: { first_name, last_name },
        },
      } = row
      row.formatted_name = (
        <ConditionalTooltip
          text={formatted_name}
          maxLength={MAX_CELL_WIDTH}
          render={() => formatted_name}
        />
      )
      row.saved_time_data_order = created_date
      row.user = `${last_name}, ${first_name}`
      row.row_count = numericHumanReadable(row_count, 0)
      row.privacyInput = (
        <BasicSelect
          selectValue={privacy || 'team_view'}
          items={[
            {
              value: 'private',
              text: TextWithIcon('Private', <BiLockAlt />),
            },

            {
              value: 'team_view',
              text: TextWithIcon('Team View', <BiGroup />),
            },

            {
              value: 'team_edit',
              text: TextWithIcon('Team Edit', <BiEditAlt />),
            },
          ]}
          onChange={(e: any) => updateFileData(id, e.target.value)}
          style={{ maxWidth: '25rem' }}
          disabled={!editable}
        />
      )

      const color = ['Uploaded', 'Uploading'].includes(status)
        ? colors.green8
        : status === 'Staged'
        ? colors.yellow8
        : colors.red8

      row.styledStatus = <span style={{ color }}>{status}</span>

      row.create = status === 'Uploaded' && (
        <Button
          text="Create Audience"
          variant="secondary"
          size="medium"
          icon={() => <Logo height={24} width={24} />}
          onClick={() => createDerivedAudience(hashed)}
          testId="createAudienceFromUploadButton"
        />
      )

      row.preview = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '0.5rem',
          }}
        >
          <div>
            <Button
              text=""
              size="medium"
              icon={() => <BiShow size={16} />}
              iconProps={{ style: { margin: 0 } }}
              variant="secondary"
              disabled={status !== 'Uploaded'}
              onClick={() => {
                setPreviewFileId(id)
                setIsOpen(true)
              }}
            />
          </div>
        </div>
      )
      return row
    })
    return customData
  }

  function createDerivedAudience(uuid: any) {
    const file = null
    try {
      makeInitialMatchState({
        dialogIsOpen: true,
        pctComplete: 1,
        file_uuid: uuid,
        fileName: (file as any)?.formatted_name,
        fileAlreadyUploaded: true,
        isCreatingDerivedAudience: true,
        hasDropped: true,
      })
    } catch (e) {
      console.log(e)
      sendAlert({
        message: 'Could not create audience. Please try again later',
      })
    }
  }

  async function updateFileData(file_id: any, privacy: any) {
    try {
      await client.put({
        endpoint: filesIdAPI(file_id),
        data: { privacy: privacy },
      })
      setRefreshCount((state) => state + 1)
    } catch (e) {
      sendAlert({
        message: 'Could not update file privacy. Please try again later.',
      })
    }
  }

  return (
    <div>
      <Accordion
        title="Uploaded Files"
        expanded={uploadsExpanded}
        Widgets={
          <UploadButton
            id="upload_button_uploaded_files"
            testId="uploadButtonUploadedFiles"
            clickHandler={() => makeInitialMatchState({ dialogIsOpen: true })}
          />
        }
      >
        <div style={{ paddingTop: '1rem' }}>
          <RemoteDataTable
            client={client}
            adminMode={adminMode}
            sendAlert={sendAlert}
            setSnackbarMessage={setSnackbarMessage}
            initialSortOrder={'-modified'}
            title="uploadedFiles"
            copyControl={false}
            csvControl={false}
            excelControl={false}
            expandableRows={false}
            deleteControl={true}
            selectableRows="multiple"
            customRowRender={customRowRender}
            url={filesAPI}
            urlParams={{ include_pending: true }}
            deleteAPI={filesIdAPI}
            theme={theme}
            disabledTheme={disabledTheme}
            columns={[
              { name: 'formatted_name', label: 'Name' },
              {
                name: 'create',
                label: 'Create Audience',
                options: { sort: false },
              },
              { name: 'saved_time_data_order', label: 'Last Updated' },
              { name: 'user', label: 'Created By' },
              { name: 'row_count', label: 'Records' },
              { name: 'styledStatus', label: 'Status' },
              { name: 'privacyInput', label: 'Privacy' },
              { name: 'preview', label: 'Preview', options: { sort: false } },
            ]}
            columnOrderMap={{
              formatted_name: 'formatted_name',
              saved_time_data_order: 'modified',
              user: ['created_by__last_name', 'created_by__first_name'],
              row_count: 'row_count',
              styledStatus: 'status',
              privacyInput: 'privacy',
            }}
          />
        </div>
      </Accordion>
    </div>
  )
}
