import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { colors } from '../../context/theme-context'
import { debounce } from '../../utils/debounce'
import SimpleInput from '../SimpleInput'
import { ReactComponent as SearchIcon } from '../../icons/16px/Search.svg'
import ClientDataTable from '../ClientDataTable'
import Button from '../Button'
import { useAuth } from '../../context/auth-context'
import { RowDiv, InputSpan } from '../GeneralSection/GeneralSectionGeneric'
import { LabelSpan } from '../GeneralSection/GeneralSectionGeneric'
import SimpleAutoComplete from 'components/SimpleAutoComplete'

interface LayoutFieldQuickI {
  exportState: any
  setExportState: any
  disabled?: boolean
  layouts?: any
  chosenExport: any
}

const LayoutFieldCreate: React.FC<LayoutFieldQuickI> = (props) => {
  const { exportState, setExportState, layouts, disabled, chosenExport } = props
  const { client } = useAuth()
  const { layoutsAPI, layoutsIdAPI } = client.endpoints

  const [search, setSearch] = useState()
  const debouncedSearch = debounce(setSearch, 200)
  const [data, setData] = useState([])
  const [previewState, setPreviewState] = useState(false)

  const handleTableData = useCallback(
    async (layoutHash) => {
      if (layoutHash) {
        try {
          const { responseData } = await client.get({
            endpoint: layoutsIdAPI(layoutHash),
          })
          const {
            results: {
              fields: { available: saved_fields },
            },
          } = responseData
          setData(saved_fields)
        } catch (e) {
          console.log('Could not retrieve table data for layout.')
        }
      }
    },
    [client, layoutsIdAPI, setData]
  )

  const handleTogglePreviewState = () => {
    setPreviewState(!previewState)
    if (!previewState) {
      handleTableData(exportState.layout)
    }
  }

  return (
    <>
      <RowDiv>
        <LabelSpan>Layout</LabelSpan>
        <InputSpan>
          <SimpleAutoComplete
            client={client}
            data-cy="export_layout_autocomplete"
            disabled={disabled}
            optionsUrl={layoutsAPI}
            valueField="hashed"
            labelField="name"
            additionalApiParameters={{
              sort: ['-last_used', '-modified'],
            }}
            placeholder="Select Layout"
            value={exportState.layout}
            onChange={(value: any) => {
              setExportState((state: any) => ({
                ...state,
                layout: value,
              }))
              handleTableData(value)
            }}
          />
          {!exportState.layout ? undefined : previewState ? (
            <Button
              text="Hide"
              variant="secondary"
              size="medium"
              onClick={() => handleTogglePreviewState()}
              style={{ marginLeft: '0.5rem' }}
              testId="hide"
            />
          ) : (
            <Button
              text="Preview"
              variant="secondary"
              size="medium"
              onClick={() => handleTogglePreviewState()}
              style={{ marginLeft: '0.5rem' }}
              testId="preview"
            />
          )}
        </InputSpan>
      </RowDiv>

      {exportState.layout && previewState && (
        <ContainerDiv>
          <SearchDiv>
            <SimpleInput
              LeftAdornment={SearchIcon}
              placeholder={'Search fields'}
              defaultValue=""
              onClick={(e: any) => e.stopPropagation()}
              onChange={(e: any) => {
                debouncedSearch(e.target.value)
              }}
              containerProps={{
                style: { backgroundColor: 'white' },
              }}
            />
          </SearchDiv>
          <ClientDataTable
            title="layoutFieldCreate"
            searchText={search}
            controls={false}
            columns={[
              { name: 'field_name', label: 'Field Name' },
              { name: 'export_name', label: 'Export Name' },
            ]}
            data={data}
          />
        </ContainerDiv>
      )}
    </>
  )
}

const ContainerDiv = styled.div`
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  border-radius: 0.25rem;
`

const SearchDiv = styled.div`
  padding: 0.5rem;
`

export default LayoutFieldCreate
