/***********************************************************/
/* Description: Note which represents a deletable DB entry */
/*    ---------------- 
/* () - Example Note - 
/*    ---------------- 
/***********************************************************/
import React from 'react'
import { colors } from 'context/theme-context'
import ExpandingButton from 'components/ExpandingButton'

interface Props {
  text: string
  author: string
  date: string
  deleteHandler: () => void
}
export default function DeletableNote(props: Props) {
  // Have to use reference here because updating state causes a re-mounting of the dom element
  // and prevents the correct animation.
  const noteRef = React.useRef(null)

  function strikeText() {
    if (noteRef.current) {
      const aRef = (noteRef.current as unknown) as HTMLElement
      aRef.style.textDecoration = 'line-through'
    }
  }

  function unStrikeText() {
    if (noteRef.current) {
      const aRef = (noteRef.current as unknown) as HTMLElement
      aRef.style.textDecoration = 'none'
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '1rem',
        margin: '1rem',
        minHeight: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: 1,
          columnGap: '1rem',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          <ExpandingButton
            text="DELETE"
            backgroundColor={colors.red4}
            hoverBackgroundColor={'white'}
            activeBackgroundColor={colors.red5}
            borderColor={colors.red6}
            onMouseOver={strikeText}
            onMouseLeave={unStrikeText}
            onClick={() => props.deleteHandler()}
            disableExpand={true}
            data-cy="deleteNote"
          />
        </div>
        <div style={{ display: 'flex', flex: 10 }}>
          <div style={{ display: 'flex', flex: 1 }} ref={noteRef} data-cy="noteText">
            {props.text}
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', flex: 1 }}
            data-cy="noteAuthor"
          >
            {props.author}
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', flex: 1 }}
            data-cy="noteDate"
          >
            {props.date}
          </div>
        </div>
      </div>
    </div>
  )
}
