import { createContext, useContext, useState } from 'react'

interface AlertState {
  message: string
  handleOk: () => any
  alertIsOpen: boolean
}

export interface AlertContextType {
  state: AlertState
  sendAlert: ({
    message,
    handleOk,
  }: {
    message: string
    handleOk?: () => any
  }) => any
  close: () => any
}

const AlertContext = createContext<AlertContextType>({
  state: {
    message: '',
    handleOk: () => null,
    alertIsOpen: false,
  },
  sendAlert: () => null,
  close: () => null,
})

const AlertProvider = (props: any) => {
  const [state, setState] = useState<AlertState>({
    message: '',
    handleOk: () => null,
    alertIsOpen: false,
  })

  const sendAlert = ({ message = '', handleOk = () => null }) => {
    setState({ message, handleOk, alertIsOpen: true })
  }

  const close = () => {
    setState((state) => ({ ...state, alertIsOpen: false }))
  }

  return <AlertContext.Provider value={{ state, sendAlert, close }} {...props} />
}

const useAlerts = () => useContext(AlertContext)

export { AlertProvider, useAlerts }
