import Button from '../../components/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../Logo.svg'

export default function AuthPage({ title, children, containerProps = {} }: any) {
  return (
    <OuterContainer>
      <Container component="main" maxWidth="xs" {...containerProps}>
        <LoginContainer>
          <AvatarContainer>
            <Logo height={48} width={48} />
          </AvatarContainer>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          <FormContainer noValidate>{children}</FormContainer>
        </LoginContainer>
      </Container>
    </OuterContainer>
  )
}

export { SubmitButton, FormContainer }
const OuterContainer = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: space-between;
  flex-direction: column;
`
const LoginContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const AvatarContainer = styled.div`
  margin: 1em;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
`

const SubmitButton = styled(Button)`
  margin: 0em 0em;
`
