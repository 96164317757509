const getHost = () => {
  const protocol = process.env.NODE_ENV === 'production' ? 'wss://' : 'ws://' 
  const host = window?.location ? window.location.hostname : process.env.REACT_APP_ENV
  return protocol + host
}

const getPort = () => {
  const port = process.env.REACT_APP_ENV === 'localhost' ? ':8000' : ''
  return port
}

const getWsURLPrefix = () => {
  const host = getHost()
  const port = getPort()
  return host + port + '/ws/'
}

export default getWsURLPrefix
