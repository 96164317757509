import styled from 'styled-components'
import StandardDialog from './StandardDialog'
import Button from './Button'
import GeneralSectionQuick from './QuickExportDialog/GeneralSectionQuick'
import LimitsAndOrderingSection from './ExportDialog/LimitsAndOrderingSection'
import FileConfigurationSection from './ExportDialog/FileConfigurationSection'
import { useAuth } from '../context/auth-context'
import { useEffect, useState } from 'react'
import { useAlerts } from 'context/alert-context'
import RequestUserConfirmation from './RequestUserConfirmation'

type Props = {
  isOpen: any
  handleExit: any
  appliedFilters: any
  dispatch: any
  audienceInfo: any
  submissionCallback: any
}

export default function ExportDialog(props: Props) {
  const { client, userData } = useAuth()
  const {
    layoutsAPI,
    prioritizeFieldsAPI,
    partitionFieldsAPI,
    executionsAPI,
    enableAppendsAPI,
  } = client.endpoints

  const [layoutList, setLayoutList] = useState([])
  const [prioritizeFields, setPrioritizeFields] = useState([])
  const [partitionFields, setPartitionFields] = useState([])
  const [showAppendsCheckbox, setShowAppendsCheckbox] = useState(false)
  const [submitTrigger, setSubmitTrigger] = useState(false)
  const { sendAlert } = useAlerts()

  type QuickForm = {
    file_name: string
    email_notifications: string[]
    layout: string
    record_limit: string
    record_limit_per_company: string
    prioritize_by: string[]
    partition_by: string[]
    delimiter: string
    strip_delimiter_and_quotes: boolean
    quotes: boolean
    compression: string
    header: boolean
    enable_appends: boolean
  }
  const initiQuickFormState: QuickForm = {
    file_name: '',
    email_notifications: [],
    layout: '',
    record_limit: '',
    record_limit_per_company: '',
    prioritize_by: [],
    partition_by: ['company_id'],
    delimiter: 'comma_standard',
    strip_delimiter_and_quotes: false,
    quotes: false,
    compression: 'none',
    header: true,
    enable_appends: false,
  }
  const [quickExportForm, setQuickExportForm] = useState(initiQuickFormState)

  const triggerSubmit = () => {
    setSubmitTrigger(!submitTrigger)
  }

  async function submitQuickExport() {
    if (
      quickExportForm.file_name === null ||
      quickExportForm.file_name.trim() === ''
    ) {
      sendAlert({ message: 'File name is required' })
      return
    }
    if (quickExportForm.file_name.includes('/')) {
      sendAlert({ message: 'File name cannot include a slash character'})
      return
    }

    try {
      const payload = {
        additional_audience: props.audienceInfo.audience_id
          ? {
              ...props.appliedFilters,
              audience_id: props.audienceInfo.audience_id,
              audience_name: props.audienceInfo.audience_name,
            }
          : props.appliedFilters,
        settings: {
          file_name: quickExportForm.file_name,
          layout: quickExportForm.layout,
          email_notifications:
            quickExportForm.email_notifications == null
              ? []
              : quickExportForm.email_notifications,
          record_limit: quickExportForm.record_limit
            ? quickExportForm.record_limit
            : null,
          record_limit_per_company: quickExportForm.record_limit_per_company
            ? quickExportForm.record_limit_per_company
            : null,
          partition_by: quickExportForm.partition_by,
          prioritize_segments: quickExportForm.prioritize_by,
          delimiter: quickExportForm.delimiter,
          quotes: quickExportForm.quotes,
          strip_delimiter_and_quotes: quickExportForm.strip_delimiter_and_quotes,
          compression: quickExportForm.compression,
          header: quickExportForm.header,
          jira_ticket: null,
          enable_appends: quickExportForm.enable_appends,
        },
      }

      await client.post({ endpoint: executionsAPI, data: payload })

      props.dispatch({ payload: { renderExportAudience: true } })

      // refresh the layout list so we get what was last used if they run another
      triggerSubmit()
    } catch (e) {
      console.log('Could not run quick export', e)
    }

    props.handleExit()
    // reset export form state, as autocomplete fields are cached
    setQuickExportForm(initiQuickFormState)
    props.submissionCallback()
  }

  useEffect(() => {
    // the layouts are only triggered here because we need a default value for
    // the quick export; we get a few values and pick the first to act as default
    async function getLayoutList() {
      try {
        const { responseData: _layoutData } = await client.get({
          endpoint: layoutsAPI,
          data: {
            size: 5,
            sort: ['-last_used', '-modified'],
            dropdown_view: true,
          },
        })
        const _items = _layoutData.results.map((result: any) => result.hashed)
        setLayoutList(_items)
      } catch (e) {
        console.log(e)
      }
    }
    getLayoutList()
  }, [client, layoutsAPI, submitTrigger])

  useEffect(() => {
    async function getPrioriizeFields() {
      try {
        const { responseData } = await client.get({
          endpoint: prioritizeFieldsAPI,
          data: { name_only: 1 },
        })
        const { results } = responseData
        setPrioritizeFields(results)
      } catch (e) {
        console.log(e)
      }
    }
    getPrioriizeFields()
  }, [client, prioritizeFieldsAPI])

  useEffect(() => {
    async function getPartitionFields() {
      try {
        const { responseData } = await client.get({
          endpoint: partitionFieldsAPI,
          data: { name_only: 1 },
        })
        const { results } = responseData
        setPartitionFields(results)
      } catch (e) {
        console.log(e)
      }
    }
    getPartitionFields()
  }, [client, partitionFieldsAPI])

  useEffect(() => {
    async function getEnableAppends() {
      try {
        const { responseData } = await client.post({
          endpoint: enableAppendsAPI,
          data: {
            additional_audience: {
              chosen_files: props.appliedFilters?.chosen_files,
              chosen_audiences: props.appliedFilters?.chosen_audiences,
            },
            settings: {
              layout: quickExportForm.layout,
            },
          },
        })
        const { should_enable_appends } = responseData
        setShowAppendsCheckbox(should_enable_appends)
      } catch (e) {
        console.log('Could not get list of enable appends')
      }
    }
    if (props.isOpen && quickExportForm.layout) {
      getEnableAppends()
    }
  }, [
    client,
    enableAppendsAPI,
    props.appliedFilters?.chosen_files,
    props.appliedFilters?.chosen_audiences,
    quickExportForm.layout,
    props.isOpen,
  ])

  const Title = (
    <TitleSpan>
      <span>Quick Export</span>
    </TitleSpan>
  )

  const Content = (
    <ContentDiv>
      <GeneralSectionQuick
        exportState={quickExportForm}
        setExportState={setQuickExportForm}
        layouts={layoutList}
        audienceInfo={props.audienceInfo}
        showAppendsCheckbox={showAppendsCheckbox}
      />
      <LimitsAndOrderingSection
        exportState={quickExportForm}
        setExportState={setQuickExportForm}
        prioritizeFields={prioritizeFields}
        partitionFields={partitionFields}
      />
      <FileConfigurationSection
        delimiter={quickExportForm.delimiter as any}
        stripDelimiterAndQuotes={quickExportForm.strip_delimiter_and_quotes}
        quotes={quickExportForm.quotes}
        compression={quickExportForm.compression as any}
        header={quickExportForm.header}
        setExportState={setQuickExportForm}
        client={client}
        dialogType="quick"
      />
    </ContentDiv>
  )

  return (
    <StandardDialog
      isOpen={props.isOpen}
      title={Title}
      Content={Content}
      handleClose={props.handleExit}
      dialogStyle={{ padding: '1rem' }}
      ActionButtons={
        <RequestUserConfirmation
          text={
            'This export will likely incur credit usage. Please click confirm to continue:'
          }
          Component={
            <Button
              text="Export"
              id="run_quick_export"
              variant="primary"
              size="large"
              onClick={submitQuickExport}
              style={{ margin: '0.5rem' }}
              testId="exportQuickExport"
            />
          }
          requestTrigger={userData.credits !== null}
        />
      }
    />
  )
}

const ContentDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 640px;
`

const TitleSpan = styled.span``
