import Skeleton from 'react-loading-skeleton'
import typography from '../../context/theme-context/typography'
import colors from '../../context/theme-context/colors'
import { useMediaSize } from '../../context/mediaQuery-context'

const { bodyRegular } = typography

function Counts({
  countNumber,
  countTitle,
  filtersHaveUpdated,
  dataReady,
  subtitleStyle = {},
}: any) {
  let color = filtersHaveUpdated ? colors.blue8 : colors.gray8
  const { verySmallScreen } = useMediaSize()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '0.3' }}>
      {countNumber != null && dataReady ? (
        <>
          <div
            style={{
              fontWeight: 500,
              fontSize: verySmallScreen ? '1.1rem' : '1.375rem',
              lineHeight: '2rem',
              color: color,
            }}
          >
            {countNumber.toLocaleString()}
          </div>
        </>
      ) : (
        <Skeleton
          height={'2rem'}
          width={'10.8rem'}
          style={{ borderRadius: '0.5rem' }}
        />
      )}

      <div
        style={{
          fontWeight: `${bodyRegular.medium.fontWeight}`,
          fontSize: `${bodyRegular.medium.fontSize}`,
          lineHeight: `${bodyRegular.medium.lineHeight}`,
          color: `${colors.gray8}`,
          ...subtitleStyle,
        }}
      >
        {countTitle}
      </div>
    </div>
  )
}

export default Counts
