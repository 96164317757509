import Button from '../../../components/Button'
import RemoteDataTable from '../../../components/RemoteDataTable'
import { useAuth } from '../../../context/auth-context'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { colors } from '../../../context/theme-context'
import { ConditionalTooltip } from '../../../components/Tooltip'
import { useAlerts } from 'context/alert-context'
import { useSnackbar } from 'context/snackbar-context'

const MAX_CELL_WIDTH = 50

function SavedLayouts({ handleClose }: any) {
  const { client, adminMode } = useAuth()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage } = useSnackbar()
  const { layoutsAPI, layoutsIdAPI, layoutsIdAPIClone } = client.endpoints
  const history = useHistory()

  async function cloneLayout(hashed: any) {
    try {
      const { responseData: savedLayout } = await client.post({
        endpoint: layoutsIdAPIClone(hashed),
      })
      const link = `/layouts?layout=${savedLayout.results.hashed}`
      history.replace(link)
      handleClose()
    } catch (e) {
      console.log('Could not clone a layout: ', e)
    }
  }

  return (
    <RemoteDataTable
      client={client}
      adminMode={adminMode}
      sendAlert={sendAlert}
      setSnackbarMessage={setSnackbarMessage}
      initialSortOrder={'-modified'}
      title="savedLayout"
      url={layoutsAPI}
      deleteAPI={layoutsIdAPI}
      selectableRows="multiple"
      expandableRows={false}
      controls={true}
      deleteControl={true}
      columnOrderMap={{
        name: 'name',
        saved_by: ['created_by__last_name', 'created_by__first_name'],
        modified: 'modified',
      }}
      columns={[
        { name: 'name', label: 'Name' },
        { name: 'saved_by', label: 'Created By' },
        { name: 'modified', label: 'Last Modified' },
        { name: 'clone', label: ' ', options: { sort: false } },
      ]}
      rowsOnPage={10}
      customRowRender={(rowData: any) => {
        const layoutsTableData = rowData?.map((item: any) => {
          const updatedItem = {
            name: null,
            saved_by: null,
            modified: null,
            clone: null,
          }
          const link = `/layouts?layout=${item.hashed}`

          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
          updatedItem.name = (
            <LinkButton
              onClick={() => {
                history.replace(link)
                handleClose()
              }}
            >
              <ConditionalTooltip text={item.name} maxLength={MAX_CELL_WIDTH} />
            </LinkButton>
          )
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
          updatedItem.saved_by = `${item.created.user.last_name}, ${item.created.user.first_name}`
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
          updatedItem.modified = new Date(
            item.modified.timestamp.raw
          ).toLocaleString('en-US')

          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
          updatedItem.clone = (
            <Button
              text="Clone"
              size="medium"
              variant="secondary"
              onClick={() => cloneLayout(item.hashed)}
              textStyle={{ fontWeight: 'bold' }}
              testId="cloneLayout"
            />
          )
          return updatedItem
        })

        return layoutsTableData
      }}
    />
  )
}

export default SavedLayouts

const LinkButton = styled.button`
  border: none;
  color: ${colors.blue8};
  background-color: inherit;
  font-size: 0.875rem;
  cursor: pointer;
`
