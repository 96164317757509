import styled from 'styled-components'
import { ReactComponent as Logo } from '../../../../../Logo.svg'
import { colors } from '../../../../../context/theme-context/'

export default function Header() {
  return (
    <HeaderContainer>
      <LeftHeader>
        <Logo height={32} width={32} />
        NetWise Platform Audience Report
      </LeftHeader>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 1.25rem 0rem;
  border-bottom: 1px solid ${colors.gray5};
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${colors.blue9};
`
const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  column-gap: 0.5rem;
`
