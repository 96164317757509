import NavItem from './NavItem'
import NavButton from './NavButton'
import { ReactComponent as Logo } from '../../../../Logo.svg'
import { useHistory } from 'react-router'

const { REACT_APP_ENV } = process.env

export default function LogoNav() {
  let label = 'NetWise'
  const history = useHistory()
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const env = REACT_APP_ENV.split('.')[0]
  if (['dev', 'beta'].includes(env)) {
    label = env.toUpperCase()
  }

  const Content = <NavButton selected={false} Icon={Logo} Label={`${label}`} />

  return (
    <NavItem
      handleClick={() => history.push('/audiences', { action: 'reset' })}
      NavContent={Content}
    />
  )
}
