import NavItem from './NavItem'
import NavButton from './NavButton'
import { BiSliderAlt } from 'react-icons/bi'
import { colors, typography } from '../../../../context/theme-context/'
import styled from 'styled-components'
const { big } = typography.bodyRegular

const Label = styled.span`
  font-size: ${big.fontSize};
  line-height: ${big.lineHeight};
  font-weight: ${big.fontWeight};
`

interface AdminNavI {
  selected: any
  setSelected?: any
}

const AdminNav: React.FC<AdminNavI> = ({ selected }) => {
  const isSelected = selected.top === 'admin'

  const Content = (
    <NavButton
      selected={isSelected}
      Icon={BiSliderAlt}
      Label={<Label>Admin</Label>}
      textColor={colors.blue8}
    />
  )

  return <NavItem to={'/admin'} NavContent={Content} id="admin" />
}

export default AdminNav
