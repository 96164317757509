import { ListAccordion } from '../../../../../components/Accordion/'
import StandardAutocomplete from '../../../../../components/StandardAutocomplete'
import { toSnakeCase, toTitleCase } from '../../../../../utils/caseHelpers'

export default function RequiredFields({
  updateFilter,
  fields,
  field_names,
  divOnly = false,
}: any) {
  return (
    <ListAccordion
      title="Required Fields"
      count={field_names?.length || ''}
      divOnly={divOnly}
    >
      <div style={{ width: '100%' }}>
        <StandardAutocomplete
          value={field_names?.map((field: any) => toTitleCase(field))}
          placeholder="Choose Required Fields"
          testId="requiredFields"
          onChange={(_: any, values: any) =>
            updateFilter({
              field_names: values.map((value: any) => toSnakeCase(value)),
            })
          }
          tagSuggestions={fields?.map((field: any) => toTitleCase(field))}
        />
      </div>
    </ListAccordion>
  )
}
