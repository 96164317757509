// Adapted from (https://davidwalsh.name/javascript-debounce-function by way of Underscore.js)
type Debounce = (
  func: any,
  wait?: number,
  immediate?: boolean
) => (value?: any) => void

const debounce: Debounce = (func, wait = 100, immediate = false) => {
  let timeout: any
  return function (this: any) {
    const context = this
    const args = arguments

    const later = function () {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

export { debounce }
