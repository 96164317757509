const reducer = (state: any, action: any) => {
  const { type, payload } = action
  switch (type) {
    case 'refresh':
      return { ...state, refreshCount: state.refreshCount + 1 }
    case 'toggleInflight':
      return { ...state, isCallInflight: !state.isCallInflight }
    default:
      return { ...state, ...payload }
  }
}

export default reducer
