export default function reducer(state: any, action: any) {
  const { type, payload } = action

  switch (type) {
    case 'newFile':
      return {
        ...state,
        currentFile: {
          file_name: null,
          filter_exclude_matches: 'Only Include Matching Records',
        },
        dialogOpen: true,
      }

    case 'updateJoins':
      const { currentFile } = state
      let match
      payload.forEach((file: any) => {
        if (Number(currentFile?.id) === Number(file.file_id)) {
          match = file
        } else {
          console.log('No Match')
        }
      })
      return {
        ...state,
        currentFile: {
          ...currentFile,
          joins: (match as any)?.joins || [],
        },
      }

    default:
      return { ...state, ...payload }
  }
}

const initialState = {
  dialogOpen: false,
  editDialogOpen: false,
  editFileIndex: 0,
  currentFile: null,
  files: [],
  dbFields: [],
}

export { initialState }
