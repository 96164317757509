import FourOhFour from 'App/SmartHub/404'
import { Switch, Route, Redirect } from 'react-router-dom'
import Audiences from 'App/SmartHub/Audiences'
import Uploads from 'App/SmartHub/Uploads'
import Exports from 'App/SmartHub/Exports'
import About from 'App/SmartHub/About'
import Layouts from 'App/SmartHub/Layouts'
import TermsScreen from 'App/Authentication/TermsScreen'
import AccountDetailNew from 'App/SmartHub/Admin/AccountDetailNew'
import Accounts from 'App/SmartHub/Admin/Accounts'
import Account from 'App/SmartHub/Admin/Account'
import { useAuth } from 'context/auth-context'
import UserDetail from 'App/SmartHub/UserDetail'

export default function Routing() {
  const { userData } = useAuth()
  const { isNetWiseAccount } = userData

  const NetWiseOnlyRoutes = (
    <Switch>
      <Route exact path="/admin" component={Accounts} />
      <Route exact path="/admin/accounts" component={Accounts} />
      <Route path="/admin/accounts/detail/new" component={AccountDetailNew} />
      <Route path="/admin/accounts/detail/:id" component={Account} />
    </Switch>
  )
  const UserRoutes = <Route exact path="/admin" component={UserDetail} />

  const ProtectedRoutes = () => (isNetWiseAccount ? NetWiseOnlyRoutes : UserRoutes)

  return (
    <Switch>
      <Route
        path="/password_reset/done/"
        render={() => <Redirect to="/audiences" />}
      />
      <Route exact path="/" render={() => <Redirect to="/audiences" />} />
      <Route exact path="/signup" render={() => <Redirect to="/audiences" />} />
      <Route exact path="/audiences" component={Audiences} />
      <Route path="/audiences/:hash?" component={Audiences} />
      <Route path="/uploads" component={Uploads} />
      <Route path="/exports/:param?" component={Exports} />
      <Route path="/layouts/:param?" component={Layouts} />
      <Route path="/admin" component={ProtectedRoutes} />
      <Route path="/about" component={About} />
      <Route path="/terms" component={TermsScreen} />
      <Route
        path="/login"
        render={({ location }) => {
          const params = new URLSearchParams(location.search)
          const next = params.get('next') || '/audiences'
          return <Redirect to={next} />
        }}
      />
      <Route path="/" component={FourOhFour} />
    </Switch>
  )
}
