import NavItem from './NavItem'
import NavButton from './NavButton'
import { ReactComponent as Logo } from '../../../../icons/24px/People.svg'
import { colors, typography } from '../../../../context/theme-context/'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../../../context/auth-context'
import LoadAudience from './AudiencesNav/LoadAudience'
import { useHistory } from 'react-router-dom'
const { big } = typography.bodyRegular

export default function AudiencesNav({ selected, smallScreen }: any) {
  const [isOpen, setIsOpen] = useState(false)
  const [audiences, setAudiences] = useState([])
  const { client } = useAuth()
  const { audiencesAPI } = client.endpoints
  const history = useHistory()

  useEffect(() => {
    async function getAudiences() {
      try {
        const { responseData } = await client.get({
          endpoint: audiencesAPI,
          data: {
            sort: '-modified',
            size: 100,
            dropdown_view: true,
          },
        })
        const { results } = responseData
        setAudiences(results)
      } catch (e) {
        console.log('Could not retrieve data at this time.')
      }
    }
    getAudiences()
  }, [client, audiencesAPI])

  const isSelected = selected.top === 'audiences'

  const Content = (
    <NavButton
      selected={isSelected}
      Icon={() => (
        <Logo
          fill={colors.blue8}
          stroke={colors.blue8}
          width={24}
          style={{ marginRight: '0.5rem' }}
        />
      )}
      Label={<Label>Audiences</Label>}
      data-cy="audiencesNav"
    />
  )

  return (
    <>
      <NavItem
        smallScreen={smallScreen}
        handleClick={() => history.push('/audiences', { action: 'reset' })}
        NavContent={Content}
        subNav={[
          {
            name: 'New',
            fn: () => {
              history.push('/audiences', { action: 'reset' })
            },
          },
          { name: 'Open', fn: () => setIsOpen(true) },
        ]}
        id="audience"
      />
      <LoadAudience
        audiences={audiences}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      />
    </>
  )
}

const Label = styled.span`
  font-size: ${big.fontSize};
  line-height: ${big.lineHeight};
  font-weight: ${big.fontWeight};
`
