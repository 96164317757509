// NOTE: Key names correspond to ref on the left nav (for scrollTo / highlight functionality).
// This is probably dumb and there is most likely a better way to do it.
const contactFilters = {
  required_contact_points: [
    'b2b_email',
    'good_catch_all',
    'good_only',
    'business_phone',
    'business_address',
    'mobile_phone',
    'hashed_personal_email',
  ],
  title_keywords: ['title_keywords'],
  job_function: ['b2b_job_sub_functions'],
  seniority: ['b2b_seniorities'],
  location: [
    'contact_location_country',
    'personal_states',
    'contact_location_zip_code',
  ],
}

const companyFilters = {
  required_firmographics: [
    'company_employees',
    'company_revenue',
    'domain',
    'industry',
  ],
  domains_and_keywords: [
    'domain_keywords',
    'company_name_keywords',
    'company_description_keywords',
    'company_tickers',
  ],
  industry: [
    'industry_keywords',
    'company_normalized_industries',
    'sic_codes',
    'naic_codes',
  ],
  revenue: ['b2b_sales_volumes'],
  company_size: ['b2b_company_sizes'],
  location: [
    'company_location_country',
    'company_location_state',
    'company_location_zip_code',
  ],
}

const advancedFilters = {
  required_fields: ['field_names'],
  file_match: ['chosen_files'],
  custom_SQL: ['sql_editor'],
}

export { contactFilters, companyFilters, advancedFilters }
