// RemoteDataTable Default Values
const getDefaultTableState = (rowsPerPage: any, initialSortOrder: any) => {
  return {
    dialogState: {
      isOpen: false,
      data: [],
    },
    searchText: '',
    autosaveDisplay: false,
    refreshCount: 0,
    selectedRows: [],
    refreshDT: {
      refresh: false,
    },
    data: null,
    page: 0,
    count: 10,
    rowsPerPage: rowsPerPage,
    sortOrder: initialSortOrder,
    isCallInflight: false,
  }
}

export default getDefaultTableState
