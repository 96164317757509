import React from 'react'
import NavItem from './NavItem'
import NavButton from './NavButton'
import ProfileButton from './ProfileNavigation/ProfileButton'
import { colors } from 'context/theme-context/'
import { useAuth } from 'context/auth-context'
import AssumeUserDialog from './ProfileNavigation/AssumeUserDialog'

type Props = {
  selected: {
    top: string
  }
}
export default function ProfileNavigation({ selected }: Props) {
  const { logout, userData } = useAuth()
  const [isOpen, setIsOpen] = React.useState(false)
  const isSelected = selected.top === 'Profile'

  type SubNav = {
    name: string
    id?: string
    fn?: () => void
  }

  // These will appear as seperate elements in a dropdown under the profile component
  let subNavs: Array<SubNav> = [
    {
      name: 'Submit Feedback',
      fn: () => window.open('https://feedback.netwisedata.com/feature-requests'),
    },
    // Commenting out for now, may need to bring back.
    // {
    //   name: 'Walkthroughs',
    //   id: 'walkthroughs',
    // },
    {
      name: 'Knowledge Base',
      fn: () => window.open('https://intercom.help/netwise/en/'),
    },
    {
      name: 'Log Out',
      fn: logout,
    },
  ]

  if (userData.can_assume_user) {
    subNavs = [
      {
        name: 'Assume User Role',
        fn: () => setIsOpen(true),
      },
      ...subNavs,
    ]
  }

  // Removing this to see if anyone notices
  // if (userData.account.admin) {
  //   subNavs = [
  //     {
  //       name: 'NetWise Admin',
  //       fn: () => window.open(url + '/netwise-admin'),
  //     },
  //     ...subNavs,
  //   ]
  // }

  const Content = (
    <NavButton
      selected={isSelected}
      Label={<ProfileButton />}
      textColor={colors.blue8}
    />
  )
  return (
    <>
      <NavItem NavContent={Content} subNav={subNavs} id="profile_other" />
      <AssumeUserDialog isOpen={isOpen} handleClose={() => setIsOpen(false)} />
    </>
  )
}
