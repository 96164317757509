import React from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../context/theme-context/'
import { useSnackbar } from '../context/snackbar-context'
import { BiCopy } from 'react-icons/bi'
import copyToClipboard from '../utils/copyToClipboard'

interface CopyOnClickI {
  text: string
  render?: any
}

// @ts-expect-error ts-migrate(2322) FIXME: Type '({ text, render: Component }: PropsWithChild... Remove this comment to see the full error message
const CopyOnClick: React.FC<CopyOnClickI> = ({ text, render: Component }) => {
  const [isHovering, setIsHovering] = useState(false)
  const { setSnackbarMessage } = useSnackbar()

  function toggleHover() {
    setIsHovering((state) => !state)
  }

  async function handleClick(e: any) {
    e.stopPropagation()
    await copyToClipboard(text)
    setSnackbarMessage({ text: 'Text copied' })
  }

  try {
    return !text ? null : (
      <span
        style={{ position: 'relative' }}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <OuterContainer onClick={handleClick}>
          {Component ? Component : text}
          <CopyIcon isHovering={isHovering ? 1 : 0} />
        </OuterContainer>
      </span>
    )
  } catch (e) {
    console.log(e)
  }
}

const OuterContainer = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const CopyIcon = styled(BiCopy)<{ isHovering: boolean | number }>`
  height: 1rem;
  width: 1rem;
  padding: 2px;
  margin-left: 0.25rem;
  border: 1px solid ${colors.blue2};
  background-color: ${colors.blue1};
  border-radius: 0.15rem;
  visibility: hidden;
  ${(props) =>
    props.isHovering &&
    css`
      visibility: visible;
      height: 1rem;
      width: 1rem;
    `};
`

export default CopyOnClick
