import { useState } from 'react'
import ClientDataTable, {
  CopyTooltip,
} from '../../../../../components/ClientDataTable'

const MAX_CELL_WIDTH = 50

export default function SampleCompanies({
  sampleCompanies,
  filtersHaveUpdated,
  audienceInfo,
}: any) {
  const [searchText, setSearchText] = useState('')
  const rows = sampleCompanies?.rows
  const columns = sampleCompanies?.columns

  function makeConditionalTooltip(text: any) {
    return <CopyTooltip text={text} maxWidth={MAX_CELL_WIDTH} />
  }

  const customRows = rows?.map((company: any) => {
    const newCompany = {}
    for (const [key, value] of Object.entries(company)) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newCompany[key] = makeConditionalTooltip(value)
    }
    return newCompany
  })

  const valueToText = (v: any) => {
    return v.props.text
  }

  const csvFilename = `${
    audienceInfo?.audience_name || 'NetWise Audience Platform'
  } Sample Companies.csv`

  return (
    <div style={{ padding: '1rem' }}>
      <ClientDataTable
        title="sampleCompanies"
        searchText={searchText}
        setSearchText={setSearchText}
        data={customRows}
        columns={columns}
        outOfDate={!filtersHaveUpdated}
        csvControl={true}
        csvFilename={csvFilename}
        valueToText={valueToText}
      />
    </div>
  )
}
