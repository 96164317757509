import { useState } from 'react'
import SimpleInput from '../SimpleInput'
import Button from '../Button'
import TableCell from '@material-ui/core/TableCell'
import styled from 'styled-components'
import Tooltip from '../Tooltip'

export default function EditableCell({
  dragging = false,
  updateKey,
  value,
  update,
  flexnumber,
  isEditable = false,
}: any) {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <StyledTableCell flexnumber={flexnumber}>
      {isEditing ? (
        <CellContainer>
          <SimpleInput
            value={value}
            onChange={(e: any) => update(updateKey, e.target.value)}
            containerProps={{ style: { padding: '0.45rem' } }}
            data-cy="exportName"
          />
          <div style={{ width: '50px' }}>
            <Button
              text="SAVE"
              size="medium"
              variant="secondary"
              onClick={() => setIsEditing(false)}
              testId="saveEdit"
            />
          </div>
        </CellContainer>
      ) : isEditable ? (
        <Button
          text={value}
          variant="secondary"
          size="medium"
          onClick={() => setIsEditing(true)}
          testId="exportNameButton"
        />
      ) : (
        <Tooltip title={value}>
          <ValueDiv>{value}</ValueDiv>
        </Tooltip>
      )}
    </StyledTableCell>
  )
}

const StyledTableCell = styled(TableCell)<{ flexnumber: number }>`
  display: flex;
  flex: ${(props) => props.flexnumber};
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`

const CellContainer = styled.div`
  display: flex;
  flex: 1;
  column-gap: 0.5rem;
  align-items: center;
`

const ValueDiv = styled.div`
  display: flex;
  flex: 1;
  max-width: 20px;
`
