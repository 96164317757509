import styles from './Banner/Banner.module.css'
import { CSSTransition } from 'react-transition-group'

type Props = {
  Component: JSX.Element | null
  isVisible: boolean
  animated?: boolean
}

export default function Banner({ Component, isVisible, animated = false }: Props) {
  const UI = isVisible ? <div className={styles.banner}>{Component}</div> : <div />
  return animated ? (
    <CSSTransition
      in={isVisible}
      timeout={175}
      classNames={{ ...styles }}
      unmountOnExit
    >
      {UI}
    </CSSTransition>
  ) : (
    UI
  )
}
