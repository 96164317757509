import React from 'react'
import styled from 'styled-components'
import BaseExportDialog from 'components/BaseExportDialog'
import RecentExports from './Exports/RecentExports'
import SavedExports from './Exports/SavedExports'
import Button from 'components/Button'
import SpecialTooltip from 'components/SpecialTooltip'
import { colors } from 'context/theme-context/'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useAuth } from 'context/auth-context'

export default function Exports() {
  // Allows us to dictate a re-render that's not tied to a piece of necessary application state.
  // Here, we're using it to trigger an API request in the RecentExports component
  const [renderCount, setRenderCount] = React.useState(0)

  const [isNewExportDialogOpen, setIsNewExportDialogOpen] = React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState({
    recent: true,
    saved: true,
  })

  const chosenExport = {}
  const history = useHistory()
  const location = useLocation()
  const { userData } = useAuth()
  const { needsUpgrade } = userData
  const parser = React.useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])

  // Handling old emails link. Eventually we'll be able to remove this code, ask @CamFortin
  const splitPath = location.pathname.replace(/\/$/, '').split('/')
  switch (splitPath.length) {
    case 4:
      const downloadHash = splitPath[3]
      if (!!Number(downloadHash)) {
        history.replace(`/exports?download_hash=${downloadHash}`)
      }
      break
    case 3:
      const exportHash = splitPath[2]
      if (!!Number(exportHash)) {
        history.replace(`/exports?export_hash=${exportHash}`)
      }
      break
    default:
      break
  }

  // Reacting to URL param-dependent actions
  React.useEffect(() => {
    const action = parser.get('action')
    switch (action) {
      case 'to_saved_exports':
        setIsExpanded((state) => ({ ...state, recent: false }))
        break
      case 'create_new_export':
        setIsNewExportDialogOpen(true)
        break
      default:
        setIsExpanded((state) => ({ ...state, recent: true }))
    }
  }, [location, parser])

  // Function that allows us to re-render on command
  const renderNow = () => {
    setRenderCount((state) => state + 1)
  }

  return (
    <ExportsContainer>
      <Header>
        <div>Exports</div>
        <div>
          <SpecialTooltip
            text="Your account doesn't have credits and/or permission to export audiences. Please use the chat to upgrade your account."
            condition={needsUpgrade}
          >
            <Button
              disabled={needsUpgrade}
              text={`New Export`}
              size="large"
              variant="primary"
              onClick={() => setIsNewExportDialogOpen(true)}
              textStyle={{ fontWeight: 'bold' }}
              testId="newExport"
            />
          </SpecialTooltip>
        </div>
      </Header>
      <RecentExports
        page="exports"
        isExpanded={isExpanded.recent}
        renderCount={renderCount}
      />
      <SavedExports
        needsUpgrade={needsUpgrade}
        isExpanded={isExpanded.saved}
        renderNow={renderNow}
      />
      <BaseExportDialog
        isOpen={isNewExportDialogOpen}
        chosenExport={chosenExport}
        setRenderExportAudience={renderNow}
        handleExit={() => setIsNewExportDialogOpen(false)}
        dialogType="create"
      />
    </ExportsContainer>
  )
}

const ExportsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  overflow-y: scroll;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0rem 0rem;
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${colors.blue8};
`
