import styled from 'styled-components'
import { ReactComponent as Logo } from '../../Logo.svg'
import { useHistory } from 'react-router-dom'
import Copyright from '../../components/Copyright'
import BuildVersion from '../../components/BuildVersion'
import { useAuth } from 'context/auth-context'
const FooterItem = ({ linkAction, children }: any) => (
  <Item onClick={linkAction}>{children}</Item>
)

const Footer = ({ containerProps = {} }) => {
  const history = useHistory()
  const { buildVersion, loggedIn } = useAuth()

  return (
    <OuterContainer {...containerProps}>
      <Container>
        <LeftContainer>
          <FooterItem linkAction={() => window.open('https://www.netwisedata.com')}>
            <Logo height={'3rem'} />
          </FooterItem>
          <FooterItem
            linkAction={() => {
              const chatButton = document.querySelector(
                '[aria-label="Open live chat"'
              ) as any
              chatButton?.click()

              if (!chatButton) {
                window.open('mailto:support@netwisedata.com')
              }
            }}
          >
            Contact
          </FooterItem>
          <FooterItem linkAction={() => history.push('/terms')}>
            Terms of Use
          </FooterItem>
          <FooterItem
            linkAction={() => window.open('https://intercom.help/netwise/en/')}
          >
            Knowledge Base
          </FooterItem>
          <FooterItem
            linkAction={() =>
              window.open('https://www.netwisedata.com/data-driven/')
            }
          >
            News & Press
          </FooterItem>
        </LeftContainer>
        <RightContainer>
          {loggedIn && <BuildVersion version={buildVersion} />}
          <Copyright />
        </RightContainer>
      </Container>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
`
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
`
const LeftContainer = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  column-gap: 2rem;
`
const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  column-gap: 2rem;
`
const Item = styled.div`
  display: flex;
  cursor: pointer;
`

export default Footer
