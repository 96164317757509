import Button from '../../../../components/Button'

export default function UploadButton({ clickHandler, ...rest }: any) {
  return (
    <Button
      icon="upload"
      text="Upload"
      variant="primary"
      onClick={(e: any) => {
        e.stopPropagation()
        clickHandler()
      }}
      size="medium"
      {...rest}
    />
  )
}
