import React from 'react'
import Accordion from 'components/Accordion'
import DeletableNote from './AccountNotes/DeletableNote'
import AddableNote from './AccountNotes/AddableNote'
import { useAuth } from 'context/auth-context'
import { Note } from 'types'
import { format } from 'date-fns'

type Props = {
  accountId: number
}
export default function AccountNotes({ accountId }: Props) {
  const { client } = useAuth()
  const notesAPI = client.endpoints.notesAPI(accountId)
  const [noteText, setNoteText] = React.useState('')
  const [savedNotes, setSavedNotes] = React.useState<Array<Note>>([])

  React.useEffect(() => {
    client
      .get({ endpoint: notesAPI })
      .then((res) => setSavedNotes(res.responseData.results))
  }, [client, notesAPI])

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target
    if (value.length < 256) setNoteText(value)
    else {
      setNoteText(value.slice(0, 256))
    }
  }

  function deleteNote(noteId: number) {
    const noteUrl = client.endpoints.notesIdAPI(accountId, noteId)
    return function () {
      client.delete({ endpoint: noteUrl }).then((res) => {
        if (res.status === 200) {
          const tempNotes = savedNotes.filter((note) => note.id !== noteId)
          setSavedNotes(tempNotes)
        } else {
          alert('Could not delete note. Please try again later')
        }
      })
    }
  }

  function saveNote() {
    client.post({ endpoint: notesAPI, data: { note: noteText } }).then((res) => {
      if (res.status === 201) {
        const savedNote: Note = res.responseData.results
        const tempNotes = [...savedNotes, savedNote]
        setSavedNotes(tempNotes)
        setNoteText('')
      }
    })
  }

  const ExistingNotes = savedNotes.map((note) => {
    const { user, timestamp } = note.created
    const date = new Date(timestamp.raw)
    const dateStr = format(date, 'MMM dd yyyy, pp')
    return (
      <DeletableNote
        key={note.note}
        text={note.note}
        author={user.first_name + ' ' + user.last_name}
        date={dateStr}
        deleteHandler={deleteNote(note.id)}
      />
    )
  })

  const noteStyle = {
    style: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '14px',
      fontWeight: 400,
    },
  }

  return (
    <div style={{ margin: '1rem 0rem' }}>
      <Accordion title="Account Notes" detailProps={noteStyle}>
        <div style={{ padding: '1rem' }}>
          {ExistingNotes}
          <AddableNote
            text={noteText}
            updateText={handleChange}
            submit={() => saveNote()}
          />
        </div>
      </Accordion>
    </div>
  )
}
