import TabCell from './TabCell'
import { TabContainer } from './CSS'

export default function Tab({
  handleSelection,
  leftLabel = 'Left Label',
  rightLabel = 'Right Label',
  leftValue = false,
  rightValue = false,
}: any) {
  function update(selection: any) {
    handleSelection({ ...selection })
  }

  return (
    <div style={{ display: 'flex' }}>
      <TabContainer>
        <TabCell
          handleClick={() => update({ leftValue: true, rightValue: false })}
          isActive={leftValue}
          text={leftLabel}
        />
        <TabCell
          handleClick={() => update({ leftValue: false, rightValue: true })}
          isActive={rightValue}
          text={rightLabel}
        />
      </TabContainer>
    </div>
  )
}
