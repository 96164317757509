import React from 'react'
import LoremIpsumCard from '../../components/LoremIpsumCard'
import { useUser } from '../../context/user-context'

function About() {
  const user = useUser()
  return (
    <LoremIpsumCard
      greeting={`About ${user.name}:`}
      bodyText={
        'Great salesman, better friend? Nope . . . . . . . . . . . . . . . . Tall. Beets.'
      }
      actionComponent={<div />}
    />
  )
}

export default About
