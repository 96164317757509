import { v4 } from 'uuid'

const IndeterminateCheckBoxIcon: React.FC = () => {
  const id = v4()
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id={id}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" rx="4" fill="#0C54D2" />
      </mask>
      <g mask={`url(#${id})`}>
        <rect width="16" height="16" fill="#0C54D2" />
        <path d="M8 8L16 0V16H0L8 8Z" fill="#A5D2FD" />
      </g>
    </svg>
  )
}

export default IndeterminateCheckBoxIcon
