function toTableFmt(data: any, category: any) {
  try {
    if (data) {
      const rows = !Array.isArray(data)
        ? Object.entries(data).map(([key, value]) => ({
            category: key,
            count: value,
          }))
        : data
      return { columns: [category, 'Count'], rows }
    }
  } catch (e) {
    console.log(e)
  }
}

function scrollTo(id: any) {
  try {
    const element = document.getElementById(id)
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    element.scrollIntoView({ behavior: 'smooth' })
  } catch (e) {
    setTimeout(() => scrollTo(id), 1000)
  }
}

function formatMetricsForDisplay(metrics: any) {
  return {
    contactData: toTableFmt(metrics['personal_linkages'], 'B2B Emails'),
    companyData: toTableFmt(metrics['company_metrics'], 'Companies'),
    employeeData: toTableFmt(
      metrics['employee_buckets'],
      'Contacts by Company Employees'
    ),
    revenueData: toTableFmt(
      metrics['revenue_buckets'],
      'Contacts by Company Revenue'
    ),
    titleData: toTableFmt(metrics['top_titles'], 'Contact Titles'),
    functionalData: toTableFmt(
      metrics['top_functional_areas'],
      'Contact Job Functions'
    ),
    seniorityData: toTableFmt(metrics['top_seniorities'], 'Contact Seniorities'),
    industryData: toTableFmt(
      metrics['company_industries'],
      'Contacts by Top Company Industries'
    ),
  }
}

export { formatMetricsForDisplay, scrollTo }
