import List from '@material-ui/core/List'
import Tab from '../../../../../components/Tab'
import { ListAccordion } from '../../../../../components/Accordion'
import { SimpleCheckbox } from '../../../../../components/Checkbox'
import { toTitleCase } from '../../../../../utils/caseHelpers'

export default function ContactPoints({
  b2b_email,
  good_catch_all,
  good_only,
  business_phone,
  business_address,
  mobile_phone,
  hashed_personal_email,
  updateFilter,
  divOnly = false,
}: any) {
  const count =
    (b2b_email || 0) +
    (business_phone || 0) +
    (business_address || 0) +
    (mobile_phone || 0) +
    (hashed_personal_email || 0)

  function ListItem(key: any, itemBool = false, addnlUpdate = {}) {
    return (
      <li
        key={key}
        onClick={() => updateFilter({ [key]: !itemBool, ...addnlUpdate })}
        style={{ margin: '0.5rem 0rem' }}
      >
        <SimpleCheckbox selected={itemBool} label={toTitleCase(key)} />
      </li>
    )
  }

  const conditionalUpdate = b2b_email
    ? { good_catch_all: false, good_only: false }
    : { good_catch_all: true, good_only: false }

  return (
    <ListAccordion
      title="Required Contact Points"
      count={count > 0 ? count : ''}
      expanded={false}
      divOnly={divOnly}
    >
      <List style={{ padding: 0 }}>
        {ListItem('b2b_email', b2b_email, conditionalUpdate)}
        {b2b_email && (
          <Tab
            handleSelection={({ leftValue, rightValue }: any) =>
              updateFilter({ good_catch_all: leftValue, good_only: rightValue })
            }
            leftLabel={'Good + Catch All'}
            leftValue={good_catch_all}
            rightLabel={'Good Only'}
            rightValue={good_only}
          />
        )}
        {ListItem('business_phone', business_phone)}
        {ListItem('business_address', business_address)}
        {ListItem('mobile_phone', mobile_phone)}
        {ListItem('hashed_personal_email', hashed_personal_email)}
      </List>
    </ListAccordion>
  )
}
