import StandardAutocomplete from '../../../../../components/StandardAutocomplete'
import SimpleInput from '../../../../../components/SimpleInput'
import { toTitleCase } from '../../../../../utils/caseHelpers'
import { ListAccordion } from '../../../../../components/Accordion'
import OnOff from './OnOff'
import QuestionTooltip from '../../../../../components/QuestionTooltip'
import MatchMaker from './MatchMaker'
import FieldCleanser from './FieldCleanser'
import { colors } from '../../../../../context/theme-context'
import styled from 'styled-components'
import { Props as MatchProps } from '../ExactMatchDialog'

type OmittedKeys =
  | 'makeMatchState'
  | 'isOpen'
  | 'submit'
  | 'handleClose'
  | 'currentFile'
  | 'matchStats'
  | 'hasSubmitted'
  | 'pctComplete'
  | 'audienceHash'
  | 'hasDropped'
  | 'onDropAccepted'
  | 'onDropRejected'
  | 'sizeLimitMb'

interface Props extends Omit<MatchProps, OmittedKeys> {
  setExactMatchWarning: React.Dispatch<React.SetStateAction<string>>
  addMatch: () => void
  updateMatch: (idx: any, update: any) => void
  removeMatch: (idx: any) => void
  exactMatchWarning: string
  fileColumns: never[]
}

export default function MatchAndCleanse(props: Props) {
  return (
    <div>
      <InitialInputs>
        <div style={{ flex: 1 }}>
          <SimpleInput
            label="File Name"
            value={props.fileName}
            disabled={props.isCreatingDerivedAudience}
            onChange={(e: any) => {
              props.setMatchState((state: any) => ({
                ...state,
                fileName: e.target.value,
              }))
            }}
            multiple={false}
            data-cy="upload-file-name"
          />
        </div>
        <div>
          <StandardAutocomplete
            label="Privacy"
            value={toTitleCase(props.privacy)}
            testId="privacy"
            tagSuggestions={['private', 'team_view', 'team_edit']}
            onChange={(_: any, value: any) => {
              props.setMatchState((state: any) => ({
                ...state,
                privacy: value,
              }))
            }}
            autocompleteProps={{
              getOptionLabel: (value: any) => toTitleCase(value),
              filterOptions: (options: any) => options,
              getOptionSelected: (opt: any, value: any) =>
                toTitleCase(opt) === value,
            }}
            multiple={false}
          />
        </div>
      </InitialInputs>
      <BorderDiv>
        <ListAccordion
          title={'File Match'}
          expanded={props.matchEnabled}
          data-cy="fileMatchSection"
          warningText={
            <>
              <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                {props.exactMatchWarning}&nbsp;
              </div>
              {!props.isCreatingDerivedAudience && (
                <OnOff
                  clearWarning={() => props.setExactMatchWarning('')}
                  matchEnabled={props.matchEnabled}
                  setMatchEnabled={(matchEnabled: boolean) =>
                    props.setMatchState((state) => ({
                      ...state,
                      matchEnabled: matchEnabled,
                    }))
                  }
                />
              )}
            </>
          }
          bottomBorder={false}
          containerProps={{ style: {} }}
        >
          <div>
            <span
              style={{ color: colors.gray7, fontWeight: 400 }}
              data-cy="fileMatchInfo"
            >
              NEW: match against all emails in our database using the advanced
              "email" match types at the top of the match type dropdown (select
              "email" for raw or the appropriate hashed type), or choose a specific
              database field (company domain is preferred) to match to.
            </span>
            {props.matchEnabled && (
              <MatchMaker
                match_fields={props.match_fields}
                updateMatch={props.updateMatch}
                addMatch={props.addMatch}
                removeMatch={props.removeMatch}
                dbFields={props.dbFields}
                fileColumns={props.fileColumns}
              />
            )}
          </div>
        </ListAccordion>
      </BorderDiv>
      <BorderDiv>
        {!props.isCreatingDerivedAudience && (
          <ListAccordion
            title={'Clean Fields (Advanced)'}
            expanded={false}
            bottomBorder={false}
            warningText={
              <QuestionTooltip
                text={`
              This options takes a file with fields 
              that can be used to directly match to 
              our database (e.g. company domain, person id, business email) 
              and creates an audience of contacts matching your file. 
              The match is “live”, enabling you to get automatically get 
              updated matching contacts when there are updates to our data.`}
              />
            }
            data-cy="cleanFieldsAdvancedSection"
          >
            <div>
              <div
                style={{
                  color: colors.gray7,
                  fontWeight: 400,
                  marginBottom: '1rem',
                }}
                data-cy="cleanFieldsAdvancedInfo"
              >
                Cleaning input fields will result in much better match rates to our
                database. Field types you should cleanse: domain, email, phone,
                company name, title.
              </div>
              <FieldCleanser
                match_fields={props.match_fields}
                cleansingOptions={props.cleansingOptions}
                fileColumns={props.fileColumns}
                setMatchState={props.setMatchState}
                cleansingMap={props.cleansingMap}
              />
            </div>
          </ListAccordion>
        )}
      </BorderDiv>
    </div>
  )
}

const InitialInputs = styled.div`
  display: flex;
  column-gap: 1rem;
  padding: 1rem 0rem;
  border-bottom: 1px solid ${colors.gray5};
  align-items: center;
`

const BorderDiv = styled.div`
  border-bottom: 1px solid ${colors.gray5};
`
