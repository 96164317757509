import React from 'react'
import Accordion from 'components/Accordion'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import RemoteDataTable from 'components/RemoteDataTable'
import StandardAutocomplete from 'components/StandardAutocomplete'
import AddCredits from './AddCredits'
import PersonSearch from './PersonSearch'
import { useAuth } from 'context/auth-context'
import { useAlerts } from 'context/alert-context'
import { useSnackbar } from 'context/snackbar-context'
import { useHistory } from 'react-router-dom'
import NumberWithDisappearingButton from './Accounts/NumberWithDisappearingButton'
import { toTitleCase } from 'utils/caseHelpers'
import FeatureAccessDialog from './FeatureAccessDialog'
import EditIcon from '@material-ui/icons/Edit'

export default function Accounts() {
  const history = useHistory()
  const { client, userData, adminMode } = useAuth()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage } = useSnackbar()
  const [, setEffectCounter] = React.useState(0)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [accountInfo, setAccountInfo] = React.useState({
    id: undefined,
    name: undefined,
  })
  const [selectedAccount, setSelectedAccount] = React.useState(null)

  const canAddCredits = userData.admin && userData.account.admin

  type Filters = {
    source: null | { text: string; value: boolean }
    stage: null | { text: string; value: boolean }
    status: null | { text: string; value: boolean }
  }
  const [filters, setFilters] = React.useState<Filters>({
    source: null,
    stage: null,
    status: null,
  })

  const columns = [
    {
      name: 'id',
      label: 'ID',
    },
    { name: 'name', label: 'Account Name' },
    { name: 'stage', label: 'Account Stage' },
    {
      name: 'type',
      label: 'Account Type',
      options: {
        sort: false,
      },
    },
    { name: 'signup_source', label: 'Signup Source' },
    {
      name: 'active',
      label: 'Active',
      options: {
        sort: false,
      },
    },
    {
      name: 'credits',
      label: 'Credits',
      options: {
        sort: false,
      },
    },
    { name: 'credits_unlimited', label: 'Credits Unlimited' },
    {
      name: 'credits_unlimited_anonymized',
      label: 'Credits Unlimited Anonymized',
    },
    {
      name: 'pii_fields',
      label: 'PII Fields',
      options: {
        hint:
          'Allow account to view / export pii fields (personal emails/phones/addresses',
      },
    },
    {
      name: 'analysis_only',
      label: 'Analysis Only',
      options: {
        hint:
          'Restrict account to only view/export analysis fields (person/company ids and title), overrides grant_access_to_pii_fields and restrict_access_to_anonymized_fields       ',
      },
    },
    {
      name: 'anonymous_only',
      label: 'Anonymous Only',
      options: {
        hint:
          'Restrict account to only view/export anonymized fields (maid, hashed emails, person/company ids), overrides grant_access_to_pii_fields',
      },
    },
    {
      name: 'feature_access',
      label: 'Feature Access',
      options: {
        hint: 'Control what features an account has access to',
      },
    },
    {
      name: 'last_30_days_records_exported',
      label: 'Records Exported (Last 30 Days)',
      options: {
        sort: false,
      },
    },
    {
      name: 'last_30_days_credits_used',
      label: 'Credits Used (Last 30 Days)',
      options: {
        sort: false,
      },
    },
    { name: 'expires', label: 'Expiration' },
  ]

  // TODO: Remove once server-side filtering is completed by @Yong
  // This allows the tables to be filtered by the filters that sit above the
  // data table.
  const filterPredicates = [
    (row: any) => {
      return row.active === filters.status?.value || filters.status === null
    },
    (row: any) => {
      return row.stage === filters.stage?.value || filters.stage === null
    },
    (row: any) => {
      return row.signup_source === filters.source?.value || filters.source === null
    },
  ]

  const canCreateAccount = userData.admin || userData.account.admin

  function triggerUpdate() {
    setEffectCounter((state: number) => state + 1)
  }

  const CreateAccount = () => {
    if (canCreateAccount) {
      return (
        <div>
          <Button
            size="small"
            variant="primary"
            text="Create Account"
            data-cy="createNewAccount"
            onClick={() => history.push('/admin/accounts/detail/new')}
          />
        </div>
      )
    }
    return <div />
  }

  const ActiveFilter = () => (
    <StandardAutocomplete
      tagSuggestions={[
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ]}
      multiple={false}
      disableClearable={false}
      value={filters.status}
      placeholder="Account Status"
      testId="accountStatus"
      onChange={(_: any, selection: any) => {
        setFilters((state) => ({ ...state, status: selection }))
      }}
      autocompleteProps={{
        getOptionLabel: (option: any) => {
          return option.text
        },
        getOptionSelected: (option: any, value: any) => {
          const result = option.value === value.value || option.value === value
          return result
        },
        renderOption: (option: any) => option.text,
      }}
    />
  )

  const SourceFilter = () => (
    <StandardAutocomplete
      tagSuggestions={[
        { text: 'NetWise', value: 'netwise' },
        { text: 'Organic', value: 'organic' },
      ]}
      multiple={false}
      disableClearable={false}
      value={filters.source}
      placeholder="Signup Source"
      testId="signupSource"
      onChange={(_: any, value) =>
        setFilters((state) => ({ ...state, source: value }))
      }
      autocompleteProps={{
        getOptionLabel: (option: any) => {
          return option.text
        },
        getOptionSelected: (option: any, value: any) => {
          const result = option.value === value.value || option.value === value
          return result
        },
        renderOption: (option: any) => option.text,
      }}
    />
  )

  async function getAccountData(id: number) {
    try {
      const { responseData } = await client.get({
        endpoint: client.endpoints.accountsIdAPI(id),
      })
      setSelectedAccount(responseData.results)
    } catch (e) {
      console.log(e)
      sendAlert({ message: 'Error loading account data' })
    }
  }

  async function updateAccount(id: number, data: any) {
    try {
      const { responseData, ok } = await client.patch({
        endpoint: client.endpoints.accountsFeatureFlagsAPI(id),
        data: data,
      })
      if (!ok) throw new Error()
      triggerUpdate()
      setSelectedAccount(responseData.results)
    } catch (e) {
      console.log(e)
      sendAlert({ message: 'Error updating account.' })
    }
  }

  const StageFilter = () => (
    <StandardAutocomplete
      tagSuggestions={[
        { text: 'Trial', value: 'trial' },
        { text: 'Internal', value: 'internal' },
        { text: 'Rev Share', value: 'rev_share' },
        { text: 'Freemium', value: 'freemium' },
        { text: 'Paying', value: 'paying' },
        { text: 'Cancelled', value: 'cancelled' },
      ]}
      multiple={false}
      disableClearable={false}
      value={filters.stage}
      placeholder="Account Stage"
      testId="accountStage"
      onChange={(_: any, value) =>
        setFilters((state) => ({ ...state, stage: value }))
      }
      autocompleteProps={{
        getOptionLabel: (option: any) => {
          return option.text
        },
        getOptionSelected: (option: any, value: any) => {
          const result = option.value === value.value || option.value === value
          return result
        },
        renderOption: (option: any) => option.text,
      }}
    />
  )

  const Controls = [PersonSearch, ActiveFilter, SourceFilter, StageFilter]

  return (
    <div style={{ width: '100%', padding: '1rem' }}>
      <Accordion expanded={true} title={'Accounts'}>
        <div style={{ padding: '1rem' }}>
          <RemoteDataTable
            setSnackbarMessage={setSnackbarMessage}
            client={client}
            sendAlert={sendAlert}
            adminMode={adminMode}
            filterPredicates={filterPredicates}
            refreshState={triggerUpdate}
            title="accountTable"
            url={client.endpoints.accountsTableAPI}
            columns={columns}
            rowsOnPage={50}
            TitleComponent={CreateAccount}
            customControls={Controls}
            externalSearchText={''}
            columnOrderMap={{
              stage: 'account_stage',
              signup_source: 'account_signup_source',
              active: 'is_active',
              credits: 'account_credits',
              pii_fields: 'grant_access_to_pii_fields',
              analysis_only: 'restrict_access_to_analysis_only_fields',
              anonymous_only: 'restrict_access_to_anonymized_fields',
            }}
            // not ordering correctly.
            //   active,
            //   credits,
            //   type,
            // No way to sort:
            //   last_30_days_records_exports,
            //   last_30_days_credits_used

            customRowRender={(row: any) => {
              const customRow = row.map((item: any) => {
                const name = (
                  <Button
                    text={item.name}
                    onClick={() => history.push(`admin/accounts/detail/${item.id}`)}
                    variant="secondary"
                    size="small"
                    data-cy="accountName"
                  />
                )

                const credits = (
                  <NumberWithDisappearingButton
                    canAddCredits={canAddCredits}
                    number={item.credits}
                    onClick={() => setAccountInfo({ id: item.id, name: item.name })}
                  />
                )

                const credits_unlimited = (
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Checkbox status={item.credits_unlimited} onClick={() => null} />
                  </div>
                )

                const credits_unlimited_anonymized = (
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Checkbox
                      onClick={() => null}
                      status={item.credits_unlimited_anonymized}
                    />
                  </div>
                )

                const active = (
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Checkbox onClick={() => null} status={item.active} />
                  </div>
                )

                const pii_fields = (
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Checkbox onClick={() => null} status={item.pii_fields} />
                  </div>
                )

                const analysis_only = (
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Checkbox onClick={() => null} status={item.analysis_only} />
                  </div>
                )

                const anonymous_only = (
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Checkbox onClick={() => null} status={item.anonymous_only} />
                  </div>
                )

                const feature_access = (
                  <div
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    <EditIcon
                      onClick={() => {
                        setDialogOpen(true)
                        getAccountData(item.id)
                      }}
                    />
                  </div>
                )

                const stage = toTitleCase(item.stage)
                const type = toTitleCase(item.type)
                const source = toTitleCase(item.signup_source)

                return {
                  ...item,
                  expires: item.expires?.local,
                  name: name,
                  stage: stage,
                  type: type,
                  signup_source: source,
                  credits: credits,
                  credits_unlimited: credits_unlimited,
                  credits_unlimited_anonymized: credits_unlimited_anonymized,
                  pii_fields: pii_fields,
                  analysis_only: analysis_only,
                  anonymous_only: anonymous_only,
                  feature_access: feature_access,
                  active: active,
                }
              })

              return customRow
            }}
          />
        </div>
        <AddCredits
          isOpen={!!accountInfo.id}
          toggle={() => setAccountInfo((state) => ({ ...state, id: undefined }))}
          accountId={accountInfo.id}
          accountName={accountInfo.name}
          triggerUpdate={triggerUpdate}
        />
      </Accordion>
      <FeatureAccessDialog
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        selectedAccount={selectedAccount}
        updateAccount={updateAccount}
      />
    </div>
  )
}
