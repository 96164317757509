import styled from 'styled-components'

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  overflow-y: scroll;
  width: 100%;
`

export default ContentArea
