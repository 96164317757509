import React from 'react'
import Button from 'components/Button'
import style from './numberWithDisappearingButton.module.css'

type Props = {
  canAddCredits: boolean
  number: number
  onClick: () => any
}

export default function NumberWithDisappearingButton(props: Props) {
  const [visible, setVisible] = React.useState(false)
  const selectedStyle = visible ? style.visible : style.invisible

  let UI: JSX.Element
  switch (props.number) {
    case null:
      UI = <div style={{ textAlign: 'center', fontSize: '1.25rem' }}>&infin;</div>
      break
    default:
      UI = (
        <div
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '0.25rem',
            cursor: 'default',
          }}
          onClick={props.onClick}
        >
          <span>{props.number?.toLocaleString()}</span>
          {props.canAddCredits && (
            <div className={selectedStyle}>
              <Button
                text="+"
                size="small"
                variant="secondary"
                onClick={props.onClick}
              />
            </div>
          )}
        </div>
      )
      break
  }

  return UI
}
