import styled from 'styled-components'
import { useCallback, useEffect, useState } from 'react'
import { colors } from '../../context/theme-context'
import { debounce } from '../../utils/debounce'
import SimpleInput from '../SimpleInput'
import { ReactComponent as SearchIcon } from '../../icons/16px/Search.svg'
import ClientDataTable from '../ClientDataTable'
import Button from '../Button'
import { useAuth } from '../../context/auth-context'
import SimpleAutoComplete from 'components/SimpleAutoComplete'

type Props = {
  exportState: any
  setExportState: any
  layouts: any
}

export default function LayoutFieldQuick(props: Props) {
  const { client } = useAuth()
  const { layoutsAPI, layoutsIdAPI } = client.endpoints

  const [search, setSearch] = useState()
  const debouncedSearch = debounce(setSearch, 200)
  const [data, setData] = useState([])
  const [previewState, setPreviewState] = useState(false)

  const handleTableData = useCallback(
    async (layoutHash) => {
      try {
        const { responseData } = await client.get({
          endpoint: layoutsIdAPI(layoutHash),
        })
        const {
          results: {
            fields: { available: saved_fields },
          },
        } = responseData
        setData(saved_fields)
      } catch (e) {
        console.log('Could not retrieve table data for layout.')
      }
    },
    [client, layoutsIdAPI, setData]
  )

  const { layouts, setExportState } = props
  useEffect(() => {
    if (layouts) {
      const layoutDefaultHashed = layouts[0]
      setExportState((state: any) => ({
        ...state,
        layout: layoutDefaultHashed,
      }))
      // get the table data for the default layout
      handleTableData(layoutDefaultHashed)
    }
  }, [setExportState, layouts, handleTableData])

  const handleTogglePreviewState = () => {
    setPreviewState(!previewState)
  }

  return (
    <ContentDiv>
      <RowDiv>
        <LabelSpan>Layout</LabelSpan>
        <InputSpan>
          <div style={{ flex: 4 }}>
            <SimpleAutoComplete
              client={client}
              value={props.exportState.layout}
              optionsUrl={layoutsAPI}
              valueField="hashed"
              labelField="name"
              additionalApiParameters={{
                sort: ['-last_used', '-modified'],
                dropdown_view: true,
              }}
              placeholder="Select Layout"
              onChange={(value: any) => {
                props.setExportState((state: any) => ({
                  ...state,
                  layout: value,
                }))
                handleTableData(value)
              }}
              data-cy="export_layout_autocomplete"
            />
          </div>

          {props.exportState.layout ? (
            previewState ? (
              <Button
                text="Hide"
                variant="secondary"
                size="medium"
                onClick={() => handleTogglePreviewState()}
                style={{ marginLeft: '0.5rem' }}
              />
            ) : (
              <Button
                text="Preview"
                variant="secondary"
                size="medium"
                onClick={() => handleTogglePreviewState()}
                style={{ marginLeft: '0.5rem' }}
              />
            )
          ) : (
            <div />
          )}
        </InputSpan>
      </RowDiv>

      {previewState && props.exportState.layout && (
        <ContainerDiv>
          <SearchDiv>
            <SimpleInput
              LeftAdornment={SearchIcon}
              defaultValue=""
              placeholder={'Search fields'}
              onClick={(e: any) => e.stopPropagation()}
              onChange={(e: any) => {
                debouncedSearch(e.target.value)
              }}
              containerProps={{
                style: { backgroundColor: 'white' },
              }}
            />
          </SearchDiv>
          <ClientDataTable
            title="layoutFieldQuick"
            searchText={search}
            controls={false}
            columns={[
              { name: 'field_name', label: 'Field Name' },
              { name: 'export_name', label: 'Export Name' },
            ]}
            data={data}
          />
        </ContainerDiv>
      )}
    </ContentDiv>
  )
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  font-weight: 400;
`

const ContainerDiv = styled.div`
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  border-radius: 0.25rem;
`

const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const LabelSpan = styled.span`
  display: flex;
  align-content: center;
  flex: 1;
`

const InputSpan = styled.span`
  display: flex;
  align-items: center;
  flex: 5;
`

const SearchDiv = styled.div`
  padding: 0.5rem;
`
