import React from 'react'
import { useAuth } from '../context/auth-context'
import GeneralSectionRun from './GeneralSection/GeneralSectionRun'
import GeneralSectionGeneric from './GeneralSection/GeneralSectionGeneric'

interface Props {
  exportState: any
  setExportState: any
  layouts: any
  dialogType: 'create' | 'edit' | 'quick' | 'run' | 'view'
  chosenExport: any
  disabled?: boolean
  audienceInfo?: any
  userData: any
  showAppendsCheckbox: boolean
}

const BaseGeneralSection: React.FC<Props> = (props) => {
  const { client } = useAuth()
  const { audiencesAPI } = client.endpoints

  return props.dialogType === 'run' ? (
    <GeneralSectionRun
      exportState={props.exportState}
      setExportState={props.setExportState}
      userData={props.userData}
      chosenExport={props.chosenExport}
      showAppendsCheckbox={props.showAppendsCheckbox}
    />
  ) : (
    <GeneralSectionGeneric
      chosenExport={props.chosenExport}
      exportState={props.exportState}
      setExportState={props.setExportState}
      audiencesAPI={audiencesAPI}
      layouts={props.layouts}
      disabled={props.disabled}
      showAppendsCheckbox={props.showAppendsCheckbox}
    />
  )
}

export default BaseGeneralSection
