import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import styled, { css } from 'styled-components'
import { colors, typography } from '../../context/theme-context'

const { medium } = typography.bodyRegular

const StyledTextareaAutosize = styled(TextareaAutosize)`
  flex: 1;
  font-size: ${medium.fontSize};
  font-weight: ${medium.fontWeight};
  line-height: ${medium.lineHeight};
  padding: 0.5rem;
  border: 1px solid ${colors.gray6};
  border-radius: 0.25rem;
  font-family: Rubik;
  resize: none;
  ::placeholder {
    color: ${colors.gray7};
  }
  :hover {
    ${(props) =>
      !props.disabled &&
      css`
        border: 1px solid ${colors.blue3};
      `}
  }
  :focus {
    color: ${colors.gray11};
    outline: none;
    border: 1px solid ${colors.blue6};
  }
  :disabled {
    background-color: ${colors.gray3};
  }
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
`

export { StyledTextareaAutosize }
