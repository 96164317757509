import styled from 'styled-components'
import ExactMatch from './Uploads/ExactMatch'
import UploadedFiles from './Uploads/UploadedFiles'
import MatchedFileTable from './Uploads/MatchedFileTable'
import PreviewFile from './Uploads/PreviewFile'
import { useEffect, useMemo, useState } from 'react'
import { colors } from '../../context/theme-context/'
import { useLocation } from 'react-router-dom'
import { initialState } from './Uploads/initialState'

export default function Uploads() {
  const [initialMatchState, setInitialMatchState] = useState(initialState)
  const [previewFileId, setPreviewFileId] = useState()
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const parser = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])

  useEffect(() => {
    const action = parser.get('action')
    switch (action) {
      case 'upload_new_file':
        makeInitialMatchState({ dialogIsOpen: true })
        break
      case 'to_matched_files':
        makeInitialMatchState({ uploadsExpanded: false })
        break
      default:
        break
    }
  }, [location, parser])

  function resetToInitialState() {
    setInitialMatchState(initialState)
  }

  function makeInitialMatchState(update: any) {
    setInitialMatchState((state) => ({ ...state, ...update }))
  }

  function triggerUpdate() {
    setRefreshCounter((state) => state + 1)
  }

  return (
    <UploadsContainer>
      <Header>Uploads and Matched Files</Header>
      <UploadedFiles
        setPreviewFileId={setPreviewFileId}
        makeInitialMatchState={makeInitialMatchState}
        refreshCounter={refreshCounter}
        setIsOpen={setIsOpen}
        uploadsExpanded={initialMatchState.uploadsExpanded}
      />
      <MatchedFileTable
        makeInitialMatchState={makeInitialMatchState}
        matchesExpanded={initialMatchState.matchesExpanded}
      />
      <PreviewFile
        previewFileId={previewFileId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <ExactMatch
        resetToInitialState={resetToInitialState}
        initialState={initialMatchState}
        triggerUpdate={triggerUpdate}
      />
    </UploadsContainer>
  )
}

export { initialState }

const UploadsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  overflow-y: scroll;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  padding: 0.75rem 0rem 0rem;
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${colors.blue8};
`
