import { CheckboxContainer } from './Css'
import { ReactComponent as CheckboxOn } from '../../icons/16px/Checkbox/On.svg'
import { ReactComponent as CheckboxOff } from '../../icons/16px/Checkbox/Off.svg'
import { ReactComponent as CheckboxHalf } from '../../icons/16px/Checkbox/Half.svg'
import { ReactComponent as Lock } from '../../icons/16px/Lock.svg'

export default function Checkbox({
  label = null,
  onClick,
  status,
  textProps = {},
  lock = false,
  disabled = false,
  iconProps = {},
  ...rest
}: any) {
  let Icon = CheckboxOn
  if ([true, 'on'].includes(status)) {
    Icon = CheckboxOn
  } else if ([false, 'off'].includes(status)) {
    Icon = CheckboxOff
  } else if (status === 'partial') {
    Icon = CheckboxHalf
  }

  return (
    <span style={{ display: 'flex', alignItems: 'center' }} {...rest}>
      <CheckboxContainer
        status={status}
        disabled={disabled}
        onClick={(e) => onClick(e)}
        {...textProps}
      >
        <Icon height={16} width={16} {...iconProps} />
        {label && <span>{label}</span>}
        {lock && <Lock />}
      </CheckboxContainer>
    </span>
  )
}
