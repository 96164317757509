import StandardDialog from 'components/StandardDialog'
import StandardAutocompleteRemote from 'components/StandardAutocompleteRemote'
import Button from 'components/Button/'
import { colors } from 'context/theme-context/'
import { bestFuzzyMatch } from 'utils/fuzzyMatch'
import { useAuth } from 'context/auth-context'
import StandardAutocomplete from 'components/StandardAutocomplete'
import MatchMaker from 'App/SmartHub/Uploads/ExactMatch/ExactMatchDialog/MatchMaker'

interface Props {
  disabled: boolean
  currentFile: any
  dbFields: Array<any>
  isOpen: boolean
  handleClose: () => void
  applyFileMatch: any
  dispatch: any
  matchFileField?: any
}

const DirectMatchModal: React.FC<Props> = (props) => {
  /*************************/
  /* Variable declarations */
  /*************************/
  const { client } = useAuth()
  const { filesAPI } = client.endpoints
  const original_fields = props.currentFile?.fields?.original
  const joins = props.currentFile?.joins

  /*********************/
  /* Component Methods */
  /*********************/
  function updateMatch(idx: any, update: any) {
    const _matchFields = [...joins]

    if (update.file_field) {
      update.database_field = bestFuzzyMatch(update.file_field, props.dbFields)
    }

    _matchFields[idx] = { ..._matchFields[idx], ...update }
    props.dispatch({
      type: 'updateMatch',
      payload: {
        currentFile: {
          ...props.currentFile,
          joins: _matchFields,
        },
      },
    })
  }

  function addMatch() {
    const _matchFields = joins ? [...joins] : []
    _matchFields.push({ database_field: 'company_domain', file_field: null })
    props.dispatch({
      type: 'addMatch',
      payload: {
        currentFile: {
          ...props.currentFile,
          joins: _matchFields,
        },
      },
    })
  }

  function removeMatch(idx: any) {
    const _matchFields = joins.filter((_: any, i: any) => i !== idx)
    props.dispatch({
      type: 'removeMatch',
      payload: {
        currentFile: {
          ...props.currentFile,
          joins: _matchFields,
        },
      },
    })
  }

  /* Content Creation */
  const InteractiveContent = (
    <div>
      <div style={{ marginBottom: '1.50rem' }}>
        Choose Matching Fields.&nbsp;
        <div style={{ color: colors.gray8, paddingTop: '0.5rem' }}>
          NEW: match against all emails in our database using the advanced "email"
          match types at the top of the match type dropdown (select "email" for raw
          or the appropriate hashed type), or choose a specific database field
          (company domain is preferred) to match to.
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
        <StandardAutocompleteRemote
          url={filesAPI}
          valueFilterField={'formatted_name'}
          additionalAPIparams={{
            size: 100,
            dropdown_view: true,
            include_pending: false,
            include_deleted: true,
            sort: '-created',
          }}
          label="File Name"
          labelProps={{ style: { fontSize: '0.875rem' } }}
          placeholder="Please select file to join"
          disabled={props.disabled}
          onChange={(_: any, value: any) => {
            const firstJoin = props.matchFileField(
              value.fields.original,
              props.dbFields
            )
            props.dispatch({
              type: 'onChange',
              payload: {
                currentFile: {
                  ...props.currentFile,
                  ...value,
                  joins: [firstJoin],
                },
              },
            })
          }}
          autocompleteProps={{
            getOptionLabel: (option: any) => {
              const name = option.formatted_name || option.name
              if (name) {
                return name + (option.deleted ? ' (DELETED)' : '')
              } else if (typeof name === 'undefined') {
                return ''
              } else {
                return 'File Missing Name'
              }
            },
            getOptionDisabled: (option: any) => {
              return !!option.deleted
            },
            getOptionSelected: (option: any, value: any) => {
              return [option.formatted_name, option.name].includes(
                value.formatted_name
              )
            },
            value: props.currentFile,
          }}
          multiple={false}
          data-cy="fileName"
        />

        <StandardAutocomplete
          label="Join Type"
          value={props.currentFile?.filter_exclude_matches}
          placeholder="Please select join type"
          tagSuggestions={[
            'Only Include Matching Records',
            'Exclude Matching Records',
          ]}
          onChange={(_: any, value: any) => {
            props.dispatch({
              type: 'onChange',
              payload: {
                currentFile: {
                  ...props.currentFile,
                  filter_exclude_matches: value,
                },
              },
            })
          }}
          multiple={false}
          testId="joinType"
        />
      </div>

      <MatchMaker
        match_fields={joins}
        updateMatch={updateMatch}
        addMatch={addMatch}
        removeMatch={removeMatch}
        dbFields={props.dbFields}
        fileColumns={original_fields}
      />
    </div>
  )

  const ActionButtons = (
    <Button
      text="Apply"
      onClick={props.applyFileMatch}
      variant="primary"
      testId="applyFileMatchButton"
    />
  )

  return (
    <StandardDialog
      title="Direct Match"
      Content={InteractiveContent}
      ActionButtons={ActionButtons}
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      fullWidth
      maxWidth="md"
    />
  )
}

export default DirectMatchModal
