export default async function copyToClipboard(text: any) {
  if (!navigator) {
    return
  }
  const result = await navigator.permissions.query({ name: 'clipboard-write' })

  if (result.state === 'granted' || result.state === 'prompt') {
    navigator.clipboard.writeText(text)
  }
}
