import { List } from '@material-ui/core'
import { ListAccordion } from '../../../../../components/Accordion/'
import { SimpleCheckbox } from '../../../../../components/Checkbox'
import { toTitleCase } from '../../../../../utils/caseHelpers'

export default function CompanySize({
  updateFilter,
  b2b_company_size,
  selectedFilters = [],
  divOnly = false,
}: any) {
  let tagSuggestions = []
  if (b2b_company_size) {
    tagSuggestions = b2b_company_size
  }

  function ListItem({ value }: any) {
    const selected = selectedFilters.includes(value)

    function handleClick() {
      let _selectedFilters
      if (selected) {
        _selectedFilters = selectedFilters.filter(
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'selection' implicitly has an 'any' type... Remove this comment to see the full error message
          (selection) => selection !== value
        )
      } else {
        _selectedFilters = [...selectedFilters, value]
      }
      updateFilter({ b2b_company_sizes: _selectedFilters })
    }

    return (
      <li onClick={handleClick} style={{ margin: '0.5rem 0rem' }}>
        <SimpleCheckbox selected={selected} label={toTitleCase(value)} />
      </li>
    )
  }

  return (
    <ListAccordion
      title="Company Size"
      count={selectedFilters?.length || ''}
      divOnly={divOnly}
    >
      <div style={{ width: '100%' }}>
        <List style={{ padding: 0 }}>
          {tagSuggestions.map((suggestion: any, i: any) => (
            <ListItem value={suggestion} key={i} />
          ))}
        </List>
      </div>
    </ListAccordion>
  )
}
