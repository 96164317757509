import StandardDialog from '../../../components/StandardDialog'
import Button from '../../../components/Button/'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useAlerts } from '../../../context/alert-context'
import UploadInterface from 'components/UploadInterface'
import UploadLayoutInfo, { LayoutInfoState } from './UploadLayoutInfo'
import { useAuth } from 'context/auth-context'
import { FileRejection } from 'react-dropzone'

interface UploadLayoutDialogState {
  showUpload: boolean
  userFile: any
  layoutInfoState: LayoutInfoState
}

interface Props {
  isOpen: boolean
  setIsOpen: any
}

export default function UploadLayoutDialog(props: Props) {
  const history = useHistory()
  const { sendAlert } = useAlerts()

  const { client } = useAuth()
  const { layoutsFileAPI } = client.endpoints

  const initialState = {
    layoutName: '',
    privacy: 'Team View',
  }

  const [state, setState] = useState<UploadLayoutDialogState>({
    showUpload: false,
    userFile: null,
    layoutInfoState: { ...initialState },
  })

  // This function gets called right after the user selects a file to upload.
  const onDropAccepted = async (files: File[]) => {
    setState((prev) => ({
      ...prev,
      userFile: files[0],
      layoutInfoState: {
        layoutName: files[0].name, // Prepopulate the layout name with the file's name for convenience.
        privacy: 'Team View',
      },
    }))
  }

  // This function gets called right after the user selects a file but the file errs out.
  // For example the file size is bigger than the accepted max size.
  const onDropRejected = (error: FileRejection[]) => {
    const err: string = error?.[0]?.errors?.[0]?.message

    if (err) {
      sendAlert({ message: err })
    } else {
      sendAlert({ message: 'An error occurred' })
    }

    close()
  }

  // Closes the dialog and cleans up state
  const close = () => {
    props.setIsOpen(false)

    setState({
      showUpload: false,
      userFile: null,
      layoutInfoState: { ...initialState },
    })
  }
  const setLayoutInfoState = (key: string, value: string) => {
    setState((prev) => ({
      ...prev,
      layoutInfoState: {
        ...prev.layoutInfoState,
        [key]: value,
      },
    }))
  }

  // Submit the selected file to the server.
  async function submitFile() {
    if (!state.layoutInfoState.layoutName.trim()) {
      sendAlert({ message: 'Please enter a layout name' })
      return
    }

    try {
      const fileData = new FormData()

      fileData.append('file', state.userFile, state.userFile.name)
      fileData.append('privacy', state.layoutInfoState.privacy)
      fileData.append('layout_name', state.layoutInfoState.layoutName)

      const res = await client.post({ endpoint: layoutsFileAPI, data: fileData })
      if (res.status === 201) {
        close()
        history.push(`layouts?layout=${res.responseData.results.hashed}`)
      } else {
        sendAlert({ message: res?.responseData?.message })
        close()
      }
    } catch {
      sendAlert({ message: 'An error occurred' })
      close()
    }
  }

  return (
    <StandardDialog
      title="Upload Layout"
      Content={
        !state.userFile ? (
          <UploadInterface
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            sizeLimitMb={10}
            subtitle="CSV files only"
          />
        ) : (
          <UploadLayoutInfo
            layoutName={state.layoutInfoState.layoutName}
            privacy={state.layoutInfoState.privacy}
            setLayoutInfoState={setLayoutInfoState}
          />
        )
      }
      ActionButtons={
        state.userFile && (
          <Button
            text="Upload File"
            id="upload_layout_button"
            onClick={submitFile}
            variant="primary"
            testId="uploadFile"
          />
        )
      }
      isOpen={props.isOpen}
      handleClose={close}
      fullWidth
      maxWidth="sm"
    />
  )
}

export type { Props }
