import fuzzy from 'fuzzy'
function bestFuzzyMatch(matchValue: string, listToMatch: Array<string>) {
  const results = fuzzy.filter(matchValue, listToMatch)
  var matches = results.map(function (el) {
    return el.string
  })
  return matches[0]
}

export { bestFuzzyMatch }
