import { Props as ExportDialogProps } from 'components/BaseExportDialog'
import SimpleAutoComplete from 'components/SimpleAutoComplete'
import ListAccordion from 'components/Accordion/ListAccordion'
import styled from 'styled-components'
import NetWiseClient from 'api/client'
import Skeleton from 'react-loading-skeleton'

const delimiterOptions = [
  { value: 'comma_standard', label: 'Comma Standard' },
  { value: 'comma_custom', label: 'Comma Custom' },
  { value: 'pipe', label: 'Pipe' },
  { value: 'tab', label: 'Tab' },
]

const stripDelimiterQuotesOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
]

const quotesOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
]

const compressionOptions = [
  { value: 'none', label: 'None' },
  { value: 'gz', label: 'gzip' },
]

const headerOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

export type FileStateKeys = {
  delimiter: 'comma_standard' | 'comma_custom' | 'pipe' | 'tab'
  stripDelimiterAndQuotes: boolean
  quotes: boolean
  compression: 'none' | 'gz'
  header: boolean
}

type Props = {
  setExportState: React.Dispatch<React.SetStateAction<any>>
  disabled?: boolean
  client: NetWiseClient
  dialogType: ExportDialogProps['dialogType']
}

export default function FileConfigurationSection(props: Props & FileStateKeys) {
  return (
    <ListAccordion
      title="File Configuration"
      bottomBorder={false}
      data-cy="fileConfiguration"
    >
      <ContentDiv>
        {!props.delimiter ? (
          <span>
            <Skeleton height={168} />
          </span>
        ) : (
          <>
            <RowDiv>
              <SimpleAutoComplete
                disabled={props.disabled}
                label="Delimiter"
                client={props.client}
                value={props.delimiter}
                options={delimiterOptions}
                onChange={(value) => {
                  props.setExportState((state: any) => ({
                    ...state,
                    delimiter: value,
                  }))
                }}
                data-cy="delimiter"
                disableClearable={true}
              />

              {props.dialogType !== 'quick' && (
                <SimpleAutoComplete
                  disabled={props.disabled}
                  label="Strip Delimiter/Quotes"
                  client={props.client}
                  value={props.stripDelimiterAndQuotes}
                  options={stripDelimiterQuotesOptions}
                  onChange={(value) => {
                    props.setExportState((state: any) => ({
                      ...state,
                      strip_delimiter_and_quotes: value,
                    }))
                  }}
                  data-cy="stripDelimiterQuotes"
                />
              )}
            </RowDiv>

            <RowDiv>
              {props.dialogType !== 'quick' && (
                <SimpleAutoComplete
                  disabled={props.disabled}
                  label="Quotes"
                  client={props.client}
                  value={props.quotes}
                  options={quotesOptions}
                  onChange={(value) => {
                    props.setExportState((state: any) => ({
                      ...state,
                      quotes: value,
                    }))
                  }}
                  data-cy="quotes"
                />
              )}

              <SimpleAutoComplete
                disabled={props.disabled}
                label="Compression"
                client={props.client}
                value={props.compression || ''}
                options={compressionOptions}
                onChange={(value) => {
                  props.setExportState((state: any) => ({
                    ...state,
                    compression: value,
                  }))
                }}
                data-cy="compression"
              />

              <SimpleAutoComplete
                disabled={props.disabled}
                label="Header"
                client={props.client}
                value={props.header}
                options={headerOptions}
                onChange={(value) => {
                  props.setExportState((state: any) => ({
                    ...state,
                    header: value,
                  }))
                }}
                data-cy="header"
              />
            </RowDiv>
          </>
        )}
      </ContentDiv>
    </ListAccordion>
  )
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  font-weight: 400;
`
const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 1rem;
`
