import React from 'react'
import Skeleton from 'react-loading-skeleton'
import isUndefined from 'utils/isUndefined'
import NetWiseClient from 'api/client'
import { TextField } from '@material-ui/core'
import { StyledAutocomplete, AutocompleteLabel } from './StandardAutocomplete/Css'
import { StyledListBox, listStyle } from './StandardAutocomplete/Css'
import { ReactComponent as ChevronDown } from '../icons/16px/Chevron/Down.svg'
import { debounce } from '../utils/debounce'
import { log } from 'utils/debug'

// * PURPOSE *
// This should cover two cases:
// 1) When the value should be presented directly to the user.
// 2) When there is a label that should be presented to the user instead of
//    of the underlying value, i.e. "number_one_choice" should be presented as
//    "Number One Choice", or there is another property on the object which should
//    be used as the label.

type Option = {
  label: string
  value: any
}
type Props = {
  value: any
  client?: NetWiseClient
  audienceName?: string
  valueField?: string
  optionsUrl?: string
  disabled?: boolean
  labelField?: string
  label?: string
  options?: Array<Option> | Array<string>
  placeholder?: string
  additionalApiParameters?: any
  disableClearable?: boolean
  onChange: (selection: any) => void
  'data-cy'?: string
}
export default function SimpleAutoComplete(props: Props) {
  const [searchText, setSearchText] = React.useState('')
  const debouncedSearch = debounce(setSearchText, 200)

  // If we provide our own options, we need to make sure that they are in the right Option format
  let standardOptions: Array<Option> | undefined = undefined
  if (props.options && props.options.length > 0) {
    const firstOption = props.options[0]

    if (typeof firstOption === 'string') {
      standardOptions = props.options.map((option: any) => ({
        label: option,
        value: option,
      }))
    } else if (typeof firstOption === 'object' && firstOption.label) {
      standardOptions = props.options as Array<Option>
    } else {
      throw Error('the options prop is not formatted correctly')
    }
  }

  const [options, setOptions] = React.useState(standardOptions)

  // There is probabably a typescript way to express this
  if (!!props.optionsUrl && !props.valueField) {
    throw Error(
      'Selecting an endpoint for options (optionsUrl) requires that you also ' +
        'specify valueField'
    )
  }

  // If provided an endpoint, get the remote options filtered by text, and then standardize them.
  // If no label field is specified, then the value field is used
  React.useEffect(() => {
    // This if is for typescript narrowing
    const _valueField = props.valueField
    const audienceName = props.audienceName

    if (props.optionsUrl && _valueField && props.client) {
      const apiParams = {
        size: 50,
        filter: searchText || audienceName || '',
        sort: '-modified',
        dropdown_view: true,
        ...props.additionalApiParameters,
      }
      try {
        props.client
          .get({ endpoint: props.optionsUrl, data: apiParams })
          .then((res) => {
            const { results } = res.responseData
            const standardResults = results.map((result: any) => {
              return {
                label: result[props.labelField ?? _valueField],
                value: result[_valueField],
              }
            })
            setOptions(standardResults)
          })
      } catch (e: unknown) {
        log(e as string)
      }
    }
  }, [
    props.client,
    props.audienceName,
    props.optionsUrl,
    props.valueField,
    props.labelField,
    props.additionalApiParameters,
    setOptions,
    searchText,
  ])

  // In the event that the value we're passing in to the component has no label attached to it,
  // we'll need a map to associate the value to the proper label.
  const labelMap: Record<any, string> = {}
  options?.forEach((option) => (labelMap[option.value] = option.label))

  // If there is no label field specified, simply return the option as the label, and compare
  // options and values directly. Otherwise, use the label map to look up the appropriate label.
  const getOptionLabel = (option: any) => {
    // A special case is the following: Autocomplete also uses this function to selection the label
    // for `value`. However, value is _not_ of type option, which means it has no attached label property.
    // For this reason we need to use the label map to look up the appropriate label value.
    // This is what makes dealing with material-ui/core/AutoComplete difficult.
    return (option as Option).label ?? labelMap[option] ?? ''
  }
  const getOptionSelected = (option: any, value: any) => {
    return (option as Option).value === value
  }

  // Values are there but they are not being selected.
  const AutoComplete = (
    <StyledAutocomplete
      value={props.value === '' ? null : props.value} // value must match an option or be null
      size="small"
      freeSolo={false}
      disabled={props.disabled}
      options={options ?? []}
      onChange={(_, selection) => {
        const value = { value: selection, ...(selection as Option | null) }.value
        props.onChange(value)
        debouncedSearch('')
      }}
      onInputChange={(_, text) => debouncedSearch(text || '')}
      popupIcon={<ChevronDown />}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      autoHighlight={true}
      disableClearable={props.disableClearable}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, 'data-cy': props['data-cy'] }}
            variant="outlined"
            placeholder={props.placeholder}
          />
        )
      }}
      PaperComponent={(params) => <StyledListBox {...params} />}
      ListboxProps={{ style: listStyle }}
      noOptionsText={
        <span style={{ ...listStyle, padding: '1rem' }}>No Options</span>
      }
    />
  )

  // Since the options are being fetched remotely, we want to wait until they are
  // available to render the component or even call the function.
  const isLoading = isUndefined(options)
  const UI = isLoading ? (
    <span data-cy={props['data-cy'] + '-skeleton'}>
      <Skeleton height={40} />
    </span>
  ) : (
    AutoComplete
  )

  return (
    <div style={{ flex: 1 }}>
      {props.label && <AutocompleteLabel>{props.label}</AutocompleteLabel>}
      {UI}
    </div>
  )
}
