import styled from 'styled-components'
import colors from '../../context/theme-context/colors'
import typography from '../../context/theme-context/typography'
import { ReactComponent as Checkmark } from '../../icons/16px/Check.svg'
import { ReactComponent as Exmark } from '../../icons/16px/Exmark.svg'

const { h4 } = typography

const ContentDiv = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${h4.fontWeight};
  font-size: 1rem;
  line-height: ${h4.lineHeight};
  color: ${colors.gray9};
  background-color: ${colors.gray2};
  border: 2px solid ${colors.green8};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  padding: 1rem 0.75rem;
`

const UndoDiv = styled.div`
  font-weight: ${h4.fontWeight};
  line-height: ${h4.lineHeight};
  color: ${colors.blue6};
  cursor: pointer;
`

const TextDiv = styled.div`
  margin: 0rem 1rem 0rem 0.5rem;
  cursor: pointer;
`

const StyledCheckmark = styled(Checkmark)`
  fill: ${colors.green8};
  height: 1.5rem;
  width: 1.5rem;
`

const StyledExmark = styled(Exmark)`
  fill: ${colors.red8};
  height: 1.5rem;
  width: 1.5rem;
`

export { ContentDiv, UndoDiv, TextDiv, StyledCheckmark, StyledExmark }
