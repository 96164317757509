import { useState, useEffect } from 'react'
import Checkbox from './Checkbox'

interface Props {
  label?: any
  onClick?: any
  selected?: boolean
  textProps?: any
}

const SimpleCheckbox: React.FC<Props> = ({
  label,
  onClick = () => null,
  selected = false,
  textProps = {},
  ...rest
}) => {
  // Allows for controlled & uncontrolled checkboxes
  let [isSelected, setIsSelected] = useState(selected)

  useEffect(() => {
    setIsSelected(selected)
  }, [selected])

  function handleClick(e: any) {
    try {
      onClick(e)
      setIsSelected((isSelected) => !isSelected)
    } catch (e) {
      console.log('Could not handle click')
    }
  }

  return (
    <Checkbox
      label={label}
      onClick={handleClick}
      status={isSelected}
      textProps={textProps}
      data-cy={label}
      {...rest}
    />
  )
}

function MultiCheckbox({ label, status, onClick = () => null, ...rest }: any) {
  return <Checkbox label={label} onClick={onClick} status={status} {...rest} />
}

export default Checkbox
export { SimpleCheckbox, MultiCheckbox }
