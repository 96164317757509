import { AuthProvider } from './context/auth-context'
import { UserProvider } from './context/user-context'
import { SnackbarProvider } from './context/snackbar-context'
import { MediaQueryProvider } from './context/mediaQuery-context'
import { AlertProvider } from './context/alert-context'
import NetwiseThemeProvider from './context/theme-context'

function AppProviders({ children }: any) {
  return (
    <SnackbarProvider>
      <AlertProvider>
        <MediaQueryProvider>
          <AuthProvider>
            <UserProvider>
              <NetwiseThemeProvider>{children}</NetwiseThemeProvider>
            </UserProvider>
          </AuthProvider>
        </MediaQueryProvider>
      </AlertProvider>
    </SnackbarProvider>
  )
}

export default AppProviders
