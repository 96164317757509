import React from 'react'
import Button from 'components/Button'
import style from './ItemWithFadeButton.module.css'

type Props = {
  item: JSX.Element
  buttonText: string
  onClick: () => any
  style?: any
}

export default function ItemWithFadeButton(props: Props) {
  const [visible, setVisible] = React.useState(false)
  const selectedStyle = visible ? style.visible : style.invisible
  const UI = (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '0.25rem',
        cursor: 'default',
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.item}
      <div className={selectedStyle}>
        <Button
          text={props.buttonText}
          size="small"
          variant="secondary"
          onClick={props.onClick}
        />
      </div>
    </div>
  )

  return UI
}
