import styled from 'styled-components'
import { colors } from '../../context/theme-context/'

export default function LinkButton({ Icon = null, text, ...rest }: any) {
  return (
    <LinkContainer {...rest}>
      {Icon && <Icon />}
      {text}
    </LinkContainer>
  )
}

const LinkContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${colors.blue8};
  cursor: pointer;
  svg {
    height: 0.875rem;
    width: 0.875rem;
  }
`
