import NetWiseClient from 'api/client'

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
const { grecaptcha } = window as any

type Args = {
  client: NetWiseClient
  action: string
  threshold?: number
  onSuccess?: () => any
  onFailure?: () => any
}

const recaptcha = {
  evaluateScore: (args: Args) => {
    const { threshold = 0.5 } = args
    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(SITE_KEY, {
          action: args.action,
        })

        const url = 'recaptcha/get_recaptcha_score'
        const { responseData } = await args.client.post({
          endpoint: url,
          data: { token: token, action: args.action },
          useCache: false,
          auth: false,
        })

        const score = responseData.results
        if (score >= threshold) args.onSuccess?.()
        else args.onFailure?.()
      } catch (e: any) {
        console.log('RECAPTCHA evaluation failed: ' + e.message)
      }
    })
  },
  promptCustomerSupport: (sendAlert: any) => {
    sendAlert({
      message:
        'Please try to sign up again later. If you have already received this message, please ' +
        'contact our sales department by using the chat in the bottom right-hand corner of this page.',
    })
    setTimeout(() => {
      const pendoResourceCenter: HTMLElement | null = document.querySelector(
        '._pendo-resource-center-badge-image'
      )
      pendoResourceCenter?.click()
    }, 300)
  },
}

const RecaptchaDisclaimer = () => (
  <span style={{ fontSize: '0.75rem' }}>
    This site is protected by reCAPTCHA and the Google{' '}
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
  </span>
)

export default recaptcha
export { RecaptchaDisclaimer }
