import Button from 'components/Button'
import { UserData } from 'types'

type Props = {
  userData: UserData
  unassumeUser: () => void
}

export default function AssumedUserInfo(props: Props) {
  const { first_name, last_name, email } = props.userData
  return (
    <div style={{ display: 'flex', margin: '0.5rem', alignItems: 'center' }}>
      Logged in as&nbsp;
      <strong>
        {first_name} {last_name} ({email})
      </strong>
      <span style={{ marginLeft: '0.5rem' }}>
        <Button
          size="small"
          variant="warning"
          text="Unassume User"
          onClick={props.unassumeUser}
          testId="unassumeUser"
        />
      </span>
    </div>
  )
}
