import { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import MenuList from '@material-ui/core/MenuList'
import Popper from '@material-ui/core/Popper'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Grow from '@material-ui/core/Grow'
import { colors, typography } from '../../../../context/theme-context/'

interface NavItemI {
  to?: string
  NavContent: any
  id?: string
  subNav?: any
  handleClick?: any
  smallScreen?: any
}

const NavItem: React.FC<NavItemI> = ({
  to,
  NavContent,
  subNav = [],
  handleClick = null,
  smallScreen = false,
  id = null,
}) => {
  const [isOpen, setOpen] = useState(false)
  const anchorEl = useRef(null)
  const { pathname, search } = window.location
  const history = useHistory()
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    <div onMouseLeave={() => setOpen(false)} id={id}>
      <div ref={anchorEl} onMouseOver={() => setOpen(true)}>
        {to ? (
          <StyledLink to={to}>{NavContent}</StyledLink>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={handleClick}>
            {NavContent}
          </div>
        )}
      </div>
      <Popper
        open={isOpen}
        anchorEl={anchorEl.current}
        role={undefined}
        transition
        disablePortal
        placement={smallScreen ? 'right-start' : 'bottom-start'}
        style={{ zIndex: 10000000 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'top' }}>
            <MenuContainer>
              <MenuList
                autoFocusItem={isOpen}
                id="menu-list"
                style={{ padding: 0, border: 'none' }}
              >
                {subNav.map((item: any, i: any) => (
                  <OuterContainer
                    key={i}
                    isFirst={i === 0}
                    isLast={i === subNav.length - 1}
                    id={item?.id || null}
                  >
                    <StyledMenuItem
                      key={i}
                      data-cy={`subnav-${item.name}`}
                      isLast={i === subNav.length - 1}
                      onClick={() => {
                        setOpen(false)
                        if (item.fn) {
                          item.fn()
                        }
                        if (item.link) history.push(item.link)
                      }}
                    >
                      {item.link ? (
                        <StyledSubLink selected={pathname + search === item.link}>
                          {item.name}
                        </StyledSubLink>
                      ) : (
                        <StyledFakeSubLink
                          selected={pathname + search === item.link}
                        >
                          {item.name}
                        </StyledFakeSubLink>
                      )}
                    </StyledMenuItem>
                  </OuterContainer>
                ))}
              </MenuList>
            </MenuContainer>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const { big } = typography.bodyRegular

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledSubLink = styled.span<{ selected?: boolean }>`
  padding: 0em;
  font-size: 1em;
  color: ${colors.blue6};
  ${(props) =>
    props.selected &&
    css`
      color: ${colors.blue9};
      font-weight: 500;
    `}
`

const StyledFakeSubLink = styled.div<{ selected?: boolean }>`
  padding: 0em;
  font-size: 1em;
  color: ${colors.blue6};
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      color: ${colors.blue9};
      font-weight: 500;
    `}
`

const MenuContainer = styled.div`
  background-color: ${colors.gray1};
  box-shadow: 0rem 0.625rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
`

const OuterContainer = styled.div<{ isFirst?: boolean; isLast?: boolean }>`
  :hover {
    background-color: ${colors.gray3};
  }
  ${(props) =>
    props.isFirst &&
    css`
      border-radius: 0.5rem 0.5rem 0rem 0rem;
    `}
  ${(props) =>
    props.isLast &&
    css`
      border: none;
      border-radius: 0rem 0rem 0.5rem 0.5rem;
    `}
`

const StyledMenuItem = styled.div<{ isLast: boolean }>`
  font-size: ${big.fontSize};
  font-weight: ${big.fontWeight};
  line-height: ${big.lineHeight};
  background-color: ${colors.gray1};
  padding: 0.8125rem 0rem;
  margin-left: 1rem;
  border-bottom: 1px solid ${colors.gray5};
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  width: 15.625rem;
  cursor: pointer;
  :hover {
    background-color: ${colors.gray3};
  }
  ${(props) =>
    props.isLast &&
    css`
      border: none;
      border-radius: 0rem 0rem 0.5rem 0.5rem;
    `}
`

export default NavItem
