import SimpleInput from 'components/SimpleInput'
import StandardAutocomplete from 'components/StandardAutocomplete'
import Checkbox from 'components/Checkbox'
import { toTitleCase } from 'utils/caseHelpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import InputSubtitle from './InputSubtitle'
import { BasicInformation } from '../adminTypes'

interface Props {
  basicInfo: BasicInformation
  updateInfo: (update: Record<'basicInformation', any>) => void
}

export default function BasicInfo({ basicInfo, updateInfo }: Props) {
  // Wraps update function with proper key
  const wrapper = (update: Partial<BasicInformation>) => {
    updateInfo({ basicInformation: { ...basicInfo, ...update } })
  }

  return (
    <>
      <h3>Basic Information (Required)</h3>
      <SimpleInput
        value={basicInfo.name}
        label="Account Name"
        placeholder="Enter account name here"
        data-cy="accountName"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          wrapper({ name: e.target.value })
        }
      />
      <SimpleInput
        label="Account Domain"
        value={basicInfo.domain}
        placeholder="Enter account domain here"
        data-cy="accountDomain"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          wrapper({ domain: e.target.value })
        }
      />
      <StandardAutocomplete
        tagSuggestions={[
          '',
          'trial',
          'internal',
          'rev_share',
          'freemium',
          'paying',
          'cancelled',
        ]}
        label="Account Stage"
        placeholder="Trial"
        testId="accountStage"
        value={basicInfo.stage || ''}
        multiple={false}
        onChange={(_: any, value: string) => wrapper({ stage: value })}
        autocompleteProps={{
          getOptionLabel: (option: any) => {
            return toTitleCase(option)
          },
          getOptionSelected: (option: any, value: any) => {
            return option === value
          },
          renderOption: (option: any) => toTitleCase(option),
        }}
      />
      <SimpleInput
        label="Salesforce Record ID"
        data-cy="salesforceRecordId"
        value={basicInfo.salesforceRecordId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          wrapper({ salesforceRecordId: e.target.value })
        }
      />
      <SimpleInput
        label="Salesforce URL"
        data-cy="salesforceUrl"
        value={basicInfo.salesforceUrl}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          wrapper({ salesforceUrl: e.target.value })
        }
      />
      <SimpleInput
        label="Total Contract Value"
        data-cy="totalContractValue"
        value={basicInfo.totalContractValue ? 
          '$ ' + basicInfo.totalContractValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ '} // format as currency by adding commas
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = parseInt(e.target.value.replace(/\D/g,'')); // remove any non-numeric characters
            wrapper({ totalContractValue: value })
          } 
        }
      />
      <SimpleInput
        label="CSM Owner"
        data-cy="csmOwner"
        value={basicInfo.csmOwner}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value
            e.target.value = value.replace(/[0-9]/g, ''); // Don't allow numeric characters
            wrapper({ csmOwner: e.target.value })
          }
        }
      />
      <SimpleInput
        label="Order Number"
        type="number"
        data-cy="orderNumber"
        value={basicInfo.orderNumber}
        defaultValue={''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          wrapper({ orderNumber: parseInt(e.target.value) })
        }
      />
      <div style={{ width: '153px' }}>
        <div style={{ marginBottom: '0.5rem' }}>Contract Start Date</div>
        <DatePicker
          data-cy="contractStartDate"
          value={basicInfo?.contractStartDate?.toString().slice(4, 15)}
          onChange={(date) => wrapper({ contractStartDate: date || undefined })}
          popperPlacement="top"
        />
      </div>
      <div style={{ width: '153px' }}>
        <div style={{ marginBottom: '0.5rem' }}>Contract End Date</div>
        <DatePicker
          data-cy="contractEndDate"
          value={basicInfo?.contractEndDate?.toString().slice(4, 15)}
          onChange={(date) => wrapper({ contractEndDate: date || undefined })}
          popperPlacement="top"
        />
      </div>
      <div style={{ marginTop: '0.5rem' }}>
        <Checkbox
          label="Is Active"
          data-cy="accountIsActive"
          status={basicInfo.isActive}
          onClick={() => wrapper({ isActive: !basicInfo.isActive })}
        />
        <InputSubtitle>
          Used to toggle if account is active (non-active accounts cannot use the
          app)
        </InputSubtitle>
      </div>
      <div style={{ width: '153px' }}>
        <div style={{ marginBottom: '0.5rem' }}>Expires</div>
        <DatePicker
          showTimeInput={true}
          value={basicInfo?.expires?.toString().slice(4, 15)}
          onChange={(date) => wrapper({ expires: date || undefined })}
          popperPlacement="top"
        />
        <InputSubtitle>
          Note, you are about 7 hours behind server time.
          <br />
          Account expiration date, access will be revoked after this date.
        </InputSubtitle>
      </div>
    </>
  )
}
