import { ListAccordion } from '../Accordion'
import styled from 'styled-components'
import SimpleInput from '../SimpleInput'
import { StyledTextareaAutosize } from '../TextareaAutosize'
import StandardAutocomplete from '../StandardAutocomplete'
import LayoutFieldCreate from '../ExportDialog/LayoutFieldCreate'
import Checkbox from 'components/Checkbox'

type GeneralSectionRunProps = {
  exportState: {
    file_name: string
    description: string
    jira_ticket?: string
    email_notifications: string[]
    enable_appends: boolean
  }
  setExportState: React.Dispatch<
    React.SetStateAction<{
      file_name: string
      description: string
      jira_ticket?: string
      email_notifications: string[]
      enable_appends: boolean
    }>
  >
  chosenExport: any
  userData: {
    account: {
      admin: boolean
    }
  }
  showAppendsCheckbox: boolean
}

export default function GeneralSectionRun(props: GeneralSectionRunProps) {
  return (
    <ListAccordion title="General" expanded data-cy="general">
      <ContentDiv>
        <RowDiv>
          <LabelSpan>File Name</LabelSpan>
          <InputSpan>
            <SimpleInput
              debounced={true}
              defaultValue={props.exportState.file_name}
              onChange={(e: any) =>
                props.setExportState((state: any) => ({
                  ...state,
                  file_name: e.target.value,
                }))
              }
              data-cy="fileName"
            />
          </InputSpan>
        </RowDiv>
        <RowDiv style={{ alignItems: 'flex-start' }}>
          <LabelSpan style={{ paddingTop: '0.5rem' }}>
            Saved Export Description
          </LabelSpan>
          <InputSpan>
            <StyledTextareaAutosize
              placeholder="Please enter description here"
              style={{ height: '75px' }}
              defaultValue={props.exportState.description}
              disabled
              data-cy="savedExportDescription"
            />
          </InputSpan>
        </RowDiv>
        {props.userData.account.admin && (
          <RowDiv>
            <LabelSpan>JIRA Ticket</LabelSpan>
            <InputSpan>
              <SimpleInput
                value={props.exportState.jira_ticket ?? ''}
                onChange={(e: any) =>
                  props.setExportState((state: any) => ({
                    ...state,
                    jira_ticket: e.target.value,
                  }))
                }
                data-cy="jiraTicket"
              />
            </InputSpan>
          </RowDiv>
        )}
        <RowDiv>
          <LabelSpan>Notify Me At</LabelSpan>
          <InputSpan>
            <StandardAutocomplete
              freeSolo
              multiple={true}
              value={props.exportState.email_notifications}
              onChange={(_: any, value: any) => {
                props.setExportState((state: any) => ({
                  ...state,
                  email_notifications: value,
                }))
              }}
              testId="notifyMeAt"
            />
          </InputSpan>
        </RowDiv>
        <LayoutFieldCreate
          disabled={true}
          exportState={props.exportState}
          setExportState={props.setExportState}
          chosenExport={props.chosenExport}
        />
        {props.showAppendsCheckbox && (
          // Check if the 'should_enable_appends' property is set to true in the service
          // and display the corresponding checkbox in the UI if it is enabled.
          <RowDiv>
            <div style={{ flex: 1, justifyContent: 'center' }}>Add Input Field</div>
            <InputSpan>
              <Checkbox
                onClick={() => {
                  props.setExportState((state) => ({
                    ...state,
                    enable_appends: !state.enable_appends,
                  }))
                }}
                status={props.exportState.enable_appends}
                data-cy="includeInputField"
                id="includeInputField"
              />
            </InputSpan>
          </RowDiv>
        )}
      </ContentDiv>
    </ListAccordion>
  )
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  font-weight: 400;
`
const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const LabelSpan = styled.span`
  padding-top: 10px;
  display: flex;
  align-self: flex-start;
  flex: 1;
`

const InputSpan = styled.span`
  display: flex;
  flex: 5;
`
