import StandardDialog from '../../../../components/StandardDialog'
import Button from '../../../../components/Button'
import SimpleInput from '../../../../components/SimpleInput'
import { useState, useEffect } from 'react'
import { useAuth } from '../../../../context/auth-context'
import StandardAutocomplete from '../../../../components/StandardAutocomplete'
import { toTitleCase } from '../../../../utils/caseHelpers'
import tm from '../../../../analytics/TagManager'

export default function SaveAudienceModal({
  isOpen,
  handleClose,
  saveAudience,
  updateAudience,
  privacy,
  audienceInfo,
  dispatch,
  updateFilter,
}: any) {
  const {
    audience_description,
    audience_name,
    audience_id_hashed,
    audience_id,
    editable,
  } = audienceInfo
  const [LOCALaudienceInfo, LOCALsetAudienceInfo] = useState({
    audience_description,
    audience_name,
    audience_id_hashed,
    audience_id,
    editable,
  })
  const [nameError, setNameError] = useState(false)
  const [allAudienceNames, setAllAudienceNames] = useState([])
  const { client } = useAuth()
  const { audiencesAPI } = client.endpoints

  useEffect(() => {
    LOCALsetAudienceInfo({
      audience_description,
      audience_name,
      audience_id_hashed,
      audience_id,
      editable,
    })
  }, [
    audience_description,
    audience_name,
    audience_id_hashed,
    audience_id,
    editable,
  ])

  useEffect(() => {
    async function getAudienceNames() {
      try {
        const { responseData } = await client.get({
          endpoint: audiencesAPI,
          data: {
            dropdown_view: true,
            size: 100,
            sort: '-modified',
            include_autosave: false,
          },
        })
        const { results } = responseData
        const _allNames = results.map((item: any) => item.name)
        setAllAudienceNames(_allNames)
      } catch (e) {
        console.log('Could not retrieve data at this time.')
      }
    }
    getAudienceNames()
  }, [client, audiencesAPI])

  function checkUniqueName(newName: any) {
    const alreadyExists = allAudienceNames.reduce((pv, cv) => {
      if (pv === true) {
        return true
      }
      const exists = newName.toLowerCase() === (cv as any).toLowerCase()
      return exists
    }, false)

    if (alreadyExists) {
      setNameError(true)
    } else {
      setNameError(false)
    }
  }

  const Content = (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
      <SimpleInput
        error={nameError}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | "(Please choose a unique name for th... Remove this comment to see the full error message
        helperText={nameError && '(Please choose a unique name for this audience)'}
        placeholder="Audience Name"
        label="Enter Audience Name"
        value={LOCALaudienceInfo.audience_name}
        onChange={(e: any) => {
          const text = e.target.value
          LOCALsetAudienceInfo({ ...LOCALaudienceInfo, audience_name: text })
          checkUniqueName(text)
        }}
        debounced={false}
        data-cy="audienceName"
      />
      <SimpleInput
        label="Description"
        placeholder="Enter Description"
        value={LOCALaudienceInfo.audience_description}
        onChange={(e: any) =>
          LOCALsetAudienceInfo({
            ...LOCALaudienceInfo,
            audience_description: e.target.value,
          })
        }
        data-cy="audienceDescription"
      />
      <StandardAutocomplete
        tagSuggestions={['private', 'team_view', 'team_edit']}
        label="Privacy"
        autocompleteProps={{
          getOptionLabel: (value: any) => toTitleCase(value),
          getOptionSelected: (option: any, value: any) =>
            toTitleCase(option) === value,
        }}
        placeholder="Select Privacy"
        value={toTitleCase(privacy)}
        onChange={(_: any, value: any) => updateFilter({ privacy: value })}
        multiple={false}
        testId="audiencePrivacy"
      />
    </div>
  )

  function save() {
    const { audience_name, audience_description } = LOCALaudienceInfo
    if (audience_name && !nameError) {
      dispatch({
        payload: { audienceInfo: { ...audienceInfo, ...LOCALaudienceInfo } },
      })
      saveAudience(audience_name, audience_description)
      setNameError(false)
      tm.captureCustomEvent('audiences_save')
    }
    if (!audience_name) {
      setNameError(true)
    }
  }

  function update() {
    const { audience_name, audience_description } = LOCALaudienceInfo
    if (audience_name && !nameError) {
      dispatch({
        payload: { audienceInfo: { ...audienceInfo, ...LOCALaudienceInfo } },
      })
      updateAudience(
        audience_name,
        audience_description,
        audience_id_hashed,
        audience_id
      )
      setNameError(false)
    }
    if (!audience_name) {
      setNameError(true)
    }
  }

  const UpdateButton = audience_id_hashed ? (
    <Button
      onClick={update}
      variant="primary"
      text={'Update Current'}
      disabled={!editable || privacy === 'global_view'}
      testId="updateAudience"
    />
  ) : (
    <div />
  )

  const ActionButtons = (
    <>
      <Button
        onClick={save}
        variant="primary"
        text={audience_id_hashed ? 'Save as New' : 'Save'}
        testId="saveAudience"
      />
      {UpdateButton}
    </>
  )

  return (
    <StandardDialog
      title={'Save / Update Audience'}
      Content={Content}
      ActionButtons={ActionButtons}
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth
      maxWidth="md"
      data-cy="audienceDialog"
    />
  )
}
