import React from 'react'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import { LinearProgress } from '@material-ui/core'

enum RequestStatus {
  IDLE,
  INITIATED,
  COMPLETED,
}

type Props = {
  resetTarget: string
  requestReset: (email: string) => any
  setResetTarget: React.Dispatch<React.SetStateAction<string>>
}

export const PasswordResetDialog = (props: Props) => {
  const [status, setStatus] = React.useState<RequestStatus>(RequestStatus.IDLE)

  React.useEffect(() => {
    setStatus(RequestStatus.IDLE)
  }, [props.resetTarget])

  async function requestPasswordReset(email: string) {
    try {
      setStatus(RequestStatus.INITIATED)
      await props.requestReset(email)
      setStatus(RequestStatus.COMPLETED)
    } catch {
      alert('Could not initiate password reset at this time')
    }
  }

  let BodyText: JSX.Element
  switch (status) {
    case RequestStatus.IDLE:
      BodyText = (
        <>
          <p style={{ fontWeight: 400, textAlign: 'center' }}>
            Are you sure you want to reset this user's password? A password reset
            link will be sent to{' '}
            <strong>
              <em>{props.resetTarget}</em>
            </strong>
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              columnGap: '1rem',
            }}
          >
            <Button
              size="medium"
              variant="secondary"
              text="YES"
              onClick={() => requestPasswordReset(props.resetTarget)}
              data-cy="yesResetPassword"
            />

            <Button
              size="medium"
              variant="secondary"
              text="NO"
              onClick={() => props.setResetTarget('')}
              data-cy="noResetPassword"
            />
          </div>
        </>
      )
      break
    case RequestStatus.INITIATED:
      BodyText = (
        <div>
          <div>Requesting password reset</div>
          <LinearProgress style={{ width: 200 }} />
        </div>
      )
      break
    case RequestStatus.COMPLETED:
      BodyText = (
        <>
          <h3>Email sent</h3>
          <p style={{ fontWeight: 400 }}>
            You successfully sent a password reset request to{' '}
            <div style={{ textAlign: 'center' }}>
              <strong>
                <em>{props.resetTarget}</em>!
              </strong>
            </div>
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              columnGap: '1rem',
            }}
          >
            <Button
              size="medium"
              variant="secondary"
              text="CLOSE"
              onClick={() => props.setResetTarget('')}
              data-cy="closeResetPassword"
            />
          </div>
        </>
      )
      break
  }

  return (
    <Dialog
      isOpen={!!props.resetTarget}
      Body={
        <div
          style={{
            maxWidth: 500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 165,
          }}
        >
          {BodyText}
        </div>
      }
      animated={true}
      closeHandler={() => props.setResetTarget('')}
    />
  )
}
