import Button from 'components/Button'
import styles from './ConfirmationBody.module.css'
type Props = {
  text: JSX.Element | string
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClick: () => any
  disableButtons: boolean
}

export default function ConfirmationBody(props: Props) {
  return (
    <div className={styles.confirmationBody} data-cy="confirmationBody">
      {props.text}
      <div className={styles.buttons}>
        <span>
          <Button
            disabled={props.disableButtons}
            data-cy="user_confirm_button"
            onClick={() => {
              props.setIsOpen(false)
              props.onClick()
            }}
            text="Confirm"
            variant="secondary"
            size="medium"
          />
        </span>
        <span>
          <Button
            disabled={props.disableButtons}
            data-cy="user_cancel_button"
            onClick={() => {
              props.setIsOpen(false)
            }}
            text="Cancel"
            variant="secondary"
            size="medium"
          />
        </span>
      </div>
    </div>
  )
}
