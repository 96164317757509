import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Tree from 'components/Tree'
import Accordion from 'components/Accordion'
import { useAuth } from 'context/auth-context'
import LayoutDataTable from 'components/LayoutDataTable'
import Controls from 'components/DataTable/Controls'

interface LayoutMakerI {
  expanded?: boolean
  initialLayout: any
  currentLayout: {
    hashed: string
    id: null
    fields: null
    chosen_revenue_share: boolean
    name: null
    chosen_field_package: string
    privacy: string
    editable: boolean
  }
  setCurrentLayout: React.Dispatch<LayoutMakerI['currentLayout']>
  saveIsOpen: boolean
  setSaveIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setTableData: React.Dispatch<React.SetStateAction<any[]>>
  tableData: any[]
}

const LayoutMaker: React.FC<LayoutMakerI> = ({
  expanded,
  initialLayout,
  currentLayout,
  setCurrentLayout,
  saveIsOpen,
  setSaveIsOpen,
  setTableData,
  tableData,
}) => {
  const { client } = useAuth()
  const {
    layoutsTreeAPI,
    layoutsTableAPI,
    layoutsAPI,
    layoutsIdAPI,
  } = client.endpoints
  const [treeData, setTreeData] = useState([])
  const [fieldData, setFieldData] = useState([])
  const history = useHistory()

  useEffect(() => {
    if (initialLayout) {
      setTableData(
        initialLayout.fields.available.map((x: any, idx: number) => ({
          id: `${idx}`,
          field_name: x[0],
          export_name: x[1],
          description: x[2],
        }))
      )
      setCurrentLayout({
        ...initialLayout,
        name: initialLayout.name,
        chosen_field_package: 'all',
        chosen_revenue_share: false,
      })
    }
  }, [initialLayout, setCurrentLayout])

  useEffect(() => {
    async function getFieldData() {
      const {
        responseData: { results: tree },
      } = await client.get({ endpoint: layoutsTreeAPI })
      const {
        responseData: { results: table },
      } = await client.get({ endpoint: layoutsTableAPI })
      setTreeData(tree)
      setFieldData(table)
    }
    getFieldData()
  }, [client, layoutsTreeAPI, layoutsTableAPI])

  const updateFilter = (data: any) => {
    function getSelectedLeaves(node: any) {
      if (node.isSelected) {
        if (node.subFunctions) {
          return node.subFunctions
            .map((subNode: any) => getSelectedLeaves(subNode))
            .flat()
        } else {
          return node.functionName
        }
      }
    }

    const selectedLeaves = data
      .map((node: any) => getSelectedLeaves(node))
      .filter((x: any) => !!x)
      .flat()

    const _tableData = fieldData.filter((field) =>
      selectedLeaves.includes((field as any).field_name)
    )

    // Set Operations to determine field addition / removal
    const freshFields = new Set(_tableData.map((x: any) => x.field_name))
    const staleFields = new Set(tableData.map((x: any) => x.field_name))

    // Keys Removed / Added
    const fieldsRemoved = [...staleFields].filter((key) => !freshFields.has(key))
    const fieldsAdded = [...freshFields].filter((key) => !staleFields.has(key))
    const filteredData = tableData.filter(
      (x: any) => !fieldsRemoved.includes(x.field_name)
    )

    const maxId = filteredData.reduce(
      (pv: number, cv: any) => Math.max(pv, Number(cv?.id)),
      0
    )

    const newData = _tableData
      .filter((x: any) => fieldsAdded.includes(x.field_name))
      .map((x: any, idx) => ({
        id: `${idx + maxId + 1}`,
        field_name: x.field_name,
        export_name: x.export_name,
        description: x.description,
        isHidden: false,
      }))

    const finalData = [...filteredData, ...newData]

    setTableData(finalData)
  }

  async function saveLayout(layout: typeof currentLayout) {
    const data = tableData.map((row: any) => [
      (row as any).field_name,
      (row as any).export_name,
      (row as any).description,
    ])
    // TODO this is passing the whole response object back as an update, no good
    const { responseData, status, errorMessage } = await client.post({
      endpoint: layoutsAPI,
      data: {
        ...layout,
        layout_name: layout.name,
        data,
      },
    })

    if (status < 200 || status >= 300) {
      throw Error(errorMessage)
    }

    const hashed = responseData.results.hashed
    setCurrentLayout({ ...layout, hashed })
    setSaveIsOpen(false)
    history.replace(`/layouts?layout=${hashed}`)
  }

  async function updateLayout(layout: typeof currentLayout) {
    const data = tableData.map((row: any) => [
      (row as any).field_name,
      (row as any).export_name,
      (row as any).description,
    ])

    const { hashed } = layout
    const { responseData, status, errorMessage } = await client.put({
      endpoint: layoutsIdAPI(hashed),
      data: {
        ...layout,
        data,
      },
    })

    if (status < 200 || status >= 300) {
      throw Error(errorMessage)
    }

    const new_hashed = responseData.results.hashed
    if (hashed !== new_hashed) {
      setCurrentLayout({ ...layout, hashed: new_hashed })
      setSaveIsOpen(false)
      history.replace(`/layouts?layout=${new_hashed}`)
    }
  }

  function filterData(filterValue: any) {
    const regexp = new RegExp(filterValue, 'i')
    const _tableData = tableData.map((item: any) => {
      let isHidden = true

      for (let value of Object.values(item)) {
        const match = String(value).match(regexp)
        if (match) {
          isHidden = false
        }
      }

      return { ...item, isHidden }
    })
    setTableData(_tableData)
  }

  const data = tableData.filter((row: any) => !(row as any).isHidden)
  const csvData = tableData.map((row: any) => ({
    field_name: (row as any).field_name,
    row_name: (row as any).export_name,
    description: (row as any).description,
  }))

  return (
    <div>
      <Accordion title="Layout Maker" expanded={expanded}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div style={{ flex: 0.3, margin: '1rem' }}>
            <Tree
              options={treeData}
              updateFilter={updateFilter}
              initialState={initialLayout?.fields?.available}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 0.7,
              margin: '1rem',
              rowGap: '0.50rem',
            }}
          >
            <Controls
              csvControl
              searchControl
              data={csvData}
              setSearchText={filterData}
            />
            <LayoutDataTable
              setCurrentLayout={setCurrentLayout}
              currentLayout={currentLayout}
              saveLayout={saveLayout}
              updateLayout={updateLayout}
              rows={data}
              setRows={setTableData}
              saveIsOpen={saveIsOpen}
              setSaveIsOpen={setSaveIsOpen}
            />
          </div>
        </div>
      </Accordion>
    </div>
  )
}

export default LayoutMaker
