import { useState } from 'react'
import Chip from '../Chip/'
import AggregateChip from './AggregateChip'
import styled from 'styled-components'
import StandardDialog from '../StandardDialog'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const Container = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  margin: 0rem;
  margin-top: 0.25rem;
  padding: 0rem;
`

interface ChipBasketI {
  selectedOptions: any
  handleChipDelete: any
  chipProps?: any
  basketProps?: any
  aggregateChip?: any
  'data-cy'?: string
}

const ChipBasket: React.FC<ChipBasketI> = ({
  selectedOptions,
  handleChipDelete,
  chipProps,
  basketProps,
  aggregateChip,
}) => {
  const [selectedTags, setSelectedTags] = useState([])
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Selected Tags')
  const Chips = selectedOptions?.map((option: any, i: any) =>
    aggregateChip ? (
      <AggregateChip
        value={option}
        key={i}
        setDialogIsOpen={setDialogIsOpen}
        setDialogTitle={setDialogTitle}
        setSelectedTags={setSelectedTags}
        {...chipProps}
      />
    ) : (
      <Chip
        clickable={false}
        key={i}
        value={option}
        text={option}
        onDelete={() => handleChipDelete(option)}
        data-cy={'chipButton'}
        {...chipProps}
      />
    )
  )

  return (
    <>
      <Container {...basketProps}>{Chips}</Container>
      <StandardDialog
        title={dialogTitle}
        Content={
          <div style={{ minWidth: '300px' }}>
            {selectedTags.map((tag) => (
              <Typography>{tag}</Typography>
            ))}
          </div>
        }
        handleClose={() => setDialogIsOpen(false)}
        isOpen={dialogIsOpen}
      />
    </>
  )
}

export default ChipBasket
