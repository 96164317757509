import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import EditableCell from './EditableDataTable/EditableCell'
import { colors } from 'context/theme-context'
import { theme } from './DataTable/theme'
import { MuiThemeProvider } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import TitleOpenSave from './EditableDataTable/TitleOpenSave'
import { preventTextInput } from 'utils/preventTextInput'
/*
 * Adapted from: https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/table/with-fixed-columns.jsx
 * Demo: https://react-beautiful-dnd.netlify.app/?path=/story/tables--with-fixed-width-columns
 */

interface EditableDataTableI {
  rows: any[]
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  currentLayout: {
    hashed: string
    id: null
    fields: null
    chosen_revenue_share: boolean
    name: null
    chosen_field_package: string
    privacy: string
    editable: boolean
  }
  setCurrentLayout: React.Dispatch<EditableDataTableI['currentLayout']>
  saveLayout: (layout: EditableDataTableI['currentLayout']) => Promise<void>
  updateLayout: (layout: EditableDataTableI['currentLayout']) => Promise<void>
  saveIsOpen: boolean
  setSaveIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const EditableDataTable: React.FC<EditableDataTableI> = ({
  rows,
  setRows,
  currentLayout,
  saveLayout,
  updateLayout,
  saveIsOpen,
  setSaveIsOpen,
  setCurrentLayout,
}) => {
  const [pagination, setPagination] = useState({
    page: 0,
    count: rows.length,
    rowsPerPage: 50,
  })

  const { page, count, rowsPerPage } = pagination
  useEffect(() => {
    setPagination((state) => ({
      ...state,
      count: rows.length,
      rowsPerPage: rows.length,
    }))
  }, [rows])

  function filterData() {
    const offset = page * rowsPerPage
    const index = { start: offset, end: offset + rowsPerPage }
    const _rows = rows.slice(index.start, index.end)
    return _rows
  }

  function changeRowsPerPage(e: any) {
    setPagination((state) => ({
      ...state,
      rowsPerPage: parseInt(e.target.value),
    }))
  }

  function changePage(_: any, page: any) {
    setPagination((state) => ({ ...state, page: page }))
  }

  function onDragEnd(result: any) {
    const idxFactor = rowsPerPage * page // Adjusting for pagination.
    if (
      !result.destination ||
      result.destination.index + idxFactor === result.source.index + idxFactor
    ) {
      return
    }
    if (result.destination.index + idxFactor === result.source.index + idxFactor) {
      return
    }

    const _rows = reorder(
      rows,
      result.source.index + idxFactor,
      result.destination.index + idxFactor
    )
    setRows(_rows)
  }

  function updateRow(rowId: any) {
    return function (key: any, value: any) {
      const _rows = rows.map((row: any) => {
        if (row.id === rowId) {
          return { ...row, [key]: value }
        }
        return row
      })
      setRows(_rows)
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ borderRight: 'none' }}>
                Field Name
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: 'none' }}>
                Export Name
              </StyledTableCell>
              <StyledTableCell style={{ flex: 2 }}>
                <div>Description</div>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <Droppable droppableId="table">
            {(droppableProvided) => (
              <TableBody
                ref={(ref) => droppableProvided.innerRef(ref)}
                {...droppableProvided.droppableProps}
              >
                {filterData().map((row: any, index: any) => (
                  <Draggable draggableId={row.id} index={index} key={row.id}>
                    {(provided, snapshot) => (
                      <EditableTableRow
                        provided={provided}
                        snapshot={snapshot}
                        row={row}
                        updateRow={updateRow}
                      />
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                count={count}
                onChangePage={changePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={changeRowsPerPage}
                rowsPerPageOptions={[10, 20, 50, 100, rows.length]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </DragDropContext>
      <TitleOpenSave
        isOpen={saveIsOpen}
        setIsOpen={setSaveIsOpen}
        setCurrentLayout={setCurrentLayout}
        currentLayout={currentLayout}
        saveLayout={saveLayout}
        updateLayout={updateLayout}
      />
    </MuiThemeProvider>
  )
}

const StyledTableRow = styled(TableRow)<{ dragging?: any }>`
  display: flex;
  flex: 1;
  ${(props) =>
    props.dragging &&
    css`
      box-shadow: 0px 0px 5px 2px ${colors.blue6};
      background-color: white;
    `}
`

const StyledTableCell = styled(TableCell)`
  display: flex;
  flex: 1;
  background-color: ${colors.gray2};
  color: ${colors.gray8};
  border: 1px solid ${colors.gray5};
  font-weight: bold;
  line-height: 1rem;
`

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function EditableTableRow({ snapshot, row, provided, updateRow }: any) {
  const update = updateRow(row.id)

  // Need to prevent certain characters from being entered for export names
  const disallowedPattern = /[^\w\s]/
  const exportNameUpdate = (key: string, value: string) => {
    const updateFn = () => update(key, value)
    preventTextInput(value, disallowedPattern, updateFn)
  }

  return (
    <StyledTableRow
      ref={provided.innerRef}
      dragging={snapshot.isDragging ? 1 : 0}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <EditableCell
        dragging={snapshot.isDragging}
        update={update}
        updateKey={'field_name'}
        value={row.field_name}
        flexnumber={1}
      />
      <EditableCell
        dragging={snapshot.isDragging}
        update={exportNameUpdate}
        updateKey={'export_name'}
        value={row.export_name}
        isEditable
        flexnumber={1}
      />
      <EditableCell
        dragging={snapshot.isDragging}
        update={update}
        updateKey={'description'}
        value={row.description}
        flexnumber={2}
      />
    </StyledTableRow>
  )
}

export default EditableDataTable
