import ep from '../endpoints'

const cacheList = [
  ep.filtersAPI,
  ep.audienceFieldsAPI,
  ep.prioritizeFieldsAPI,
  ep.partitionFieldsAPI,
  ep.validation,
  ep.filePreviewAPI,
]

export default cacheList
