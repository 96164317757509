export interface Props {
  type?:
    | 'updateFilter'
    | 'deleteFilter'
    | 'reset'
    | 'mergeUpdate'
    | 'metrics'
    | undefined
  payload: {
    filters?: any
    sqlIsVerified?: boolean
    fields?: number
    newFilterCount?: number
    filtersHaveUpdated?: boolean | null
    selectedFilters?: any
    appliedFilters?: any
    savedFilters?: any
    audienceSize?: any
    pastLocation?: string | null
    saveIsCurrent?: boolean
    renderExportAudience?: boolean
    printableMetrics?: {}
    initialLocation?: any
    audienceInfo?: any
    metricsDataReady?: boolean
    metrics?: any
    keys?: Array<string>
    countsDataReady?: boolean
  }
}

const reducer = (state: any, action: Props) => {
  const { type, payload } = action

  switch (type) {
    case 'updateFilter':
      const _selectedFilters = { ...state.selectedFilters }
      for (const [key, value] of Object.entries(payload)) {
        _selectedFilters[key] = value
      }
      return { ...state, selectedFilters: _selectedFilters }

    case 'deleteFilter':
      const currentFilters = { ...state.selectedFilters }
      for (const key of payload.keys || []) {
        delete currentFilters[key]
      }
      return { ...state, selectedFilters: currentFilters }

    case 'reset':
      return payload

    case 'mergeUpdate':
      const key = Object.keys(payload)[0]
      const value = Object.values(payload)[0]
      const mergedUpdate = { [key]: { ...state[key], ...value } }
      return { ...state, ...mergedUpdate }

    case 'metrics':
      return {
        ...state,
        metrics: {
          ...state.metrics,
          ...payload.metrics,
        },
      }
    case undefined:
      return { ...state, ...payload }

    default:
      return state
  }
}

export default reducer
