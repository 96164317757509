import { useEffect, useState, useCallback, useMemo } from 'react'
import Accordion from 'components/Accordion'
import LinkButton from 'components/Button/LinkButton'
import { useAuth } from 'context/auth-context'
import { ReactComponent as AudienceIcon } from 'icons/24px/People.svg'
import { ReactComponent as ExportIcon } from 'icons/24px/Export File.svg'
import { numericHumanReadable } from 'utils/numericHelpers'
import Button from 'components/Button'
import { Typography } from '@material-ui/core'
import { colors } from 'context/theme-context'
import Chip from 'components/Chip'
import DownloadExecutionDialog from '../Audiences/Dashboard/DashboardTabs/ExportsTab/DownloadExecutionDialog'
import RemoteDataTable from 'components/RemoteDataTable'
import { useLocation, useHistory } from 'react-router-dom'
import { ConditionalTooltip } from 'components/Tooltip'
import BaseExportDialog from 'components/BaseExportDialog'
import { useAlerts } from 'context/alert-context'
import { useSnackbar } from 'context/snackbar-context'

const MAX_CELL_WIDTH = 50

interface Props {
  page: 'audiences' | 'exports'
  isExpanded: boolean
  containerProps?: any
  renderCount?: number
  setRenderExportAudience?: any
}

const RecentExports: React.FC<Props> = ({
  page,
  containerProps,
  isExpanded,
  renderCount: renderExportAudience,
  setRenderExportAudience,
}) => {
  const { client, userData, adminMode } = useAuth()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage } = useSnackbar()

  const history = useHistory()
  const {
    executionsAPI,
    exportsIdAPI,
    executionAPI,
    cancelExecutionAPI,
  } = client.endpoints
  const [downLoadForm, setDownloadForm] = useState({})
  const [isDownloadExecutionOpen, setIsDownloadExecutionOpen] = useState(false)
  const [refreshRecentExports, setRefreshRecentExports] = useState({})
  const location = useLocation()
  const parser = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])
  const [chosenExport, setChosenExport] = useState({})
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const refreshTable = () => {
    setRefreshRecentExports((state) => ({ ...state, refresh: true }))
  }

  useEffect(() => {
    refreshTable()
  }, [renderExportAudience, setRefreshRecentExports])

  const downloadModalHandler = useCallback(
    async (hash) => {
      setIsDownloadExecutionOpen(true)

      try {
        const { responseData } = await client.get({
          endpoint: executionAPI(hash),
          data: { download_urls: true },
        })

        const { results } = responseData

        setDownloadForm({
          rows: results.results.records,
          credits_used: results.results.credits_used,
          file_name: results.results.file_name,
          file_size: results.results.file_size,
          download_full_file_url: results.results.download_url,
          download_sample_file_url: results.results.analysis.sample.download_url,
          download_fillrates_url: results.results.analysis.fillrate.download_url,
          file_path: results.results.file_path,
          sample_file_path: results.results.analysis.sample.file_path,
          fillrate_path: results.results.analysis.fillrate.file_path,
          custom_file_path: results.results.custom_file_path,
          show_internal_links: userData.account.admin,
        })
      } catch (e) {
        setIsDownloadExecutionOpen(false)
        console.log('Could not fetch downloads info: ', e)
      }
    },
    [client, executionAPI, userData.account.admin]
  )

  useEffect(() => {
    const downloadHash = parser.get('download_hash')
    if (downloadHash) {
      downloadModalHandler(downloadHash)
    }
  }, [location.pathname, downloadModalHandler, parser])

  async function openRecentExport(hash: any) {
    setDialogOpen(true)
    const { responseData } = await client.get({ endpoint: exportsIdAPI(hash) })
    const { results: savedExport } = responseData
    setChosenExport(savedExport)
  }

  async function cancelExecution(executionId: any) {
    try {
      await client.put({ endpoint: cancelExecutionAPI(executionId) })
    } catch (e) {
      console.log('Could not cancel task: ', e)
    }

    setRefreshRecentExports((state) => ({ ...state, refresh: true }))
  }

  function handleExit() {
    setChosenExport({})
    setDialogOpen(false)
  }

  const columns = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'run_by',
      label: 'Run By',
      options: {
        sort: true,
        setCellProps: () => ({
          style: { flex: 1 },
        }),
      },
    },
    {
      name: 'modified',
      label: 'Started',
      options: {
        sort: true,
        setCellProps: () => ({
          style: { flex: 1 },
        }),
      },
    },
    {
      name: 'records',
      label: 'Records',
      options: {
        sort: true,
        setCellProps: () => ({
          style: { flex: 1 },
        }),
      },
    },
  ]

  if (page === 'exports') {
    columns.push({
      name: 'download',
      label: 'Status',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { flex: 1 },
        }),
      },
    })
  }

  return (
    <div style={{ ...containerProps }}>
      <Accordion
        title="Recently Exported Files"
        infoText="Files that are 6 months past your upload date won’t be accessible"
        expanded={isExpanded}
        id="recentlyExportedDropdown"
      >
        <div style={{ paddingTop: '1rem' }}>
          <RemoteDataTable
            client={client}
            adminMode={adminMode}
            sendAlert={sendAlert}
            setSnackbarMessage={setSnackbarMessage}
            title="recentExports"
            refreshState={refreshRecentExports}
            url={executionsAPI}
            expandableRows={false}
            initialSortOrder={'-created'}
            controls={true}
            columnOrderMap={{
              name: 'export__name',
              run_by: ['created_by__last_name', 'created_by__first_name'],
              modified: 'created',
              records: 'results__records',
            }}
            columns={columns}
            rowsOnPage={5}
            customRowRender={(rowData: any) => {
              const executionsTableData = rowData?.map((item: any, idx: any) => {
                const updatedItem = {
                  hashed: null,
                  name: null,
                  run_by: null,
                  modified: null,
                  status: null,
                  records: null,
                  download: null,
                }

                updatedItem.hashed = item.hashed

                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
                updatedItem.name = (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '0.5rem',
                    }}
                    id={idx === 0 ? 'RecentlyExportedRow1' : undefined}
                  >
                    <div>
                      <ConditionalTooltip
                        text={item.results.file_name}
                        maxLength={MAX_CELL_WIDTH}
                        render={(text: any) => text}
                      />
                    </div>
                    {item.audience.name && (
                      <ConditionalTooltip
                        text={item.audience.name}
                        maxLength={MAX_CELL_WIDTH}
                        render={(text: any) => (
                          <LinkButton
                            text={text}
                            Icon={() => (
                              <AudienceIcon
                                stroke={colors.blue8}
                                fill={colors.blue8}
                              />
                            )}
                            onClick={() => {
                              history.push(`/audiences/${item.audience?.hashed}`)
                            }}
                          />
                        )}
                      />
                    )}
                    <ConditionalTooltip
                      text={item.export.name}
                      maxLength={MAX_CELL_WIDTH}
                      render={(text: any) => (
                        <LinkButton
                          text={text}
                          Icon={ExportIcon}
                          onClick={() => openRecentExport(item.export.hashed)}
                        />
                      )}
                    />
                  </div>
                )

                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
                updatedItem.run_by = `${item.created.user.last_name}, ${item.created.user.first_name}`
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
                updatedItem.modified = new Date(
                  item.created.timestamp.raw
                ).toLocaleString('en-US')
                updatedItem.status = item.status
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
                updatedItem.records = (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '0.5rem',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      {numericHumanReadable(item.results?.records, 0) ?? '-'}
                    </div>
                    {item.results?.records != null && (
                      <div>
                        ({numericHumanReadable(item.results?.credits_used, 0) ?? '-'}{' '}
                        credits)
                      </div>
                    )}
                  </div>
                )

                // Handle Download Button
                if (item.status === 'complete') {
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
                  updatedItem.download = (
                    <Button
                      icon="download"
                      text="Download"
                      size="medium"
                      variant="secondary"
                      onClick={() => downloadModalHandler(item.hashed)}
                      textStyle={{ fontWeight: 'bold' }}
                    />
                  )
                } else if (item.status === 'cancelled' || item.status === 'failed') {
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
                  updatedItem.download = <Typography>{item.status}</Typography>
                } else {
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
                  updatedItem.download = (
                    <Chip
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '() => Promise<void>' is not assignable to ty... Remove this comment to see the full error message
                      onDelete={() => cancelExecution(item.hashed)}
                      text={item.status}
                    />
                  )
                }

                if (page === 'audiences') {
                  return {
                    ...updatedItem,
                    records: (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          rowGap: '0.25rem',
                        }}
                      >
                        {updatedItem.download}
                        {updatedItem.records}
                      </div>
                    ),
                  }
                } else {
                  return updatedItem
                }
              })

              return executionsTableData
            }}
          />
        </div>
      </Accordion>

      <DownloadExecutionDialog
        isOpen={isDownloadExecutionOpen}
        setIsOpen={setIsDownloadExecutionOpen}
        data={downLoadForm}
        credits={userData.credits}
      />

      <BaseExportDialog
        isOpen={dialogOpen}
        disabled={true}
        handleExit={handleExit}
        setRenderExportAudience={setRenderExportAudience}
        dialogType={'view'}
        chosenExport={chosenExport}
      />
    </div>
  )
}

export default RecentExports
