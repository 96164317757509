const Terms = () => (
  <>
    <p>
      <strong>Acceptance of Terms</strong>
      <br />
      If you do not agree to these Terms of Use, you are not permitted to sign up for
      or otherwise use the NetWise Platform. NetWise Data, LLC (“NetWise) may change
      these Terms of Use at any time without providing notice to you. It is your
      responsibility to review these Terms of Use, as it creates a binding legal
      agreement between you and NetWise. If you use the NetWise Platform after we
      have changed any of the Terms of Use, you are agreeing to any and all such
      changes. To the extent that you or your employer (to the extent you are using
      the Platform in your capacity as an agent of your employer) has entered into
      another written agreement with NetWise that contains terms that directly
      conflict with any terms of these Terms of Use, then the conflicted terms set
      forth in such other agreement shall control.
    </p>

    <p>
      <strong>Eligibility / Age Restrictions</strong>
      <br />
      Any access or use of the NetWise Platform by anyone under the age of 18 is
      prohibited. By agreeing to these Terms of Use, you represent and warrant that
      you are 18 years of age or older and have the legal capacity to enter into and
      abide by these Terms of Use.
    </p>

    <p>
      <strong>Account and Security</strong>
      <br />
      You will need to set up an account in order to access the NetWise Platform.
      When you are setting up your account, you represent and warrant that you have
      provided us with accurate and complete information. You may not set up an
      account using a name or contact information that does not apply to you, and you
      must provide accurate and current information on all registration forms that
      are part of the NetWise Platform signup and account features. You may not use
      any account belonging to any other person without NetWise’s express, prior,
      written permission. You have complete responsibility for your account and
      everything that happens on your account. This means you need to be careful with
      your password. If you find out that someone is using your account without your
      permission, you must let us know immediately. You may not transfer your account
      to someone else and doing so could subject you to civil or criminal liability.
      We are not liable for any damages or losses caused by someone using your
      account without your permission.
    </p>

    <p>
      <strong>Privacy</strong>
      <br />
      Any personal information provided while using or signing up for an account on
      the NetWise Platform is subject to the terms of our Privacy Policy which can be
      found at (
      <a href="https://www.netwisedata.com/privacy-policy">
        https://www.netwisedata.com/privacy-policy
      </a>
      ).
    </p>

    <p>
      <strong>Proprietary Materials</strong>
      <br />
      The NetWise Platform contains copyrighted materials, trademarks, proprietary
      and confidential information, and intellectual property of NetWise and
      licensors of NetWise (collectively, “Proprietary Materials”), including without
      limitation source code, video, text, software, photos, graphics, images, music,
      and sound. You agree not to modify, publish, transmit, participate in the
      transfer or sale of, create derivative works of, or in any way exploit, in
      whole or in part, any Proprietary Materials. Proprietary Materials may only be
      accessed through the NetWise Platform, and not by or from any other site or
      means. The right of access to the NetWise Platform does not grant to you any
      right to download or store any Proprietary Materials in any medium.
    </p>

    <p>
      NetWise reserves all intellectual property rights to the Proprietary Materials,
      other than as specifically granted under the applicable license granted you
      under these Terms of Use. No posting, copying, transmission, retransmission,
      distribution, redistribution, publication, republication, decompilation,
      disassembling, reverse engineering, or otherwise reproducing, storing,
      transmitting, modifying, or commercially exploiting any Proprietary Materials
      in any form or by any means, for any purpose, is permitted without our express
      written permission from NetWise.
    </p>

    <p>
      The entire NetWise Platform is protected by copyright. Complying with all
      applicable copyright laws is your responsibility. If you give feedback on the
      NetWise Platform, for example recommendations for improvements or features,
      such feedback will be deemed non-confidential and non-proprietary, and
      implementation of that feedback is owned by us and may become part of the
      Platform without compensation to you. We reserve all rights in and to the
      NetWise Platform unless we expressly state otherwise.
    </p>

    <p>
      <strong>Files Uploads</strong>
      <br />
      In these Terms of Use, “User Files” means material you upload or otherwise
      provide while using the NetWise Platform, which NetWise allows as a feature of
      the NetWise Platform at your benefit. You hereby agree that you will not upload
      any prohibited information, including, but not limited to, social security
      numbers, GDPR regulated personal information, personal information on minors
      under the age of eighteen (18), third-party information for which you do not
      have the right or license to use, or any other information prohibited by way of
      any applicable law, rule or regulation. By uploading User Files within the
      NetWise Platform, you represent and warrant that such uploads are not done in
      violation of any agreement with any other data provider. NetWise does not make
      any intellectual property claim to your User Files, which remain sole property
      of you. NetWise agrees not to otherwise use your User Files within its products
      or services.
    </p>

    <p>
      <strong>Prohibited Uses</strong>
      <br />
      You must not: · Use the NetWise Platform in any way that causes, could be
      reasonably expected to cause, or is intended to cause damage to the NetWise
      Platform or impairment of the availability or accessibility of the NetWise
      Platform; or in any way which is unlawful, illegal, fraudulent, or harmful, or
      in connection with any unlawful, illegal, fraudulent, or harmful purpose or
      activity. · Use the NetWise Platform to copy, store, host, transmit, send, use,
      publish, or distribute any material which consists of (or is linked to) any
      spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit, or
      other malicious computer software. · Conduct any systematic or automated data
      collection activities (including without limitation scraping, data mining, data
      extraction, and data harvesting) on or in relation to the NetWise Platform
      without our express written consent. · Republish any data or other materials
      found on the NetWise Platform (including republication on another website). ·
      Sell, rent, or sub-license data or other materials found on the NetWise
      Platform. · Reproduce, duplicate, copy, or otherwise exploit data or other
      materials found on the NetWise Platform for a commercial purpose. · Develop,
      support, or use software, devices, scripts, robots, or any other means or
      processes (including crawlers, browser plugins and add-ons or any other
      technology) to scrape the NetWise Platform or otherwise copy any data from the
      NetWise Platform. · Violate or attempt to violate the NetWise Platform’s
      security mechanisms, attempt to gain unauthorized access to the NetWise
      Platform or assist others to do so, or otherwise breach the security of the
      NetWise Platform or corrupt the NetWise Platform in any way. We reserve the
      right to monitor your use of the NetWise Platform and to suspend, revoke, deny,
      disable, or terminate your access if you violate any provisions of these Terms
      of Use or if your or behavior exceeds reasonable limits, as determined in our
      sole discretion. Warranty Disclaimer
    </p>

    <p>
      WE DO NOT WARRANT THE COMPLETENESS OR ACCURACY OF THE INFORMATION PUBLISHED ON
      THE NETWISE PLATFORM; NOR DO WE COMMIT TO ENSURING THAT THE NETWISE PLATFORM OR
      ANY FEATURES THEREOF REMAINS AVAILABLE OR THAT THE MATERIAL ON THE NETWISE
      PLATFORM IS KEPT UP-TO-DATE. USE OF THE NETWISE PLATFORM IS AT YOUR OWN RISK.
      THE NETWISE PLATFORM IS PROVIDED ON A “AS IS” AND “AS AVAILABLE” BASIS. NETWISE
      AND ITS AFFILIATES, SUPPLIERS AND PARTNERS EXPRESSLY DISCLAIM ALL WARRANTIES OF
      ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
      IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
      AND NON-INFRINGEMENT. NETWISE AND ITS AFFILIATES, SUPPLIERS AND PARTNERS MAKE
      NO WARRANTY THAT (i) THE NETWISE PLATFORM OR ANY EXPORT CREDIT PLANS PURCHASED
      THROUGH THE NETWISE PLATFORM WILL MEET YOUR REQUIREMENTS; (ii) THE NETWISE
      PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; OR (iii) THAT
      THERE WILL BE NO ERRORS IN THE NETWISE PLATFORM OR THAT NETWISE WILL FIX ANY
      ERRORS. ANY MATERIALS OBTAINED THROUGH USE OF THE NETWISE PLATFORM ARE OBTAINED
      AT YOUR OWN DISCRETION AND RISK AND NETWISE SHALL NOT BE RESPONSIBLE FOR ANY
      DAMAGE CAUSED TO YOUR COMPUTER OR DATA OR FOR ANY BUGS, VIRUSES, TROJAN HORSES,
      OR OTHER DESTRUCTIVE CODE RESULTING FROM USE OF THE PLATFORM OR ANY CONTENT
      OBTAINED FROM THE PLATFORM. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED
      WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE
      OTHER LEGAL RIGHTS, WHICH VARY FROM STATE TO STATE.
    </p>

    <p>
      <strong>Links to Other Sites</strong>
      <br />
      The NetWise Platform may contain links to other websites that we do not own or
      control. We are not responsible for any of these other websites and any links
      to these other websites should not be interpreted as an endorsement of any
      company, content, or products. We are not responsible to you for any aspect of
      these other websites, including their content, privacy policies, or anything
      else. You may be exposed to things on other websites that you do not like or
      that you find offensive. We are not responsible for this, either. You must use
      your own discretion when you go to other websites. You should also read the
      terms and conditions and privacy policies of these other websites.
    </p>

    <p>
      <strong>Export Controls</strong>
      <br />
      The NetWise Platform may be subject to export laws and regulations of the
      United States and other jurisdictions. Each party represents that it is not
      named on any U.S. government denied-party list. You shall not access or use the
      NetWise Platform in a U.S.-embargoed country or in violation of any U.S. export
      law or regulation.
    </p>

    <p>
      <strong>General Terms</strong>
      <br />
      <p>
        <strong>Non-Waiver.</strong> Failure by NetWise to enforce any provision(s)
        of these Terms of Use will not be construed as a waiver of any provision or
        right.
      </p>
      <p>
        <strong>Severability.</strong> If any provision of these Terms of Use is
        found to be illegal, void, or unenforceable, then that provision will be
        deemed severable from these Terms of Use and will not affect the validity and
        enforceability of any remaining provisions of these Terms of Use.
      </p>
      <p>
        <strong>Governing Law and Jurisdiction.</strong> These Terms of Use, and all
        claims or causes of action (whether in contract, tort, or statute) that may
        be based upon, arise out of, or relate to these Terms of Use, or the
        performance under these Terms of Use, are governed by and will be construed
        and enforced in accordance with the laws of the State of Florida, including
        its statute of limitations, without reference to any conflicts of law
        principles that would apply the substantive laws of another jurisdiction to
        the parties’ rights or duties. Any action or other judicial proceeding for
        the enforcement of these Terms of Use or any of its provisions shall be
        instituted only in the courts of the State of Florida.
      </p>
      <p>
        <strong>Compliance with Legal Requests.</strong> Without limiting the
        foregoing, we have the right to fully cooperate with any valid legal process
        from a law enforcement authority with jurisdiction that requests or directs
        us to disclose customer data or other information on the NetWise Platform.
        YOU WAIVE AND HOLD HARMLESS US AND OUR AFFILIATES, LICENSEES, AND SERVICE
        PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY US OR ANY OF THE
        FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY
        ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER US, SUCH PARTIES,
        OR LAW ENFORCEMENT AUTHORITIES.
      </p>
      <p>
        <strong>Limitation of Liability.</strong> TO THE FULLEST EXTENT PERMITTED
        UNDER LAW, NETWISE AND ITS AFFILIATES, SUPPLIERS AND PARTNERS HAVE NO
        OBLIGATION OR LIABILITY (WHETHER ARISING IN CONTRACT, WARRANTY, TORT
        (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE) FOR ANY INDIRECT,
        INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OR LIABILITIES
        (INCLUDING, BUT NOT LIMITED TO, ANY LOSS OF DATA, REVENUE OR PROFIT) ARISING
        FROM OR RELATED TO YOUR USE OF THE NETWISE PLATFORM OR ANY CONTENT PROVIDED
        BY OR THROUGH THE NETWISE PLATFORM, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES IN ADVANCE. SOME STATES DO NOT ALLOW THE
        LIMITATION OR EXCLUSION OF INCIDENTAL, CONSEQUENTIAL OR OTHER TYPES OF
        DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, NETWISE’S
        LIABILITY AND THE LIABILITY OF EACH OF ITS OFFICERS, DIRECTORS, INVESTORS,
        EMPLOYEES, AGENTS, ADVERTISERS, LICENSORS, SUPPLIERS, SERVICE PROVIDERS AND
        OTHER CONTRACTORS TO YOU OR ANY THIRD PARTIES UNDER ANY CIRCUMSTANCE IS
        LIMITED TO A MAXIMUM AMOUNT OF $100.
      </p>
      <p>
        <strong>Indemnity</strong>. You agree to indemnify and hold harmless NetWise
        and its affiliates, suppliers, partners, officers, agents, and employees from
        and against any claim, demand, losses, damages or expenses (including
        reasonable attorney’s fees) arising from use or uploads of any User Files,
        your general use of the NetWise Platform, your connection to the NetWise
        Platform, your violation of these Terms of Use or your violation of any
        rights of any third-party.
      </p>
      <p>
        <strong>Equitable Relief.</strong> You acknowledge that a breach of these
        Terms of Use may cause us irreparable damage, for which the award of damages
        would not be adequate compensation. Consequently, you agree that we may
        institute an action to enjoin you from any and all acts in violation of those
        provisions, which remedy will be cumulative and not exclusive, and we may
        seek the entry of an injunction enjoining any breach or threatened breach of
        those provisions, in addition to any other relief to which we may be entitled
        at law or in equity.
      </p>
      <p>
        <strong>Assignment.</strong> We may freely transfer or assign any portion of
        our rights or delegate our obligations under these Terms of Use. You may not
        assign or otherwise transfer your rights, obligations, or duties under these
        Terms of Use, in whole or in part.
      </p>
      <p>
        <strong>DMCA.</strong> It is the policy of NetWise to promptly process and
        investigate notices of alleged copyright infringement, and take appropriate
        actions under the Digital Millennium Copyright Act, Title 17, United States
        Code, Section 512 (“DMCA”). If you are a copyright owner, authorized to act
        on behalf of one, or authorized to act under any exclusive right under
        copyright, please report alleged copyright infringements taking place on or
        through the NetWise Platform by providing the following information to
        NetWise’s Designated Copyright Agent:
        <ul>
          <li>
            Identify the copyrighted work that you claim has been infringed, or, if
            multiple copyrighted works are covered by this DMCA Notice of Alleged
            Infringement (“Notice”), you may provide a representative list of the
            copyrighted works that you claim have been infringed;
            <br />
          </li>
          <li>
            Identify the material or link you claim is infringing (or the subject of
            infringing activity) and to which access is to be disabled, including at
            a minimum, if applicable, the URL of the link shown on the NetWise
            Platform or the exact location where such material may be found;
            <br />
          </li>
          <li>
            Provide your company affiliation (if applicable), mailing address,
            telephone number, and, if available, email address; · Include the
            following statements in the body of the Notice:
          </li>
          <ul>
            <li>
              “I hereby state that I have a good faith belief that the disputed use
              of the copyrighted material is not authorized by the copyright owner,
              its agent, or the law (e.g., as a fair use).”
            </li>
            <li>
              “I hereby state that the information in this Notice is accurate and,
              under penalty of perjury, that I am the owner, or authorized to act on
              behalf of the owner, of the copyright or of an exclusive right under
              the copyright that is allegedly infringed.”
            </li>
            <br />
          </ul>
          <li>
            Provide your full legal name and your electronic or physical signature.
          </li>
        </ul>
        The Notice, with all items completed, may be delivered to NetWise’s
        Designated Copyright Agent by email at{' '}
        <a href="privacy@netwisedata.com">privacy@netwisedata.com</a>, or by mail at
        the following address:
        <p>
          Attn: Copyright Agent
          <br />
          NetWise Data, LLC
          <br />
          350 Camino Gardens Blvd Suite 202
          <br />
          Boca Raton, FL 33432
        </p>
        <p>
          Upon receipt of a Notice, with all items completed, NetWise will take
          whatever action, in its sole discretion, it deems appropriate, including
          removal of the challenged content from the NetWise Platform. NetWise
          receiving, investigating, or responding to your Notice does not constitute
          NetWise’s agreement or verification of your claim(s) or any admission of
          liability therefore.
          <p>Revised: October 29, 2021</p>
        </p>
      </p>
    </p>
  </>
)

export default Terms
