import React from 'react'
import styled from 'styled-components'
import Chip from '../../../../../components/Chip/'
import { toTitleCase } from '../../../../../utils/caseHelpers'

export default function TagManagerRow({
  title,
  selections,
  deleteFilter,
  setPopupAnchor,
}: any) {
  function handleClick(e: React.ChangeEvent<HTMLElement>, key: string) {
    setPopupAnchor(e.currentTarget, key)
  }

  return (
    <Container>
      {Object.keys(selections).length > 0 && <Title>{title}</Title>}
      <FiltersContainer>
        {Object.entries(selections).map(([key, values]: [string, any]) => {
          return (
            <Chip
              key={key}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: React.ChangeEvent<HTMLElement>) => void'... Remove this comment to see the full error message
              onClick={(e: React.ChangeEvent<HTMLElement>) =>
                handleClick(e, title + '.' + key)
              }
              // @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type 'null ... Remove this comment to see the full error message
              onDelete={() => {
                let keys = values.keys
                if (values.keys.includes('b2b_email')) {
                  keys = [...keys, 'good_catch_all', 'good_only']
                }
                deleteFilter(keys)
              }}
              text={toTitleCase(key)}
              number={key !== 'custom_SQL' && values.count}
            />
          )
        })}
      </FiltersContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-content: center;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5rem 0rem;
`

const FiltersContainer = styled.div`
  display: flex;
  flex: 10;
  gap: 0.5rem;
`
