import { useEffect, useState } from 'react'
import StandardDialog from '../StandardDialog'
import SimpleInput from '../SimpleInput'
import StandardAutocomplete from '../StandardAutocomplete'
import { toTitleCase } from 'utils/caseHelpers'
import Button from '../Button'
import { colors } from 'context/theme-context'

type Props = {
  title: string
  isOpen: boolean
  handleClose: () => void
  currentLayout: {
    hashed: string
    id: null
    fields: null
    chosen_revenue_share: boolean
    name: null
    chosen_field_package: string
    privacy: string
    editable: boolean
  }
  setCurrentLayout: React.Dispatch<Props['currentLayout']>
  saveLayout: (layout: Props['currentLayout']) => Promise<void>
  updateLayout: (layout: Props['currentLayout']) => Promise<void>
}

export default function SaveNewDialog(props: Props) {
  const {
    title,
    isOpen,
    handleClose,
    currentLayout,
    setCurrentLayout,
    saveLayout,
    updateLayout,
  } = props
  const { hashed } = currentLayout
  const [localState, setLocalState] = useState(currentLayout)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    setLocalState(currentLayout)
  }, [currentLayout])

  const generateHandler = (
    func: (layout: typeof currentLayout) => Promise<void>
  ) => {
    return async (layout: any) => {
      try {
        setErrorMessage(null)
        await func(layout)
        // update current layout with new name and display it in the title
        setCurrentLayout({
          ...currentLayout,
          name: localState.name,
        })
        // make sure to close the dialog
        handleClose()
      } catch (e: any) {
        setErrorMessage(e.message)
      }
    }
  }

  const saveLayoutHandler = generateHandler(saveLayout)
  const updateLayoutHandler = generateHandler(updateLayout)
  const handleCloseHandler = () => {
    setErrorMessage(null)
    handleClose()
  }

  const Content = (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
      <SimpleInput
        placeholder="Layout Name"
        label="Enter Layout Name"
        data-cy="enterLayoutName"
        value={localState.name || ''}
        onChange={(e: any) => {
          setLocalState((state: any) => ({
            ...state,
            name: e.target.value,
          }))
        }}
        debounced={false}
      />
      <StandardAutocomplete
        tagSuggestions={['private', 'team_view', 'team_edit']}
        label="Privacy"
        testId="layoutPrivacy"
        autocompleteProps={{
          getOptionLabel: (value: any) => toTitleCase(value),
          getOptionSelected: (option: any, value: any) =>
            toTitleCase(option) === value,
        }}
        placeholder="Select Privacy"
        value={toTitleCase(localState.privacy)}
        onChange={(_: any, value: any) =>
          setLocalState((localState) => ({
            ...localState,
            privacy: value,
          }))
        }
        multiple={false}
      />
      {errorMessage && (
        <div
          style={{
            color: colors.red7,
          }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  )

  const UpdateButton = hashed ? (
    <Button
      onClick={() => updateLayoutHandler(localState)}
      variant="primary"
      text={'Update Current'}
      disabled={!currentLayout.editable || currentLayout.privacy === 'global_view'}
      testId="updateCurrentLayout"
    />
  ) : (
    <div />
  )

  const ActionButtons = (
    <>
      <Button
        onClick={() => saveLayoutHandler(localState)}
        variant="primary"
        text={hashed ? 'Save as New' : 'Save'}
        id="save_layout"
        testId="saveNewLayout"
      />
      {UpdateButton}
    </>
  )

  return (
    <StandardDialog
      title={title}
      Content={Content}
      ActionButtons={ActionButtons}
      isOpen={isOpen}
      handleClose={handleCloseHandler}
      fullWidth
      maxWidth="md"
    />
  )
}
