import Tooltip from './Tooltip/'
import { ReactComponent as ExMark } from '../icons/16px/Exmark.svg'
import { colors } from '../context/theme-context/'

export default function UpdateWarningTooltip({ text = null }) {
  return (
    <Tooltip
      title={
        text ||
        `Changes made but not applied. Hit "u" key or click "Apply Changes" button to apply updates.`
      }
    >
      <ExMark
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
        className={null}
        style={{
          height: '24px',
          width: '24px',
          fill: colors.orange7,
        }}
      />
    </Tooltip>
  )
}
