import { useEffect, useState } from 'react'
import { useAuth } from 'context/auth-context'
import QuickExportDialog from 'components/QuickExportDialog'
import { useHistory, useLocation } from 'react-router-dom'
import ExportsLink from '../../../NameAudience/ExportsLink'
import { useSnackbar } from 'context/snackbar-context'
import BaseExportDialog from 'components/BaseExportDialog'
import SavedExportsTable from './SavedExports/SavedExportsTable'
import { MapEntry } from 'types'

type Props = {
  filtersHaveUpdated: boolean
  containerProps: any
  audienceInfo: any
  appliedFilters: any
  dispatch: any
}
type DialogMode = {
  isOpen: boolean
  type: 'run' | 'edit' | 'view'
}

export default function SavedExports(props: Props) {
  const { client, userData, socketHandler: sockets } = useAuth()
  const { setSnackbarMessage } = useSnackbar()
  const [dialog, setDialog] = useState<DialogMode>({ isOpen: false, type: 'run' })
  const [isQuickExportOpen, setIsQuickExportOpen] = useState(false)
  const [refreshSavedExports, setRefreshSavedExports] = useState({})
  const [chosenExport, setChosenExport] = useState({})
  const [updatedDTCache, setUpdatedDTCache] = useState({})
  const [creditsToBeUsed, setCreditsToBeUsed] = useState<number>()
  const history = useHistory()
  const location = useLocation()
  const { needsUpgrade } = userData

  useEffect(() => {
    setUpdatedDTCache({})
  }, [props.filtersHaveUpdated, props.audienceInfo.audience_id])

  const submissionCallback = () => {
    const url = location.pathname + '?tab=exports&focus=recent'
    const action = () => history.push(url)
    const LinkToRecentExports = <ExportsLink action={action} />
    setSnackbarMessage({ text: LinkToRecentExports })
  }

  const recentExportsRefreshHandler = () => {
    setRefreshSavedExports({ ...refreshSavedExports, refresh: true })
  }

  function toggleDialog() {
    setChosenExport({})
    setDialog((state) => ({ ...state, isOpen: !state.isOpen }))
  }
  const extractResults: MapEntry['callback'] = ({ results }) => {
    const {
      size,
      size_credited,
      size_uncredited,
      size_creditable,
      size_exportable,
      export_hash,
    } = results

    setUpdatedDTCache((state) => ({
      ...state,
      [export_hash]: {
        size: size,
        size_credited: size_credited,
        size_uncredited: size_uncredited,
        size_creditable: size_creditable,
        size_exportable: size_exportable,
      },
    }))

    // This is used for the Saved Exports modal.  After you open the modal and then attempt to run the export
    // it will try and calculate the number of credits used.  Once we have the result from the api we save it here.
    setCreditsToBeUsed(size_creditable)
  }

  if (sockets) {
    sockets.taskSocket?.addMapback({
      id: 'savedExports',
      socketRequestType: 'export_size',
      callback: extractResults,
    })
  }

  async function calculate(hashed: any) {
    setCreditsToBeUsed(undefined)

    try {
      await client.post({
        endpoint: client.endpoints.exportSizeAPI,
        data: {
          exports: [hashed],
          additional_audience: props.appliedFilters,
          channel_name: sockets?.taskSocket?.channelName,
        },
      })
    } catch (e) {
      console.log('Could not retrieve export size', e)
    }
  }

  async function runExport(hash: any) {
    toggleDialog()
    const { responseData } = await client.get({
      endpoint: client.endpoints.exportsIdAPI(hash),
      data: { file_name_date: true },
    })
    const { results: savedExport } = responseData
    setChosenExport(savedExport)
  }

  async function editExport(hash: any) {
    toggleDialog()
    const { responseData } = await client.get({
      endpoint: client.endpoints.exportsIdAPI(hash),
    })
    const { results: savedExport } = responseData
    setChosenExport(savedExport)
  }

  async function updateFavoriteStatus(updatedFavoriteItem: any) {
    try {
      const payload = { favorite: !updatedFavoriteItem.favorite }
      await client.put({
        endpoint: client.endpoints.exportsIdAPIFavorite(updatedFavoriteItem.hashed),
        data: payload,
      })
      recentExportsRefreshHandler()
    } catch (e) {
      console.log('Could not edit export (favorite)', e)
    }
  }

  async function viewExport(hash: any) {
    toggleDialog()
    const { responseData } = await client.get({
      endpoint: client.endpoints.exportsIdAPI(hash),
    })
    const { results: savedExport } = responseData
    setChosenExport(savedExport)
  }

  function handleQuickExport() {
    setIsQuickExportOpen(true)
  }

  let dialogProps = {}
  switch (dialog.type) {
    case 'view':
      dialogProps = {
        disabled: true,
      }
      break
    case 'edit':
      dialogProps = {
        setRenderExportAudience: () =>
          props.dispatch({
            payload: { renderExportAudience: true },
          }),
      }
      break
    case 'run':
      dialogProps = {
        audienceInfo: props.audienceInfo,
        appliedFilters: props.appliedFilters,
        setRenderExportAudience: () =>
          props.dispatch({ payload: { renderExportAudience: true } }),
        submissionCallback: submissionCallback,
      }
      break
  }

  return (
    <div {...props.containerProps}>
      <SavedExportsTable
        needsUpgrade={needsUpgrade}
        filtersHaveUpdated={props.filtersHaveUpdated}
        handleQuickExport={handleQuickExport}
        refreshSavedExports={refreshSavedExports}
        exportsAPI={client.endpoints.exportsTableAPI}
        exportsIdAPI={client.endpoints.exportsIdAPI}
        updateFavoriteStatus={updateFavoriteStatus}
        viewExport={viewExport}
        runExport={runExport}
        userData={userData}
        editExport={editExport}
        updatedDTCache={updatedDTCache}
        calculate={calculate}
      />
      <QuickExportDialog
        audienceInfo={props.audienceInfo}
        isOpen={isQuickExportOpen}
        handleExit={() => setIsQuickExportOpen(false)}
        appliedFilters={props.appliedFilters}
        dispatch={props.dispatch}
        submissionCallback={submissionCallback}
      />

      <BaseExportDialog
        dialogType={dialog.type}
        chosenExport={chosenExport}
        handleExit={() => setDialog((state) => ({ ...state, isOpen: false }))}
        isOpen={dialog.isOpen}
        credits={userData.credits}
        calculateCreditUsage={calculate}
        creditsToBeUsed={creditsToBeUsed}
        {...dialogProps}
      />
    </div>
  )
}
