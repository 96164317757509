import { useState, useEffect } from 'react'
import MUISnackbar from '@material-ui/core/Snackbar'
import { ContentDiv, UndoDiv, TextDiv, StyledCheckmark, StyledExmark } from './Css'
import Button from '../../components/Button'

const Undo = ({ action }: any) => <UndoDiv onClick={action}>Undo</UndoDiv>

interface Props {
  text: string
  type: 'success' | 'failure'
  clickAction?: any
  undoAction?: React.FC<any>
}

const Snackbar: React.FC<Props> = ({
  text,
  type = 'success',
  clickAction = null,
  undoAction = null,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [messageQueue, setMessageQueue] = useState([])

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission()
    } else if (!!text && document.visibilityState !== 'visible') {
      const notification = new Notification(text, { icon: '/logo.png' })
      notification.addEventListener('click', clickAction)
    }
  }, [text, clickAction])

  useEffect(() => {
    if (!!text) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(state: never[]) => { text: stri... Remove this comment to see the full error message
      setMessageQueue((state) => [{ text, type, undoAction }, ...state])
      setIsOpen(true)
    }
  }, [text, type, undoAction])

  if (!text) {
    return null
  }

  function handleClose() {
    setMessageQueue((state) => {
      const stateCopy = [...state]
      stateCopy.pop()
      return [...stateCopy]
    })
    setIsOpen(false)
  }

  if (messageQueue.length > 0 && document.visibilityState === 'visible') {
    const queueCopy = [...messageQueue]
    const queuedMessage = queueCopy.pop()
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (!!queuedMessage.text) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'text' does not exist on type 'undefined'... Remove this comment to see the full error message
      const { text, type, undoAction } = queuedMessage
      const Icon = type === 'success' ? StyledCheckmark : StyledExmark

      // Temporary hack to satify Cam's request
      // TODO: Update with more customizable message (more than just text)
      let updatedMessage = text
      if (text === 'Export finished. Click here to download.') {
        updatedMessage = (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Export finished</span>
            <div style={{ marginLeft: '0.5rem' }}>
              <Button
                text="Download Now"
                onClick={clickAction}
                variant="secondary"
                size="medium"
                testId="downloadNowButton"
              />
            </div>
          </div>
        )
      }
      return (
        <MUISnackbar
          open={isOpen}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={() => handleClose()}
        >
          <ContentDiv data-cy="snackbar">
            <Icon />
            <TextDiv>{updatedMessage}</TextDiv>
            {undoAction && <Undo action={undoAction} />}
          </ContentDiv>
        </MUISnackbar>
      )
    }
  }

  return null
}

export default Snackbar
