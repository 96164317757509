import ListAccordion from '../Accordion/ListAccordion'
import styled from 'styled-components'
import SimpleInput from '../SimpleInput'
import StandardAutocomplete from '../StandardAutocomplete'
import { useAuth } from '../../context/auth-context'
import { useEffect } from 'react'
import LayoutFieldQuick from '../ExportDialog/LayoutFieldQuick'
import { getDateTime } from '../../utils/dateTimeFormatter'
import Checkbox from 'components/Checkbox'

type ExportState = {
  file_name: string
  email_notifications: string[]
  layout: string
  record_limit: string
  record_limit_per_company: string
  prioritize_by: string[]
  partition_by: string[]
  delimiter: string
  strip_delimiter_and_quotes: boolean
  quotes: boolean
  compression: string
  header: boolean
  enable_appends: boolean
}

type Props = {
  setExportState: React.Dispatch<React.SetStateAction<ExportState>>
  exportState: ExportState
  layouts: any
  audienceInfo: any
  showAppendsCheckbox: boolean
}

export default function GeneralSectionQuick(props: Props) {
  const { userData } = useAuth()

  const { audienceInfo, setExportState } = props
  useEffect(() => {
    const fileDateTime = getDateTime()
    setExportState((state: any) => ({
      ...state,
      email_notifications: [userData.email],
      file_name: audienceInfo.audience_name
        ? `${audienceInfo.audience_name} - Quick Export (${fileDateTime})`
        : `Quick Export (${fileDateTime})`,
    }))
  }, [userData, audienceInfo.audience_name, setExportState])

  return (
    <ListAccordion title="General" expanded>
      <ContentDiv>
        <RowDiv>
          <LabelSpan>File Name</LabelSpan>
          <InputSpan id="quick_export_file_name">
            <SimpleInput
              value={props.exportState.file_name}
              onChange={(e: any) =>
                props.setExportState((state: any) => ({
                  ...state,
                  file_name: e.target.value,
                }))
              }
              data-cy="fileName"
            />
          </InputSpan>
        </RowDiv>
        <RowDiv>
          <LabelSpan>Notify Me At</LabelSpan>
          <InputSpan>
            <StandardAutocomplete
              freeSolo
              multiple={true}
              value={props.exportState.email_notifications}
              tagSuggestions={props.exportState.email_notifications}
              onChange={(_: any, value: any) => {
                props.setExportState((state: any) => ({
                  ...state,
                  email_notifications: value,
                }))
              }}
              testId="notifyMeAt"
            />
          </InputSpan>
        </RowDiv>
        <LayoutFieldQuick
          exportState={props.exportState}
          setExportState={props.setExportState}
          layouts={props.layouts}
        />
        {props.showAppendsCheckbox && (
          // Check if the 'should_enable_appends' property is set to true in the service
          // and display the corresponding checkbox in the UI if it is enabled.
          <RowDiv>
            <div style={{ flex: 1, justifyContent: 'center' }}>Add Input Field</div>
            <InputSpan>
              <Checkbox
                onClick={() => {
                  props.setExportState((state: ExportState) => ({
                    ...state,
                    enable_appends: !state.enable_appends,
                  }))
                }}
                status={props.exportState.enable_appends}
                data-cy="includeInputField"
                id="includeInputField"
              />
            </InputSpan>
          </RowDiv>
        )}
      </ContentDiv>
    </ListAccordion>
  )
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  font-weight: 400;
`
const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`
const LabelSpan = styled.span`
  padding-top: 10px;
  display: flex;
  align-self: flex-start;
  flex: 1;
`
const InputSpan = styled.span`
  display: flex;
  flex: 5;
`
