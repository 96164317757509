import { useState } from 'react'
import SimpleInput from '../../components/SimpleInput'
import { useAuth } from '../../context/auth-context'
import AuthPage, { SubmitButton } from './AuthPage'
import LoadingScreen from '../../components/LoadingScreen'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const LoginScreen: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false)
  const { login, hasAttemptedRefresh } = useAuth()

  const stateHandler = (stateFn: any, e: any) => {
    const value = e.target.value.trim()
    stateFn(value.trim())
  }

  const loginHandler = async () => {
    const loginSuccess = await login(email, password)
    if (!loginSuccess) {
      setLoginError(true)
    }
  }

  const AuthComponent = (
    <AuthPage title={'Sign In'}>
      <InputContainer>
        <SimpleInput
          error={loginError}
          placeholder={'Email Address'}
          name="email"
          value={email}
          autoComplete="email"
          onChange={(e: any) => stateHandler(setEmail, e)}
          autoFocus={true}
          inputProps={{ style: { padding: '1rem' } }}
          debounced={false}
          data-cy="emailAddress"
        />
        <SimpleInput
          error={loginError}
          placeholder="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e: any) => stateHandler(setPassword, e)}
          inputProps={{ style: { padding: '1rem' } }}
          debounced={false}
          data-cy="password"
        />
        <SubmitButton
          text="SIGN IN"
          variant="primary"
          size="medium"
          id="sign_in_button"
          onClick={() => loginHandler()}
          data-cy="signInButton"
        />
        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
          <div>
            <Link to="/request_reset" data-cy="forgotPassword">
              Forgot password?
            </Link>
          </div>
          <div>
            <a href="https://www.netwisedata.com/sign-up/" data-cy="signUp">
              Sign Up
            </a>
          </div>
        </div>
      </InputContainer>
    </AuthPage>
  )

  if (!hasAttemptedRefresh) {
    return <LoadingScreen />
  }
  return AuthComponent
}

const InputContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`

export default LoginScreen
export { InputContainer }
