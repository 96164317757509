import { MapRecord, MapEntry, SnackbarMessage } from 'types'
import { log } from 'utils/debug'
import getWsURLPrefix from 'utils/getURLPrefix'

// TaskSocket.ts summary:
// 1) Tracks its own unique channel name (channelName), which should be used by the server
//    to deliver customized messages. Using the "id" attribute allows the user to map
//    multiple functions and / or components to a SocketRequestType.
// 2) Knows how server-sent reponses of a given type (SocketRequestType) are to be handled.
//    Accomplishes this by mapping the request type to a given function (callbackMap).
// 3) Can send notifications based on the message type. The message is also tied to the
//    request type.

type SocketRequestType =
  | 'parse_file'
  | 'clean_file'
  | 'calculate_statistics'
  | 'audience_size'
  | 'total_companies'
  | 'company_metrics'
  | 'top_titles'
  | 'personal_linkages'
  | 'employee_buckets'
  | 'revenue_buckets'
  | 'top_functional_areas'
  | 'top_seniorities'
  | 'company_industries'
  | 'export_size'
  | 'top_contacts'
  | 'top_companies'

type Args = {
  channelName?: string
  callbackMap: Partial<Record<SocketRequestType, MapRecord>>
  sendNotification?: (message: SnackbarMessage) => any
}

type Mapback = {
  id: string
  socketRequestType: SocketRequestType
  callback: MapEntry['callback']
  notificationMessage?: MapEntry['notificationMessage']
}

class TaskSocket extends WebSocket {
  channelName: Args['channelName']
  callbackMap: Args['callbackMap']
  sendNotification: Args['sendNotification']

  constructor(sendNotification?: Args['sendNotification']) {
    // Websockets must always use this as their URL (server-defined).
    const url = getWsURLPrefix() + 'tasks/'
    super(url)

    // Setting class properties
    this.channelName = undefined
    this.callbackMap = {}
    this.sendNotification = sendNotification

    // Adding event listeners
    this.addEventListener('open', () => {
      log('WebSocket opened with url of ' + url)
    })

    this.addEventListener('close', (e) => {
      log(`TaskSocket closed`)
    })

    this.addEventListener('error', (e) => {
      log(`TaskSocket errored: ${JSON.stringify(e)}`)
    })

    this.addEventListener('message', (e: MessageEvent) => {
      try {
        // Creating types
        const { callbackMap } = this
        type MessageType = 'channel_name' | keyof typeof callbackMap
        type Message = {
          type: MessageType
          channel_name: string
          results: any
          task_id: string
        }

        // Parsing and extracting data
        const message = JSON.parse(e.data)
        const { type, channel_name, results = {}, task_id }: Message = message

        // A channel_name type is a special case
        if (type === 'channel_name') {
          log('Channel name is: ' + channel_name)
          this.channelName = channel_name
        }

        // All other types are handled by acting on the callback function and notification message
        if (type !== 'channel_name') {
          const mapRecord = this.callbackMap[type] || {}
          Object.entries(mapRecord).forEach(([, entry]) => {
            if (entry.callback) entry.callback({ taskType: type, results, task_id })
            if (entry.notificationMessage)
              this.sendNotification?.({ text: entry.notificationMessage })
          })
        }
      } catch (e: any) {
        log(e)
      }
    })
  }

  addMapback({ id, socketRequestType, callback, notificationMessage }: Mapback) {
    // A MapEntry is added for each id. This allows multiple components / functions to respond to websocket messages
    this.callbackMap[socketRequestType] = {
      ...this.callbackMap[socketRequestType],
      [id]: {
        callback,
        notificationMessage,
      },
    }
  }
}

class SocketHandler {
  taskSocket: TaskSocket
  sendNotification: Args['sendNotification']

  constructor() {
    this.taskSocket = new TaskSocket()
    this.taskSocket.onclose = this.resetSocket
  }

  // Allows handler to set notification functions for TaskSocket
  setNotificationFunction = (sendNotification: Args['sendNotification']) => {
    this.taskSocket.sendNotification = sendNotification
  }

  resetSocket = (e: CloseEvent): any => {
    log(`TaskSocket errored: ${JSON.stringify(e, ['type'])}`)
    setTimeout(() => {
      if (window.document.visibilityState === 'hidden') {
        // Do not reconnect since the browser tab is hidden.
        const waitEvent = { ...e, type: 'wait-until-active' }
        this.resetSocket(waitEvent)
      } else {
        // Only reconnect to the websocket if browser tab is visible
        if (this.taskSocket) {
          this.taskSocket.onclose = null
          this.taskSocket = new TaskSocket(this.sendNotification)
          this.taskSocket.onclose = this.resetSocket
        }
      }
    }, 5000)
    this.taskSocket = new TaskSocket(this.sendNotification)
  }
}

export { SocketHandler }
