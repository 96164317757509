import NavItem from './NavItem'
import NavButton from './NavButton'
import { ReactComponent as Logo } from '../../../../icons/24px/Upload.svg'
import { colors, typography } from '../../../../context/theme-context/'
import styled from 'styled-components'
const { big } = typography.bodyRegular

const Label = styled.span`
  font-size: ${big.fontSize};
  line-height: ${big.lineHeight};
  font-weight: ${big.fontWeight};
`

export default function AudiencesNav({ selected, smallScreen }: any) {
  const isSelected = selected.top === 'uploads'

  const Content = (
    <NavButton
      selected={isSelected}
      Icon={Logo}
      Label={<Label>Uploads</Label>}
      textColor={colors.blue8}
    />
  )

  return (
    <NavItem
      smallScreen={smallScreen}
      to={'/uploads'}
      NavContent={Content}
      subNav={[
        {
          name: 'Upload New File',
          link: '/uploads?action=upload_new_file',
          id: 'uploads_upload_new',
        },
        { name: 'Uploaded Files', link: '/uploads' },
        { name: 'Matched Files', link: '/uploads?action=to_matched_files' },
      ]}
      id="uploads"
    />
  )
}
