import React from 'react'
import Button from '@material-ui/core/Button'
import DropdownChevron from '../DropdownChevron'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const NumberCount = styled(Typography)<{ count: number }>`
  margin-left: 0.5em;
  border-radius: 50%;
  width: 1.75em;
  background-color: ${(props) =>
    props.count > 0 ? props.theme.palette.primary.main : 'lightgrey'};
  color: white;
  font-size: 1em;
  text-align: center;
  transition: background-color 500ms ease-in;
`

export default function FilterLabel({
  menuName,
  isOpen,
  setIsOpen,
  anchorRef,
  options,
}: any) {
  const filterCount = options.filter((option: any) => option.selected).length

  function handleClick() {
    setIsOpen(!isOpen)
  }
  return (
    <Container>
      <Button
        style={{ backgroundColor: isOpen && '#e0e0e0' }}
        ref={anchorRef}
        onClick={handleClick}
      >
        {menuName}
        <NumberCount count={filterCount}>{filterCount}</NumberCount>
        <DropdownChevron isAnimating={isOpen} />
      </Button>
    </Container>
  )
}

export { NumberCount }
