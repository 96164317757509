import RemoteDataTableProps from './remoteDataTableProps'
import { theme as defaultTheme } from 'components/DataTable/theme'
import { disabledTheme as defaultDisabledTheme } from 'components/DataTable/theme'

export default function getDefaultDataTableProps(props: RemoteDataTableProps) {
  // This generates default values for properties that aren't specified.
  // It is done here in a separate file for cleanliness.
  const updatedEntries = Object.entries(props).map(([key, value]) => {
    try {
      if (typeof value === 'undefined') {
        return [key, defaults[key]]
      }
      return [key, value]
    } catch (e) {
      return [key, undefined]
    }
  })

  const updatedProps = Object.fromEntries(updatedEntries)
  return { ...defaults, ...updatedProps }
}

const defaults: Record<string, any> = {
  title: undefined,
  columns: undefined,
  initialSortOrder: '-modified',
  refreshState: false,
  columnOrderMap: {},
  externalSearchText: '',
  expandableRows: false,
  controls: true,
  layouts: [],
  debounceDelay: 250,
  viewColumns: false,
  searchControl: true,
  autosaveControl: false,
  deleteAPI: (id: number) => '',
  excelControl: false,
  csvControl: false,
  copyControl: false,
  selectLayout: false,
  selectableRows: 'none',
  outOfDate: false,
  customControls: null,
  setColumns: () => null,
  url: undefined,
  urlParams: undefined,
  theme: defaultTheme,
  disabledTheme: defaultDisabledTheme,
  rowsOnPage: 10,
  customRowRender: (x: any) => x,
  deleteControl: false,
  refreshAtInterval: true,
  responsive: 'standard',
  TitleComponent: null,
  getHumanRowIdentifier: () => '',
  useWebSocket: false,
  testId: 'remoteTable',
}
