import { bestFuzzyMatch } from '../../../../utils/fuzzyMatch'

export default function matchFileField(
  fields: Array<string> = [],
  dbFields: Array<string>
) {
  function matchField(pattern: RegExp, databaseMatch?: string) {
    const matches = fields.map((field) => {
      const didMatch = field.match(pattern)
      return didMatch
        ? {
            file_field: field,
            database_field: databaseMatch || bestFuzzyMatch(field, dbFields),
          }
        : undefined
    })
    return matches[0]
  }

  const dunsMatch = matchField(/d_?u_?n/i, 'duns_number')
  if (dunsMatch) {
    const domainDunsMatch = matchField(/domain/i, 'company_domain_duns_number')
    if (domainDunsMatch) return domainDunsMatch

    const headquartersDunsMatch = matchField(
      /headquarter/i,
      'company_duns_number_headquarters'
    )
    if (headquartersDunsMatch) return headquartersDunsMatch

    const localDunsMatch = matchField(/local/i, 'company_duns_number_local')
    if (localDunsMatch) return localDunsMatch

    const waterfallDunsMatch = matchField(
      /waterfall/i,
      'company_duns_number_waterfall'
    )
    if (waterfallDunsMatch) return waterfallDunsMatch

    // If it's not any specific duns column then let's return back the duns_number
    return dunsMatch
  }

  const b2bEmailDomainMatch = matchField(/b2b_email_domain/i, 'b2b_email_domain')
  if (b2bEmailDomainMatch) return b2bEmailDomainMatch

  const emailMatch = matchField(/email/i, 'email')
  if (emailMatch) return emailMatch

  const domainMatch = matchField(/domain/i, 'company_domain')
  if (domainMatch) return domainMatch

  const websiteMatch = matchField(/website/i, 'company_website')
  if (websiteMatch) return websiteMatch

  return {
    file_field: fields[0],
    database_field: bestFuzzyMatch(fields[0], dbFields) || 'company_domain',
  }
}
