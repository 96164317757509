import styled from 'styled-components'
import { useAuth } from '../../../../../context/auth-context'
import { colors, typography } from '../../../../../context/theme-context'

export default function ProfileButton() {
  const { userData } = useAuth()
  const { first_name, last_name, credits, account } = userData
  const initials = first_name[0] + last_name[0]
  const name = first_name + ' ' + last_name
  const isCta = credits === 0 && account.stage === 'freemium'

  let CreditsElement =
    credits == null ? (
      <CountDiv>
        <div style={{ flex: 1 }}>Credits:&nbsp;</div>
        <div style={{ flex: 1, fontSize: '1.15rem' }}>&infin;</div>
      </CountDiv>
    ) : (
      <CountDiv>Credits: {credits.toLocaleString()}</CountDiv>
    )

  if (isCta) {
    CreditsElement = (
      <CountDiv id="header_upgrade_link">
        <div style={{ flex: 1 }}>Upgrade Now</div>
      </CountDiv>
    )
  }

  return (
    <>
      <BoxInitials>{initials}</BoxInitials>
      <ProfileContainer>
        <NameDiv>{name}</NameDiv>
        {CreditsElement}
      </ProfileContainer>
    </>
  )
}

const { medium } = typography.bodyBold
const { big, small } = typography.bodyRegular

const BoxInitials = styled.div`
  padding: 0.75rem;
  line-height: ${medium.lineHeight};
  font-size: ${medium.fontSize};
  font-weight: ${medium.fontWeight};
  color: ${colors.green9};
  background-color: ${colors.green3};
  border-radius: 0.25rem;
  border: 1px solid ${colors.green5};
`

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.875rem 0rem 0.875rem 0.5rem;
`

const NameDiv = styled.div`
  font-size: ${big.fontSize};
  font-weight: ${big.fontWeight};
  line-height: ${big.lineHeight};
  color: ${colors.gray11};
`

const CountDiv = styled.div`
  display: flex;
  align-content: center;
  font-size: ${small.fontSize};
  font-weight: ${small.fontWeight};
  line-height: ${small.lineHeight};
  color: ${colors.gray8};
`
