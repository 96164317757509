import React from 'react'
import BasicInfo from './AccountDetail/BasicInfo'
import CustomInfo from './AccountDetail/CustomInfo'
import AdvancedInfo from './AccountDetail/AdvancedInfo'
import AccountsCreditsSchedule from './AccountDetail/AccountsCreditsSchedule'
import ReferenceInfo from './AccountDetail/ReferenceInfo'
import ContentArea from 'components/ContentArea'
import Button from 'components/Button'
import Grid from '@material-ui/core/Grid'
import Alert from 'components/Alert'
import makeInitialAdminState from './adminInitialState'
import {
  AccountInfo,
  AdvancedInformation,
  BasicInformation,
  ReferenceInformation,
  CustomFields,
  Features,
} from './adminTypes'
import { useAuth } from 'context/auth-context'
import { useAlerts } from 'context/alert-context'
import { Redirect, useParams } from 'react-router-dom'
import { TimeCollection } from 'types'
import { toTitleCase } from 'utils/caseHelpers'
import ProofOfDelivery from './AccountDetail/ProofOfDelivery'

export default function AccountDetail() {
  const { client, userData } = useAuth()
  const { sendAlert } = useAlerts()
  const { id } = useParams<{ id: string }>()

  const accountId = Number(id)
  const canCreateAccounts = userData.admin || userData.account.admin

  const [hasCreditSchedule, setHasCreditSchedule] = React.useState(false)
  const [state, setState] = React.useState<AccountInfo>(
    makeInitialAdminState({ valuesUndefined: true })
  )
  const [alertState, setAlertState] = React.useState({
    isOpen: false,
    message: 'Account Created',
  })

  const {
    basicInformation,
    advancedInformation,
    customFields,
    referenceInformation,
    creditSchedule,
    features,
  } = state

  React.useEffect(() => {
    async function getData() {
      const url = client.endpoints.accountsIdAPI(accountId)
      const [accountRes, scheduleRes] = await Promise.all([
        client.get({ endpoint: url }),
        client.get({ endpoint: url + '/credits_schedule' }),
      ])

      const accountData = accountRes.responseData?.results || {}
      const scheduleData = scheduleRes.ok
        ? scheduleRes.responseData?.results
        : undefined

      const _account: {
        basicInformation: BasicInformation
        customFields: CustomFields
        advancedInformation: AdvancedInformation
        referenceInformation: ReferenceInformation
        features: Features
      } = {
        basicInformation: {
          name: accountData.name,
          domain: accountData.domain,
          stage: accountData.stage,
          signupSource: 'netwise',
          isActive: accountData.active,
          isAdminAccount: accountData.is_admin_account,
          expires: new Date((accountData.expires as TimeCollection).raw),
          salesforceUrl: accountData.salesforce_url,
          salesforceRecordId: accountData.salesforce_record_id,
          totalContractValue: accountData.total_contract_value,
          csmOwner: accountData.csm_owner,
          orderNumber: accountData.order_number,
          contractStartDate: accountData.contract_start_date.raw ? new Date((accountData.contract_start_date as TimeCollection).raw) : undefined,
          contractEndDate: accountData.contract_end_date.raw ? new Date((accountData.contract_end_date as TimeCollection).raw) : undefined,
        },
        customFields: {
          piiAccess: accountData.pii_fields,
          analysisOnly: accountData.analysis_only,
          anonymizedOnly: accountData.anonymous_only,
          additionalFields: accountData.additional_fields,
          restrictedFields: accountData.restricted_fields,
          accountTypeRefId: accountData.account_type_ref_id,
          layoutFilters: accountData?.layout_filters,
        },
        advancedInformation: {
          creditsUnlimited: accountData.credits_unlimited,
          creditsUnlimitedAnonymized: accountData.credits_unlimited_anonymized,
          s3DeliveryFolder: accountData.s3_delivery_folder,
          ownershipRetentionDays: accountData.record_ownership_retention_days,
          disableUploads: accountData.disable_uploads,
          suppressFromCRM: accountData.suppress_from_crm,
        },
        referenceInformation: {
          testAccount: accountData.test_account,
          lifetimeRecordsExported: accountData.lifetime_records_exported,
          lifetimeCreditsGranted: accountData.lifetime_credits_granted,
          lifetimeCreditsUsed: accountData.lifetime_credits_used,
          monthlyInvoiceAmount: accountData.monthly_invoice_amount,
          currentlyPaying: accountData.currently_paying,
        },
        features: {
          enableAppends: accountData.enable_appends,
          enableUploadDuns: accountData.enable_upload_duns,
          enableExportDuns: accountData.enable_export_duns,
        },
      }

      const _schedule = {
        creditSchedule: {
          active: scheduleData?.active || false,
          scheduleStartYear: Number(scheduleData?.credit_schedule_start_year),
          scheduleStartMonth: Number(scheduleData?.credit_schedule_start_month),
          credits: Number(scheduleData?.credits),
          creditSchedule: scheduleData?.credit_schedule,
          creditLimit: scheduleData?.credit_limit,
        },
      }
      setState({ ..._account, ..._schedule })
      setHasCreditSchedule(!!scheduleData)
    }

    getData()
  }, [])

  const toggleAlert = () => {
    setAlertState((state) => ({ ...state, isOpen: !state.isOpen }))
  }

  const save = async () => {
    const account = {
      name: basicInformation?.name,
      domain: basicInformation?.domain,
      account_stage: basicInformation?.stage,
      account_signup_source: basicInformation?.signupSource,
      is_active: basicInformation?.isActive,
      expires: basicInformation?.expires,
      is_admin_account: basicInformation?.isAdminAccount,

      grant_access_to_pii_fields: customFields?.piiAccess,
      restrict_access_to_analysis_only_fields: customFields?.analysisOnly,
      restrict_access_to_anonymized_fields: customFields?.anonymizedOnly,
      restricted_fields: customFields?.restrictedFields,
      additional_fields: customFields?.additionalFields,
      account_type_ref_id: customFields?.accountTypeRefId || '',
      layout_filters: customFields?.layoutFilters,

      credits_unlimited: advancedInformation?.creditsUnlimited,
      credits_unlimited_anonymized: advancedInformation?.creditsUnlimitedAnonymized,
      s3_delivery_folder: advancedInformation?.s3DeliveryFolder,
      record_ownership_retention_days: advancedInformation?.ownershipRetentionDays,
      disable_uploads: advancedInformation?.disableUploads,
      suppress_from_crm: advancedInformation?.suppressFromCRM,

      test_account: referenceInformation?.testAccount,
      monthly_invoice_amount: referenceInformation?.monthlyInvoiceAmount,
      currently_paying: referenceInformation?.currentlyPaying,
      salesforce_url: basicInformation.salesforceUrl,
      salesforce_record_id: basicInformation.salesforceRecordId,
      total_contract_value: basicInformation.totalContractValue,
      csm_owner: basicInformation.csmOwner,
      order_number: basicInformation.orderNumber,
      contract_start_date: basicInformation?.contractStartDate,
      contract_end_date: basicInformation?.contractEndDate,
      enable_appends: features.enableAppends,
      enable_upload_duns: features.enableUploadDuns,
      enable_export_duns: features.enableExportDuns,
    }

    const schedule = {
      active: creditSchedule?.active,
      credit_schedule_start_year: creditSchedule?.scheduleStartYear,
      credit_schedule_start_month: creditSchedule?.scheduleStartMonth,
      credits: creditSchedule?.credits || 0,
      credit_schedule: creditSchedule?.creditSchedule || 'monthly',
      credit_limit: creditSchedule?.creditLimit || 0,
    }

    try {
      const url = client.endpoints.accountsIdAPI(accountId)

      let accountResults
      let scheduleResults
      if (
        schedule.credit_schedule_start_month ||
        schedule.credit_schedule_start_year
      ) {
        const results = await Promise.all([
          client.put({ endpoint: url, data: account }),
          client.put({ endpoint: url + '/credits_schedule', data: schedule }),
        ])
        accountResults = results[0]
        scheduleResults = results[1]
      } else {
        accountResults = await client.put({ endpoint: url, data: account })
      }

      // Display only first error to user
      const accountMessage = accountResults?.errorMessage
      const scheduleMessage = scheduleResults?.errorMessage
      const errorMessage = accountMessage ?? scheduleMessage

      if (errorMessage) {
        const [key, value] = Object.entries(errorMessage)[0]
        sendAlert({ message: toTitleCase(key) + ': ' + value })
      } else {
        sendAlert({ message: 'Account Updated' })
      }
    } catch (e) {
      console.log('Could not update account')
    }
  }

  const removeCreditSchedule = async () => {
    if (hasCreditSchedule) {
      const url = client.endpoints.accountsAPI
      await client.delete({ endpoint: url + '/' + id + '/credits_schedule' })
      setState((state) => ({
        ...state,
        creditSchedule: {},
      }))
      setHasCreditSchedule(false)
    }
  }

  const updateInfo = (update: Partial<AccountInfo>) => {
    const fn = (state: any) => ({ ...state, ...update })
    setState(fn)
  }

  return canCreateAccounts && userData ? (
    <ContentArea style={{ padding: '0.5rem 2rem 1rem' }}>
      <BasicInfo basicInfo={basicInformation} updateInfo={updateInfo} />
      <CustomInfo customFields={customFields} updateInfo={updateInfo} />
      <AdvancedInfo advancedInfo={advancedInformation} updateInfo={updateInfo} />
      <ReferenceInfo referenceInfo={referenceInformation} />
      <AccountsCreditsSchedule
        creditSchedule={creditSchedule}
        updateInfo={updateInfo}
        hasCreditSchedule={hasCreditSchedule}
        removeCreditSchedule={removeCreditSchedule}
        addCreditSchedule={() => setHasCreditSchedule(true)}
      />
      <ProofOfDelivery accountId={id} />
      <Grid container justify="center" style={{ columnGap: '1rem' }}>
        <Grid item>
          <Button
            text={'Update Account'}
            onClick={save}
            variant="primary"
            data-cy="updateAccount"
          />
        </Grid>
      </Grid>
      <Alert
        alertIsOpen={alertState.isOpen}
        message={alertState.message}
        handleOk={toggleAlert}
        handleClose={toggleAlert}
      />
    </ContentArea>
  ) : (
    <Redirect to="audiences" />
  )
}
