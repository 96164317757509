import ClientDataTable, {
  CopyTooltip,
} from '../../../../../components/ClientDataTable'
import makeThemes from '../../../../../components/DataTable/theme'

const MAX_CELL_WIDTH = 40
const [theme, disabledTheme] = makeThemes(MAX_CELL_WIDTH)

export default function SampleContacts({
  sampleContacts,
  getAndApplyLayout,
  filtersHaveUpdated,
  audienceInfo,
}: any) {
  const rows = sampleContacts?.rows
  const columns = sampleContacts?.columns

  function makeConditionalTooltip(text: any) {
    return <CopyTooltip text={text} maxWidth={MAX_CELL_WIDTH} />
  }

  const customRows = rows?.map((contact: any) => {
    const newContact = {}
    for (const [key, value] of Object.entries(contact)) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newContact[key] = makeConditionalTooltip(value)
    }
    return newContact
  })

  const valueToText = (v: any) => {
    return v.props.text
  }

  const csvFilename = `${
    audienceInfo?.audience_name || 'NetWise Audience Platform'
  } Sample Contacts.csv`

  return (
    <div style={{ padding: '1rem' }}>
      <ClientDataTable
        title="sampleContacts"
        setColumns={getAndApplyLayout}
        selectLayout={true}
        data={customRows}
        columns={columns}
        outOfDate={!filtersHaveUpdated}
        csvControl={true}
        csvFilename={csvFilename}
        valueToText={valueToText}
        theme={theme}
        disabledTheme={disabledTheme}
      />
    </div>
  )
}
