import matchFileField from './ExactMatch/matchFileField'
import ExactMatchDialog from './ExactMatch/ExactMatchDialog'
import { useEffect, useState, useCallback } from 'react'
import { useAlerts } from 'context/alert-context'
import { useAuth } from 'context/auth-context'
import { initialState as uploadInitialState } from 'App/SmartHub/Uploads'
import { MapEntry } from 'types'
import { FileRejection } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import { InitialState } from './initialState'

interface Props {
  resetToInitialState: () => void
  initialState: typeof uploadInitialState
  triggerUpdate: () => void
}

export default function ExactMatch(props: Props) {
  const { resetToInitialState } = props
  const { client, socketHandler: sockets } = useAuth()
  const { sendAlert } = useAlerts()
  const [matchState, setMatchState] = useState(props.initialState)
  const ep = client.endpoints
  const SIZE_LIMIT_MB = 1000

  async function createAudienceAndStats() {
    try {
      if (matchState.match_fields?.length) {
        const { file_id } = matchState.currentFile
        const {
          id: audience_id,
          hashed: audienceHash,
          privacy,
        } = await createAudience()

        const audienceResult = await client.get({
          endpoint: ep.audiencesIdAPI(audienceHash),
        })

        makeMatchState({ audienceHash })
        await client.post({
          endpoint: ep.matchStatsAPI(file_id),
          data: {
            file_id: matchState.currentFile.file_id,
            audience_id: audience_id,
            match_fields: matchState.match_fields,
            channel_name: sockets?.taskSocket?.channelName,
            chart_data: {
              privacy: privacy,
              ...audienceResult.responseData.results.data,
            },
          },
        })
      } else {
        resetMatchState()
      }
    } catch (e) {
      resetToInitialState()
    }
  }

  const makeMatchStatistics: MapEntry['callback'] = ({ results }) => {
    const matchStats = results?.rows

    let stats = matchStats
    if (typeof matchStats === 'undefined') {
      stats = [{ statistic: 'Error: No Statistics Generated', value: undefined }]
    }
    makeMatchState({ matchStats: stats })
    props.triggerUpdate() // For the Match Stats table
  }

  if (sockets.taskSocket) {
    sockets.taskSocket.addMapback({
      id: 'exactMatch',
      socketRequestType: 'parse_file',
      callback: () => makeMatchState({ pctComplete: 1 }),
    })

    sockets.taskSocket.addMapback({
      id: 'exactMatch',
      socketRequestType: 'clean_file',
      callback: createAudienceAndStats,
    })

    sockets.taskSocket.addMapback({
      id: 'exactMatch',
      socketRequestType: 'calculate_statistics',
      callback: makeMatchStatistics,
    })
  }

  const makeMatchState = useCallback(
    (update: Partial<InitialState>) => {
      setMatchState((state) => ({
        ...state,
        ...update,
      }))
    },
    [setMatchState]
  )

  const resetMatchState = useCallback(() => {
    resetToInitialState()
  }, [resetToInitialState])

  const cancelProcess = useCallback(async () => {
    resetMatchState()
    if (!matchState.fileAlreadyUploaded && matchState.currentFile?.file_id) {
      try {
        await client.put({
          endpoint: ep.cancelCleanseAPI,
          data: [{ file_id: matchState.currentFile.file_id }],
        })
      } catch (e) {
        console.log(e)
      }
    }
    makeMatchState({ dialogIsOpen: false })
    props.triggerUpdate()
  }, [
    client,
    ep.cancelCleanseAPI,
    makeMatchState,
    matchState?.currentFile?.file_id,
    matchState?.fileAlreadyUploaded,
    props,
    resetMatchState,
  ])

  useEffect(() => {
    const columns = matchState.currentFile?.file_columns
    const firstMatch = matchFileField(columns, matchState.dbFields)
    makeMatchState({ match_fields: [firstMatch] })
  }, [
    makeMatchState,
    matchState.currentFile?.file_columns,
    matchState.cleansingOptions,
    matchState.dbFields,
  ])

  const onDropAccepted = useCallback(
    async (files: Array<File>) => {
      try {
        makeMatchState({ hasDropped: true })

        const file = files[0]
        const uuid = uuidv4()
        const chunkSize = 1_024_000
        const { filesAPI } = client.endpoints
        const totalChunks = Math.ceil(file.size / chunkSize)

        // Before uploading, perform check to see if headers start with alphabetic characters.
        // Since we are only interested in the header, and file sizes can be quite large, we
        // use a stream to grab only the data necessary for the check.
        const reader = file.stream().getReader()
        const decoder = new TextDecoder()
        let readerOpen = true
        let text = ''

        function validateHeader(headerText: string) {
          // After header has been inferred, split text into column values and
          // subsequently test the first character of each value for whether or not
          // it contains a numeric value.
          headerText.split(',').forEach((value: string) => {
            const firstChar = value.trim()[0]
            if (firstChar.search(/[0-9]/) > -1) {
              throw new Error(
                `Header value ${value} starts with number. Please rename it to start with a non-numeric value`
              )
            }
          })
        }

        while (readerOpen) {
          // Strategy:
          // 1: Decode value to string and split on new line character
          // 2: If multiple values, we must have reached the end of the header
          // 3: Otherwise, continue reading and append to current decoded value
          const { done, value } = await reader.read()
          const values = decoder.decode(value).split(/\n/)
          text += values[0]

          if (values.length > 1) {
            validateHeader(text)
            // If no values result in an error, cancel the stream
            readerOpen = false
            reader.cancel()
          }

          // This should never happen if there is a file with a header and at least one row of data
          else if (done) {
            reader.cancel()
            throw new Error('No data found in file.')
          }
        }

        for (let i = 0; i < totalChunks; i++) {
          const fileData = new FormData()
          const byteStart = chunkSize * i
          const byteEnd = chunkSize * i + chunkSize

          const channelName: any = sockets?.taskSocket?.channelName

          const fileSlice = file.slice(byteStart, byteEnd)
          fileData.append('file', fileSlice, file.name)
          fileData.append('dzuuid', uuid)
          fileData.append('dzchunkindex', String(i))
          fileData.append('dztotalfilesize', String(file.size))
          fileData.append('dzchunksize', String(chunkSize))
          fileData.append('dztotalchunkcount', String(totalChunks))
          fileData.append('dzchunkbyteoffset', String(byteEnd - chunkSize))
          fileData.append('dzchannelname', channelName)

          const res = await client.post({ endpoint: filesAPI, data: fileData })

          if (res.status === 200) {
            let pctComplete = (i + 1) / totalChunks

            if (pctComplete === 1) {
              pctComplete = 0.9999999
            }
            makeMatchState({ pctComplete, file_uuid: uuid })
          } else {
            const UploadError = new Error(res?.responseData?.message)
            throw UploadError
          }
        }
      } catch (e: any) {
        console.log(e)
        sendAlert({ message: e?.message })
        cancelProcess()
      }
    },
    [
      client,
      makeMatchState,
      cancelProcess,
      sendAlert,
      sockets?.taskSocket?.channelName,
    ]
  )

  const onDropRejected = useCallback(
    (rejections: Array<FileRejection>) => {
      try {
        // Report only first error to user
        const firstError = rejections[0].errors[0]
        if (firstError.code === 'file-too-large') {
          const message = `File exceeds maximum size of ${SIZE_LIMIT_MB}MB`
          sendAlert({ message: message })
        } else {
          sendAlert({ message: firstError.message })
        }
      } catch (e) {
        console.log(e)
      }
    },
    [sendAlert]
  )

  useEffect(() => {
    setMatchState(props.initialState)
  }, [props.initialState])

  useEffect(() => {
    async function getColumnOptions() {
      try {
        if (matchState.pctComplete === 1) {
          const [{ responseData: r1 }, { responseData: r2 }] = await Promise.all([
            client.post({
              endpoint: ep.cleanseAPI,
              data: {
                hashes: [{ file_uuid: matchState.file_uuid }],
              },
            }),
            client.get({
              endpoint: ep.joinFieldsAPI,
              data: {
                name_only: 1,
              },
            }),
          ])
          const { files, cleansing_options } = r1
          const { results: fields } = r2

          // this was being retriggered if cancelling
          // (which deletes the file and will throw errors when trying to refetch)
          if (!files) return

          const newFileName = files[0]?.file_formatted_name

          setMatchState((state) => ({
            ...state,
            currentFile: files[0],
            fileName: newFileName,
            dbFields: fields,
            cleansingOptions: cleansing_options,
          }))
        }
      } catch (e) {
        console.log(e)
      }
    }
    getColumnOptions()
  }, [client, ep, matchState.file_uuid, matchState.pctComplete, props.initialState])


  async function updateFileName() {
    const { file_id } = matchState.currentFile
    if (matchState.fileName !== matchState.currentFile.file_formatted_name) {
      try {
        const response = await client.put({
          endpoint: ep.filesIdAPI(file_id),
          data: { formatted_name: matchState.fileName },
        })

        // Failed to update file name, close dialog and pop error message alert
        if (!response.ok) {
          makeMatchState({ dialogIsOpen: false })
          sendAlert({ message: response.responseData.message })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  async function createAudience() {
    try {
      const { responseData } = await client.post({
        endpoint: ep.audiencesAPI,
        data: {
          audience_type: 'Offline',
          audience_name: `${
            matchState.fileName
          } - Match (${matchState.match_fields
            .map((match) => match.database_field)
            .join(', ')})`,
          chosen_files: [
            {
              filter_exclude_matches: 'Only Include Matching Records',
              file_id: matchState.currentFile.file_id,
              joins: matchState.match_fields,
            },
          ],
          privacy: matchState.privacy,
          auto_unique_name: true,
        },
      })
      const { results, message } = responseData

      if (message) {
        sendAlert({ message: message })
      }

      return results
    } catch (e) {
      console.log(e)
    }
  }

  async function cleanseFile() {
    const { file_id } = matchState.currentFile
    const field_to_cleansing_method_map = Object.entries(
      matchState.cleansingMap
    ).map(([key, value]) => ({
      input_field_name: key,
      cleansing_method: value,
    }))
    try {
      const response = await client.post({
        endpoint: ep.fileCleanserAPI(file_id),
        data: {
          field_to_cleansing_method_map,
          channel_name: sockets?.taskSocket?.channelName,
        },
      })

      // Failed to clean file, close dialog and pop error message alert
      if (!response.ok) {
        makeMatchState({ dialogIsOpen: false })
        sendAlert({ message: response.responseData.message })
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function submit() {
    /*
     * Submission steps are as follows:
     * 1) Update the formatted file name.
     * 2) Create cleanse object and submit to cleansing endpoint.
     * 3) Transfer to S3
     * 4) Create audience based on specified matches
     * 5) Generate match statistics for audience
     */
    try {
      makeMatchState({ hasSubmitted: true })
      await updateFileName()

      if (!matchState.isCreatingDerivedAudience) {
        await cleanseFile()
        makeMatchState({ fileAlreadyUploaded: true })
        props.triggerUpdate() // For the uploads table
      } else {
        createAudienceAndStats()
      }
    } catch (e) {
      sendAlert({
        message:
          'System could not process request at this time, please try again later.',
      })
      resetMatchState()
    } finally {
    }
  }

  return (
    <ExactMatchDialog
      cleansingMap={matchState.cleansingMap}
      cleansingOptions={matchState.cleansingOptions}
      audienceHash={matchState.audienceHash}
      initialState={props.initialState}
      hasSubmitted={matchState.hasSubmitted}
      hasDropped={matchState.hasDropped}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      makeMatchState={makeMatchState}
      matchStats={matchState.matchStats}
      match_fields={matchState.match_fields}
      dbFields={matchState.dbFields}
      isOpen={matchState.dialogIsOpen}
      currentFile={matchState.currentFile}
      fileName={matchState.fileName}
      setMatchState={setMatchState}
      privacy={matchState.privacy}
      submit={submit}
      handleClose={cancelProcess}
      matchEnabled={matchState.matchEnabled}
      isCreatingDerivedAudience={matchState.isCreatingDerivedAudience}
      pctComplete={matchState.pctComplete}
      sizeLimitMb={SIZE_LIMIT_MB}
    />
  )
}
