import StandardDialog from './StandardDialog'
import Button from './Button'
import styled from 'styled-components'

export type AlertProps = {
  message: string
  handleCancel?: () => void
  handleOk?: () => void
  handleClose: () => void
  alertIsOpen: boolean
}

const Alert: React.FC<AlertProps> = ({
  message,
  handleCancel,
  handleOk,
  handleClose,
  alertIsOpen,
}) => {
  const Content = (
    <ContentContainer data-cy="alertContent">{message}</ContentContainer>
  )

  const CancelButton = handleCancel && (
    <Button
      key={'button1'}
      variant="primary"
      text="Cancel"
      size="medium"
      onClick={() => {
        handleCancel?.()
      }}
      data-cy="alertCancel"
    />
  )
  const OkButton = (
    <Button
      key={'button2'}
      variant="primary"
      text="Ok"
      size="medium"
      onClick={() => {
        handleOk?.()
        handleClose()
      }}
      data-cy="alertOk"
    />
  )

  return (
    <StandardDialog
      title="Alert"
      isOpen={alertIsOpen}
      handleClose={handleClose}
      Content={Content}
      ActionButtons={[CancelButton, OkButton]}
      data-cy="alertDialog"
    />
  )
}

const ContentContainer = styled.div`
  padding: 1rem 0.5rem;
  min-width: 200px;
  max-width: 500px;
`

export default Alert
