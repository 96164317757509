import { CSSTransition } from 'react-transition-group'
import animation from './TopSlider/animation.module.css'
import container from './TopSlider/container.module.css'

type Props = {
  isVisible: boolean
  children: JSX.Element
}

export default function TopSlider(props: Props) {
  return (
    <CSSTransition
      in={props.isVisible}
      timeout={250}
      classNames={{ ...animation }}
      style={{ left: 'calc(50% - 250px)' }}
      unmountOnExit
    >
      <div className={container.dropdown}>{props.children}</div>
    </CSSTransition>
  )
}
