import { Endpoints } from 'types'

const endpoints: Endpoints = {
  assumeUser: 'token/assume',
  unassumeUser: 'token/unassume',
  tokenAPI: 'token',
  refreshTokenAPI: 'token/refresh',
  deleteTokenAPI: 'token/delete',
  filtersAPI: 'filters',
  fieldsAPI: 'fields',
  audienceFieldsAPI: 'fields/audience_fields',
  joinFieldsAPI: 'fields/join_fields',
  prioritizeFieldsAPI: 'fields/prioritize_fields',
  partitionFieldsAPI: 'fields/partition_fields',
  filesAPI: 'files',
  cleanseAPI: 'files/cleanse_column_options',
  cancelCleanseAPI: 'files/cancel_cleanser',
  matchedFilesAPI: 'files/matched_files',
  audiencesAPI: 'audiences',
  exportsAPI: 'exports',
  exportsTableAPI: 'exports/table',
  executionsAPI: 'executions',
  sampleContactsAPI: 'metrics/top_contacts',
  sampleCompaniesAPI: 'metrics/top_companies',
  checkTasksAPI: 'tasks/check_tasks',
  chartMetricsAPI: 'metrics/chart',
  audienceSizeAPI: 'metrics/audience_size',
  exportSizeAPI: 'metrics/export_size',
  accountsAPI: 'accounts',
  accountsTableAPI: 'accounts/table',
  accountsSignUpAPI: 'accounts/sign_up',
  accountsTypesAPI: 'accounts/types',
  usersAPI: 'users',
  enableAppendsAPI: 'audiences/should_enable_appends',
  layoutsAPI: 'layouts',
  layoutsTreeAPI: 'fields/layout_tree',
  layoutsTableAPI: 'fields/layout_table',
  layoutsFileAPI: 'layouts/file',
  layoutsLayoutFiltersAPI: 'layouts/layout_filters',
  sessions: 'sessions',
  validation: 'validation/validate_where_clause',
  passwordResetRequest: 'password_reset',
  passwordResetTokenValidate: 'password_reset/validate_token',
  passwordResetSubmit: 'password_reset/confirm',
  filesIdAPI: (id?: number | string) => `files/${id}`,
  matchStatsAPI: (id?: number | string) => `files/${id}/calculate_statistics`,
  fileCleanserAPI: (id?: number | string) => `files/${id}/clean`,
  filePreviewAPI: (id?: number | string) => `files/${id}/preview_file`,
  audiencesIdAPI: (id?: number | string) => `audiences/${id}`,
  exportsIdAPI: (id?: number | string) => `exports/${id}`,
  exportsIdAPIFavorite: (id?: number | string) => `exports/${id}/favorite`,
  exportsIdAPIClone: (id?: number | string) => `exports/${id}/clone`,
  executionAPI: (id?: number | string) => `executions/${id}`,
  cancelExecutionAPI: (id?: number | string) => `executions/${id}/cancel`,
  accountsIdAPI: (id?: number | string) => `accounts/${id}`,
  accountsFeatureFlagsAPI: (id?: number | string) => `accounts/${id}/feature_flags`,
  accountsIdProofOfDeliveryAPI: (id?: number | string) =>
    `accounts/${id}/proof_of_delivery`,
  accountsIdCreditsAPI: (id?: number | string) => `accounts/${id}/credits`,
  usersIdAPI: (id?: number | string) => `users/${id}`,
  layoutsIdAPI: (id?: number | string) => `layouts/${id}`,
  layoutsIdAPIClone: (id?: number | string) => `layouts/${id}/clone`,
  notesAPI: (accountId: number | string) => `accounts/${accountId}/notes`,
  notesIdAPI: (accountId: number | string, noteId: number | string) => {
    return `accounts/${accountId}/notes/${noteId}`
  },
}

export default endpoints
