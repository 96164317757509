import sleep from 'utils/sleep'
import getWsURLPrefix from 'utils/getURLPrefix'
import { log } from 'utils/debug'
import { SnackbarMessage, Task } from 'types'

interface Props {
  endpoint: string
  callbackFn?: (results: any) => void
  setNotification?: (message: SnackbarMessage) => any
  notificationMsg?: string
}
class NetwiseSocket extends WebSocket {
  taskMap: Record<string, Task>
  callbackFn?: (results: any) => void

  constructor({ endpoint, callbackFn: customFn, setNotification }: Props) {
    const url = getWsURLPrefix() + endpoint

    super(url)
    this.taskMap = {}
    this.callbackFn = customFn

    this.addEventListener('open', () => {
      log('WebSocket opened with url of ' + url)
    })

    this.addEventListener('close', () => {
      log(`WebSocket::${endpoint} closed`)
    })

    this.addEventListener('error', (e) => {
      log(`Websocket::${endpoint} errored: ${JSON.stringify(e)}`)
    })

    this.addEventListener('message', (e: MessageEvent) => {
      try {
        const { results, request, total_size } = JSON.parse(e.data)
        let processedResults: any

        switch (request.url) {
          case '/ws/check_tasks/':
            const { task_ids } = request.parameters

            const namedResults: Record<string, any> = {
              task_ids: Object.keys(results),
            }
            for (const [key, value] of Object.entries(results)) {
              const task = this.taskMap[key]
              if (!!task?.name) {
                namedResults[task.name] = (value as any)['result']
              }
            }

            if (setNotification) {
              for (const task of task_ids) {
                const { message, clickAction } = this.taskMap[task]
                setNotification({ text: message, clickAction })
                delete this.taskMap[task]
              }
            }

            processedResults = namedResults
            break

          case '/ws/executions/':
            processedResults = { results, total_size }
            break

          default:
            break
        }

        this.callbackFn?.(processedResults)
      } catch (e: any) {
        console.log(`Socket received message from ${url} but could not process it`)
        log(e)
      }
    })
  }

  async processTasks(tasks: Task[], customTaskId?: Array<string>) {
    // 1) Map new unfinished tasks to the task map so we can reference their names later.
    // 2) Extract unifinished taskIDs and send through socket.
    // 3) Return any completed results from already-finished tasks.
    try {
      tasks.forEach((task) => {
        if (!task.result) {
          this.taskMap[task.task_id] = {
            name: task.name || task.task_id,
            message: task.message,
            clickAction: task.clickAction,
            task_id: task.task_id,
          }
        }
      })

      // Determine the task IDs which do not yet have results and pass them through to the API
      const task_ids = tasks.filter((task) => !task.result).map((obj) => obj.task_id)
      if (task_ids.length > 0) {
        await this.safeSend({ task_ids })
      }

      // For task IDs which do have results ready, create a mapping from the name / id to the result
      const results: Record<string, any> = {}
      tasks
        .filter((task) => !!task.result)
        .forEach((obj) => {
          results[obj.name || obj.task_id] = obj.result
        })

      if (Object.keys(results).length > 0) {
        results['task_ids'] = customTaskId
        this.callbackFn?.(results)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Sometimes the server isn't ready to yet receive a payload from the client.
  // We'll sleep and retry a few times.
  async safeSend(payload: any) {
    for (let i = 0; i < 10; i++) {
      if (this.readyState === 1) {
        this.send(JSON.stringify(payload))
        break
      }
      await sleep(500)
    }
  }
}

export default NetwiseSocket
