import SaveNewDialog from './SaveNewDialog'

interface TitleOpenSaveI {
  currentLayout: {
    hashed: string
    id: null
    fields: null
    chosen_revenue_share: boolean
    name: null
    chosen_field_package: string
    privacy: string
    editable: boolean
  }
  setCurrentLayout: React.Dispatch<TitleOpenSaveI['currentLayout']>
  saveLayout: (layout: TitleOpenSaveI['currentLayout']) => Promise<void>
  updateLayout: (layout: TitleOpenSaveI['currentLayout']) => Promise<void>
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const TitleOpenSave: React.FC<TitleOpenSaveI> = ({
  setCurrentLayout,
  currentLayout,
  saveLayout,
  updateLayout,
  isOpen,
  setIsOpen,
}) => {
  return (
    <SaveNewDialog
      setCurrentLayout={setCurrentLayout}
      currentLayout={currentLayout}
      saveLayout={saveLayout}
      updateLayout={updateLayout}
      title={'Save'}
      isOpen={isOpen}
      handleClose={() => setIsOpen(false)}
    />
  )
}

export default TitleOpenSave
