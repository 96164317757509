import styled from 'styled-components'
import Filters from './Body/Filters'
import Metrics from './Body/Metrics'

export default function Body({
  appliedFilters,
  audienceSize,
  printableMetrics,
}: any) {
  return (
    <Container>
      <Filters appliedFilters={appliedFilters} />
      <Metrics audienceSize={audienceSize} printableMetrics={printableMetrics} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  padding: 1rem 0rem;
`
