import colors from './colors'

const typography = {
  fontFamily: ['Rubik', 'normal'].join(','),
  textColor: colors.gray12,
  h1: {
    fontWeight: '500',
    fontSize: '28px',
    lineHeight: '32px',
  },
  h2: {
    fontWeight: '500',
    fontSize: '22px',
    lineHeight: '24px',
  },
  h3: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '16px',
  },
  h4: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
  },
  bodyRegular: {
    big: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
    },
    medium: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
    },
    small: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  bodyBold: {
    big: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
    },
    medium: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '16px',
    },
    small: {
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}

export default typography
