import styled from 'styled-components'
import colors from '../../context/theme-context/colors'
import typography from '../../context/theme-context/typography'

const { bodyRegular } = typography

const TabContainer = styled.div`
  display: flex;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
  font-size: ${bodyRegular.medium.fontSize};
  line-height: ${bodyRegular.medium.lineHeight};
  font-weight: ${bodyRegular.medium.fontWeight};
  background-color: ${colors.gray2};
  color: ${colors.gray8};
  border: 1px solid ${colors.gray5};
  border-radius: 0.25rem;
`

const TabCellContainer = styled.div<{ isActive: boolean }>`
  background: ${(props) => props.isActive && colors.blue1};
  color: ${(props) => props.isActive && colors.blue8};
  border: 1px solid ${(props) => (props.isActive ? colors.blue2 : 'inherit')};
  border-radius: 0.25rem;
  margin: -1px;
  padding: ${(props) => (props.isActive ? `0.5rem` : `calc(0.5rem + 1px)`)};
  width: 8.625rem;
  text-align: center;
  cursor: pointer;
`

export { TabCellContainer, TabContainer }
