import Skeleton from 'react-loading-skeleton'
import Button from 'components/Button/'
import StandardDialog from 'components/StandardDialog'
import tm from 'analytics/TagManager'
import styled, { css } from 'styled-components'
import { numericHumanReadable, bytesHumanReadable } from 'utils/numericHelpers'
import { colors } from 'context/theme-context/'

const handleCopy = (text: any) => {
  if (!navigator) {
    return
  }
  navigator?.permissions.query({ name: 'clipboard-write' as any }).then((result) => {
    if (result.state === 'granted' || result.state === 'prompt') {
      navigator?.clipboard.writeText(text)
    }
  })
}

const DownloadsInfo = ({
  rows = '',
  creditsUsed = '',
  fileName = '',
  fileSize = '',
  customFilePath = '',
  downloadSampleFileUrl = '',
  downloadFillratesUrl = '',
  downloadFullFileUrl = '',
  showInternalLinks = true,
}) => {
  return (
    <TopSummary showInternalLinks={showInternalLinks}>
      <TopDisplay>
        <div style={{ flex: 1 }}>
          <div style={{ marginBottom: '1rem' }}>
            {!!downloadFullFileUrl ? (
              <Button
                text="Download Full File"
                size="large"
                variant="primary"
                onClick={() => {
                  const a = document.createElement('a')
                  a.href = downloadFullFileUrl
                  a.download = downloadFullFileUrl
                  a.click()
                }}
                id="download_full_file_button"
                testId="downloadFullFile"
              />
            ) : (
              <Skeleton height={60} width={570} />
            )}
          </div>
          <div data-cy="fileRows">Rows: {numericHumanReadable(rows, 0)}</div>
          <div data-cy="creditsUsed">
            Credits Used: {numericHumanReadable(creditsUsed, 0)}
          </div>
          <div data-cy="exportFileName">File: {fileName}</div>
          <div data-cy="fileSize">Size: {bytesHumanReadable(fileSize, 1)}</div>
          <div>
            Custom Delivery Location:&nbsp;
            <div style={{ display: 'inline-block' }}>
              <Button
                variant="secondary"
                size="small"
                text="Copy URL"
                onClick={() => handleCopy(customFilePath)}
                testId="customDeliveryLocationCopyUrl"
              />
            </div>
          </div>
        </div>
      </TopDisplay>
      <BottomDisplay>
        <div>
          <Button
            onClick={() => window.open(downloadSampleFileUrl)}
            size="medium"
            variant="secondary"
            text="Download Sample File"
            testId="downloadSampleFile"
          />
        </div>
        <div>
          <Button
            id="download_fill_rate_button"
            onClick={() => {
              window.open(downloadFillratesUrl)
              tm.captureCustomEvent('download_fill_rate_click')
            }}
            size="medium"
            variant="secondary"
            text="Download Fill Rates"
            testId="downloadFillRates"
          />
        </div>
      </BottomDisplay>
    </TopSummary>
  )
}

const InternalLinks = ({
  filePath = '',
  samplePath = '',
  fillratePath = '',
  customDelivery = '',
}) => (
  <>
    <div style={{ fontSize: '1rem', fontWeight: 500, paddingTop: '1rem' }}>
      Internal Links
    </div>
    <BottomSummary>
      <div
        style={{
          display: 'flex',
          fontWeight: 500,
          flexDirection: 'column',
          lineHeight: '1.50rem',
          gap: '0.50rem',
        }}
      >
        <div>File Path: </div>
        <div>Sample File Path: </div>
        <div>Fill Rate Path: </div>
        <div>Custom Delivery: </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <div style={{ display: 'inline-block' }}>
          <Button
            variant="secondary"
            size="small"
            text="Copy URL"
            onClick={() => handleCopy(filePath)}
            testId="filePathCopyUrl"
          />
        </div>
        <div style={{ display: 'inline-block' }}>
          <Button
            variant="secondary"
            size="small"
            text="Copy URL"
            onClick={() => handleCopy(samplePath)}
            testId="sampleFilePathCopyUrl"
          />
        </div>
        <div style={{ display: 'inline-block' }}>
          <Button
            variant="secondary"
            size="small"
            text="Copy URL"
            onClick={() => handleCopy(fillratePath)}
            testId="fillRatePathCopyUrl"
          />
        </div>
        <div style={{ display: 'inline-block' }}>
          <Button
            variant="secondary"
            size="small"
            text="Copy URL"
            onClick={() => handleCopy(customDelivery)}
            testId="customDeliveryCopyUrl"
          />
        </div>
      </div>
    </BottomSummary>
  </>
)

export default function DownloadExecutionDialog({
  isOpen,
  setIsOpen,
  data,
  credits,
}: any) {
  const DialogContent = (
    <div>
      <DownloadsInfo
        rows={data.rows}
        creditsUsed={data.credits_used}
        fileName={data.file_name}
        fileSize={data.file_size}
        customFilePath={data.custom_file_path}
        downloadSampleFileUrl={data.download_sample_file_url}
        downloadFillratesUrl={data.download_fillrates_url}
        downloadFullFileUrl={data.download_full_file_url}
        showInternalLinks={data.show_internal_links}
      />
      {data.show_internal_links && (
        <InternalLinks
          filePath={data.file_path}
          samplePath={data.sample_file_path}
          fillratePath={data.fillratePath}
          customDelivery={data.custom_file_path}
        />
      )}
    </div>
  )

  return (
    <StandardDialog
      isOpen={isOpen}
      title={`Download Options`}
      Content={DialogContent}
      handleClose={() => setIsOpen(false)}
      dialogStyle={{ width: '37.5rem' }}
    />
  )
}

const TopSummary = styled.div<{ showInternalLinks: boolean }>`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: ${colors.gray8};
  row-gap: 1rem;
  line-height: 1.5rem;
  ${(props) =>
    props.showInternalLinks &&
    css`
      border-bottom: 1px solid ${colors.gray6};
      padding-bottom: 1rem;
    `};
`

const TopDisplay = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  column-gap: 2rem;
  align-items: center;
`
const BottomDisplay = styled.div`
  display: flex;
  gap: 1rem;
`
const BottomSummary = styled.div`
  display: flex;
  padding-top: 1rem;
  gap: 1rem;
`
