import Button from 'components/Button'
import ClientDataTable from 'components/ClientDataTable'
import SimpleInput from 'components/SimpleInput'
import StandardDialog from 'components/StandardDialog'
import { useEffect, useState } from 'react'
import { useAuth } from 'context/auth-context'
import { numericHumanReadable } from 'utils/numericHelpers'

type Props = {
  isOpen: boolean
  toggle: (value: React.SetStateAction<boolean>) => void
  accountId?: number
  accountName?: string
  triggerUpdate: () => void
}

export default function AddCredits(props: Props) {
  const { accountId, isOpen, triggerUpdate, toggle } = props
  const [creditHistory, setCreditHistory] = useState([])
  const [additionalCredits, setAdditionalCredits] = useState(0)
  const { client } = useAuth()
  const creditURL = client.endpoints.accountsIdCreditsAPI

  function standardize(creditData: any) {
    return creditData.map((item: any) => ({
      dateAdded: new Date(item.created.timestamp.raw).toLocaleString('en-US'),
      addedBy: item.created.user.first_name + ' ' + item.created.user.last_name,
      credits: (
        <span style={{ color: item.credits < 0 ? 'red' : '' }}>
          {numericHumanReadable(item.credits, 0)}
        </span>
      ),
    }))
  }

  useEffect(() => {
    async function getCredits() {
      // Prevent credit from previous account sticking around if dialog re-opens
      setCreditHistory([])
      const { responseData } = await client.get({ endpoint: creditURL(accountId) })
      const { results } = responseData
      const history = results.credit_history || []
      const _creditHistory = standardize(history)
      setCreditHistory(_creditHistory)
    }
    if (isOpen) {
      getCredits()
    }
  }, [isOpen, accountId, client, creditURL])

  async function submit() {
    async function getCredits() {
      const { responseData } = await client.get({ endpoint: creditURL(accountId) })
      const { results } = responseData
      const history = results.credit_history || []
      const _creditHistory = standardize(history)
      setCreditHistory(_creditHistory)
    }
    if (additionalCredits) {
      try {
        await client.post({
          endpoint: creditURL(accountId),
          data: { credits: additionalCredits },
        })
        getCredits()
      } catch (e) {
        console.log('Could not clone an export: ', e)
      } finally {
        triggerUpdate()
        setAdditionalCredits(0)
      }
    }
  }

  const ApplyButton = () => (
    <div>
      <Button
        text="Apply"
        variant="primary"
        size="medium"
        onClick={submit}
        testId="applyCredits"
      />
    </div>
  )
  const DialogContent = (
    <div style={{ minHeight: '43.75rem', minWidth: '28.125rem' }}>
      <div style={{ maxWidth: '14rem' }}>
        <SimpleInput
          value={additionalCredits}
          label="Add Credits"
          onChange={(e: any) => setAdditionalCredits(e.target.value)}
          ApplyButton={ApplyButton}
          type="number"
          data-cy="addCreditsInput"
        />
      </div>
      <ClientDataTable
        title="addCredits"
        copyControl={false}
        csvControl={false}
        excelControl={false}
        searchControl={false}
        data={creditHistory}
        customControls={null}
        columns={[
          { name: 'dateAdded', label: 'Date Added' },
          { name: 'addedBy', label: 'Added By' },
          { name: 'credits', label: 'Credits' },
        ]}
      />
    </div>
  )

  return (
    <>
      <StandardDialog
        title={`Add ${props.accountName} Credits`}
        handleClose={toggle}
        isOpen={isOpen}
        Content={DialogContent}
      />
    </>
  )
}
