import styled from 'styled-components'
import { ReactComponent as Arrow } from 'icons/24px/Arrow.svg'
import { colors } from 'context/theme-context'
import StandardAutocomplete from 'components/StandardAutocomplete'
import Button from 'components/Button'
import GroupedAutoComplete from 'components/GroupedAutoComplete'
import Skeleton from 'react-loading-skeleton'

interface Props {
  match_fields: Array<{
    file_field: string | undefined
    database_field: string | undefined
  }>
  updateMatch: (idx: number, update: any) => void
  addMatch: () => void
  removeMatch: (idx: number) => void
  dbFields: Array<string>
  fileColumns: Array<string>
}

export default function MatchComponent(props: Props) {
  const highlightedGroup = 'Multi-Field Match'
  const otherGroup = 'Single-Field Match'
  let disabledGroup: undefined | string = undefined
  const emailMatches = [
    'email',
    'email_md5',
    'email_sha1',
    'email_sha256',
    'duns_number',
  ]

  let dbFieldOptions = props.dbFields.map((value) => ({
    value: value,
    label: value,
    group: emailMatches.includes(value) ? highlightedGroup : otherGroup,
  }))

  // Special Cases
  // 1: Normalized field selected first, remove Add Join button
  const selectedDbFields = props.match_fields?.map((match) => match.database_field)
  const showButton = !selectedDbFields?.find((field) =>
    emailMatches.includes(field || '')
  )
  // 2: A field has been selected, but it's not a non-normalized field
  // then remove normalized fields from dropdown list
  if (selectedDbFields?.length > 1 && showButton) {
    disabledGroup = highlightedGroup
  }

  // Loading & Ready UI
  const LoadingUI = <Skeleton height={130} />
  const ReadyUI = (
    <>
      <MatchHeaderDiv>
        <div style={{ flex: 1 }}>File Field</div>
        <div style={{ flex: 1 }}>Match Type or Field</div>
      </MatchHeaderDiv>

      {props.match_fields?.map((match: any, i: any) => (
        <div key={i} style={{ display: 'flex', margin: '0.25rem 0em' }}>
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <StandardAutocomplete
              testId={`file_field_${i}`}
              value={match?.file_field}
              placeholder="Please select file field"
              onChange={(_: any, value: any) =>
                props.updateMatch(i, { file_field: value })
              }
              multiple={false}
              style={{ flex: 1, marginRight: '0.5em' }}
              tagSuggestions={props.fileColumns}
              autocompleteProps={{ disableClearable: true }}
              tooltip={true}
            />
            <Arrow fill={colors.blue7} />
          </div>

          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <GroupedAutoComplete
              onChange={(value) => props.updateMatch(i, { database_field: value })}
              options={dbFieldOptions}
              highlightGroup={highlightedGroup}
              disabledGroup={disabledGroup}
              style={{ flex: 1, paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
              testId={`match_field_${i}`}
              value={match.database_field}
              placeholder="Please select database field"
              tooltip={true}
            />
            <Button
              text="Remove"
              testId="removeMatch"
              disabled={props.match_fields.length === 1}
              size="medium"
              variant="warning"
              onClick={() => props.removeMatch(i)}
              style={{ lineHeight: '1.25rem' }}
            />
          </div>
        </div>
      ))}

      {showButton && (
        <span style={{ display: 'inline-block', marginTop: '0.5rem' }}>
          <Button
            text="Add Join Field"
            size="medium"
            variant="warning"
            onClick={props.addMatch}
            testId="addJoinField"
          />
        </span>
      )}
    </>
  )

  // Render Condition
  const UI = !props.match_fields?.[0]?.file_field ? LoadingUI : ReadyUI

  return <div style={{ marginTop: '0.5rem' }}>{UI}</div>
}

const MatchHeaderDiv = styled.div`
  display: flex;
  font-size: 0.875rem;
  border-bottom: 1px solid ${colors.gray5};
  padding: 0.5rem 0rem;
  align-items: flex-start;
  margin-bottom: 0.75rem;
`
