import { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import Skeleton from 'react-loading-skeleton'
import { MuiThemeProvider } from '@material-ui/core/styles'
import ExpandButton from './DataTable/ExpandButton'
import TableDialog from './DataTable/TableDialog'
import {
  theme as defaultTheme,
  disabledTheme as defaultDisabledTheme,
} from './DataTable/theme'
import Controls from './DataTable/Controls'
import Checkbox from './Checkbox'
import CopyOnClick from './CopyOnClick'
import { ConditionalTooltip } from './Tooltip'
import styled from 'styled-components'
import { colors } from '../context/theme-context'

export default function ClientDataTable({
  columns,
  data,
  title,
  searchText = null,
  setSearchText = null,
  expandableRows = true,
  controls = true,
  debounceDelay = 175,
  viewColumns = false,
  searchControl = true,
  deleteControl = false,
  deleteAction = () => null,
  excelControl = false,
  csvControl = false,
  csvFilename = 'download.csv',
  copyControl = false,
  selectLayout = false,
  selectableRows = 'none',
  markSelected = (_: any) => {},
  rowsSelected = [],
  outOfDate = false,
  theme = defaultTheme,
  disabledTheme = defaultDisabledTheme,
  customControls = null,
  // Necessary for selecting layout
  setColumns = () => null,

  rowsPerPage = 10,
  setRowsPerPage = (n: any) => null,

  valueToText = (d: any) => {
    return d
  },

  download = false,
  responsive = 'standard',
  testId = '',
  ...rest
}: any) {
  const [dialogState, setDialogState] = useState({ isOpen: false, data: [] })
  const [internalSearchText, setInternalSearchText] = useState('')

  function showDialog(index: any) {
    setDialogState({
      isOpen: true,
      data: data[index],
    })
  }

  const defaultSort = (data: any, colIndex: any, order: any) => {
    return data.sort((a: any, b: any) => {
      return (
        (valueToText(a.data[colIndex])?.toLowerCase() <
        valueToText(b.data[colIndex])?.toLowerCase()
          ? -1
          : 1) * (order === 'desc' ? -1 : 1)
      )
    })
  }

  const customSearch = (searchQuery: any, currentRow: any) => {
    let match = false
    for (const v of currentRow) {
      if (
        typeof valueToText(v) === 'string' &&
        valueToText(v).toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        match = true
        break
      }
    }
    return match
  }

  const tableOptions = {
    fixedSelectColumn: false,
    elevation: 0,
    expandableRows: expandableRows,
    fixedHeader: true,
    selectableRows: selectableRows,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    resizableColumns: false,
    print: false,
    filter: false,
    search: false,
    customSearchRender: () => null,
    customToolbar: () => null,
    download: download,
    searchText: searchText || internalSearchText,
    viewColumns: false,
    rowsSelected: rowsSelected,
    onRowSelectionChange: markSelected,
    rowsPerPageOptions: [5, 10, 15, 100],
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: (numRows: any) => setRowsPerPage(numRows),
    responsive: responsive,
    customSort: defaultSort,
    customSearch: customSearch,
    customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
      const selected = rowsSelected.includes(dataIndex)
      return (
        <StyledRow key={'l+' + dataIndex}>
          <td
            style={{ fontSize: '0.875rem', fontWeight: 'normal' }}
            id={`${title}_expandButton`}
            data-cy={`${title}_RowDetail_${rowIndex}`}
          >
            <ExpandButton
              showDialog={showDialog}
              dataIndex={dataIndex}
              isHeaderCell={false}
            />
          </td>
          {selectableRows !== 'none' ? (
            <StyledCell>
              <td style={{ padding: '1rem' }} id={`${title}_checkbox_${rowIndex}`}>
                <Checkbox status={selected} onClick={(e: any) => markSelected(e)} />
              </td>
            </StyledCell>
          ) : null}
          {data.map((item: any, i: any) => (
            <StyledCell
              id={`${title}_${rowIndex}_${i}`}
              key={i}
              data-cy={`${title}_${rowIndex}_${i}`}
            >
              {item}
            </StyledCell>
          ))}
        </StyledRow>
      )
    },
    ...rest,
  }

  return (
    <div>
      {controls && (
        <div style={{ marginBottom: '1rem' }} id={title}>
          <Controls
            data={data}
            setSearchText={setSearchText || setInternalSearchText}
            setColumns={setColumns}
            debounceDelay={debounceDelay}
            deleteControl={deleteControl}
            deleteAction={deleteAction}
            searchControl={searchControl}
            excelControl={excelControl}
            csvControl={csvControl}
            csvFilename={csvFilename}
            copyControl={copyControl}
            selectLayout={selectLayout}
            warningControl={outOfDate}
            customControls={customControls}
            valueToText={valueToText}
            {...{ 'data-cy': rest?.['data-cy'] + 'Search' }}
          />
        </div>
      )}
      <MuiThemeProvider theme={outOfDate ? disabledTheme : theme}>
        {data ? (
          <MUIDataTable
            title={undefined}
            options={tableOptions}
            columns={columns}
            data={data}
          />
        ) : (
          <Skeleton count={10} height={37} />
        )}
      </MuiThemeProvider>
      <TableDialog
        isOpen={dialogState.isOpen}
        handleClose={() => setDialogState((state) => ({ ...state, isOpen: false }))}
        data={dialogState.data}
      />
    </div>
  )
}

const CopyTooltip = ({ text, maxWidth }: any) => (
  <CopyOnClick
    text={text}
    render={<ConditionalTooltip text={text} maxLength={maxWidth} />}
  />
)

export { CopyTooltip }

const StyledRow = styled.tr`
  white-space: nowrap;
  background-color: none;
  :nth-of-type(even) {
    background-color: ${colors.gray2};
  }
  :hover {
    background-color: ${colors.gray3};
  }
`

const StyledCell = styled.td`
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  border: 1px solid ${colors.gray4};
  padding: 0.5rem;
  max-width: 50ch;
  .Mui-checked {
    color: ${colors.blue6};
  }
`
