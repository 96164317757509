import React, { BaseSyntheticEvent, ChangeEvent, SyntheticEvent } from 'react'
import Button from 'components/Button'
import StandardDialog from 'components/StandardDialog'
import BasicSelect from 'components/BasicSelect'
import RequestUserConfirmation from 'components/RequestUserConfirmation'
import { LinearProgress } from '@material-ui/core'
import { useAuth } from 'context/auth-context'

type Props = {
  accountId: string
}

export default function ProofOfDelivery(props: Props) {
  // Will use to track submission status and update UI accordingly
  enum sendStatus {
    UNSENT,
    INFLIGHT,
    SUCCESS,
    FAILURE,
  }

  // State
  const { client } = useAuth()
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState('')
  const [submission, setSubmission] = React.useState<sendStatus>(sendStatus.UNSENT)
  const [message, setMessage] = React.useState('')

  // Submission options (POD Types)
  const options = [
    { value: 'new_client', text: 'New Client' },
    { value: 'renewal', text: 'Renewal' },
    { value: 'upgrade_update_change', text: 'Upgrade/Update/Change' },
  ]

  const ButtonText = () => {
    const testId = 'sendEmailBtnText'
    let text = <div data-cy={testId}>Send Email</div>
    switch (submission) {
      case sendStatus.INFLIGHT:
        text = <div data-cy={testId}>Sending Email</div>
        break
      case sendStatus.SUCCESS:
        text = <div data-cy={testId}>Sent Email!</div>
        break
      default:
        break
    }
    return <div>{text}</div>
  }

  const ProofDropdown = (
    <div
      style={{
        minHeight: 150,
        width: 300,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <BasicSelect
        label="Proof of Delivery"
        selectValue={selectedValue}
        items={options}
        onChange={(x: BaseSyntheticEvent) => setSelectedValue(x.target.value)}
        data-cy="proofOfDeliveryOptions"
      />
      <div data-cy="confirmationTxt">
        <RequestUserConfirmation
          text={
            <div>
              Are you sure you want to send a{' '}
              <strong>{options.find((x) => x.value === selectedValue)?.text}</strong>{' '}
              proof of delivery?
            </div>
          }
          Component={
            <Button
              text={<ButtonText />}
              onClick={submit}
              disabled={submission !== sendStatus.UNSENT || !selectedValue}
              variant="secondary"
              size="medium"
              testId="sendEmailBtn"
            />
          }
        />
        {submission === sendStatus.INFLIGHT && (
          <LinearProgress style={{ marginTop: '0.5rem' }} />
        )}
        {message && message}
      </div>
    </div>
  )

  async function submit() {
    try {
      setSubmission(sendStatus.INFLIGHT)
      const { status, responseData } = await client.post({
        endpoint: client.endpoints.accountsIdProofOfDeliveryAPI(props.accountId),
        data: {
          delivery_type: selectedValue,
        },
      })
      if (status === 200) {
        setSubmission(sendStatus.SUCCESS)
        setMessage('Email sent successfully. You may close this dialog.')
      } else {
        const message =
          responseData?.message || 'An error occurred. Please retry later.'
        setMessage(message)
        throw Error()
      }
    } catch (e) {
      setSubmission(sendStatus.FAILURE)
    }
  }

  return (
    <>
      <Button
        text="Send Proof of Delivery"
        size="small"
        variant="primary"
        onClick={() => setIsOpen(true)}
        data-cy="sendProofOfDeliveryBtn"
        style={{}}
      />
      {isOpen && (
        <StandardDialog
          title={'Delivery'}
          Content={ProofDropdown}
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false)
            setSubmission(sendStatus.UNSENT)
            setSelectedValue('')
            setMessage('')
          }}
        />
      )}
    </>
  )
}
