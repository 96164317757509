import StandardDialog from '../../../../../components/StandardDialog'
import Button from '../../../../../components/Button'
import styled from 'styled-components'
import { colors } from '../../../../../context/theme-context/'
import RemoteDataTable from '../../../../../components/RemoteDataTable'
import { useAuth } from '../../../../../context/auth-context'
import { ConditionalTooltip } from '../../../../../components/Tooltip'
import { useHistory } from 'react-router-dom'
import tm from '../../../../../analytics/TagManager'
import { useAlerts } from 'context/alert-context'
import { useSnackbar } from 'context/snackbar-context'

const MAX_CELL_WIDTH = 50

export default function LoadAudience({ audiences, isOpen, handleClose }: any) {
  const { client, adminMode } = useAuth()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { audiencesAPI, audiencesIdAPI } = client.endpoints

  const AudienceTable = (
    <div style={{ padding: '1rem' }}>
      <RemoteDataTable
        client={client}
        adminMode={adminMode}
        sendAlert={sendAlert}
        setSnackbarMessage={setSnackbarMessage}
        initialSortOrder={'-modified'}
        title="loadAudience"
        url={audiencesAPI}
        deleteAPI={audiencesIdAPI}
        autosaveControl={true}
        selectableRows="multiple"
        controls={true}
        expandableRows={false}
        deleteControl={true}
        columnOrderMap={{
          name: 'name',
          savedBy: ['created_by__last_name', 'created_by__first_name'],
          createdTime: 'created',
        }}
        columns={[
          { name: 'name', label: 'Name' },
          { name: 'savedBy', label: 'Saved By' },
          { name: 'createdTime', label: 'Created Time' },
          {
            name: 'urlLink',
            label: 'URL Link',
            options: { sort: false },
          },
        ]}
        rowsOnPage={10}
        customRowRender={(rowData: any) => {
          const data = rowData?.map((audience: any) => {
            const { name, created, hashed } = audience
            const { user } = created
            const link = `/audiences/${hashed}`
            return {
              name: (
                <LinkButton
                  onClick={() => {
                    history.push(link)
                    handleClose()
                  }}
                  data-cy="loadAudienceName"
                >
                  <ConditionalTooltip text={name} maxLength={MAX_CELL_WIDTH} />
                </LinkButton>
              ),
              savedBy: user.last_name + ', ' + user.first_name,
              createdTime: new Date(created.timestamp.raw).toLocaleString('en-US'),
              urlLink: (
                <Button
                  size="medium"
                  variant="secondary"
                  text="Copy URL"
                  testId="copyUrl"
                  onClick={() => {
                    if (!navigator) {
                      return
                    }
                    navigator?.permissions
                      .query({ name: 'clipboard-write' as PermissionName })
                      .then((result) => {
                        if (
                          result.state === 'granted' ||
                          result.state === 'prompt'
                        ) {
                          navigator?.clipboard.writeText(
                            window.location.origin + link
                          )
                          tm.captureCustomEvent('audiences_open_copy_url')
                        }
                      })
                  }}
                />
              ),
            }
          })

          return data
        }}
      />
    </div>
  )

  return (
    <StandardDialog
      title={'Choose Audience to Load'}
      Content={AudienceTable}
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="xl"
      dialogStyle={{ padding: 0, minHeight: 750, minWidth: 950 }}
    />
  )
}

const LinkButton = styled.button`
  border: none;
  color: ${colors.blue8};
  background-color: inherit;
  font-size: 0.875rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
