const filterDefaults = () => ({
  privacy: 'team_view',
  company_location_country: [],
  contact_location_country: [],
  sql_editor: '',
})

const infoDefaults = {
  audience_name: '',
  audience_description: '',
  editable: true,
}
const locationDefaults = {
  company: [],
  contact: [],
}

const initialState = {
  filters: {},
  sqlIsVerified: true,
  fields: null,
  newFilterCount: 0,
  filtersHaveUpdated: true,
  selectedFilters: filterDefaults(),
  appliedFilters: filterDefaults(),
  savedFilters: filterDefaults(),
  defaultFilters: filterDefaults(),
  defaultFiltersString: JSON.stringify(filterDefaults()),
  audienceSize: {},
  pastLocation: null,
  renderExportAudience: true,
  saveIsCurrent: true,
  printableMetrics: {},
  metrics: {},
  initialLocation: locationDefaults,
  audienceInfo: infoDefaults,
}

export { filterDefaults, infoDefaults, locationDefaults, initialState }
