const colors = {
  blue1: '#E7F3FF',
  blue2: '#A5D2FD',
  blue3: '#7CB8FA',
  blue4: '#5195ED',
  blue5: '#2A74E0',
  blue6: '#0C54D2',
  blue7: '#0840AD',
  blue8: '#042D88',
  blue9: '#021E61',
  blue10: '#01103C',

  orange1: '#FFF7E7',
  orange2: '#FFE7BD',
  orange3: '#FED496',
  orange4: '#FEBF70',
  orange5: '#FDA94C',
  orange6: '#F88C2D',
  orange7: '#D26B1F',
  orange8: '#AC4E13',
  orange9: '#86380C',
  orange10: '#602506',

  purple1: '#F9F1FF',
  purple2: '#EFDCFE',
  purple3: '#D3AFF5',
  purple4: '#B382E9',
  purple5: '#925ADB',
  purple6: '#7237CE',
  purple7: '#5326A8',
  purple8: '#391883',
  purple9: '#220D5D',
  purple10: '#120537',

  yellow1: '#FEFFE7',
  yellow2: '#FFFEBC',
  yellow3: '#FFFA96',
  yellow4: '#FFF471',
  yellow5: '#FEEB50',
  yellow6: '#F9DA36',
  yellow7: '#D3B028',
  yellow8: '#AC8A1C',
  yellow9: '#866813',
  yellow10: '#61470A',

  lime1: '#FCFFE7',
  lime2: '#F4FEBC',
  lime3: '#EBFE95',
  lime4: '#D4F06B',
  lime5: '#BBE448',
  lime6: '#A2D72F',
  lime7: '#7EB222',
  lime8: '#5D8B17',
  lime9: '#40650D',
  lime10: '#264005',

  magenta1: '#FFF0F6',
  magenta2: '#FED7E7',
  magenta3: '#FEAED2',
  magenta4: '#FD87C0',
  magenta5: '#F55DAB',
  magenta6: '#E93696',
  magenta7: '#C2247F',
  magenta8: '#9D1768',
  magenta9: '#770C50',
  magenta10: '#510639',

  red1: '#FFF1F0',
  red2: '#FECCC8',
  red3: '#FDA4A0',
  red4: '#FD7978',
  red5: '#FD4F54',
  red6: '#F22735',
  red7: '#CD1829',
  red8: '#A8071A',
  red9: '#810318',
  red10: '#5B0213',

  green1: '#F6FFEE',
  green2: '#DAF6C0',
  green3: '#B9EA93',
  green4: '#97DD6B',
  green5: '#76CF48',
  green6: '#57C22D',
  green7: '#3D9D1F',
  green8: '#237804',
  green9: '#165108',
  green10: '#0B2B02',

  gray1: '#FFFFFF',
  gray2: '#FAFAFA',
  gray3: '#F5F5F5',
  gray4: '#F0F0F0',
  gray5: '#D9D9D9',
  gray6: '#BFBFBF',
  gray7: '#8C8C8C',
  gray8: '#595959',
  gray9: '#434343',
  gray10: '#262626',
  gray11: '#1F1F1F',
  gray12: '#141414',
  gray13: '#000000',
}

export default colors
