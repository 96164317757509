//@ts-nocheck
import { ThemeProvider } from 'styled-components'
import {
  MuiThemeProvider,
  StylesProvider,
  createMuiTheme,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import typography from './theme-context/typography'
import colors from './theme-context/colors'

// First, create netwise theme object
// Reference: https://material-ui.com/customization/theming/
const netwiseTheme = {
  font: {
    headline: 'font-family: Europa',
    body: 'font-family: Helvetica Neue',
  },
  color: {
    text: '#404040',
    netwiseBlue: '#0077bd',
    netwiseGreen: '#009442',
    lightGrey: '#ecf1ee',
    lightGreyGreen: '#d5f8e4',
    lightBlue: '#e0f4ff',
    highlightRed: '#bd0042',
  },
}

const { medium } = typography.bodyRegular

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1000,
      xl: 1920,
    },
  },
  typography,
  palette: {
    primary: {
      main: netwiseTheme.color.netwiseBlue,
    },
    secondary: {
      main: netwiseTheme.color.netwiseGreen,
    },
    ...netwiseTheme,
  },
  overrides: {
    MuiAccordionSummary: {
      root: {
        '&$focused': {
          backgroundColor: '#F5F5F5',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: medium.fontSize,
        fontWeight: medium.fontWeight,
        lineHeight: medium.lineHeight,
      },
      input: {
        fontFamily: 'Rubik',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: 'Rubik',
      },
      caption: {
        fontFamily: 'Rubik',
      },
      body1: {
        fontFamily: 'Rubik',
      },
      body2: {
        fontFamily: 'Rubik',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Rubik',
      },
    },
    MuiButton: {
      root: {
        color: colors.gray8,
        fontWeight: 500,
        padding: '0.5rem',
      },
      text: {
        fontFamily: 'Rubik',
        fontSize: '0.875rem',
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Rubik',
      },
    },
    MUIDataTableViewCol: {
      root: {
        fontFamily: 'Rubik',
      },
    },
    MUIDataTableFilter: {
      root: {
        fontFamily: 'Rubik',
      },
    },
    MuiTableRow: {
      root: {
        whiteSpace: 'nowrap',
        backgroundColor: colors.gray,
        '&:nth-of-type(even)': {
          backgroundColor: colors.gray2,
        },
        '&.Mui-selected:hover': {
          backgroundColor: colors.gray4,
        },
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      sortActive: {
        color: colors.gray8,
      },
      data: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: colors.gray8,
      },
      fixedHeader: {
        padding: '0rem 0.5rem',
        backgroundColor: colors.gray1,
        border: `1px solid ${colors.gray4}`,
        color: colors.gray8,
        fontFamily: 'Rubik',
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        border: `1px solid ${colors.gray4}`,
      },
    },
    MUIDataTableBodyRow: {
      root: {
        '&.mui-row-selected': {
          backgroundColor: colors.gray4,
          ':hover': {
            backgroundColor: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '0.5rem',
      },
      body: {
        border: `1px solid ${colors.gray4}`,
      },
      paddingCheckbox: {
        padding: 0,
      },
    },
    MUIDataTableBodyCell: {
      root: {
        fontFamily: 'Rubik',
        maxWidth: '50ch',
        color: colors.gray10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    MUIDataTableToolbar: {
      root: {
        display: 'none',
      },
      actions: {
        display: 'none',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '0.5rem',
      },
      icon: {
        border: `1px solid white`,
        borderRadius: '0.25rem',
        padding: '0.25rem',
        margin: 0,
        '&:hover': {
          color: colors.blue8,
          backgroundColor: colors.blue1,
          border: `1px solid ${colors.blue2}`,
        },
      },
      iconActive: {
        border: `1px solid ${colors.blue2}`,
        borderRadius: '0.25rem',
        padding: '0.25rem',
        margin: 0,
        color: colors.blue8,
        backgroundColor: colors.blue1,
      },
      left: {
        flex: 0,
      },
    },
    MuiToolbar: {
      regular: {
        padding: 0,
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&.Mui-checked': {
          color: colors.blue6,
        },
      },
    },
    MUIDataTablePagination: {
      tableCellContainer: {
        border: 'none',
        padding: 0,
      },
    },
    MUIDataTableResize: {
      resizer: {
        border: 'none',
      },
    },
    MuiAutocomplete: {
      endAdornment: {
        top: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '0.875rem',
        border: `1px solid ${colors.gray6}`,
      },
      notchedOutline: {
        border: 'none',
      },
    },
  },
})

function NetWiseThemeProvider(props) {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ThemeProvider theme={theme} {...props} />
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export default NetWiseThemeProvider

export { typography, colors, theme }
