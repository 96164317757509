import { ListAccordion } from '../../../../../components/Accordion'
import { ReactComponent as Search } from '../../../../../icons/16px/Search.svg'
import TagInput from '../../../../../components/TagInput'
import StandardAutocomplete from '../../../../../components/StandardAutocomplete'

export default function Industry({
  industry_keywords,
  company_normalized_industry,
  company_normalized_industries,
  sic_codes,
  naic_codes,
  updateFilter,
  divOnly = false,
}: any) {
  const count =
    (industry_keywords?.length || 0) +
    (company_normalized_industries?.length || 0) +
    (sic_codes?.length || 0) +
    (naic_codes?.length || 0)

  return (
    <ListAccordion title="Industry" count={count || ''} divOnly={divOnly}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <TagInput
          title={'Keyword'}
          placeholder="Add Keywords"
          testId="addKeywords"
          LeftAdornment={Search}
          selectedTags={industry_keywords || []}
          addTag={(tag: any) =>
            updateFilter({
              industry_keywords: [...(industry_keywords || []), ...tag],
            })
          }
          deleteTag={(tag: any) =>
            updateFilter({
              industry_keywords: industry_keywords.filter(
                (keyword: any) => keyword !== tag
              ),
            })
          }
        />
        <StandardAutocomplete
          label={'Normalized Industry'}
          value={company_normalized_industries}
          placeholder="Add Normalized Industry"
          testId="addNormalizedIndustry"
          LeftAdornment={Search}
          tagSuggestions={company_normalized_industry || []}
          onChange={(_: any, values: any) => {
            updateFilter({ company_normalized_industries: values })
          }}
        />
        <TagInput
          title={'SIC Codes'}
          placeholder="Enter SIC Code"
          testId="enterSICCodes"
          LeftAdornment={Search}
          selectedTags={sic_codes || []}
          addTag={(tag: any) =>
            updateFilter({
              sic_codes: [...(sic_codes || []), ...tag],
            })
          }
          deleteTag={(tag: any) =>
            updateFilter({
              sic_codes: sic_codes.filter((keyword: any) => keyword !== tag),
            })
          }
        />
        <TagInput
          title={'NAICS Codes'}
          placeholder="Enter NAICS Code"
          testId="enterNAICSCodes"
          LeftAdornment={Search}
          selectedTags={naic_codes || []}
          addTag={(tag: any) =>
            updateFilter({ naic_codes: [...(naic_codes || []), ...tag] })
          }
          deleteTag={(tag: any) =>
            updateFilter({
              naic_codes: naic_codes.filter((keyword: any) => keyword !== tag),
            })
          }
        />
      </div>
    </ListAccordion>
  )
}
