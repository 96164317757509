import { colors } from '../../../../../context/theme-context'
import SavedExports from './ExportsTab/SavedExports'
import styled from 'styled-components'
import RecentExports from '../../../Exports/RecentExports'

export default function ExportsTab({
  appliedFilters,
  filtersHaveUpdated,
  audienceInfo,
  dispatch,
}: any) {
  return (
    <>
      <SavedExports
        appliedFilters={appliedFilters}
        filtersHaveUpdated={filtersHaveUpdated}
        audienceInfo={audienceInfo}
        dispatch={dispatch}
        containerProps={{ style: { padding: '1rem' } }}
      />
      <BorderDiv />
      <RecentExports
        page="audiences"
        isExpanded={true}
        containerProps={{ padding: '1rem' }}
      />
    </>
  )
}

const BorderDiv = styled.div`
  border-top: 1px solid ${colors.gray5};
  margin: 0rem 1rem;
`
