const today = new Date()

// values should be initialized to undefined when receiving values from a remote source
// i.e. when loading an account which already exists vs creating a new one
const makeInitialAdminState = ({ valuesUndefined = false }) => {
  return {
    basicInformation: {
      name: valuesUndefined ? undefined : 'New Account',
      domain: valuesUndefined ? undefined : 'New.com',
      stage: valuesUndefined ? undefined : 'trial',
      signupSource: valuesUndefined ? undefined : 'netwise',
      isActive: valuesUndefined ? undefined : true,
      expires: valuesUndefined
        ? undefined
        : new Date(Number(today) + 365 * 24 * 60 * 60 * 1000),
      salesforceUrl: valuesUndefined ? undefined : '',
      salesforceRecordId: valuesUndefined ? undefined : '',
      csmOwner: valuesUndefined ? undefined : '',
    },

    customFields: {
      piiAccess: valuesUndefined ? undefined : false,
      analysisOnly: valuesUndefined ? undefined : false,
      anonymizedOnly: valuesUndefined ? undefined : false,
      additionalFields: valuesUndefined ? undefined : [],
      restrictedFields: valuesUndefined ? undefined : [],
      accountType: valuesUndefined ? undefined : 'multichannel',
    },

    advancedInformation: {
      creditsUnlimited: valuesUndefined ? undefined : false,
      creditsUnlimitedAnonymized: valuesUndefined ? undefined : false,
      s3DeliveryFolder: valuesUndefined ? undefined : '',
      ownershipRetentionDays: valuesUndefined ? undefined : 365,
      disableUploads: valuesUndefined ? undefined : false,
      suppressFromCRM: valuesUndefined ? undefined : false,
    },

    referenceInformation: {
      testAccount: valuesUndefined ? undefined : false,
      lifetimeRecordsExported: valuesUndefined ? undefined : 0,
      lifetimeCreditsGranted: valuesUndefined ? undefined : 0,
      lifetimeCreditsUsed: valuesUndefined ? undefined : 0,
      monthlyInvoiceAmount: valuesUndefined ? undefined : 0,
      currentlyPaying: valuesUndefined ? undefined : false,
    },

    creditSchedule: {
      active: valuesUndefined ? undefined : true,
      scheduleStartYear: valuesUndefined ? undefined : 2022,
      scheduleStartMonth: valuesUndefined ? undefined : 5,
      credits: valuesUndefined ? undefined : 0,
      creditSchedule: valuesUndefined ? undefined : 'monthly',
      creditLimit: valuesUndefined ? undefined : 0,
    },

    features: {
      enableAppends: valuesUndefined,
      enableUploadDuns: valuesUndefined,
      enableExportDuns: valuesUndefined,
    },
  }
}

export default makeInitialAdminState
