import localforage from 'localforage'
import { ClientRequest } from 'types'
import { log as debugLog } from 'utils/debug'
import cacheList from './cacheList'

type FetchType = (args: ClientRequest) => any

interface Props {
  makeFetch: FetchType
  args: ClientRequest
}

const cacheController = async function ({ makeFetch, args }: Props): Promise<any> {
  try {
    const today = new Date()
    // Certain API responses are NEVER cached
    if (!cacheList.includes(args.endpoint)) {
      // debugLog('Endpoint never cached: ', args.endpoint)
      const res = await makeFetch(args)
      return res
    }

    // If a cached value exists, check the expiration date.
    // If it is not expired, use, otherwise refresh.
    const cacheKey = args.endpoint + JSON.stringify(args.data) + 'buster01'
    let cachedValue = await localforage.getItem(cacheKey)
    if (cachedValue) {
      if (today.getDate() < (cachedValue as any)['expirationDate']) {
        debugLog('Cache hit: ', args.endpoint)
        return (cachedValue as any)['value']
      }
    }

    // If a cached value does not exist, get the value and cache it
    debugLog('Cache miss or outdated: ', args.endpoint)
    const newValue = await makeFetch(args)

    if (args.useCache) {
      const twoWeeksFromToday = today.setDate(today.getDate() + 7 * 2)
      await localforage.setItem(cacheKey, {
        expirationDate: twoWeeksFromToday,
        value: newValue,
      })
    }

    return newValue
  } catch (e) {
    throw e
  }
}

export default cacheController
