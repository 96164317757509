import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { colors } from '../context/theme-context/'
import { toTitleCase } from '../utils/caseHelpers'
import { ReactComponent as ExMark } from '../icons/16px/Exmark.svg'
import Tooltip from './Tooltip/'
import { useMediaSize } from '../context/mediaQuery-context'
import { IconType } from 'react-icons'

type Props = {
  title: string
  rows?: Array<{ value: string; count: number }>
  Icon?: IconType
  percentkey?: string
  filtersHaveUpdated: boolean
  denominator: number
}

export default function TableBarChart(props: Props) {
  const { verySmallScreen } = useMediaSize()
  const [localRows, setLocalRows] = React.useState<Array<any> | undefined>(undefined)
  const { denominator, percentkey = 'count', rows, filtersHaveUpdated } = props

  React.useEffect(() => {
    const makePct = (rows: Array<any> | undefined, extractFn: (x: any) => any) => {
      return rows?.map(extractFn)
    }

    if (typeof rows === 'undefined') {
      setLocalRows(undefined)
    }

    if (typeof localRows === 'undefined' && denominator) {
      const initialRows = makePct(rows, (item: any) => ({ ...item, pct: 0 }))
      const finalRows = makePct(rows, (item: any) => ({
        ...item,
        percent: item[percentkey] / denominator,
      }))

      setLocalRows(initialRows as any)
      setTimeout(() => setLocalRows(finalRows as any), 250)
    }
  }, [denominator, localRows, percentkey, rows, filtersHaveUpdated])

  const id = props.title === 'Revenue' ? 'RevenueTable' : props.title
  return (
    <Container style={{ margin: '0.5rem' }} id={id}>
      <ContainerHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.Icon && (
            <props.Icon
              style={{ height: '24px', width: '24px', marginRight: '0.5rem' }}
            />
          )}
          <div>{props.title}</div>
        </div>
        <Tooltip
          title={`Changes made but not applied. Hit "u" key or click "Apply Changes" button to apply updates.`}
        >
          {props.filtersHaveUpdated ? (
            <div />
          ) : (
            <ExMark
              style={{ height: '24px', width: '24px', fill: colors.orange7 }}
            />
          )}
        </Tooltip>
      </ContainerHeader>
      <StyledTable>
        <tbody>
          {localRows?.length === 0 && (
            <StyledTableRow>
              <StyledTableCell style={{ width: '100%' }}>No Data</StyledTableCell>
            </StyledTableRow>
          )}
          {typeof localRows !== 'undefined' ? (
            localRows?.map((row: any, i: any, array: any) => {
              const isLast = i === array.length - 1

              // Items prefixed with __ will be hidden from displaying on the table
              if (row[Object.keys(row)[0]].startsWith('__')) {
                return null
              }

              const name = toTitleCase(row[Object.keys(row)[0]])
              return (
                <StyledTableRow key={i}>
                  <Tooltip placement="top-start" title={name}>
                    <StyledTableCell
                      isLast={isLast}
                      style={{
                        flex: 1,
                        borderRight: 'none',
                        fontSize: verySmallScreen ? '0.75rem' : '0.875rem',
                      }}
                    >
                      {name}
                    </StyledTableCell>
                  </Tooltip>
                  <StyledTableCell
                    isLast={isLast}
                    style={{
                      borderRight: 'none',
                      fontSize: verySmallScreen ? '0.75rem' : '0.875rem',
                      flex: 0.5,
                      color: props.filtersHaveUpdated ? colors.gray8 : colors.gray7,
                    }}
                  >
                    {row['count'].toLocaleString()}
                  </StyledTableCell>
                  <StyledCell
                    key={'percent'}
                    percent={row.percent}
                    isLast={isLast}
                    filtersHaveUpdated={props.filtersHaveUpdated}
                    verySmallScreen={verySmallScreen}
                  />
                </StyledTableRow>
              )
            })
          ) : (
            <tr>
              <td>
                <Skeleton height={260} />
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </Container>
  )
}

function StyledCell({
  percent = 0,
  filtersHaveUpdated,
  verySmallScreen,
  isLast,
}: any) {
  return (
    <StyledTableCell style={{ flex: 1.5, borderRight: 'none' }} isLast={isLast}>
      <div style={{ display: 'flex', flex: 1, columnGap: '0.5rem' }}>
        <div
          style={{
            width: '4ch',
            color: filtersHaveUpdated ? colors.gray7 : colors.gray6,
            fontSize: verySmallScreen ? '0.75rem' : '0.875rem',
          }}
        >
          {percent > 1 ? (
            <span>{percent.toFixed(1)}</span>
          ) : 0 < 100 * percent && 100 * percent < 1 ? (
            '<1%'
          ) : 100 > 100 * percent && 100 * percent > 99 ? (
            '>99%'
          ) : (
            `${(100 * percent).toFixed(0)}%`
          )}
        </div>
        <ColoredBar
          percent={percent}
          backgroundColor={filtersHaveUpdated ? colors.orange3 : colors.gray3}
          borderColor={filtersHaveUpdated ? colors.orange5 : colors.gray5}
        />
        <div style={{ display: 'flex', flex: 1 - percent }} />
      </div>
    </StyledTableCell>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid ${colors.gray5};
  overflow: hidden;
  background-color: ${colors.gray2};
`

const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray5};
  padding: 0.75rem 1rem calc(0.75rem + 1px);
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
  background-color: ${colors.gray3};
  svg {
    fill: ${colors.gray11};
  }
`

const StyledTable = styled.table`
  line-height: 1rem;
  background-color: ${colors.gray1};
  border-spacing: 0px;
  font-size: 0.875rem;
  font-weight: 400;
`

const StyledTableRow = styled.tr`
  display: flex;
`

const StyledTableCell = styled.td<{ isLast?: boolean }>`
  color: ${colors.gray8};
  border-bottom: ${(props) => (props.isLast ? 'none' : `1px solid ${colors.gray5}`)};
  border-right: 1px solid ${colors.gray5};
  padding: 0.75rem 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ColoredBar = styled.div<{
  percent: number
  backgroundColor: string
  borderColor: string
}>`
  display: flex;
  flex: ${(props) => props.percent};
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 2px;
  height: 0.75rem;
  transition: flex 2s;
`
