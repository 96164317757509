import { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from './Button'
import { colors } from '../../context/theme-context/'
import styled from 'styled-components'

export default function LoadButton({ loadingText, onClick, ...rest }: any) {
  const [hasClicked, setHasClicked] = useState(false)

  function clickHandler(e: any) {
    setHasClicked(true)
    onClick(e)
  }

  const ButtonComponent = hasClicked ? (
    <Button
      onClick={() => null}
      {...rest}
      text={
        <ProgressContainer>
          <CircularProgress size={'0.75rem'} />
          {loadingText}
        </ProgressContainer>
      }
    />
  ) : (
    <Button onClick={clickHandler} {...rest} />
  )

  return ButtonComponent
}

const ProgressContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  svg {
    color: ${colors.blue6};
    stroke-width: 2px;
  }
`
