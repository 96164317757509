import React, { useCallback, useRef } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import styled, { css } from 'styled-components'
import { useState, useEffect } from 'react'
import SampleContacts from './DashboardTabs/SampleContacts'
import SampleCompanies from './DashboardTabs/SampleCompanies'
import MetricsTab from './DashboardTabs/MetricsTab'
import ExportsTab from './DashboardTabs/ExportsTab'
import { useAuth } from 'context/auth-context'
import typography from 'context/theme-context/typography'
import colors from 'context/theme-context/colors'
import { toTitleCase } from 'utils/caseHelpers'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { scrollTo } from './DashboardTabs/utils'
import TabPanel from './DashboardTabs/TabPanel'
import { Props as AudienceDispatch } from 'App/SmartHub/Audiences/reducer'
import tm from 'analytics/TagManager'
import { MapEntry, MapEntryCallbackArgs } from 'types'

interface Props {
  audienceSize: any
  appliedFilters: any
  filtersHaveUpdated: boolean
  audienceInfo: any
  dispatch: React.Dispatch<AudienceDispatch>
  metricsDataReady: boolean
  metrics: any
  allDataMode: boolean
}

export default function DashboardTabs(props: Props) {
  const { client, socketHandler: sockets } = useAuth()
  const { dispatch } = props
  const { chartMetricsAPI, sampleContactsAPI, sampleCompaniesAPI } = client.endpoints
  const { hash: audienceHash } = useParams<{ hash: string | undefined }>()

  /***************/
  /* State Hooks */
  /***************/
  const [currentTab, setCurrentTab] = useState(0)
  const [layoutHash, setLayoutHash] = useState(null)
  const [sampleContacts, setSampleContacts] = useState({
    rows: null,
    columns: null,
  })
  const [sampleCompanies, setSampleCompanies] = useState({
    rows: null,
    columns: null,
  })

  // An array of taskIds.  An http request for the chart will return back a taskId which we store in this
  // array.  Later the websocket will return back the same taskId.  We use this array to match up the taskId
  // from the websocket which helps against race conditions.
  const activeTaskIds = useRef<Array<string>>([])
  const latestMetricsRequest = useRef(0)
  const stringAppliedFilters = JSON.stringify(props.appliedFilters)

  /****************/
  /* Router Hooks */
  /****************/
  const location = useLocation()
  const history = useHistory()

  /****************************/
  /* useEffects: side effects */
  /****************************/
  useEffect(() => {
    const parser = new URLSearchParams(location.search)
    const tab = parser.get('tab')
    const focus = parser.get('focus')
    switch (tab) {
      case 'metrics':
        setCurrentTab(0)
        break
      case 'contacts':
        setCurrentTab(1)
        break
      case 'companies':
        setCurrentTab(2)
        break
      case 'exports':
        setCurrentTab(3)
        if (focus === 'recent') {
          scrollTo('RecentlyExportedRow1')
        }
        break
      default:
        break
    }
  }, [location])

  /*****************************/
  /* useEffects: data fetching */
  /*****************************/

  const getMetricsData = useCallback(async () => {
    try {
      if (audienceHash && props.audienceInfo?.audience_id == null) {
        return
      }

      dispatch({
        payload: {
          metricsDataReady: false,
        },
      })

      dispatch({
        type: 'metrics',
        payload: {
          printableMetrics: {},
          metrics: {},
        },
      })

      const charts = [
        'personal_linkages',
        'employee_buckets',
        'top_titles',
        'top_seniorities',
        'company_metrics',
        'revenue_buckets',
        'top_functional_areas',
        'company_industries',
      ]
      latestMetricsRequest.current += 1
      let requestId = latestMetricsRequest.current

      activeTaskIds.current = []
      const responses = await Promise.all(
        charts.map((chart) =>
          client.post({
            endpoint: chartMetricsAPI,
            data: {
              ...JSON.parse(stringAppliedFilters),
              chart,
              channel_name: sockets?.taskSocket?.channelName,
            },
          })
        )
      )
      if (requestId === latestMetricsRequest.current) {
        const taskIds = responses.map((res) => res.responseData.tasks)
        activeTaskIds.current = taskIds
      }
    } catch (e) {
      console.log('Could not retrieve data at this time.')
      activeTaskIds.current = []
    }
  }, [
    audienceHash,
    chartMetricsAPI,
    client,
    dispatch,
    props.audienceInfo?.audience_id,
    sockets?.taskSocket?.channelName,
    stringAppliedFilters,
  ])

  useEffect(() => {
    async function getSampleData(endpoint: string, data: Object) {
      try {
        if (audienceHash && props.audienceInfo?.audience_id == null) {
          return
        }

        await client.post({
          endpoint,
          data,
        })
      } catch (e) {
        console.log('Could not retrieve sample data at this time.')
      }
    }

    dispatch({
      payload: {
        countsDataReady: false,
        audienceSize: {},
      },
    })

    const parser = new URLSearchParams(location.search)
    const tab = parser.get('tab') || 'metrics'
    switch (tab) {
      case 'metrics':
        getMetricsData()
        dispatch({
          payload: {
            printableMetrics: {},
            metricsDataReady: false,
            metrics: {},
          },
        })
        break
      case 'contacts':
        getMetricsData()
        setSampleContacts({ rows: null, columns: null })
        getSampleData(sampleContactsAPI, {
          layout_hash: layoutHash,
          data: {
            ...JSON.parse(stringAppliedFilters),
            channel_name: sockets?.taskSocket?.channelName,
          },
        })
        break
      case 'companies':
        getMetricsData()
        setSampleCompanies({ rows: null, columns: null })
        getSampleData(sampleCompaniesAPI, {
          ...JSON.parse(stringAppliedFilters),
          channel_name: sockets?.taskSocket?.channelName,
        })
        break
      case 'exports':
        getMetricsData()
        break
      default:
        break
    }
  }, [
    location,
    stringAppliedFilters,
    chartMetricsAPI,
    client,
    dispatch,
    sockets?.taskSocket?.channelName,
    audienceHash,
    props.audienceInfo?.audience_id,
    props.allDataMode,
    layoutHash,
    sampleCompaniesAPI,
    sampleContactsAPI,
    getMetricsData,
  ])

  /*********************************************************/
  /* Determine if we need to do the following:
  /* -Save chart data
  /* -Cancel the chart request based on certain conditions.
  /*********************************************************/
  const determineFlow = (
    args: MapEntryCallbackArgs,
    retryFunction: (args: MapEntryCallbackArgs, retryAttempt: number) => void,
    retryAttempt = 0
  ) => {
    const webSocketTaskId = args.task_id
    if (!activeTaskIds.current?.includes(webSocketTaskId)) {
      // Retry this up to ten times.  This is necessary to prevent an infinite loop that can occur when you
      // start a chain of chart requests and then leave the audience page.
      if (retryAttempt < 10) {
        console.log(`path 2 - retry ${retryAttempt}`)
        setTimeout(
          () => retryFunction(args, retryAttempt + 1),
          200 * (retryAttempt + 1)
        )
      }

      return {
        shouldCancelChain: true,
      }
    } else {
      console.log(`taskId found: ${webSocketTaskId}`)
    }

    return {
      shouldCancelChain: false,
      shouldSaveData: true,
    }
  }

  const extractMetricResults = async (
    args: MapEntryCallbackArgs,
    retryAttempt = 0
  ) => {
    const { shouldSaveData, shouldCancelChain } = determineFlow(
      args,
      extractMetricResults,
      retryAttempt
    )

    // Update with the data that just came back
    if (shouldSaveData) {
      props.metrics[args.taskType] = args.results

      dispatch({
        type: 'metrics',
        payload: {
          printableMetrics: props.metrics,
          metrics: props.metrics,
        },
      })

      if (args.taskType === 'company_metrics') {
        dispatch({
          type: 'mergeUpdate',
          payload: {
            audienceSize: {
              companies: props.metrics['company_metrics']['total_companies'],
            },
          },
        })
      }

      if (args.taskType === 'personal_linkages') {
        dispatch({
          type: 'mergeUpdate',
          payload: {
            audienceSize: {
              contacts: props.metrics['personal_linkages']['__total_contacts'],
            },
          },
        })
      }
    }

    if (shouldCancelChain) {
      return
    }

    const tasksAreLatest = activeTaskIds.current?.includes(args.task_id)
    if (tasksAreLatest) {
      dispatch({
        payload: {
          printableMetrics: props.metrics,
          // metrics: props.metrics,
          metricsDataReady: !!props.metrics['top_seniorities'], //TODO: change this to check for all values
        },
      })
    }
  }

  const extractSampleResults: MapEntry['callback'] = (args) => {
    const { records = [], fields = [] } = args.results

    const titleCase = fields.map((fieldName: any) => {
      return { name: fieldName, label: toTitleCase(fieldName) }
    })

    if (args.taskType === 'top_contacts') {
      setSampleContacts({ rows: records, columns: titleCase })
    }
    if (args.taskType === 'top_companies') {
      setSampleCompanies({ rows: records, columns: titleCase })
    }
  }

  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'personal_linkages',
    callback: extractMetricResults,
  })
  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'employee_buckets',
    callback: extractMetricResults,
  })
  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'top_titles',
    callback: extractMetricResults,
  })
  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'top_seniorities',
    callback: extractMetricResults,
  })
  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'company_metrics',
    callback: extractMetricResults,
  })
  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'revenue_buckets',
    callback: extractMetricResults,
  })
  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'top_functional_areas',
    callback: extractMetricResults,
  })
  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'company_industries',
    callback: extractMetricResults,
  })
  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'top_contacts',
    callback: extractSampleResults,
  })
  sockets?.taskSocket?.addMapback({
    id: 'dashboard',
    socketRequestType: 'top_companies',
    callback: extractSampleResults,
  })

  /***************************/
  /* functions tied to state */
  /***************************/
  function changeTab(_: any, idx: any) {
    const tabs = ['metrics', 'contacts', 'companies', 'exports']
    const newEntry = location.pathname
    history.push(newEntry + `?tab=${tabs[idx]}`)
    setCurrentTab(idx)
  }

  async function getAndApplyLayout(layout: any) {
    try {
      const { hashed } = layout
      setLayoutHash(hashed)
    } catch (e) {
      console.log(e)
    }
  }

  /***********************/
  /* Component Rendering */
  /***********************/
  return (
    <Grid item xs={12} style={{ minHeight: '600px' }} id="dashboard">
      <AppBar
        position="static"
        color="default"
        variant="outlined"
        style={{ border: 'none' }}
      >
        <Tabs
          style={{
            backgroundColor: `${colors.gray3}`,
            border: `1px solid ${colors.gray5}`,
            borderBottom: 'none',
            borderTopRightRadius: `0.5rem`,
            borderTopLeftRadius: `0.5rem`,
          }}
          value={currentTab}
          onChange={changeTab}
          TabIndicatorProps={{
            style: {
              height: '0.25rem',
              top: '0px',
              backgroundColor: colors.blue8,
            },
          }}
        >
          <StyledTab label="Metrics" data-cy="metricsTab" />
          <StyledTab
            label="Sample Contacts"
            id="sample_contacts"
            data-cy="sampleContactsTab"
          />
          <StyledTab
            label="Sample Companies"
            id="sample_companies"
            data-cy="sampleCompaniesTab"
          />
          <StyledTab
            label="Export Audience"
            id="export_tab"
            onClick={() => tm.captureCustomEvent('audiences_export_audience_tab')}
            data-cy="exportAudienceTab"
          />
        </Tabs>
      </AppBar>
      <TabPanel currentTab={currentTab} idx={0}>
        <MetricsTab
          audienceSize={props.audienceSize}
          metrics={props.metrics}
          filtersHaveUpdated={props.filtersHaveUpdated}
        />
      </TabPanel>
      <TabPanel currentTab={currentTab} idx={1}>
        <SampleContacts
          sampleContacts={sampleContacts}
          getAndApplyLayout={getAndApplyLayout}
          filtersHaveUpdated={props.filtersHaveUpdated}
          audienceInfo={props.audienceInfo}
        />
      </TabPanel>
      <TabPanel currentTab={currentTab} idx={2}>
        <SampleCompanies
          filtersHaveUpdated={props.filtersHaveUpdated}
          sampleCompanies={sampleCompanies}
          audienceInfo={props.audienceInfo}
        />
      </TabPanel>
      <TabPanel currentTab={currentTab} idx={3}>
        <ExportsTab
          appliedFilters={props.appliedFilters}
          filtersHaveUpdated={props.filtersHaveUpdated}
          audienceInfo={props.audienceInfo}
          dispatch={props.dispatch}
        />
      </TabPanel>
    </Grid>
  )
}

const { h4 } = typography

const StyledTab = styled(Tab)`
  text-transform: capitalize;
  background-color: ${(props) => (props.selected ? colors.gray1 : colors.gray3)};
  color: ${(props) => (props.selected ? colors.gray11 : colors.gray9)};
  font-weight: ${h4.fontWeight};
  font-size: 1rem;
  line-height: ${h4.lineHeight};
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-right: 1px solid ${colors.gray5};
  ${(props) =>
    props.selected
      ? css`
          border-bottom: none;
        `
      : css`
          border-bottom: 1px solid ${colors.gray5};
        `}
`
export { StyledTab }
