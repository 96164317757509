import React, { useState } from 'react'
import styled from 'styled-components'
import PlayButton from './PlayButton'
import Button from 'components/Button'
import { BiCalculator } from 'react-icons/bi'
import { colors } from 'context/theme-context/'
import { ReactComponent as UploadIcon } from 'icons/24px/Upload.svg'
import { ReactComponent as FacebookIcon } from 'icons/24px/Facebook.svg'
import { ReactComponent as NetWiseIcon } from 'icons/24px/NetWiseLogo.svg'
import { BiInfoCircle } from 'react-icons/bi'
import CountWidget from './CountWidget'
import Tooltip from 'components/Tooltip'
import { DialogExport } from '../../../NameAudience'

interface DTCache {
  size: number
  size_credited: number
  size_uncredited: number
  size_creditable: number
  size_exportable: number
}
interface Props {
  itemData: DialogExport
  calculateCounts: () => void
  counts: DTCache
  fieldCount: number
  bottomBorder: boolean
  index: number
  userCredits: number | null
}

const ExportAudienceDialogItem: React.FC<Props> = ({
  itemData,
  calculateCounts,
  counts,
  fieldCount,
  bottomBorder,
  index,
}) => {
  const [hasClicked, setHasClicked] = useState(false)
  const { name, description, created, icon, runHandler } = itemData
  const exportDate = created?.timestamp?.local

  let ItemIcon = NetWiseIcon
  switch (icon) {
    case 'quick':
      ItemIcon = UploadIcon
      break
    case 'facebook':
      ItemIcon = FacebookIcon
      break
    default:
      break
  }

  return (
    <div>
      <Container>
        <LeftSide>
          <ItemIcon style={{ alignSelf: 'flex-start', margin: '0.5rem' }} />
          <TitleContainer>
            <TitleSpan>
              <Title data-cy="exportName">{name}</Title>
              {description && (
                <Tooltip title={description} data-cy="toolTipValue">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BiInfoCircle color={colors.blue8} />
                  </div>
                </Tooltip>
              )}
            </TitleSpan>
            <SubTitle>
              {fieldCount && (
                <div>
                  Fields:{' '}
                  <span style={{ color: colors.gray11 }} data-cy="fieldCount">
                    {fieldCount}
                  </span>
                </div>
              )}
            </SubTitle>
            {exportDate && (
              <SubTitle data-cy="lastExported">Last Exported: {exportDate}</SubTitle>
            )}
            {(hasClicked || counts) && (
              <CountWidget
                records={counts?.size_exportable}
                credits={counts?.size_creditable}
                style={{ paddingTop: '1rem' }}
                id="quick_export_counts"
              />
            )}
          </TitleContainer>
        </LeftSide>
        <RightSide>
          <div>
            <Button
              icon={BiCalculator}
              text="Calculate"
              id={`exportAudience_${index}_${0}`}
              onClick={() => {
                if (!hasClicked) {
                  calculateCounts()
                  setHasClicked(true)
                }
              }}
              variant="secondary"
              size="medium"
              disabled={!!counts || hasClicked}
              testId="calculateExport"
            />
          </div>
          <div>
            <div
              onClick={() => {
                if (counts?.size_creditable) return
                calculateCounts()
              }}
            >
              <Button
                text={<PlayButton />}
                id={`exportAudience_${index}_${1}`}
                onClick={runHandler}
                variant="primary"
                size="medium"
                testId="runExport"
              />
            </div>
          </div>
        </RightSide>
      </Container>
      {bottomBorder && <BottomBorder />}
    </div>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 3rem;
  padding: 1rem 0rem;
`
const LeftSide = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.5rem;
`
const RightSide = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
const Title = styled.div`
  color: ${colors.gray9};
  font-size: 1rem;
  font-weight: 500;
`
const SubTitle = styled.div`
  color: ${colors.gray8};
  font-size: 0.875rem;
  line-height: 1rem;
`
const BottomBorder = styled.div`
  width: calc(100% + 1rem);
  border-bottom: 1px solid ${colors.gray5};
`
const TitleSpan = styled.span`
  display: flex;
  column-gap: 0.35rem;
  align-items: center;
`

export default ExportAudienceDialogItem
