import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import colors from '../../context/theme-context/colors'
import withStyles from '@material-ui/styles/withStyles'

const StyledAccordion = withStyles({
  root: {
    boxShadow: 'none',
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 500,
    margin: '0rem 0rem 1rem',
    borderRadius: '0.5rem',
    border: `1px solid ${colors.gray5}`,
    backgroundColor: colors.gray3,
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0rem 0rem 1rem',
    },
  },
  rounded: {
    '&:first-child': {
      borderRadius: '0.5rem',
    },
    '&:last-child': {
      borderRadius: '0.5rem',
    },
  },
  expanded: {},
})(Accordion)

const StyledAccordionSummary = withStyles({
  root: {
    borderRadius: '0.5rem',
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
      borderRadius: '0.5rem 0.5rem 0rem 0rem',
    },
  },
  content: {
    borderRadius: '0.5rem 0.5rem 0rem 0rem',
    '&$expanded': {
      margin: 'inherit',
    },
  },
  expanded: {},
})(AccordionSummary)

const StyledAccordionDetails = withStyles({
  root: {
    border: 'none',
    borderTop: `1px solid ${colors.gray5}`,
    backgroundColor: colors.gray1,
    borderRadius: '0rem 0rem 0.5rem 0.5rem',
    padding: '0rem 0.5rem',
  },
})(AccordionDetails)

export { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails }
