import ToolTip from './Tooltip'

export default function SpecialTooltip({ text, condition, children }: any) {
  if (condition) {
    return (
      <>
        <ToolTip title={text}>
          <div>{children}</div>
        </ToolTip>
      </>
    )
  }
  return children
}
