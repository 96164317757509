import { createContext, useContext } from 'react'
import { useMediaQuery } from '@material-ui/core'

const MediaQueryContext: React.Context<any> = createContext(null)

const MediaQueryProvider: React.FC<any> = (props) => {
  const mediumScreen: boolean = useMediaQuery('(max-width: 940px)')
  const smallScreen: boolean = useMediaQuery('(max-width: 799px)')
  const verySmallScreen: boolean = useMediaQuery('(max-width: 599px)')

  return (
    <MediaQueryContext.Provider
      value={{
        mediumScreen,
        smallScreen,
        verySmallScreen,
      }}
      {...props}
    />
  )
}

interface MediaSizeInterface {
  mediumScreen: boolean
  smallScreen: boolean
  verySmallScreen: boolean
}

const useMediaSize = (): MediaSizeInterface => useContext(MediaQueryContext)

export { MediaQueryProvider, useMediaSize }
