import React from 'react'
import styled from 'styled-components'
import Counts from '../../../../../components/TitleBar/Counts'
import { colors } from '../../../../../context/theme-context/'

interface Props {
  dataReady: boolean
  printingUser: string
  buildVersion: string
  audienceInfo: any
  audienceSize: any
}

const Summary: React.FC<Props> = ({
  dataReady,
  printingUser = 'Unknown Printer',
  buildVersion = 'Unknown',
  audienceInfo = {},
  audienceSize = {},
}) => {
  const printDate = new Date()
  const { contacts, companies } = audienceSize
  const { audience_name = 'Unknown', created_by = 'Unknown' } = audienceInfo

  return (
    <SummaryContainer>
      <LeftSummary>
        <AudienceName>{audience_name}</AudienceName>
        <Details>
          Prepared by <strong>{created_by}</strong>, printed by{' '}
          <strong>{printingUser}</strong>
          <br />
          {printDate.toLocaleTimeString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })}
          <br />
          Build version #{buildVersion}
        </Details>
      </LeftSummary>
      <RightSummary>
        <div>
          <Counts
            filtersHaveUpdated={true}
            dataReady={dataReady}
            countNumber={contacts}
            countTitle={'Contacts'}
            subtitleStyle={{ alignSelf: 'flex-end' }}
          />
        </div>
        <div>
          <Counts
            filtersHaveUpdated={true}
            dataReady={dataReady}
            countNumber={companies}
            countTitle={'Companies'}
            subtitleStyle={{ alignSelf: 'flex-end' }}
          />
        </div>
      </RightSummary>
    </SummaryContainer>
  )
}

const SummaryContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5rem 0rem 0.75rem;
  border-bottom: 1px solid ${colors.gray5};
  align-items: center;
  justify-content: space-between;
  font-size: 1.125rem;
  line-height: 1.5rem;
`
const LeftSummary = styled.div``
const RightSummary = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`
const AudienceName = styled.div`
  font-weight: 500;
  padding-bottom: 0.25rem;
`
const Details = styled.div`
  flex-direction: column;
  font-size: 0.75rem;
  color: ${colors.gray8};
  line-height: 1rem;
`

export default Summary
