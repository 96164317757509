import { ListAccordion } from '../../../../../components/Accordion'
import { ReactComponent as Search } from '../../../../../icons/16px/Search.svg'
import TagInput from '../../../../../components/TagInput'

export default function Keywords({
  title_keywords,
  updateFilter,
  divOnly = false,
}: any) {
  return (
    <ListAccordion
      title="Title Keywords"
      count={title_keywords?.length || ''}
      divOnly={divOnly}
    >
      <TagInput
        LeftAdornment={Search}
        placeholder={'Enter Keywords'}
        id="enterTitleKeywords"
        testId="enterTitleKeywords"
        selectedTags={title_keywords || []}
        addTag={(tag: any) =>
          updateFilter({ title_keywords: [...(title_keywords || []), ...tag] })
        }
        deleteTag={(tag: any) =>
          updateFilter({
            title_keywords: title_keywords.filter((keyword: any) => keyword !== tag),
          })
        }
      />
    </ListAccordion>
  )
}
