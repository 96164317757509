import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Alert from './Alert'

type PromptProps = {
  message: string
  blockCondition: boolean
}

const NavigationPrompt: React.FC<PromptProps> = ({ message, blockCondition }) => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const unblockFn = useRef<() => void>()

  useEffect(() => {
    let lastAction: any
    const unblockHistory = history.block((action) => {
      lastAction = action
      if (
        (history.location.pathname !== action.pathname ||
          !!((action as any).state?.['action'] === 'reset')) &&
        blockCondition
      ) {
        setIsOpen(true)
        return false as any
      }
      return true
    })

    unblockFn.current = () => {
      unblockHistory()
      history.push(lastAction.pathname, lastAction.state)
    }

    return () => unblockHistory()
  }, [history, blockCondition])

  function handleCancel() {
    setIsOpen(false)
  }

  function handleOk() {
    unblockFn.current?.()
    setIsOpen(false)
  }

  return (
    <Alert
      handleClose={handleCancel}
      message={message}
      alertIsOpen={isOpen}
      handleCancel={handleCancel}
      handleOk={handleOk}
    />
  )
}

export default NavigationPrompt
