import { ListAccordion } from 'components/Accordion'
import styled from 'styled-components'
import SimpleInput from 'components/SimpleInput'
import { StyledTextareaAutosize } from 'components/TextareaAutosize'
import { debounce } from '@material-ui/core'
import LayoutFieldCreate from 'components/ExportDialog/LayoutFieldCreate'
import { privacyOptions } from '../../constants'
import SimpleAutoComplete from 'components/SimpleAutoComplete'
import { useAuth } from 'context/auth-context'
import Checkbox from 'components/Checkbox'

type GeneralSectionGenericProps = {
  disabled?: boolean
  exportState: {
    name: string
    file_name: string
    audience: string
    privacy: string
    description: string
    enable_appends: boolean
  }
  chosenExport: any
  setExportState: React.Dispatch<
    React.SetStateAction<{
      name: string
      file_name: string
      audience: string
      privacy: string
      description: string
      enable_appends: boolean
    }>
  >
  audiencesAPI: string
  placeholder?: string
  layouts: any[]
  showAppendsCheckbox: boolean
}

export default function GeneralSectionGeneric(props: GeneralSectionGenericProps) {
  const { client } = useAuth()
  return (
    <ListAccordion title="General" expanded data-cy="general">
      <ContentDiv>
        <RowDiv>
          <LabelSpan>Name</LabelSpan>
          <InputSpan>
            <SimpleInput
              debounced={true}
              disabled={props.disabled}
              defaultValue={props.exportState.name}
              placeholder="New Export"
              onChange={(e: any) =>
                props.setExportState((state: any) => ({
                  ...state,
                  name: e.target.value,
                }))
              }
              data-cy="exportName"
            />
          </InputSpan>
        </RowDiv>
        <RowDiv>
          <LabelSpan>Static File Name</LabelSpan>
          <InputSpan>
            <SimpleInput
              disabled={props.disabled}
              value={props.exportState.file_name}
              placeholder="Optional, leave blank for auto populated file name"
              onChange={(e: any) =>
                props.setExportState((state: any) => ({
                  ...state,
                  file_name: e.target.value,
                }))
              }
              data-cy="staticFileName"
            />
          </InputSpan>
        </RowDiv>
        <RowDiv>
          <LabelSpan>Audience</LabelSpan>
          <InputSpan>
            <SimpleAutoComplete
              client={client}
              audienceName={props?.chosenExport?.audience?.name}
              optionsUrl={props.audiencesAPI}
              disabled={props.disabled}
              placeholder={
                props.placeholder === undefined ? 'No Audience' : props.placeholder
              }
              valueField="hashed"
              labelField="name"
              value={props.exportState.audience}
              onChange={(value: any) => {
                props.setExportState((state: any) => ({
                  ...state,
                  audience: value,
                }))
              }}
              data-cy="exportAudience"
            />
          </InputSpan>
        </RowDiv>
        <RowDiv>
          <LabelSpan>Privacy</LabelSpan>
          <InputSpan>
            <SimpleAutoComplete
              client={client}
              disabled={props.disabled}
              placeholder="Privacy"
              value={props.exportState.privacy || 'team_view'}
              options={privacyOptions}
              onChange={(value: any) => {
                props.setExportState((state: any) => ({
                  ...state,
                  privacy: value,
                }))
              }}
              data-cy="privacy"
            />
          </InputSpan>
        </RowDiv>
        <RowDiv style={{ alignItems: 'flex-start' }}>
          <LabelSpan style={{ paddingTop: '0.5rem' }}>Description</LabelSpan>
          <InputSpan>
            <StyledTextareaAutosize
              defaultValue={props.exportState.description}
              disabled={props.disabled}
              placeholder="Please enter description here"
              style={{ height: '75px' }}
              onChange={debounce((event) => {
                props.setExportState((state: any) => ({
                  ...state,
                  description: event.target.value,
                }))
              }, 250)}
              data-cy="description"
            />
          </InputSpan>
        </RowDiv>
        <LayoutFieldCreate
          disabled={props.disabled}
          exportState={props.exportState}
          chosenExport={props.chosenExport}
          setExportState={props.setExportState}
          layouts={props.layouts}
        />
        {props.showAppendsCheckbox && (
          // Check if the 'should_enable_appends' property is set to true in the service
          // and display the corresponding checkbox in the UI if it is enabled.
          <RowDiv>
            <LabelSpan>Add Input Field</LabelSpan>
            <InputSpan>
              <Checkbox
                onClick={() => {
                  props.setExportState((state) => ({
                    ...state,
                    enable_appends: !state.enable_appends,
                  }))
                }}
                status={props.exportState.enable_appends}
                data-cy="includeInputField"
                id="appends_input_field"
              />
            </InputSpan>
          </RowDiv>
        )}
      </ContentDiv>
    </ListAccordion>
  )
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  font-weight: 400;
`
const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const LabelSpan = styled.span`
  display: flex;
  align-self: center;
  flex: 1;
`

const InputSpan = styled.span`
  display: flex;
  flex: 5;
`

export { ContentDiv, RowDiv, LabelSpan, InputSpan }
