import LayerLogic from './LeftMatch/LayerLogic'
import { ListAccordion } from '../../../../../components/Accordion/'
import Button from '../../../../../components/Button/'
import DirectmatchModal from './LeftMatch/DirectMatchModal'
import { useEffect, useReducer } from 'react'
import { useAuth } from '../../../../../context/auth-context'
import reducer, { initialState } from './LeftMatch/reducer'
import matchFileField from '../../../Uploads/ExactMatch/matchFileField'
import { useAlerts } from '../../../../../context/alert-context'

interface Props {
  chosen_files: Array<any>
  dispatch: React.Dispatch<any>
  divOnly: boolean | null
}

const LeftMatch: React.FC<Props> = (props) => {
  const { chosen_files = [], dispatch, divOnly } = props
  const { client } = useAuth()
  const { sendAlert } = useAlerts()

  /***************/
  /* State Setup */
  /***************/
  const [state, dispatchLocal] = useReducer(reducer, initialState)
  const { dialogOpen, editDialogOpen, editFileIndex, currentFile, dbFields } = state

  /*********************************/
  /* Effects to Handle Local State */
  /*********************************/
  useEffect(() => {
    if (chosen_files.length) {
      dispatchLocal({ type: 'updateJoins', payload: chosen_files })
    }
  }, [chosen_files])

  useEffect(() => {
    async function getData() {
      try {
        const { responseData } = await client.get({
          endpoint: client.endpoints.joinFieldsAPI,
          data: { name_only: 1 },
        })

        const { results: dbFields } = responseData
        dispatchLocal({ payload: { dbFields: dbFields } })
      } catch (e) {
        console.log('Could not retrieve data at this time.')
      }
    }
    getData()
  }, [client])

  /*******************/
  /* Match Functions */
  /*******************/
  function removeDuplicates(array: any, key: any) {
    let lookup = new Set()
    return array.filter((obj: any) => !lookup.has(obj[key]) && lookup.add(obj[key]))
  }

  function checkBeforeApply(applyFunc: any) {
    const { joins, name } = currentFile

    if (typeof name === 'undefined') {
      sendAlert({ message: 'Please select a file for joining' })
      return
    }
    if (typeof joins === 'undefined') {
      sendAlert({ message: 'Please select joins for file' })
      return
    }
    const allSelected = joins.reduce(
      (pv: any, cv: any) =>
        Math.min(Number(!!cv.file_field), Number(!!cv.database_field), pv),
      true
    )

    if (allSelected) {
      applyFunc()
    } else {
      sendAlert({ message: 'Please make all selections before applying' })
    }
  }

  function applyFileMatch() {
    dispatch({
      type: 'updateFilter',
      payload: {
        chosen_files: [
          ...chosen_files,
          {
            file_id: currentFile?.id,
            joins: removeDuplicates(currentFile.joins, 'file_field'),
            filter_exclude_matches: currentFile.filter_exclude_matches,
            file_name: currentFile.formatted_name || currentFile.file_name,
          },
        ],
      },
    })
    dispatchLocal({ payload: { dialogOpen: false } })
  }

  function applyEditFileMatch() {
    const _chosen_files = [...chosen_files]

    _chosen_files[editFileIndex] = {
      ..._chosen_files[editFileIndex],
      file_id: currentFile?.id,
      joins: removeDuplicates(currentFile.joins, 'file_field'),
      filter_exclude_matches: currentFile.filter_exclude_matches,
    }

    dispatch({
      type: 'updateFilter',
      payload: {
        chosen_files: [..._chosen_files],
      },
    })
    dispatchLocal({ payload: { editDialogOpen: false } })
  }

  function deleteMatchedFile(index: any) {
    const updatedFiles = chosen_files.filter((_, i) => i !== index)
    dispatch({ type: 'updateFilter', payload: { chosen_files: updatedFiles } })
  }

  async function updateFileId(updateFileId: any, index: any) {
    try {
      const {
        responseData: { results: file },
      } = await client.get({ endpoint: client.endpoints.filesIdAPI(updateFileId) })
      const _chosen_file = chosen_files[index]
      const { joins, filter_exclude_matches } = _chosen_file
      const { fields, formatted_name, name, hashed } = file

      dispatchLocal({
        type: 'updateFileId',
        payload: {
          editDialogOpen: true,
          editFileIndex: index,
          currentFile: {
            id: updateFileId,
            hashed,
            name,
            formatted_name,
            fields,
            joins,
            filter_exclude_matches,
          },
        },
      })
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <ListAccordion
      title="File Match"
      count={chosen_files?.length || ''}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
      divOnly={divOnly}
    >
      <div style={{ width: '100%' }}>
        {chosen_files.map((file, i) => (
          <LayerLogic
            updateFileId={() => updateFileId(file.file_id, i)}
            handleDelete={() => deleteMatchedFile(i)}
            filename={file.file_name}
            joins={file.joins}
            filter_exclude_matches={file.filter_exclude_matches}
            key={i}
          />
        ))}
        <Button
          size="medium"
          variant="secondary"
          text="Add File"
          testId="addMatchFile"
          style={{
            width: '100%',
            margin: '0.5rem 0rem',
          }}
          onClick={() => dispatchLocal({ type: 'newFile' })}
        />
      </div>
      <DirectmatchModal
        disabled={false}
        currentFile={currentFile}
        dbFields={dbFields}
        isOpen={dialogOpen}
        handleClose={() => dispatchLocal({ payload: { dialogOpen: false } })}
        applyFileMatch={() => checkBeforeApply(applyFileMatch)}
        dispatch={dispatchLocal}
        matchFileField={matchFileField}
      />
      <DirectmatchModal
        disabled={true}
        dbFields={dbFields}
        currentFile={currentFile}
        isOpen={editDialogOpen}
        handleClose={() => dispatchLocal({ payload: { editDialogOpen: false } })}
        applyFileMatch={() => checkBeforeApply(applyEditFileMatch)}
        dispatch={dispatchLocal}
      />
    </ListAccordion>
  )
}

export default LeftMatch
