import Checkbox from 'components/Checkbox'
import InputSubtitle from './InputSubtitle'
import { ReferenceInformation } from '../adminTypes'

type RefFact = {
  label: string
  value?: string | number | boolean
}
const ReferenceFact = ({ label, value }: RefFact) => (
  <div style={{ display: 'flex', rowGap: '0.25rem', flexDirection: 'column' }}>
    <div>{label}</div>
    <div>{value || '-'}</div>
  </div>
)

type RefInfo = {
  referenceInfo: ReferenceInformation
}
const ReferenceInfo = ({ referenceInfo }: RefInfo) => {
  return (
    <>
      <h3>Reference Info (Read Only)</h3>
      <div>
        <Checkbox
          label="Test Account"
          status={referenceInfo.testAccount}
          disabled={true}
        />
        <InputSubtitle>
          Account is used only for testing (object may be purged periodically)
        </InputSubtitle>
      </div>
      <ReferenceFact
        label={'Currently Paying'}
        value={referenceInfo.currentlyPaying}
      />
      <ReferenceFact
        label={'Lifetime Records Exported'}
        value={referenceInfo.lifetimeRecordsExported}
      />
      <ReferenceFact
        label={'Lifetime Credits Granted'}
        value={referenceInfo.lifetimeCreditsGranted}
      />
      <ReferenceFact
        label={'Lifetime Credits Used'}
        value={referenceInfo.lifetimeCreditsUsed}
      />
      <ReferenceFact
        label={'Monthly Invoice Amount'}
        value={referenceInfo.monthlyInvoiceAmount}
      />
    </>
  )
}

export default ReferenceInfo
