import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'context/theme-context'
import QuestionTooltip from 'components/QuestionTooltip'

export type Props = {
  id: string
  title: string
  tooltipText: string
  active: boolean | undefined
  handleClick: (enableAppends: boolean) => void
}

export default function FeatureAccessDialogRow(props: Props) {

  const appendsActive = props.active === undefined ? false : props.active

  return (
    <div
      style={{
        display: 'flex',
        width: '700px',
        justifyContent: 'space-between',
      }}
    >
      <div>{props.title}</div>
      <div>
        <ButtonContainer>
          <Button
            left
            active={appendsActive}
            onClick={() => props.handleClick(true)}
            data-cy={`${props.id}On`}
            aria-selected={Boolean(appendsActive)}
          >
            <span>On</span>
          </Button>
          <Button
            right
            active={!appendsActive}
            onClick={() => props.handleClick(false)}
            data-cy={`${props.id}Off`}
            aria-selected={Boolean(!appendsActive)}
          >
            <span>Off</span>
          </Button>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              paddingLeft: '0.5rem',
            }}
          >
            <QuestionTooltip
              text={props.tooltipText}
              data-cy={`${props.id}FeatureAccessTooltip`}
            />
          </div>
        </ButtonContainer>
      </div>
    </div>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-content: center;
  line-height: 1rem;
  cursor: pointer;
`

const Button = styled.div<{ left?: boolean; right?: boolean; active?: boolean }>`
  padding: 0rem 0.5rem;
  display: flex;
  font-size: 0.75rem;
  align-content: center;
  font-weight: 400;
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  border-radius: 0.25rem;
  ${(props) =>
    props.active &&
    css`
      background-color: ${colors.blue1};
      border: 1px solid ${colors.blue2};
      border-radius: 0.25rem;
    `}
  ${(props) =>
    props.left &&
    css`
      border-radius: 0.25rem 0rem 0rem 0.25rem;
      border-right: 0;
    `};
  ${(props) =>
    props.right &&
    css`
      border-radius: 0rem 0.25rem 0.25rem 0rem;
    `};
`