// @ts-nocheck
import { useState, useCallback, useEffect } from 'react'
import { ListAccordion } from '../../../../../components/Accordion/'
import { StyledTextareaAutosize } from '../../../../../components/TextareaAutosize/'
import { ReactComponent as ExMark } from '../../../../../icons/16px/Exmark.svg'
import { ReactComponent as Check } from '../../../../../icons/16px/Check.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import colors from '../../../../../context/theme-context/colors'
import { useAuth } from '../../../../../context/auth-context'
import { makeStyles } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '../../../../../components/Tooltip'
import { debounce } from '../../../../../utils/debounce'

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
}))

interface SQLPropsI {
  updateFilter: any
  sql_editor: string | null
  sqlIsVerified: boolean
  dispatch: any
  divOnly?: boolean
}

const CustomSQL: React.FC<SQLPropsI> = ({
  updateFilter,
  sql_editor,
  sqlIsVerified,
  dispatch,
  divOnly = false,
}) => {
  const classes = useStyles()
  const { client } = useAuth()
  const [errorMsg, setErrorMsg] = useState()
  const [isWaiting, setIsWaiting] = useState(false)
  const [localSql, setLocalSql] = useState({
    text: sql_editor,
    isVerified: false,
  })

  const { isVerified } = localSql

  useEffect(() => {
    setLocalSql((state) => {
      if (state.text !== sql_editor) {
        const textValue = !!sql_editor ? sql_editor : ''
        return { ...state, text: textValue }
      } else {
        return state
      }
    })
  }, [sql_editor])

  //eslint-disable-next-line
  const debouncedUpdate = useCallback(
    debounce((value, sqlIsVerified) => {
      if (sqlIsVerified !== null) {
        dispatch({ payload: { sqlIsVerified: null } })
      }
      updateFilter({ sql_editor: value })
    }, 1000),
    []
  )

  function handleChange(e) {
    const value = e.target.value
    debouncedUpdate(value, sqlIsVerified)
    setLocalSql({ isVerified: null, text: value })
  }

  useEffect(() => {
    async function handleApply() {
      setIsWaiting(true)
      try {
        const { responseData } = await client.put({
          endpoint: client.endpoints.validation,
          data: {
            where_clause: sql_editor,
          },
        })

        const { success, error } = responseData.results
        if (success) {
          setLocalSql((state) => ({ ...state, isVerified: true }))
          dispatch({ payload: { sqlIsVerified: true } })
          updateFilter({ sql_editor })
        } else {
          setErrorMsg(error)
          setLocalSql((state) => ({ ...state, isVerified: false }))
          dispatch({ payload: { sqlIsVerified: false } })
        }
      } catch (e) {
        console.log(e)
      } finally {
        setIsWaiting(false)
      }
    }
    if (sql_editor) {
      handleApply()
    } else if (sql_editor === '') {
      setErrorMsg()
    }
  }, [sql_editor, client, dispatch]) //eslint-disable-line

  let WarningText = <div />
  if (isWaiting) {
    WarningText = (
      <div style={{ alignSelf: 'center' }}>
        <ProgressIndicator size="1rem" />
      </div>
    )
  } else if (sql_editor) {
    if (sqlIsVerified) {
      WarningText = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ alignSelf: 'center' }}>
            <StyledCheck />
          </div>
          <span style={{ color: colors.green8 }}>&nbsp;Valid SQL</span>
        </div>
      )
    } else if (sqlIsVerified === false) {
      WarningText = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StyledExMark />
          <span style={{ color: colors.red8 }}>&nbsp;Invalid SQL</span>
        </div>
      )
    }
  }

  const Title = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>Custom SQL</div>
      <Tooltip
        title={'Add additional custom logic to the SQL WHERE clause'}
        classes={{ tooltip: classes.customWidth }}
      >
        <InfoOutlinedIcon
          style={{ marginLeft: '0.25rem', height: '1rem', width: '1rem' }}
        />
      </Tooltip>
    </div>
  )

  return (
    <ListAccordion
      bottomBorder={false}
      title={Title}
      warningText={WarningText}
      id={'Custom SQL'}
      divOnly={divOnly}
    >
      <div style={{ width: '100%' }}>
        <StyledTextareaAutosize
          value={localSql.text}
          onChange={handleChange}
          placeholder="lower(title) like 'director of%' OR personal_state = 'Texas'"
          data-cy="customSQL"
          style={{ 
            height: '10rem', 
            width: '100%', 
            marginBottom: '0.5rem',
            overflow: 'scroll'
          }}
        />
        <div
          style={{
            color: colors.red8,
          }}
        >
          {isVerified === false && errorMsg}
        </div>
      </div>
    </ListAccordion>
  )
}

const StyledExMark = styled(ExMark)`
  path {
    fill: ${colors.red8};
  }
`
const StyledCheck = styled(Check)`
  path {
    fill: ${colors.green8};
  }
`
const ProgressIndicator = styled(CircularProgress)`
  display: block;
  color: ${colors.green8};
`

export default CustomSQL
