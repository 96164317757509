import styled from 'styled-components'
import { useEffect, useState } from 'react'
import ClientDataTable from '../../../../../components/ClientDataTable'
import SimpleInput from '../../../../../components/SimpleInput'
import { ReactComponent as SearchIcon } from '../../../../../icons/16px/Search.svg'
import { debounce } from '../../../../../utils/debounce'
import { colors } from '../../../../../context/theme-context/'
import CleansingSelector from './CleansingSelector'

export default function FieldCleanser({
  match_fields,
  fileColumns,
  cleansingOptions,
  cleansingMap,
  setMatchState,
}: any) {
  const [search, setSearch] = useState()
  const debouncedSearch = debounce(setSearch, 200)

  useEffect(() => {
    const _cleansingMap = {}
    for (const column of fileColumns) {
      const match = match_fields.filter((x: any) => x.file_field === column)[0]

      if (!match) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (!_cleansingMap[column]) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          _cleansingMap[column] = 'none'
        }
      } else {
        const { database_field } = match
        cleansingOptions?.forEach((option: any) => {
          if (option.direct_match_value.includes(database_field)) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            _cleansingMap[column] = option.value
          }
        })
      }
      setMatchState((state: any) => ({
        ...state,
        cleansingMap: _cleansingMap,
      }))
    }
  }, [match_fields, fileColumns, cleansingOptions, setMatchState])

  function changeHandler(key: any) {
    return (e: any) => {
      const _cleansingMap = { ...cleansingMap }
      _cleansingMap[key] = e.target.value
      setMatchState((state: any) => ({
        ...state,
        cleansingMap: _cleansingMap,
      }))
    }
  }

  const data = Object.entries(cleansingMap).map(([key, value]) => {
    return {
      input_field_name: key,
      cleansing_method: (
        <CleansingSelector
          value={value}
          changeHandler={changeHandler(key)}
          locked={match_fields.map((x: any) => x.file_field).includes(key)}
          options={cleansingOptions}
        />
      ),
    }
  })

  return (
    <ContainerDiv>
      <SearchDiv>
        <SimpleInput
          LeftAdornment={SearchIcon}
          placeholder={'Search fields'}
          defaultValue=""
          onClick={(e: any) => e.stopPropagation()}
          onChange={(e: any) => {
            debouncedSearch(e.target.value)
          }}
          containerProps={{
            style: { backgroundColor: 'white' },
          }}
          data-cy="searchFields"
        />
      </SearchDiv>
      <ClientDataTable
        title="fieldCleanser"
        searchText={search}
        controls={false}
        columns={[
          { name: 'input_field_name', label: 'Input Field(s)' },
          { name: 'cleansing_method', label: 'Cleansing Method' },
        ]}
        expandableRows={false}
        data={data}
      />
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  border-radius: 0.25rem;
`

const SearchDiv = styled.div`
  padding: 0.5rem;
`
