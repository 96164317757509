import { SimpleCheckbox } from '../../components/Checkbox'
import styled from 'styled-components'
import { colors } from '../../context/theme-context/'

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0.75rem 0em 1rem;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  font-size: 0.875rem;
`

const StyledSpan = styled.span`
  cursor: pointer;
  font-weight: 400;
  color: ${colors.blue6};
`

export default function TreeButtons({
  selectAll,
  clearAll,
  selectedOnly = false,
  expandedNodes = [],
  toggleSelectedOnly = null,
  isPopup = false,
  allSelected,
  ...rest
}: any) {
  return (
    <Container {...rest}>
      {isPopup ? (
        <StyledSpan
          onClick={() => {
            toggleSelectedOnly()
          }}
        >
          {selectedOnly ? 'Show All' : 'Show Selected'}
        </StyledSpan>
      ) : (
        <SimpleCheckbox
          selected={allSelected}
          onClick={() => {
            if (!allSelected) {
              selectAll()
            } else {
              clearAll()
            }
          }}
          label="Select All"
          textProps={{ style: { color: colors.gray11 } }}
        />
      )}
      <StyledSpan
        onClick={() => {
          clearAll()
        }}
        data-cy="clearAll"
      >
        Clear All
      </StyledSpan>
    </Container>
  )
}
