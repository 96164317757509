import Terms from './TermsScreen/Terms'
import AuthPage from './AuthPage'
import styled from 'styled-components'

const TermsScreen = () => (
  <AuthPage
    title="NetWise Platform Terms of Use"
    containerProps={{ maxWidth: 'sm' }}
  >
    <TermsContainer>
      <Terms />
    </TermsContainer>
  </AuthPage>
)

const TermsContainer = styled.div`
  margin-top: 2rem;
`
export default TermsScreen
