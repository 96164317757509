import AudiencesNav from './TopNav/AudiencesNav'
import UploadsNav from './TopNav/UploadsNav'
import ExportsNav from './TopNav/ExportsNav'
import AdminNav from './TopNav/AdminNav'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import Modes from './TopNav/Modes'

interface AppLinksI {
  selected: any
  toggleDrawer?: any
  canViewAdmin: any
  canViewModes: any
  saveIsCurrent?: any
  setSaveIsCurrent?: any
  smallScreen?: boolean
}

const AppLinks: React.FC<AppLinksI> = ({
  selected,
  toggleDrawer,
  canViewAdmin,
  canViewModes,
  saveIsCurrent,
  setSaveIsCurrent,
  smallScreen,
}) => {
  return (
    <Container>
      <LinkBar smallScreen={smallScreen}>
        {smallScreen && (
          <ButtonContainer>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeft />
              <span style={{ fontSize: '1rem' }}>back</span>
            </IconButton>
          </ButtonContainer>
        )}
        <AudiencesNav
          selected={selected}
          smallScreen={smallScreen}
          saveIsCurrent={saveIsCurrent}
          setSaveIsCurrent={setSaveIsCurrent}
        />
        <UploadsNav selected={selected} smallScreen={smallScreen} />
        <ExportsNav selected={selected} smallScreen={smallScreen} />
        {canViewAdmin && <AdminNav selected={selected} />}
        {canViewModes && smallScreen && (
          <ModesContainer>
            <Modes />
          </ModesContainer>
        )}
      </LinkBar>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`
const LinkBar = styled.div<{ smallScreen?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: ${(props) => (props.smallScreen ? 'column' : 'row')};
`
const ButtonContainer = styled.div`
  display: flex;
  width: 12rem;
  justify-content: flex-end;
  align-items: center;
`
const ModesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
`

export default AppLinks
