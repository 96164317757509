import ToolTip from './Tooltip'
import { ReactComponent as QuestionMark } from '../icons/16px/Question.svg'

interface Props {
  text: string
  'data-cy'?: string
}

export default function QuestionTooltip(props: Props) {
  return (
    <div
      data-cy={props['data-cy']}
      style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}
    >
      <ToolTip title={props.text}>
        <QuestionMark height={16} width={16} />
      </ToolTip>
    </div>
  )
}
