import React from 'react'
import Dialog from './Dialog'
import ConfirmationBody from './RequestUserConfirmation/ConfirmationBody'

type Props = {
  Component: React.ReactElement<{ onClick: () => any }>
  text: JSX.Element | string
  Header?: JSX.Element
  dialogStyle?: React.CSSProperties
  requestTrigger?: boolean
  disableButtons?: boolean
  onOpenCallback?: () => void
}

export default function RequestUserConfirmation(props: Props) {
  // Defaults
  const { dialogStyle = { height: 150 } } = props
  const { Header = <span>Confirm Action</span> } = props
  const { requestTrigger = true, disableButtons = false } = props

  // Dialog State
  const [isOpen, setIsOpen] = React.useState(false)

  // Cloning the element so that the onClick event handler can be
  // overrriden in order to open the dialog. The previous onClick
  // event handler will be triggered on confirmation.
  const ClonedElement = React.cloneElement(props.Component, {
    ...props.Component.props,
    onClick: () => {
      props?.onOpenCallback?.()
      setIsOpen(true)
    },
  })

  const Body = (
    <ConfirmationBody
      setIsOpen={setIsOpen}
      text={props.text}
      onClick={props.Component.props.onClick}
      disableButtons={disableButtons}
    />
  )

  return requestTrigger ? (
    <div>
      {ClonedElement}
      <Dialog
        surfaceStyle={dialogStyle}
        isOpen={isOpen}
        closeHandler={() => setIsOpen(false)}
        Body={Body}
        Header={Header}
        animated={true}
      />
    </div>
  ) : (
    props.Component
  )
}
