import React from 'react'
import UserTable from './Admin/UserTable'
import Skeleton from 'react-loading-skeleton'
import { useAuth } from 'context/auth-context'
import { numericHumanReadable } from 'utils/numericHelpers'
import { useAlerts } from 'context/alert-context'
import { useParams } from 'react-router-dom'
import { Account, User } from 'types'

export default function UserDetail() {
  // Hooks
  const { client, userData } = useAuth()
  const { id } = useParams<{ id?: string }>()

  // State
  const [account, setAccount] = React.useState<Account>()
  const [effectCounter, setEffectCounter] = React.useState(0)
  const { sendAlert } = useAlerts()

  // Derived Variables
  const accountId = userData.isNetWiseAccount && !!id ? id : userData.account.id

  React.useEffect(() => {
    async function getSetAccount() {
      try {
        const { responseData } = await client.get({
          endpoint: client.endpoints.accountsIdAPI(accountId),
          data: { usage: true },
        })
        const { results: accountData } = responseData
        setAccount(accountData)
      } catch (e) {
        console.log('Could not retrieve data at this time.')
      }
    }
    getSetAccount()
  }, [client, effectCounter, userData, accountId])

  function triggerUpdate() {
    setEffectCounter((state: number) => state + 1)
  }

  async function changeStatus(user: User, payload = {}) {
    try {
      await client.put({
        endpoint: client.endpoints.usersIdAPI(user.id),
        data: payload,
      })
      triggerUpdate()
    } catch (e) {
      sendAlert({ message: 'Could not update user. Please try again later.' })
    }
  }

  function makeUserTable(acct: Account) {
    const { name, credits_unlimited, credits } = acct
    const { credit_schedule, users, id, expires } = acct
    const { active, credits: intervalCredits, interval, next } = credit_schedule

    const expirationDate = new Date(expires.raw)
    const expired = expirationDate < new Date()

    const fmtCredits = credits_unlimited
      ? 'No Limit'
      : numericHumanReadable(credits, 0)

    const scheduleInterval = ({
      monthly: 'month',
      quarterly: 'quarter',
      biannually: '6 months',
      annually: 'year',
    } as any)[interval as string]

    const creditSchedule = active
      ? `, (credit schedule: ${numericHumanReadable(intervalCredits, 0)} ` +
        `per ${scheduleInterval}, next update ${next?.local})`
      : ''

    const title =
      `${name} - Credits: ${fmtCredits}${creditSchedule}. ` +
      `Users: ${numericHumanReadable(users?.length || 0, 0)}`

    return (
      <div>
        <UserTable
          triggerUpdate={triggerUpdate}
          account_id={id}
          title={title}
          users={users}
          changeStatus={changeStatus}
          Expiration={
            <span style={{ color: expired ? 'red' : 'black' }}>
              {'Expiration: ' +
                expirationDate.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
            </span>
          }
        />
      </div>
    )
  }

  const UI = account?.id ? makeUserTable(account) : <Skeleton height={300} />
  return <div style={{ padding: '1rem' }}>{UI}</div>
}
