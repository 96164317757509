import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { colors } from '../../../../context/theme-context'

export default async function printPdf(audienceName: any) {
  const printDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
  const pdfName = `NetWise Platform Audience Report - ${audienceName} (${printDate})`
  const pxScale = 2 // Pixel scaling (sharpness)
  const reportHTML = document.getElementById('audienceReport')
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  reportHTML.setAttribute('style', 'display: flex')
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'offsetWidth' does not exist on type 'HTM... Remove this comment to see the full error message
  const { offsetWidth: width, offsetHeight: height } = reportHTML
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
  const canvas = await html2canvas(reportHTML, {
    scale: pxScale,
    width,
    height,
  })
  const img = canvas.toDataURL()
  const pdf = new jsPDF({ unit: 'px', format: [width, height] })
  pdf.addImage(img, 'JPEG', 0, 0, width, height, 'report', 'FAST')
  pdf.setTextColor(colors.blue9)
  pdf.setFont('helvetica', 'bold')
  pdf.textWithLink('View Audience', width - 98, 48, {
    url: window.location.href,
  })
  pdf.save(pdfName)
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  reportHTML.setAttribute('style', 'display: none')
}
