import colors from '../../../../../context/theme-context/colors'

export default function TabPanel({ idx, currentTab, children }: any) {
  const selected = currentTab === idx
  return (
    <div
      style={{ fontWeight: 'bold', borderBottom: 'none' }}
      role="tabpanel"
      hidden={!selected}
    >
      {selected && (
        <div
          style={{
            backgroundColor: colors.gray1,
            border: `1px solid ${colors.gray5}`,
            borderTop: 'none',
          }}
        >
          {children}
        </div>
      )}
    </div>
  )
}
