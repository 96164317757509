import jwt_decode from 'jwt-decode'

const decodeToken = (jwt: string) => {
  const decoded: { exp: number } = jwt_decode(jwt)
  return decoded
}

const getHost = () => {
  let host = process.env.REACT_APP_ENV
  const protocol = process.env.NODE_ENV === 'production' ? 'https://' : 'http://' 
  if (typeof window !== 'undefined') {
    host = window.location.hostname
  }
  return protocol + host
}

const getPort = () => {
  const port = process.env.REACT_APP_ENV === 'localhost' ? ':8000' : ''
  return port
}

const getURLPrefix = () => {
  const host = getHost()
  const port = getPort()
  return host + port
}

export { decodeToken, getHost, getPort, getURLPrefix }
