import { LayerContainer, LayerHeader, StyledClose, EditSpan } from './Css'
import SimpleInput from '../../../../../../components/SimpleInput'

export default function LayerLogic({
  joins,
  updateFileId,
  filter_exclude_matches,
  selectedMatchType = 'Exact Field Match',
  filename = 'No Name',
  handleDelete,
}: any) {
  return (
    <LayerContainer>
      <LayerHeader>
        <div style={{ display: 'flex' }}>Layer Logic</div>
        <div style={{ display: 'flex' }}>
          <EditSpan
            onClick={() => {
              updateFileId()
            }}
          >
            Edit
          </EditSpan>
          <StyledClose onClick={handleDelete} />
        </div>
      </LayerHeader>
      <SimpleInput
        value={filter_exclude_matches}
        onChange={() => null}
        disabled
        containerProps={{ style: { marginBottom: '1rem' } }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
        <SimpleInput
          value={filename}
          label="Input File"
          disabled
          onChange={() => null}
        />
        <SimpleInput
          label="Join Field"
          value={joins.map((join: any) => join.file_field).join(', ')}
          onChange={() => null}
          disabled
        />
      </div>
    </LayerContainer>
  )
}
