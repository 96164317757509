import styled, { css } from 'styled-components'
import { colors } from '../../../../../context/theme-context/'
import QuestionTooltip from '../../../../../components/QuestionTooltip'

interface Props {
  clearWarning: () => void
  matchEnabled: boolean
  setMatchEnabled: (matchEnabled: boolean) => void
}

export default function OnOff({
  clearWarning,
  matchEnabled,
  setMatchEnabled,
}: Props) {
  function toggleOn(e: any) {
    if (matchEnabled) {
      e.stopPropagation()
    }
    setMatchEnabled(true)
  }

  function toggleOff(e: any) {
    if (!matchEnabled) {
      e.stopPropagation()
    }
    clearWarning()
    setMatchEnabled(false)
  }

  return (
    <ButtonContainer>
      <Button left active={matchEnabled} onClick={toggleOn} data-cy="fileMatchOn">
        <span>On</span>
      </Button>
      <Button
        right
        active={!matchEnabled}
        onClick={toggleOff}
        data-cy="fileMatchOff"
      >
        <span>Off</span>
      </Button>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          paddingLeft: '0.5rem',
        }}
      >
        <QuestionTooltip
          text="Turn Exact Match On / Off"
          data-cy="fileMatchTooltip"
        />
      </div>
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-content: center;
  line-height: 1rem;
`

const Button = styled.div<{ left?: boolean; right?: boolean; active?: boolean }>`
  padding: 0rem 0.5rem;
  display: flex;
  font-size: 0.75rem;
  align-content: center;
  font-weight: 400;
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  border-radius: 0.25rem;
  ${(props) =>
    props.active &&
    css`
      background-color: ${colors.blue1};
      border: 1px solid ${colors.blue2};
      border-radius: 0.25rem;
    `}
  ${(props) =>
    props.left &&
    css`
      border-radius: 0.25rem 0rem 0rem 0.25rem;
      border-right: 0;
    `};
  ${(props) =>
    props.right &&
    css`
      border-radius: 0rem 0.25rem 0.25rem 0rem;
    `};
`
