import { ListAccordion } from '../../../../../components/Accordion'
import List from '@material-ui/core/List'
import { SimpleCheckbox } from '../../../../../components/Checkbox'
import { toTitleCase } from '../../../../../utils/caseHelpers'

export default function Firmographics({
  company_employees,
  company_revenue,
  domain,
  industry,
  updateFilter,
  divOnly = false,
}: any) {
  function ListItem(key: any, itemBool = false, addnlUpdate = {}) {
    return (
      <li
        key={key}
        onClick={() => updateFilter({ [key]: !itemBool, ...addnlUpdate })}
        style={{ margin: '0.5rem 0rem' }}
      >
        <SimpleCheckbox selected={itemBool} label={toTitleCase(key)} />
      </li>
    )
  }

  const count =
    (company_employees || 0) +
    (company_revenue || 0) +
    (domain || 0) +
    (industry || 0)

  const Filter = (
    <List style={{ padding: 0 }}>
      {ListItem('company_employees', company_employees)}
      {ListItem('company_revenue', company_revenue)}
      {ListItem('domain', domain)}
      {ListItem('industry', industry)}
    </List>
  )

  return (
    <ListAccordion
      title="Required Firmographics"
      count={count || ''}
      divOnly={divOnly}
    >
      {Filter}
    </ListAccordion>
  )
}
