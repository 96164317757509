import React from 'react'
import StandardDialog from 'components/StandardDialog'
import FeatureAccessDialogRow from './FeatureAccessDialogRow'
import { Account } from 'types'

export type Props = {
  isOpen: boolean
  handleClose: (value: React.SetStateAction<boolean>) => void
  selectedAccount: Account | null
  updateAccount: (id: number, data: any) => Promise<void>
}

export default function FeatureAccessDialog(props: Props) {
  const appendsActive = props.selectedAccount?.enable_appends || false
  const uploadDunsActive = props.selectedAccount?.enable_upload_duns || false
  const exportDunsActive = props.selectedAccount?.enable_export_duns || false
  const handleClick = (enableAppends: boolean, enableUploadDuns: boolean, enableExportDuns: boolean) => {
    const accountData = {
      enable_appends: enableAppends,
      enable_upload_duns: enableUploadDuns,
      enable_export_duns: enableExportDuns,
    }
    if (props.selectedAccount?.id) {
      props.updateAccount(props.selectedAccount.id, accountData)
    }
  }

  const appendsHandler = (enable: boolean) => {
    handleClick(enable, uploadDunsActive, exportDunsActive)
  }

  const uploadDunsHandler = (enable: boolean) => {
    handleClick(appendsActive, enable, exportDunsActive)
  }

  const exportDunsHandler = (enable: boolean) => {
    handleClick(appendsActive, uploadDunsActive, enable)
  }

  return (
    <StandardDialog
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      title="Feature Access"
      data-cy="featureAccessDialog"
      Content={
        <>
          <FeatureAccessDialogRow id="matchFieldAppends" title="Matched Field Appends" handleClick={appendsHandler} active={appendsActive} tooltipText="Add the upload record that we matched on into your export."/>
          <FeatureAccessDialogRow id="uploadDuns" title="Upload D-U-N-S Number files" handleClick={uploadDunsHandler} active={uploadDunsActive} tooltipText="Upload a file with D-U-N-S Numbers and match against them in our database."/>
          <FeatureAccessDialogRow id="exportDuns" title="Export D-U-N-S Number files" handleClick={exportDunsHandler} active={exportDunsActive} tooltipText="Export D-U-N-S Number data or data that is matched against it."/>
        </>
      }
      dialogStyle={{ padding: '1rem' }}
    />
  )
}
