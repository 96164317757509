import Checkbox from 'components/Checkbox'
import SimpleInput from 'components/SimpleInput'
import { AdvancedInformation } from '../adminTypes'
import InputSubtitle from './InputSubtitle'

interface Props {
  advancedInfo: AdvancedInformation
  updateInfo: (update: Record<'advancedInformation', any>) => void
}

const AdvancedInfo: React.FC<Props> = ({ advancedInfo, updateInfo }) => {
  // Wraps update function with proper key
  const wrapper = (update: Record<string, any>) => {
    updateInfo({ advancedInformation: { ...advancedInfo, ...update } })
  }

  return (
    <>
      <h3>Advanced Information</h3>

      <div>
        <Checkbox
          label="Credits Unlimited"
          status={advancedInfo.creditsUnlimited}
          data-cy="creditsUnlimited"
          onClick={() =>
            wrapper({
              creditsUnlimited: !advancedInfo.creditsUnlimited,
            })
          }
        />
        <InputSubtitle>
          Grant account unlimited credits, overrides all other account credit
          restrictions
        </InputSubtitle>
      </div>

      <div>
        <Checkbox
          label="Credits Unlimited Anonymized"
          status={advancedInfo.creditsUnlimitedAnonymized}
          data-cy="creditsUnlimitedAnonymized"
          onClick={() =>
            wrapper({
              creditsUnlimitedAnonymized: !advancedInfo.creditsUnlimitedAnonymized,
            })
          }
        />
        <InputSubtitle>
          Grant account unlimited credits for anonymized layout exports, these
          records are not tracked for record ownership
        </InputSubtitle>
      </div>

      <div>
        <SimpleInput
          label="S3 Delivery Folder"
          value={advancedInfo.s3DeliveryFolder}
          error={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            wrapper({ s3DeliveryFolder: e.target.value })
          }
          data-cy="s3DeliveryFolder"
        />
        <InputSubtitle>
          optional, i.e. "s3://nwd-3rd-party-storage/153/"
        </InputSubtitle>
      </div>

      <div>
        <SimpleInput
          label="Record Ownership Retention Days"
          type="number"
          data-cy="recordOwnershipRetentionDays"
          value={advancedInfo.ownershipRetentionDays}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            wrapper({ ownershipRetentionDays: e.target.value })
          }
        />
      </div>

      <div>
        <Checkbox
          label="Disable Uploads"
          status={advancedInfo.disableUploads}
          data-cy="disableUploads"
          onClick={() =>
            wrapper({
              disableUploads: !advancedInfo.disableUploads,
            })
          }
        />
        <InputSubtitle>
          Revoke ability for account to upload files (and the account's access File
          Uploads page)
        </InputSubtitle>
      </div>

      <div>
        <Checkbox
          label="Suppress from CRM"
          status={advancedInfo.suppressFromCRM}
          data-cy="suppressFromCRM"
          onClick={() =>
            wrapper({
              suppressFromCRM: !advancedInfo.suppressFromCRM,
            })
          }
        />
        <InputSubtitle>
          Don't track users in our CRM (synthetic tests and test/demo accounts)
        </InputSubtitle>
      </div>
    </>
  )
}

export default AdvancedInfo
