import styled from 'styled-components'

export default function ExportsLink({ action }: any) {
  return (
    <Container onClick={action}>
      File exporting, <FauxLink>view status</FauxLink>
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
`

const FauxLink = styled.span`
  text-decoration: underline;
`
