import RemoteDataTable from 'components/RemoteDataTable'
import { useAuth } from 'context/auth-context'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'context/alert-context'
import { useSnackbar } from 'context/snackbar-context'

export default function FileExportHistory() {
  const { client, adminMode } = useAuth()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage } = useSnackbar()
  const { id } = useParams<{ id: string }>()

  const url = client.endpoints.exportsTableAPI
  const columns = [
    { name: 'name', label: 'File Export Name' },
    { name: 'user', label: 'User' },
    { name: 'email', label: 'Email' },
    { name: 'updated', label: 'Exported' },
  ]
  const customRowRender = (row: any) => {
    const customRow = row.map((item: any) => {
      const { first_name, last_name, email } = item.modified.user
      const user = `${first_name} ${last_name}`

      const { raw } = item.modified.timestamp
      const updated = new Date(raw).toLocaleString()
      return { ...item, user, email, updated }
    })
    return customRow
  }

  return (
    <div style={{ padding: '1rem' }}>
      <RemoteDataTable
        client={client}
        adminMode={adminMode}
        sendAlert={sendAlert}
        setSnackbarMessage={setSnackbarMessage}
        url={url}
        urlParams={{
          account_id: id,
        }}
        columns={columns}
        customRowRender={customRowRender}
        testId="exportSearch"
      />
    </div>
  )
}
