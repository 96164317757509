import RemoteDataTable from 'components/RemoteDataTable'
import Button from 'components/Button'
import { useAuth } from 'context/auth-context'
import { useAlerts } from 'context/alert-context'
import { useSnackbar } from 'context/snackbar-context'
import { Account } from 'types'
import Dialog from 'components/Dialog'

type Props = {
  isOpen: boolean
  handleClose: () => void
}
export default function AssumeUserDialog(props: Props) {
  const { adminMode, client, assumeUser } = useAuth()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage } = useSnackbar()
  const { isOpen, handleClose } = props

  const Content = (
    <div
      style={{
        minWidth: 700,
        height: 400,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <RemoteDataTable
        adminMode={adminMode}
        client={client}
        sendAlert={sendAlert}
        setSnackbarMessage={setSnackbarMessage}
        url={client.endpoints.usersAPI}
        urlParams={{ active: 1 }}
        rowsOnPage={5}
        refreshAtInterval={false}
        columns={[
          {
            name: 'assume_user',
            label: 'Assume',
            options: {
              sort: false,
            },
          },
          {
            name: 'first_name',
            label: 'First Name',
            options: {
              sort: false,
            },
          },
          {
            name: 'last_name',
            label: 'Last Name',
            options: {
              sort: false,
            },
          },
          {
            name: 'email',
            label: 'Email',
            options: {
              sort: false,
            },
          },
          {
            name: 'admin',
            label: 'Is Administrator',
            options: {
              sort: false,
            },
          },
        ]}
        customRowRender={(data: any) => {
          const customData = data.map((row: Account) => {
            const updatedRow = row as any
            updatedRow.admin = (<span>{row.admin ? 'Yes' : 'No'}</span>) as any
            updatedRow['assume_user'] = (
              <Button
                text="Assume"
                size="small"
                variant="secondary"
                onClick={() => {
                  assumeUser(row.id)
                  handleClose()
                }}
                testId="assumeUser"
              />
            )
            return updatedRow
          })
          return customData
        }}
      />
    </div>
  )

  return (
    <Dialog
      Header={<span>Select User to Assume</span>}
      Body={Content}
      isOpen={isOpen}
      closeHandler={handleClose}
    />
  )
}
