import styled from 'styled-components'

interface Props {
  text: string
  backgroundColor: string
  hoverBackgroundColor: string
  activeBackgroundColor: string
  borderColor: string
  onMouseOver?: any
  onMouseLeave?: any
  onClick?: any
  disableExpand?: boolean
  'data-cy'?: string
}

function ExpandingButton(props: Props) {
  return (
    <StyledDiv
      onClick={props?.onClick}
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
      backgroundColor={props.backgroundColor}
      borderColor={props.borderColor}
      hoverBackgroundColor={props.hoverBackgroundColor}
      activeBackgroundColor={props.activeBackgroundColor}
      disableExpand={props.disableExpand}
      data-cy={props['data-cy']}
    >
      <span>{props.text}</span>
    </StyledDiv>
  )
}

export default ExpandingButton

interface StyledProps {
  backgroundColor: string
  borderColor: string
  hoverBackgroundColor: string
  activeBackgroundColor: string
  disableExpand?: boolean
}
const StyledDiv = styled.div<StyledProps>`
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-radius: 1rem;
  width: ${(props) => (props.disableExpand ? '7ch' : '1ch')};
  background-color: ${(props) => String(props.backgroundColor)};
  border: 1px solid ${(props) => String(props.borderColor)};
  cursor: pointer;
  transition: all 0.2s;
  color: ${(props) =>
    props.disableExpand ? String(props.hoverBackgroundColor) : 'rgba(0, 0, 0, 0)'};
  :hover {
    width: 7ch;
    color: ${(props) => String(props.hoverBackgroundColor)};
  }
  :active {
    background-color: ${(props) => String(props.activeBackgroundColor)};
  }
`
