import { useEffect, useState } from 'react'
import { useAuth } from '../../../context/auth-context'
import StandardDialog from '../../../components/StandardDialog'
import ClientDataTable from '../../../components/ClientDataTable'

export default function PreviewFile({ previewFileId, isOpen, setIsOpen }: any) {
  const { client } = useAuth()
  const ep = client.endpoints
  const [preview, setPreview] = useState({ rows: null, columns: null })

  useEffect(() => {
    async function getFilePreview() {
      if (previewFileId) {
        setPreview({ rows: null, columns: null })
        const {
          responseData: {
            results: { preview: _preview = {} },
          },
        } = await client.get({ endpoint: ep.filePreviewAPI(previewFileId) })
        setPreview(_preview)
      }
    }
    getFilePreview()
  }, [previewFileId, client, ep.filePreviewAPI, ep])

  const { rows = null, columns = null } = preview

  return (
    <div style={{ margin: '1em', flex: 1 }}>
      <StandardDialog
        isOpen={isOpen}
        title="File Preview"
        handleClose={() => {
          setIsOpen(false)
        }}
        Content={
          <ClientDataTable
            title="previewFile"
            data={rows}
            columns={columns}
            csvControl={true}
            data-cy={'previewFile'}
          />
        }
      />
    </div>
  )
}
