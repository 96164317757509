import React, { useState, useRef, useEffect } from 'react'
import { ReactComponent as Plus } from '../../icons/16px/Plus.svg'
import { ReactComponent as Minus } from '../../icons/16px/Minus.svg'
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  Title,
  Count,
  BottomBorder,
} from './ListCss'

interface ListAccordionI {
  title: any
  count?: any
  children?: any
  summaryProps?: any
  iconProps?: any
  expanded?: any
  bottomBorder?: any
  containerProps?: any
  warningText?: any
  divOnly?: boolean
}

const ListAccordion: React.FC<ListAccordionI> = ({
  title,
  count = null,
  children,
  summaryProps = {},
  iconProps = {},
  expanded = false,
  bottomBorder = true,
  containerProps = {},
  warningText = '',
  divOnly = false,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded)
  const ExpandIcon = isExpanded ? Minus : Plus

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  const summaryRef = useRef(null)
  const bodyRef = useRef(null)

  if (divOnly) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '0.875rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '1rem',
          }}
        >
          <Title expanded={isExpanded}>{title}</Title>
          <Count>{count}</Count>
          <span style={{ width: '6ch' }} />
        </div>
        <div>{children}</div>
      </div>
    )
  }

  return (
    <div {...containerProps}>
      <StyledAccordion expanded={isExpanded} defaultExpanded={expanded}>
        <StyledAccordionSummary
          onClick={() => setIsExpanded((state: any) => !state)}
          expandIcon={<ExpandIcon {...iconProps} />}
          {...summaryProps}
          ref={summaryRef}
          id={title}
          {...rest}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Title expanded={isExpanded}>{title}</Title>
              <Count>{count}</Count>
            </div>
            <div style={{ display: 'flex' }}>{warningText}</div>
          </div>
        </StyledAccordionSummary>
        <StyledAccordionDetails ref={bodyRef}>{children}</StyledAccordionDetails>
      </StyledAccordion>
      {bottomBorder && <BottomBorder />}
    </div>
  )
}

export default ListAccordion
