import React from 'react'
import SimpleInput from '../../components/SimpleInput'
import { ReactComponent as Search } from '../../icons/16px/Search.svg'
import { debounce } from '../../utils/debounce'

export default function TreeSearch({ filterNodes }: any) {
  const debouncedSearch = debounce(filterNodes, 500)
  return (
    <SimpleInput
      LeftAdornment={Search}
      defaultValue=""
      placeholder={'Search'}
      onChange={debouncedSearch}
    />
  )
}
