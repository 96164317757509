import styled from 'styled-components'
import { toTitleCase } from 'utils/caseHelpers'
import SimpleInput from 'components/SimpleInput'
import StandardAutocomplete from 'components/StandardAutocomplete'

export interface LayoutInfoState {
  layoutName: string
  privacy: string
}

interface Props {
  layoutName: string
  privacy: string
  setLayoutInfoState: (key: string, value: string) => void
}

export default function UploadLayoutInfo(props: Props) {
  return (
    <div>
      <InitialInputs>
        <div style={{ flex: 1 }}>
          <SimpleInput
            label="Layout Name"
            value={props.layoutName}
            onChange={(e: any) => {
              props.setLayoutInfoState('layoutName', e.target.value)
            }}
            multiple={false}
            data-cy="upload-layout-name"
          />
        </div>
        <div>
          <StandardAutocomplete
            label="Privacy"
            value={toTitleCase(props.privacy)}
            tagSuggestions={['private', 'team_view', 'team_edit']}
            onChange={(_: any, value: any) => {
              props.setLayoutInfoState('privacy', value)
            }}
            autocompleteProps={{
              getOptionLabel: (value: any) => toTitleCase(value),
              filterOptions: (options: any) => options,
              getOptionSelected: (opt: any, value: any) =>
                toTitleCase(opt) === value,
            }}
            multiple={false}
            testId="layoutPrivacy"
          />
        </div>
      </InitialInputs>
    </div>
  )
}

const InitialInputs = styled.div`
  display: flex;
  column-gap: 1rem;
  padding: 1rem 0rem;
  align-items: center;
`
