interface Props {
  exportState: any
  hash: string
  dialogType: 'create' | 'edit' | 'quick' | 'run' | 'view'
  appliedFilters?: any
  audienceInfo?: any
}

const createPayload = ({
  hash,
  exportState,
  dialogType,
  appliedFilters,
  audienceInfo,
}: Props) => {
  const staticSettings = {
    file_name: exportState.file_name,
    favorite: exportState.favorite,
    name: exportState.name,
    partition_by: exportState.partition_by,
    prioritize_segments: exportState.prioritize_by,
    delimiter: exportState.delimiter,
    quotes: exportState.quotes,
    strip_delimiter_and_quotes: exportState.strip_delimiter_and_quotes,
    compression: exportState.compression,
    header: exportState.header,
    privacy: exportState.privacy,
    layout: exportState.layout,
    description: exportState.description,
    audience: exportState.audience === 'None' ? null : exportState.audience,
    enable_appends: exportState.enable_appends,
  }

  let additional_audience
  let dynamicSettings = {
    email_notifications:
      exportState.email_notifications == null ? [] : exportState.email_notifications,
    record_limit: exportState.record_limit
      ? parseInt(exportState.record_limit, 10)
      : null,
    record_limit_per_company: exportState.record_limit_per_company
      ? parseInt(exportState.record_limit_per_company, 10)
      : null,
    jira_ticket: null,
  }

  switch (dialogType) {
    case 'run':
      additional_audience = audienceInfo?.audience_id
        ? {
            ...appliedFilters,
            audience_id: audienceInfo?.audience_id,
            audience_name: audienceInfo?.audience_name,
          }
        : appliedFilters
      dynamicSettings['jira_ticket'] = exportState.jira_ticket
      break

    default:
      break
  }

  const settings = { ...staticSettings, ...dynamicSettings }
  const payload: any = { export: hash, additional_audience, settings }

  if (['edit', 'create'].includes(dialogType)) {
    return settings
  }

  return payload
}

export default createPayload
