import Button from '../Button'
import Tooltip from '../Tooltip'
import SimpleInput from '../SimpleInput'
import { ReactComponent as SearchIcon } from '../../icons/16px/Search.svg'
import { ReactComponent as CopyIcon } from '../../icons/24px/Copy.svg'
import { ReactComponent as ExcelIcon } from '../../icons/24px/Excel.svg'
import { ReactComponent as DeleteIcon } from '../../icons/24px/Delete.svg'
import { ReactComponent as ExMark } from '../../icons/16px/Exmark.svg'
import { debounce } from '../../utils/debounce'
import { colors } from '../../context/theme-context/'
import React, { useState } from 'react'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import CsvDownload from 'react-json-to-csv'
import { SimpleCheckbox } from '../Checkbox'
import StandardAutocompleteRemote from '../StandardAutocompleteRemote'
import { useAuth } from '../../context/auth-context'
import { ButtonStyledLink } from 'components/Button/CSS'

interface Props {
  data?: any
  setSearchText?: any
  setColumns?: any
  setAutosaveDisplay?: any
  autosaveControl?: any
  debounceDelay?: any
  deleteControl?: any
  deleteAction?: any
  searchControl?: any
  excelControl?: any
  csvControl?: any
  csvFilename?: any
  valueToText?: any
  copyControl?: any
  selectLayout?: any
  warningControl?: any
  customControls?: any
  TitleComponent?: any
  rowsAreSelected?: boolean
  'data-cy'?: string
}

export default function Controls(props: Props) {
  const {
    data = null,
    setSearchText,
    setColumns = () => {},
    setAutosaveDisplay = () => {},
    autosaveControl = false,
    debounceDelay = 500,
    deleteAction = () => {},
    deleteControl = false,
    searchControl = false,
    excelControl = false,
    csvControl = false,
    warningControl = false,
    customControls = false,
    csvFilename = null,
    copyControl = false,
    valueToText = (v: any) => v,
    selectLayout = null,
    TitleComponent = null,
    rowsAreSelected = false,
  } = props

  const { client } = useAuth()
  const { layoutsAPI } = client.endpoints
  const debouncedSearch = debounce(setSearchText, debounceDelay)
  const [layoutValue, setLayoutValue] = useState('')

  const csvData = data?.map((row: any) => {
    const newRow = {}
    for (const [key, value] of Object.entries(row)) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newRow[key] = valueToText(value)
    }
    return newRow
  })

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {TitleComponent && <TitleComponent />}
      {selectLayout && !warningControl ? (
        <StandardAutocompleteRemote
          url={layoutsAPI}
          value={layoutValue}
          valueFilterField={'name'}
          additionalAPIparams={{
            sort: ['-last_used', '-modified'],
            dropdown_view: true,
          }}
          placeholder="Select Layout"
          data-cy="selectLayout"
          onChange={(_: any, obj: any) => {
            setLayoutValue(obj)
            setColumns(obj)
          }}
          multiple={false}
          style={{ margin: 0, width: '275px' }}
          autocompleteProps={{
            getOptionLabel: (x: any) => {
              if (Array.isArray(x)) return ''
              return x.name
            },
            getOptionSelected: (option: any, value: any) => {
              return option.name === value.name
            },
          }}
        />
      ) : (
        <div />
      )}
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {warningControl && (
          <>
            <Tooltip
              title={`Changes made but not applied. Hit "u" key or click "Apply Changes" button to apply updates.`}
            >
              <ExMark
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
                className={null}
                style={{
                  height: '24px',
                  width: '24px',
                  fill: colors.orange7,
                }}
              />
            </Tooltip>
          </>
        )}
        {customControls &&
          customControls.map((Control: any, i: any) => <Control key={i} />)}
        {autosaveControl && (
          <SimpleCheckbox
            label="Display Autosaved"
            onClick={() => {
              setAutosaveDisplay((state: any) => !state)
            }}
            data-cy="displayAutosaved"
          />
        )}
        {deleteControl && (
          <span>
            <Button
              icon={DeleteIcon}
              text="Delete"
              disabled={!rowsAreSelected}
              variant={'delete'}
              size="medium"
              id="delete_button"
              style={{ padding: '0.5rem' }}
              onClick={deleteAction}
              testId="deleteButton"
            />
          </span>
        )}
        {copyControl && (
          <span>
            <Button
              onClick={() => null}
              icon={CopyIcon}
              text="Copy"
              variant="secondary"
              size="medium"
              disabled={warningControl}
              style={{ padding: '0.5rem' }}
              testId="copyButton"
            />
          </span>
        )}
        {csvControl && (
          <span>
            <CsvDownload
              data={csvData}
              filename={csvFilename}
              style={{
                padding: 0,
                backgroundColor: 'none',
                border: 'none',
                color: 'none',
              }}
            >
              <ButtonStyledLink
                onClick={() => null}
                variant="secondary"
                size="medium"
                style={{
                  fontWeight: 800,
                }}
                disabled={warningControl}
                data-cy="downloadCSV"
              >
                CSV
              </ButtonStyledLink>
            </CsvDownload>
          </span>
        )}
        {excelControl && (
          <span>
            <Button
              onClick={() => null}
              text="Excel"
              icon={ExcelIcon}
              variant="secondary"
              size="medium"
              disabled={warningControl}
              style={{ padding: '0.5rem' }}
              testId="excelButton"
            />
          </span>
        )}
        {searchControl && (
          <SimpleInput
            data-cy={props['data-cy'] + 'searchbox'}
            LeftAdornment={SearchIcon}
            placeholder={'Search'}
            defaultValue=""
            disabled={warningControl}
            onClick={(e: any) => e.stopPropagation()}
            onChange={(e: any) => {
              debouncedSearch(e.target.value)
            }}
          />
        )}
      </div>
    </div>
  )
}
