import ContactPoints from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/ContactPoints'
import Keywords from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/Keywords'
import Seniority from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/Seniority'
import JobFunction from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/JobFunction'
import LocationSelection from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/LocationSelection'
import Firmographics from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/Firmographics'
import DomainsAndKeywords from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/DomainsAndKeywords'
import Industry from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/Industry'
import Revenue from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/Revenue'
import CompanySize from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/CompanySize'
import CompanyLocation from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/CompanyLocation'
import AdvancedRequiredFields from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/AdvancedRequiredFields'
import LeftMatch from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/LeftMatch'
import CustomSQL from '../App/SmartHub/Audiences/LeftNav/AudienceDropdowns/CustomSQL'
import React from 'react'
import { FilterCategory } from '../types'

interface Props {
  filterType: FilterCategory
  selectedFilters: any
  filters: any
  updateFilter: React.Dispatch<any>
  fields: Array<any>
  sqlIsVerified: boolean
  dispatch: React.Dispatch<any>
  resetCounter: any
}

const PopupFilter: React.FC<Props> = (props) => {
  const {
    filterType,
    selectedFilters,
    filters,
    updateFilter,
    fields,
    sqlIsVerified,
    dispatch,
  } = props

  switch (filterType) {
    case 'Company.required_firmographics':
      return (
        <Firmographics
          company_employees={selectedFilters.company_employees}
          company_revenue={selectedFilters.company_revenue}
          domain={selectedFilters.domain}
          industry={selectedFilters.industry}
          updateFilter={updateFilter}
          divOnly={true}
        />
      )
    case 'Company.industry':
      return (
        <Industry
          industry_keywords={selectedFilters.industry_keywords}
          company_normalized_industry={filters.company_normalized_industry}
          company_normalized_industries={
            selectedFilters.company_normalized_industries
          }
          sic_codes={selectedFilters.sic_codes}
          naic_codes={selectedFilters.naic_codes}
          updateFilter={updateFilter}
          divOnly={true}
        />
      )
    case 'Company.domains_and_keywords':
      return (
        <DomainsAndKeywords
          domain_keywords={selectedFilters.domain_keywords}
          company_name_keywords={selectedFilters.company_name_keywords}
          company_description_keywords={selectedFilters.company_description_keywords}
          company_tickers={selectedFilters.company_tickers}
          updateFilter={updateFilter}
          divOnly={true}
        />
      )

    case 'Company.revenue':
      return (
        <Revenue
          b2b_sales_volume={filters.b2b_sales_volume}
          selectedFilters={selectedFilters.b2b_sales_volumes}
          updateFilter={updateFilter}
          divOnly={true}
        />
      )
    case 'Company.company_size':
      return (
        <CompanySize
          b2b_company_size={filters.b2b_company_size}
          selectedFilters={selectedFilters.b2b_company_sizes}
          updateFilter={updateFilter}
          divOnly={true}
        />
      )
    case 'Company.location':
      return (
        <CompanyLocation
          company_country={filters.company_country}
          company_state={filters.company_state}
          company_location_zip_code={selectedFilters.company_location_zip_code}
          company_location_country={selectedFilters.company_location_country}
          company_location_state={selectedFilters.company_location_state}
          updateFilter={updateFilter}
          divOnly={true}
        />
      )
    case 'Contact.required_contact_points':
      return (
        <ContactPoints
          b2b_email={selectedFilters.b2b_email}
          good_catch_all={selectedFilters.good_catch_all}
          good_only={selectedFilters.good_only}
          business_phone={selectedFilters.business_phone}
          business_address={selectedFilters.business_address}
          mobile_phone={selectedFilters.mobile_phone}
          hashed_personal_email={selectedFilters.hashed_personal_email}
          updateFilter={updateFilter}
          divOnly={true}
        />
      )

    case 'Contact.title_keywords':
      return (
        <Keywords
          title_keywords={selectedFilters.title_keywords}
          updateFilter={updateFilter}
          divOnly={true}
        />
      )
    case 'Contact.job_function':
      return (
        <JobFunction
          b2b_job_sub_functions={selectedFilters.b2b_job_sub_functions}
          b2b_job_function={filters.b2b_job_function}
          updateFilter={updateFilter}
          divOnly={true}
          isPopup={true}
        />
      )
    case 'Contact.seniority':
      return (
        <Seniority
          selectedFilters={selectedFilters.b2b_seniorities}
          b2b_seniority={filters.b2b_seniority}
          updateFilter={updateFilter}
          divOnly={true}
        />
      )
    case 'Contact.location':
      return (
        <LocationSelection
          personal_country={filters.personal_country}
          personal_state={filters.personal_state}
          updateFilter={updateFilter}
          contact_location_country={selectedFilters.contact_location_country}
          personal_states={selectedFilters.personal_states}
          contact_location_zip_code={selectedFilters.contact_location_zip_code}
          divOnly={true}
        />
      )
    case 'Advanced.required_fields':
      return (
        <AdvancedRequiredFields
          updateFilter={updateFilter}
          fields={fields}
          field_names={selectedFilters.field_names}
          divOnly={true}
        />
      )
    case 'Advanced.file_match':
      return (
        <LeftMatch
          chosen_files={selectedFilters.chosen_files}
          dispatch={dispatch}
          divOnly={true}
        />
      )
    case 'Advanced.custom_SQL':
      return (
        <CustomSQL
          sql_editor={selectedFilters.sql_editor}
          updateFilter={updateFilter}
          sqlIsVerified={sqlIsVerified}
          dispatch={dispatch}
          divOnly={true}
        />
      )
    default:
      return <div />
  }
}

export default PopupFilter
