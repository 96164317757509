import { MdRefresh } from 'react-icons/md'
import styles from 'components/animations/spin.module.css'
import Tooltip from './Tooltip/Tooltip'

export default function AnimatedRefresh() {
  return (
    <Tooltip title="Reset Password" data-cy="resetPasswordIcon">
      <div style={{ display: 'flex' }}>
        <MdRefresh className={styles.spin} />
      </div>
    </Tooltip>
  )
}
