const filterCategories = {
  company: {
    required_firmographics: [
      'company_employees',
      'company_revenue',
      'domain',
      'industry',
    ],
    domains_and_keywords: [
      'domain_keywords',
      'company_name_keywords',
      'company_description_keywords',
      'company_tickers',
    ],
    industry: [
      'industry_keywords',
      'company_normalized_industry',
      'company_normalized_industries',
      'sic_codes',
      'naic_codes',
    ],
    revenue: ['b2b_sales_volumes'],
    company_size: ['b2b_company_sizes'],
    company_location: [
      'company_location_zip_code',
      'company_location_country',
      'company_location_state',
    ],
  },
  contact: {
    contact_points: [
      'b2b_email',
      'good_catch_all',
      'good_only',
      'business_phone',
      'business_address',
      'mobile_phone',
      'hashed_personal_email',
    ],
    keywords: ['title_keywords'],
    job_function: ['b2b_job_sub_functions'],
    seniority: ['b2b_seniorities'],
    location_selection: [
      'contact_location_country',
      'personal_states',
      'contact_location_zip_code',
    ],
  },
  advanced: {
    advanced_required_fields: ['field_names'],
    left_match: ['chosen_files'],
    custom_sql: ['sql_editor'],
  },
}

export default filterCategories
