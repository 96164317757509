import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import AppProviders from 'AppProviders'
import { BrowserRouter as Router } from 'react-router-dom'
import 'analytics/TagManager'
import './index.css'

const AppToRender = () => {
  return (
    <React.StrictMode>
      <AppProviders>
        <Router>
          <App />
        </Router>
      </AppProviders>
    </React.StrictMode>
  )
}

ReactDOM.render(<AppToRender />, document.getElementById('root'))
