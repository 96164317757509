interface InitialState {
  currentFile: {
    file_columns: undefined
    file_id: undefined
    file_formatted_name: undefined
  }
  match_fields: Array<{
    file_field: string | undefined
    database_field: string | undefined
  }>
  cleansingOptions: Array<string>
  fileName: string
  dbFields: Array<string>
  privacy: string
  matchStats: Array<any>
  hasSubmitted: boolean
  dialogIsOpen: boolean
  pctComplete: number
  file_uuid: string
  fileAlreadyUploaded: boolean
  field_to_cleansing_method_map: Array<any>
  audienceHash: string
  cleansingMap: any
  matchEnabled: boolean
  isCreatingDerivedAudience: boolean
  matchesExpanded: boolean
  uploadsExpanded: boolean
  hasDropped: boolean
}

const initialState: InitialState = {
  currentFile: {
    file_columns: undefined,
    file_id: undefined,
    file_formatted_name: undefined,
  },
  match_fields: [{ file_field: undefined, database_field: undefined }],
  cleansingOptions: [],
  fileName: '',
  dbFields: [],
  privacy: 'team_view',
  matchStats: [],
  hasSubmitted: false,
  dialogIsOpen: false,
  pctComplete: 0,
  file_uuid: '',
  fileAlreadyUploaded: false,
  field_to_cleansing_method_map: [],
  audienceHash: '',
  cleansingMap: {},
  matchEnabled: true,
  isCreatingDerivedAudience: false,
  matchesExpanded: true,
  uploadsExpanded: true,
  hasDropped: false,
}

export { initialState }
export type { InitialState }
