function numericHumanReadable(num: any, decimals: number | null = null) {
  if (num === '' || num == null) {
    return ''
  } else {
    return num.toLocaleString('en-US', { maximumFractionDigits: decimals })
  }
}

function bytesHumanReadable(bytes: any, decimals = 1) {
  if (bytes === '' || bytes == null) {
    return ''
  } else if (bytes === 0) {
    return '0 B'
  } else {
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return (
      numericHumanReadable((bytes / Math.pow(k, i)).toFixed(dm), dm) + ' ' + sizes[i]
    )
  }
}

export { numericHumanReadable, bytesHumanReadable }
