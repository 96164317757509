import { ListAccordion } from '../../../../../components/Accordion'
import { ReactComponent as Search } from '../../../../../icons/16px/Search.svg'
import TagInput from '../../../../../components/TagInput'

export default function DomainsAndKeywords({
  domain_keywords,
  company_name_keywords,
  company_description_keywords,
  company_tickers,
  updateFilter,
  divOnly = false,
}: any) {
  const count =
    (domain_keywords?.length || 0) +
    (company_name_keywords?.length || 0) +
    (company_description_keywords?.length || 0) +
    (company_tickers?.length || 0)

  return (
    <ListAccordion
      title="Domains and Keywords"
      count={count || ''}
      divOnly={divOnly}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <TagInput
          title={'Domains'}
          placeholder="Add Domains"
          id="addDomains"
          LeftAdornment={Search}
          selectedTags={domain_keywords || []}
          addTag={(tag: any) =>
            updateFilter({
              domain_keywords: [...(domain_keywords || []), ...tag],
            })
          }
          deleteTag={(tag: any) =>
            updateFilter({
              domain_keywords: domain_keywords.filter(
                (keyword: any) => keyword !== tag
              ),
            })
          }
          testId="addDomains"
        />
        <TagInput
          title={'Company Name Keywords'}
          placeholder="Add Name Keywords"
          id="addCompanyNameKeywords"
          LeftAdornment={Search}
          selectedTags={company_name_keywords || []}
          addTag={(tag: any) =>
            updateFilter({
              company_name_keywords: [...(company_name_keywords || []), ...tag],
            })
          }
          deleteTag={(tag: any) =>
            updateFilter({
              company_name_keywords: company_name_keywords.filter(
                (keyword: any) => keyword !== tag
              ),
            })
          }
          testId="addCompanyNameKeywords"
        />
        <TagInput
          title={'Company Description Keywords'}
          placeholder="Add Description Keywords"
          id="addCompanyDescriptionKeywords"
          LeftAdornment={Search}
          selectedTags={company_description_keywords || []}
          addTag={(tag: any) =>
            updateFilter({
              company_description_keywords: [
                ...(company_description_keywords || []),
                ...tag,
              ],
            })
          }
          deleteTag={(tag: any) =>
            updateFilter({
              company_description_keywords: company_description_keywords.filter(
                (keyword: any) => keyword !== tag
              ),
            })
          }
          testId="addCompanyDescriptionKeywords"
        />
        <TagInput
          title={'Stock Tickers'}
          placeholder="Add Stock Ticker"
          id="addStockTickers"
          LeftAdornment={Search}
          selectedTags={company_tickers || []}
          addTag={(tag: any) =>
            updateFilter({
              company_tickers: [...(company_tickers || []), ...tag],
            })
          }
          deleteTag={(tag: any) =>
            updateFilter({
              company_tickers: company_tickers.filter(
                (keyword: any) => keyword !== tag
              ),
            })
          }
          testId="addStockTickers"
        />
      </div>
    </ListAccordion>
  )
}
