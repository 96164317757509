import { List } from '@material-ui/core'
import { ListAccordion } from '../../../../../components/Accordion/'
import { SimpleCheckbox } from '../../../../../components/Checkbox'
import { toTitleCase } from '../../../../../utils/caseHelpers'
import TreeButtons from '../../../../../components/Tree/TreeButtons'
import { useEffect, useState } from 'react'

const managementLevels = [
  'board of directors',
  'owner or partner',
  'founder',
  'c level',
  'vice president',
  'director',
  'manager',
]

export default function Seniority({
  selectedFilters = [],
  b2b_seniority,
  updateFilter,
  divOnly = false,
}: any) {
  let tagSuggestions = []
  if (b2b_seniority) {
    tagSuggestions = b2b_seniority
  }
  const [managementSelected, setManagementSelected] = useState(false)

  useEffect(() => {
    const allSubLevelsChecked = managementLevels.every((lvl) =>
      selectedFilters.includes(lvl)
    )
    if (allSubLevelsChecked) {
      setManagementSelected(true)
    } else {
      setManagementSelected(false)
    }
  }, [setManagementSelected, selectedFilters])

  function handleClick(value: any) {
    let _selectedFilters
    const selected = selectedFilters.includes(value)
    if (selected) {
      _selectedFilters = selectedFilters.filter(
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'selection' implicitly has an 'any' type... Remove this comment to see the full error message
        (selection) => selection !== value
      )
    } else {
      _selectedFilters = [...selectedFilters, value]
    }
    updateFilter({ b2b_seniorities: _selectedFilters })
  }

  function ListItem({ value }: any) {
    return (
      <li onClick={() => handleClick(value)} style={{ margin: '0.5rem 0rem' }}>
        <SimpleCheckbox
          selected={selectedFilters.includes(value)}
          label={toTitleCase(value)}
        />
      </li>
    )
  }

  function deDupe(arrayWithDuplicates: Array<any>) {
    const newSet = new Set(arrayWithDuplicates)
    return Array.from(newSet)
  }

  function handleManagementClick() {
    let _selectedFilters
    if (managementSelected) {
      _selectedFilters = selectedFilters.filter(
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
        (value) => !managementLevels.includes(value)
      )
    } else {
      _selectedFilters = [...selectedFilters, ...managementLevels]
    }

    updateFilter({ b2b_seniorities: deDupe(_selectedFilters) })
  }

  const deDupedSuggestions = deDupe([
    ...managementLevels,
    'staff',
    'advisors',
    'student',
    'retired',
    ...tagSuggestions,
  ])
  const allSelected = selectedFilters.length === deDupedSuggestions.length
  const allSubLevelsChecked = managementLevels.every((lvl) =>
    selectedFilters.includes(lvl)
  )

  return (
    <ListAccordion
      title="Seniority"
      count={selectedFilters?.length || ''}
      divOnly={divOnly}
    >
      <div style={{ width: '100%' }}>
        <TreeButtons
          selectAll={() => {
            updateFilter({ b2b_seniorities: deDupedSuggestions })
          }}
          clearAll={() => {
            updateFilter({ b2b_seniorities: [] })
          }}
          style={{ margin: '0rem' }}
          allSelected={allSelected}
        />
        <List style={{ paddingTop: 0, margin: '0.5rem 0rem' }}>
          <SimpleCheckbox
            onClick={handleManagementClick}
            label="Manager Plus"
            selected={allSubLevelsChecked}
          />
        </List>
        <List style={{ padding: 0 }}>
          {deDupedSuggestions.map((suggestion, i) => (
            <ListItem value={suggestion} key={i} />
          ))}
        </List>
      </div>
    </ListAccordion>
  )
}
