import { ListAccordion } from '../../../../../components/Accordion/'
import StandardAutocomplete from '../../../../../components/StandardAutocomplete'
import TagInput from '../../../../../components/TagInput'
import { useAuth } from '../../../../../context/auth-context'

export default function CompanyLocation({
  company_country,
  company_state,
  company_location_zip_code,
  company_location_country,
  company_location_state,
  updateFilter,
  divOnly = false,
}: any) {
  const { allDataMode } = useAuth()
  const count =
    (company_location_zip_code?.length || 0) + (company_location_state?.length || 0)

  return (
    <ListAccordion
      bottomBorder={false}
      title="Location"
      count={count || ''}
      divOnly={divOnly}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <StandardAutocomplete
          label="Country"
          id="company_country"
          testId="companyLocationCountry"
          placeholder={!allDataMode ? 'United States' : undefined}
          value={company_location_country}
          tagSuggestions={company_country}
          disabled={!allDataMode}
          onChange={(_: any, value: any) =>
            updateFilter({ company_location_country: value })
          }
        />
        <StandardAutocomplete
          label="State"
          id="company_state"
          testId="companyLocationState"
          value={company_location_state}
          placeholder={'Select State'}
          tagSuggestions={company_state || []}
          onChange={(_: any, value: any) =>
            updateFilter({ company_location_state: value })
          }
        />
        <TagInput
          title="Zip Code"
          placeholder="Enter Zip Code"
          id="companyLocationZipCode"
          testId="companyLocationZipCode"
          selectedTags={company_location_zip_code}
          addTag={(tag: any) =>
            updateFilter({
              company_location_zip_code: [
                ...(company_location_zip_code || []),
                ...tag,
              ],
            })
          }
          deleteTag={(tag: any) =>
            updateFilter({
              company_location_zip_code: company_location_zip_code.filter(
                (state: any) => state !== tag
              ),
            })
          }
        />
      </div>
    </ListAccordion>
  )
}
