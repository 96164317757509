import styled from 'styled-components'
import Accordion from '../../../../components/Accordion/'
import ContactPoints from './AudienceDropdowns/ContactPoints'
import Keywords from './AudienceDropdowns/Keywords'
import Seniority from './AudienceDropdowns/Seniority'
import JobFunction from './AudienceDropdowns/JobFunction'
import LocationSelection from './AudienceDropdowns/LocationSelection'
import Firmographics from './AudienceDropdowns/Firmographics'
import DomainsAndKeywords from './AudienceDropdowns/DomainsAndKeywords'
import Industry from './AudienceDropdowns/Industry'
import Revenue from './AudienceDropdowns/Revenue'
import CompanySize from './AudienceDropdowns/CompanySize'
import CompanyLocation from './AudienceDropdowns/CompanyLocation'
import AdvancedRequiredFields from './AudienceDropdowns/AdvancedRequiredFields'
import LeftMatch from './AudienceDropdowns/LeftMatch'
import CustomSQL from './AudienceDropdowns/CustomSQL'

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default function AudienceDropdowns({
  selectedFilters,
  filters,
  updateFilter,
  fields,
  sqlIsVerified,
  dispatch,
}: any) {
  return (
    <DropdownContainer id="filter_panel">
      <Accordion title="Company" id="company_filters">
        <Firmographics
          company_employees={selectedFilters.company_employees}
          company_revenue={selectedFilters.company_revenue}
          domain={selectedFilters.domain}
          industry={selectedFilters.industry}
          updateFilter={updateFilter}
        />
        <DomainsAndKeywords
          domain_keywords={selectedFilters.domain_keywords}
          company_name_keywords={selectedFilters.company_name_keywords}
          company_description_keywords={selectedFilters.company_description_keywords}
          company_tickers={selectedFilters.company_tickers}
          updateFilter={updateFilter}
        />
        <Industry
          industry_keywords={selectedFilters.industry_keywords}
          company_normalized_industry={filters.company_normalized_industry}
          company_normalized_industries={
            selectedFilters.company_normalized_industries
          }
          sic_codes={selectedFilters.sic_codes}
          naic_codes={selectedFilters.naic_codes}
          updateFilter={updateFilter}
        />
        <Revenue
          b2b_sales_volume={filters.b2b_sales_volume}
          selectedFilters={selectedFilters.b2b_sales_volumes}
          updateFilter={updateFilter}
        />
        <CompanySize
          b2b_company_size={filters.b2b_company_size}
          selectedFilters={selectedFilters.b2b_company_sizes}
          updateFilter={updateFilter}
        />
        <CompanyLocation
          company_country={filters.company_country}
          company_state={filters.company_state}
          company_location_zip_code={selectedFilters.company_location_zip_code}
          company_location_country={selectedFilters.company_location_country}
          company_location_state={selectedFilters.company_location_state}
          updateFilter={updateFilter}
        />
      </Accordion>
      <Accordion title="Contact" id="contact_filters">
        <ContactPoints
          b2b_email={selectedFilters.b2b_email}
          good_catch_all={selectedFilters.good_catch_all}
          good_only={selectedFilters.good_only}
          business_phone={selectedFilters.business_phone}
          business_address={selectedFilters.business_address}
          mobile_phone={selectedFilters.mobile_phone}
          hashed_personal_email={selectedFilters.hashed_personal_email}
          updateFilter={updateFilter}
        />
        <Seniority
          selectedFilters={selectedFilters.b2b_seniorities}
          b2b_seniority={filters.b2b_seniority}
          updateFilter={updateFilter}
        />
        <JobFunction
          b2b_job_sub_functions={selectedFilters.b2b_job_sub_functions}
          b2b_job_function={filters.b2b_job_function}
          updateFilter={updateFilter}
        />
        <Keywords
          title_keywords={selectedFilters.title_keywords}
          updateFilter={updateFilter}
        />
        <LocationSelection
          personal_country={filters.personal_country}
          personal_state={filters.personal_state}
          updateFilter={updateFilter}
          contact_location_country={selectedFilters.contact_location_country}
          personal_states={selectedFilters.personal_states}
          contact_location_zip_code={selectedFilters.contact_location_zip_code}
        />
      </Accordion>
      <Accordion title="Advanced" id="advanced_filters">
        <AdvancedRequiredFields
          updateFilter={updateFilter}
          fields={fields}
          field_names={selectedFilters.field_names}
        />
        <LeftMatch
          chosen_files={selectedFilters.chosen_files}
          dispatch={dispatch}
          divOnly={false}
        />
        <CustomSQL
          sql_editor={selectedFilters.sql_editor}
          updateFilter={updateFilter}
          sqlIsVerified={sqlIsVerified}
          dispatch={dispatch}
        />
      </Accordion>
    </DropdownContainer>
  )
}
