import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { colors } from '../../../../context/theme-context/'
import { useState } from 'react'
import { ReactComponent as Filter } from '../../../../icons/24px/Filter.svg'
import TagManagerRow from './TagManager/TagManagerRow'
import {
  contactFilters,
  companyFilters,
  advancedFilters,
} from './TagManager/TagCategories'
import Button from '../../../../components/Button'
import PopupFilterBox from '../../../../components/PopupFilterBox'
import { useAlerts } from '../../../../context/alert-context'
import tm from '../../../../analytics/TagManager'

export default function TagManager({
  deleteFilter,
  allFilters,
  selectedFilters,
  clearChanges,
  resetCounter,
  unappliedFilterCount,
  sqlIsVerified,
  sql_editor,
  updateFilter,
  filters,
  fields,
  dispatch,
}: any) {
  let contactSelections = {}
  let companySelections = {}
  let advancedSelections = {}
  const [popupState, setPopupState] = useState({
    popupAnchor: null,
    isOpen: false,
    filterType: null,
  })

  const { popupAnchor, isOpen, filterType } = popupState
  const { sendAlert } = useAlerts()
  const setPopupAnchor = (anchor: any, key: string) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(state: { popupAnchor: null; isO... Remove this comment to see the full error message
    setPopupState((state) => ({
      ...state,
      popupAnchor: anchor,
      filterType: key,
    }))
  }

  function categorizeSelections(
    filterCategory: any,
    selectedFilter: any,
    selectionGroup: any,
    count: any
  ) {
    for (const [key, values] of Object.entries(filterCategory)) {
      const _values = values as Array<string>
      if (_values.includes(selectedFilter)) {
        selectionGroup[key] = {
          keys: [...(selectionGroup[key]?.keys || []), selectedFilter],
          count: (selectionGroup[key]?.count || 0) + count,
        }
      }
    }
  }

  for (const item of allFilters) {
    let _item = item as Array<string>
    const selectedFilter = Object.keys(item)[0]
    const count = Object.values(_item)[0].length || 1
    categorizeSelections(contactFilters, selectedFilter, contactSelections, count)
    categorizeSelections(companyFilters, selectedFilter, companySelections, count)
    categorizeSelections(advancedFilters, selectedFilter, advancedSelections, count)
  }

  function handleClick() {
    if (sqlIsVerified || sql_editor === '') {
      setTimeout(resetCounter, 100)
      tm.captureCustomEvent('audiences_apply_changes_button')
    } else {
      sendAlert({ message: 'Please correct SQL before submitting' })
    }
  }

  const filterCount =
    Object.keys(contactSelections).length +
    Object.keys(companySelections).length +
    Object.keys(advancedSelections).length

  return (
    <Grid item xs={12}>
      {!!allFilters.length && (
        <Container id="filter_selections">
          <ContainerHeader>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5.5px',
              }}
            >
              <Filter style={{ marginRight: '1rem' }} />
              {filterCount} Filters
            </div>
            {!!unappliedFilterCount && (
              <div style={{ display: 'flex', flex: 0.25, columnGap: '0.5rem' }}>
                <span style={{ display: 'flex', flex: 0.5 }}>
                  <Button
                    text="Clear"
                    variant="secondary"
                    size="medium"
                    onClick={clearChanges}
                    testId="clearChanges"
                  />
                </span>
                <span style={{ display: 'flex', flex: 2 }}>
                  <Button
                    text={`Apply Changes (${unappliedFilterCount})`}
                    variant="primary"
                    size="medium"
                    onClick={handleClick}
                    testId="applyChanges"
                  />
                </span>
              </div>
            )}
          </ContainerHeader>
          <ContainerBody>
            {!!Object.keys(companySelections).length && (
              <TagManagerRow
                deleteFilter={deleteFilter}
                title="Company"
                selections={companySelections}
                setPopupAnchor={setPopupAnchor}
              />
            )}
            {!!Object.keys(contactSelections).length && (
              <TagManagerRow
                deleteFilter={deleteFilter}
                title="Contact"
                selections={contactSelections}
                setPopupAnchor={setPopupAnchor}
              />
            )}
            {!!Object.keys(advancedSelections).length && (
              <TagManagerRow
                deleteFilter={deleteFilter}
                title="Advanced"
                selections={advancedSelections}
                setPopupAnchor={setPopupAnchor}
              />
            )}
            <PopupFilterBox
              updateFilter={updateFilter}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLElement... Remove this comment to see the full error message
              popupAnchor={popupAnchor}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Dispatch<SetStateAction<{ popupAnchor: null;... Remove this comment to see the full error message
              setPopupState={setPopupState}
              isOpen={isOpen}
              selectedFilters={selectedFilters}
              filters={filters}
              fields={fields}
              sqlIsVerified={sqlIsVerified}
              dispatch={dispatch}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'FilterCateg... Remove this comment to see the full error message
              filterType={filterType}
              resetCounter={resetCounter}
              unappliedFilterCount={unappliedFilterCount}
            />
          </ContainerBody>
        </Container>
      )}
    </Grid>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 1rem;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid ${colors.gray5};
  background-color: ${colors.gray1};
`

const ContainerHeader = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray5};
  padding: 0.75rem;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
  svg {
    fill: ${colors.gray11};
  }
`

const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  line-height: 1rem;
  row-gap: 0.5rem;
`
