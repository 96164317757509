import styled, { css } from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { colors } from '../context/theme-context/'
import { toTitleCase } from '../utils/caseHelpers'

export default function TableBarChart({
  title = 'Chart Title',
  percentkey = 'count',
  denominator,
  rows,
  ...rest
}: any) {
  const _rows = rows?.map((item: any) => ({
    ...item,
    percent: item[percentkey] / denominator || 0,
  }))

  return (
    <Container {...rest} id={title}>
      <ContainerHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>{title}</div>
      </ContainerHeader>
      <StyledTable>
        <tbody>
          {_rows?.length ? (
            _rows.map((row: any, i: any) => {
              const name = toTitleCase(row[Object.keys(row)[0]])
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell
                    style={{
                      flex: 2,
                      borderRight: 'none',
                      fontSize: '0.75rem',
                    }}
                  >
                    {name}
                  </StyledTableCell>
                  <StyledCell key={'percent'} percent={row.percent} />
                  <StyledTableCell
                    style={{
                      borderRight: 'none',
                      textAlign: 'right',
                      fontSize: '0.75rem',
                      flex: 1,
                      color: colors.gray11,
                    }}
                  >
                    {row['count'].toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              )
            })
          ) : (
            <tr>
              <td>
                <Skeleton height={260} />
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </Container>
  )
}

function StyledCell({ percent = 0 }) {
  return (
    <StyledTableCell style={{ flex: 1.5, borderRight: 'none' }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          lineHeight: '1rem',
          columnGap: '0.25rem',
        }}
      >
        <div style={{ display: 'flex', flex: 1 }}>
          <ColoredBar percent={percent} />
          <div
            style={{
              display: 'flex',
              flex: 1 - percent,
              justifyContent: 'flex-end',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 0.25,
            color: colors.gray8,
            fontSize: '0.75rem',
          }}
        >
          {percent > 1 ? percent.toFixed(1) : `${(100 * percent).toFixed(0)}%`}
        </div>
      </div>
    </StyledTableCell>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
`

const StyledTable = styled.table`
  line-height: 1rem;
  border-spacing: 0px;
  font-size: 0.875rem;
  font-weight: 400;
`

const StyledTableRow = styled.tr`
  display: flex;
`

const StyledTableCell = styled.td<{ isLast?: boolean }>`
  color: ${colors.gray8};
  white-space: nowrap;
  ${(props) =>
    props.isLast &&
    css`
      border-radius: 0.5rem;
      color: pink;
    `}
`

const ColoredBar = styled.div<{ percent?: number }>`
  display: flex;
  flex: ${(props) => props.percent};
  background-color: ${colors.orange3};
  border: 1px solid ${colors.orange6};
  border-radius: 2px;
  height: 0.75rem;
`
