import Tree from '../../../../../components/Tree'
import { ListAccordion } from '../../../../../components/Accordion/'

export default function JobFunctions({
  b2b_job_sub_functions,
  b2b_job_function,
  updateFilter,
  divOnly = false,
  isPopup = false,
}: any) {
  const count = b2b_job_sub_functions?.length || ''

  function update(data: any) {
    const selectedGroups = data.filter((item: any) => item.isSelected)
    const b2b_job_sub_functions = selectedGroups
      .map((group: any) => {
        const groupName = group.functionName
        return group.subFunctions
          .filter((fn: any) => fn.isSelected)
          .map((item: any) => [groupName, item.functionName])
      })
      .flat()

    const _b2b_job_sub_functions = postfixTransformer(
      b2b_job_sub_functions,
      'remove'
    )

    updateFilter({
      b2b_job_sub_functions: _b2b_job_sub_functions,
    })
  }

  // TODO: create long-term fix.
  function postfixTransformer(pairs: any, mode: any) {
    const _pairs = pairs?.map((pair: any) => {
      const [fn, subFn] = pair
      if (
        [
          'mortgage specialist',
          'quality assurance',
          'mortgage specialist - finance',
          'quality assurance - operations',
        ].includes(subFn)
      ) {
        let postfix = ''
        let _subFn = subFn

        if (fn === 'finance') {
          postfix = ' - finance'
        } else if (fn === 'operations') {
          postfix = ' - operations'
        }

        if (mode === 'add') {
          _subFn = subFn + postfix
        } else if (mode === 'remove') {
          _subFn = subFn.replace(postfix, '')
        }
        return [fn, _subFn]
      }
      return pair
    })

    return _pairs
  }

  function addPostfix(options: any) {
    const isDuplicate = (value: any) => {
      return ['mortgage specialist', 'quality assurance'].includes(value)
    }

    const finance = options?.finance?.map((value: any) => {
      if (isDuplicate(value)) {
        return value + ' - finance'
      }
      return value
    })

    const operations = options?.operations?.map((value: any) => {
      if (isDuplicate(value)) {
        return value + ' - operations'
      }
      return value
    })

    return { ...options, finance, operations }
  }

  const _b2b_job_function = b2b_job_function && addPostfix(b2b_job_function)
  const _b2b_job_sub_functions = postfixTransformer(b2b_job_sub_functions, 'add')

  return (
    <ListAccordion title="Job Function" count={count} divOnly={divOnly}>
      <div style={{ width: '100%' }}>
        <Tree
          updateFilter={update}
          options={_b2b_job_function}
          initialState={_b2b_job_sub_functions}
          isPopup={isPopup}
        />
      </div>
    </ListAccordion>
  )
}
