import React from 'react'
import { useAuth } from 'context/auth-context'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { CustomEvent } from 'types'
import tm from 'analytics/TagManager'
import SimpleInput from 'components/SimpleInput'
import AuthPage, { SubmitButton } from './AuthPage'
import passwordIsCompliant from 'utils/passwordIsCompliant'
import PasswordComplianceMessage from './PasswordComplianceMessage'

// Relationship between the signup / reset types and the associated events to
// be fired when a user successfully signs in after reset. This will be leveraged
// by google tag manager, and ultimately consumed by Pendo and other tags.
const resetMapping: Record<string, CustomEvent> = {
  password_reset_request: 'password_set_success_reset_request',
  password_set_organic_signup: 'password_set_success_organic_signup',
  password_set_netwise_signup: 'password_set_success_netwise_signup',
}

export default function PasswordResetScreen() {
  const urlParams = new URLSearchParams(useLocation().search)
  const token = urlParams.get('token')
  const type = urlParams.get('type') as CustomEvent
  const history = useHistory()
  const { resetPassword, client } = useAuth()

  const [state, setState] = React.useState({
    password: '',
    resetError: false,
    tokenValid: true,
    hasAttemptedPassword: false,
  })

  // These checks will be replicated by backend for additional security,
  // but this leads to a better UX since the user will be notified immediately
  const passwordCheck = passwordIsCompliant({
    password: state.password,
  })

  if (!token) {
    history.replace('/login')
  }

  React.useEffect(() => {
    tm.captureCustomEvent(type)
  }, [type])

  // Validating that the reset token is still valid. These tokens are on a timer,
  // and after a certain period of time (or if the user completes a reset) will
  // become invalid and the user will need to request another password reset.
  React.useEffect(() => {
    async function validateToken() {
      try {
        const url = client.endpoints.passwordResetTokenValidate
        const { status } = await client.post({
          endpoint: url,
          data: { token },
          auth: false,
        })
        if (status === 200) {
          setState((state) => ({ ...state, tokenValid: true }))
        } else {
          setState((state) => ({ ...state, tokenValid: false }))
        }
      } catch (e) {
        console.log('Could not validate token: ', e)
        setState((state) => ({ ...state, tokenValid: false }))
      }
    }
    validateToken()
  }, [token, client, setState])

  const stateHandler = (
    stateFn: React.Dispatch<any>,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.trim()
    stateFn(value)
  }

  const resetHandler = async (token: any, password: any) => {
    const resetSuccess = await resetPassword(token, password)
    if (!resetSuccess) {
      setState((state) => ({ ...state, resetError: true }))
      return
    }
    const successType = resetMapping[type]
    await tm.queueEvent({ trigger: 'login', event: successType })
    history.push('/')
  }

  return (
    <AuthPage title={'Set a New Password'}>
      {state.tokenValid ? (
        <>
          <div style={{ marginTop: '1rem' }}>
            <SimpleInput
              type="password"
              placeholder="New Password"
              error={state.resetError}
              value={state.password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                stateHandler(
                  (password) =>
                    setState((state) => ({
                      ...state,
                      hasAttemptedPassword: true,
                      password,
                    })),
                  e
                )
              }
              inputProps={{ style: { padding: '1rem' } }}
              autoComplete="new-password"
              autoFocus={true}
            />
          </div>
          <SubmitButton
            text="SET PASSWORD"
            variant="primary"
            size="medium"
            disabled={!passwordCheck.compliant}
            onClick={() => resetHandler(token, state.password)}
          />
          <PasswordComplianceMessage
            password={state.password}
            hasAttemptedPassword={state.hasAttemptedPassword}
          />
        </>
      ) : (
        <div>
          Your email reset link has expired. Please request another password reset{' '}
          <Link to="/request_reset">here</Link>.
        </div>
      )}
    </AuthPage>
  )
}
