import LoginScreen from 'App/Authentication/LoginScreen'
import RequestResetScreen from 'App/Authentication/RequestResetScreen'
import ResetPasswordScreen from 'App/Authentication/ResetPasswordScreen'
import ResetCompleteScreen from 'App/Authentication/ResetCompleteScreen'
import TermsScreen from 'App/Authentication/TermsScreen'
import { Switch, Route, Redirect } from 'react-router-dom'

function Authentication() {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route path="/login" component={LoginScreen} />
      <Route path="/password_reset/done/" component={ResetCompleteScreen} />
      <Route path="/request_reset/" component={RequestResetScreen} />
      <Route path="/password_reset/" component={ResetPasswordScreen} />
      <Route
        path="/signup"
        render={() => {
          window.location.href = 'https://www.netwisedata.com/sign-up/'
          return null
        }}
      />
      <Route path="/terms" component={TermsScreen} />
      <Route
        path="/"
        render={({ history, location }) => {
          const { pathname, search } = location
          history.replace('/login?next=' + pathname + search)
          return null
        }}
      />
    </Switch>
  )
}

export default Authentication
