import withStyles from '@material-ui/core/styles/withStyles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { Fragment } from 'react'
import { colors } from '../../../../context/theme-context/'
import { useAuth } from '../../../../context/auth-context'
import Tooltip from '../../../../components/Tooltip'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
}))

export default function Modes() {
  const classes = useStyles()

  const { allDataMode, toggleAllDataMode, adminMode, toggleAdminMode } = useAuth()

  const handleAdminModeChange = () => {
    toggleAdminMode()
  }

  const handleAllDataModeChange = () => {
    toggleAllDataMode()
  }

  return (
    <FormGroup>
      <Tooltip
        title={
          <Fragment>
            <p>{'ON: can see other accounts'}</p>
            <p>{'OFF: cannot see other accounts'}</p>
          </Fragment>
        }
      >
        <FormControlLabel
          control={
            <CustomSwitch
              checked={adminMode}
              onChange={handleAdminModeChange}
              name="adminMode"
              size="small"
              data-cy="adminModeToggle"
            />
          }
          label="Admin Mode"
          style={{ color: adminMode ? colors.blue8 : colors.gray8 }}
        />
      </Tooltip>

      <Tooltip
        title={
          <Fragment>
            <p>{'ON: can see all of our data'}</p>
            <p>{'OFF: can only see the "app" subset of our data'}</p>
          </Fragment>
        }
        classes={{ tooltip: classes.customWidth }}
      >
        <FormControlLabel
          control={
            <CustomSwitch
              checked={allDataMode}
              onChange={handleAllDataModeChange}
              name="dataModeAll"
              size="small"
              data-cy="allDataToggle"
            />
          }
          label="All Data"
          style={{ color: allDataMode ? colors.blue8 : colors.gray8 }}
        />
      </Tooltip>
    </FormGroup>
  )
}

const CustomSwitch = withStyles({
  switchBase: {
    color: colors.gray7,
    '&$checked': {
      color: colors.blue6,
    },
    '&$checked + $track': {
      backgroundColor: colors.blue6,
    },
  },
  checked: {},
  track: {
    backgroundColor: colors.gray7,
  },
})(Switch)
