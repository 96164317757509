import styled, { css } from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { colors, typography } from '../../context/theme-context'

const { medium } = typography.bodyRegular

const AutocompleteLabel = styled.div`
  font-size: ${medium.fontSize};
  line-height: ${medium.lineHeight};
  font-weight: ${medium.fontWeight};
  color: ${colors.gray11};
  padding-bottom: 0.5rem;
`

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-input {
    padding: 0rem;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] {
    min-width: 200px;
    :hover {
      ${(props) =>
        !props.disabled &&
        css`
          border-color: ${colors.blue3};
        `}
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'].Mui-focused {
    border-color: ${colors.blue6};
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'].Mui-disabled {
    background-color: ${colors.gray3};
    opacity: 1;
    input[type='text']::placeholder {
      opacity: 1;
      color: black;
    }
  }
`

const StyledListBox = styled.div<{ highlightColor?: string | null }>`
  .MuiAutocomplete-option[data-focus='true'] {
    background-color: ${(props) => props.highlightColor || colors.blue1};
    color: ${colors.gray11};
  }
`

const listStyle = {
  borderRadius: '0.25rem',
  backgroundColor: colors.gray1,
  border: `1px solid ${colors.gray6}`,
  fontSize: '0.875rem',
  color: colors.gray8,
}

export { AutocompleteLabel, StyledAutocomplete, StyledListBox, listStyle }
