import { createContext, useContext, useState } from 'react'

const SnackbarContext = createContext(null)

type MsgProps = {
  text: string
  type?: string
  clickAction?: () => void
  undoAction?: () => void
}
interface SnackbarI {
  message: any
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  setSnackbarMessage: (MsgProps) => void
}

const SnackbarProvider = (props: any) => {
  const [state, setState] = useState({
    text: undefined,
    type: undefined,
    undoAction: undefined,
    clickAction: undefined,
  })

  const setSnackbarMessage = ({
    text,
    type = 'success',
    undoAction,
    clickAction,
  }: MsgProps) => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
    setState({ text, type, undoAction, clickAction })
  }

  return (
    <SnackbarContext.Provider
      value={{ message: state, setSnackbarMessage: setSnackbarMessage }}
      {...props}
    />
  )
}

// @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'SnackbarI'.
const useSnackbar = (): SnackbarI => useContext(SnackbarContext)

export { SnackbarProvider, useSnackbar }
