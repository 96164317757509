import { FormControl, MenuItem } from '@material-ui/core'
import { StyledSelect, AutocompleteLabel } from './BasicSelect/css'

interface BasicSelectI {
  label?: string
  selectValue: any
  items?: Array<any>
  containerStyle?: any
  onChange?: any
  disabled?: boolean
  style?: any
  testId?: string
}

const BasicSelect: React.FC<BasicSelectI> = ({
  label,
  selectValue,
  items = [],
  containerStyle = {},
  ...rest
}) => {
  const _items = items || [{ value: selectValue, text: selectValue }]
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...containerStyle }}>
      {label && <AutocompleteLabel>{label}</AutocompleteLabel>}
      <FormControl size="small" style={{ flex: 1 }}>
        <StyledSelect variant="outlined" value={selectValue} {...rest}>
          {_items?.map((item, i) => (
            <MenuItem key={i} value={item.value} style={{ fontSize: '1em' }}>
              {item.text}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  )
}

export default BasicSelect
