// Initial state to be used by exports in the case that there is no seed export

// data available.
const initialExportState = {
  reset: false,
  file_name: '',
  favorite: false,
  name: '',
  audience: '',
  layout: '',
  privacy: '',
  description: '',
  email_notifications: [],
  record_limit: '',
  record_limit_per_company: '',
  prioritize_by: [],
  partition_by: ['company_id'],
  delimiter: 'comma_standard',
  strip_delimiter_and_quotes: false,
  quotes: false,
  compression: 'none',
  header: true,
  enable_appends: false,
}

// Conforms seed export data to what the export components expect.
type Props = { seedExport: any; type: any; currentUserEmail: string }
function makeInitialExportState({ seedExport, type, currentUserEmail }: Props) {
  if (type === 'create') {
    return initialExportState
  }

  if (seedExport?.name) {
    return {
      // InitialExport state values are used as default values since some default exports do not contain these values.
      file_name: seedExport.data.file_name,
      favorite: seedExport.favorite,
      name: seedExport.name,
      audience: seedExport?.audience?.hashed,
      layout: seedExport?.layout?.hashed,
      privacy: seedExport.privacy,
      description: seedExport.description,
      email_notifications:
        seedExport.data?.email_notifications?.length === 0
          ? [currentUserEmail]
          : seedExport.data.email_notifications,
      record_limit: seedExport.data.record_limit ?? initialExportState.record_limit,
      record_limit_per_company:
        seedExport.data.record_limit_per_company ??
        initialExportState.record_limit_per_company,
      prioritize_by: seedExport.data.prioritize_segments,
      partition_by: seedExport.data.partition_by,
      delimiter: seedExport.data.delimiter,
      strip_delimiter_and_quotes:
        seedExport.data.strip_delimiter_and_quotes ??
        initialExportState.strip_delimiter_and_quotes,
      header: seedExport.data.header,
      quotes: seedExport.data.quotes,
      compression: seedExport.data.compression,
      enable_appends: seedExport.data.enable_appends,
    }
  }
  return {}
}
export { makeInitialExportState }
