import React from 'react'
import LoremIpsumCard from '../../components/LoremIpsumCard'

function FourOhFour() {
  return (
    <LoremIpsumCard
      greeting="404"
      bodyText={'Sorry! The page or resource you were looking for does not exist 😔'}
      actionComponent={<div />}
    />
  )
}

export default FourOhFour
