import { formatMetricsForDisplay } from 'App/SmartHub/Audiences/Dashboard/DashboardTabs/utils'
import React from 'react'
import styled from 'styled-components'
import PdfTableBarChart from 'components/PdfTableBarChart'

interface PrintableMetricsI {
  contactData: any
  companyData: any
  employeeData: any
  revenueData: any
  titleData: any
  functionalData: any
  seniorityData: any
  industryData: any
}

interface MetricsI {
  audienceSize: {
    companies: null
    contacts: null
  }
  printableMetrics: PrintableMetricsI
}

const Metrics: React.FC<MetricsI> = ({
  audienceSize = {},
  printableMetrics = {},
}) => {
  const {
    contactData,
    companyData,
    employeeData,
    revenueData,
    titleData,
    functionalData,
    seniorityData,
    industryData,
  } = formatMetricsForDisplay(printableMetrics)

  return (
    <MetricsContainer>
      <PdfTableBarChart
        title="Companies"
        columns={companyData?.columns}
        rows={companyData?.rows}
        denominator={audienceSize?.companies}
      />

      <PdfTableBarChart
        title="Contacts"
        columns={contactData?.columns}
        rows={contactData?.rows}
        denominator={audienceSize?.contacts}
      />

      <PdfTableBarChart
        title="Size"
        columns={employeeData?.columns}
        rows={employeeData?.rows}
        denominator={audienceSize?.contacts}
      />

      <PdfTableBarChart
        title="Revenue"
        columns={revenueData?.columns}
        rows={revenueData?.rows}
        denominator={audienceSize?.contacts}
      />

      <PdfTableBarChart
        title="Contact Titles"
        columns={titleData?.columns}
        rows={titleData?.rows}
        denominator={audienceSize?.contacts}
      />

      <PdfTableBarChart
        title="Contact Job Functions"
        columns={functionalData?.columns}
        rows={functionalData?.rows}
        denominator={audienceSize?.contacts}
      />

      <PdfTableBarChart
        title="Seniority"
        columns={seniorityData?.columns}
        rows={seniorityData?.rows}
        denominator={audienceSize?.contacts}
      />

      <PdfTableBarChart
        title="Industry"
        columns={industryData?.columns}
        rows={industryData?.rows}
        denominator={audienceSize?.contacts}
      />
    </MetricsContainer>
  )
}

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  row-gap: 1rem;
  padding-left: 1rem;
`

export default Metrics
