import React from 'react'
import PlayButton from '../Audiences/Dashboard/DashboardTabs/ExportsTab/PlayButton'
import LinkButton from 'components/Button/LinkButton'
import Accordion from 'components/Accordion'
import Button from 'components/Button'
import RemoteDataTable from 'components/RemoteDataTable'
import FavoriteButton from '../Audiences/Dashboard/DashboardTabs/ExportsTab/FavoriteButton'
import LinkedInLogo from 'icons/linkedInLogo.png'
import LiveRampLogo from 'icons/liveRampLogo.png'
import BaseExportDialog from 'components/BaseExportDialog'
import SpecialTooltip from 'components/SpecialTooltip'
import { useLocation } from 'react-router-dom'
import { ReactComponent as FacebookIcon } from 'icons/24px/Facebook.svg'
import { ReactComponent as NetWiseIcon } from 'icons/24px/NetWiseLogo.svg'
import { ConditionalTooltip } from 'components/Tooltip'
import { useSnackbar } from 'context/snackbar-context'
import { useAuth } from 'context/auth-context'
import { useAlerts } from 'context/alert-context'
import { MapEntry } from 'types'

// Constants
const ACTION_CELL_WIDTH = 20
const RUN_BY_CELL_WIDTH = 30
const FAVORITES_CELL_WIDTH = 10
const NAME_CELL_WIDTH = 85
const DATE_CELL_WIDTH = 40

// Types
type DialogType = 'create' | 'edit' | 'quick' | 'run' | 'view'
type Props = {
  isExpanded: boolean
  renderNow: any
  needsUpgrade: boolean
}

// Component
export default function SavedExports(props: Props) {
  const [state, setState] = React.useState({
    appliedFilters: {
      chosen_files: [],
      chosen_audiences: [],
    },
    isOpen: false,
    dialogType: 'edit',
    chosenExport: {},
    refreshSavedExports: {},
  })

  const [creditsToBeUsed, setCreditsToBeUsed] = React.useState<number>()

  const { client, adminMode, userData, socketHandler: sockets } = useAuth()
  const { sendAlert } = useAlerts()
  const location = useLocation()
  const { setSnackbarMessage } = useSnackbar()
  const parser = React.useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])
  const ep = client.endpoints
  const { needsUpgrade } = props

  function prepExportState() {
    setState((state) => ({ ...state, chosenExport: {}, isOpen: true }))
  }

  const runExport = React.useCallback(
    async (hash) => {
      prepExportState()
      const { responseData } = await client.get({
        endpoint: ep.exportsIdAPI(hash),
        data: { file_name_date: true },
      })
      const { results: savedExport } = responseData
      setState((state) => ({
        ...state,
        chosenExport: savedExport,
        appliedFilters: savedExport?.audience?.data,
        dialogType: 'run',
      }))
    },
    [client, ep]
  )

  React.useEffect(() => {
    const exportHash = parser.get('export_hash')
    if (exportHash) {
      runExport(exportHash)
    }
  }, [location.pathname, parser, runExport])

  const savedExportsRefreshHandler = () => {
    setState((state) => ({
      ...state,
      refreshSavedExports: { ...state.refreshSavedExports, refresh: true },
    }))
  }

  async function editExport(hash: any) {
    prepExportState()
    const { responseData } = await client.get({ endpoint: ep.exportsIdAPI(hash) })
    const { results: savedExport } = responseData
    setState((state) => ({
      ...state,
      chosenExport: savedExport,
      dialogType: 'edit',
    }))
  }

  async function cloneExport(hash: any) {
    try {
      await client.post({ endpoint: ep.exportsIdAPIClone(hash) })
      savedExportsRefreshHandler()
    } catch (e) {
      console.log('Could not clone an export: ', e)
    }
  }

  async function viewExport(hash: any) {
    prepExportState()
    const { responseData } = await client.get({ endpoint: ep.exportsIdAPI(hash) })
    const { results: savedExport } = responseData
    setState((state) => ({
      ...state,
      chosenExport: savedExport,
      dialogType: 'view',
    }))
  }

  async function updateFavoriteStatus(updatedFavoriteItem: any) {
    try {
      const payload = {
        favorite: !updatedFavoriteItem.favorite,
      }
      await client.put({
        endpoint: ep.exportsIdAPIFavorite(updatedFavoriteItem.hashed),
        data: payload,
      })
      savedExportsRefreshHandler()
    } catch (e) {
      console.log('Could not edit export (favorite)', e)
    }
  }

  function closeExportDialog() {
    setState((state) => ({
      ...state,
      appliedFilters: {
        chosen_files: [],
        chosen_audiences: [],
      },
      isOpen: false,
      chosenExport: {},
    }))
  }

  const extractResults: MapEntry['callback'] = ({ results }) => {
    setCreditsToBeUsed(results.size_creditable)
  }

  if (sockets) {
    sockets.taskSocket?.addMapback({
      id: 'nameAudience',
      socketRequestType: 'export_size',
      callback: extractResults,
    })
  }

  async function calculateCreditUsage(hashed: string) {
    setCreditsToBeUsed(undefined)

    try {
      await client.post({
        endpoint: ep.exportSizeAPI,
        data: {
          exports: [hashed],
          channel_name: sockets?.taskSocket?.channelName,
        },
      })
    } catch (e) {
      console.log('Could not retrieve export size', e)
    }
  }

  return (
    <div>
      <Accordion title="Saved Exports" expanded={props.isExpanded}>
        <div style={{ paddingTop: '1rem' }}>
          <RemoteDataTable
            client={client}
            adminMode={adminMode}
            sendAlert={sendAlert}
            setSnackbarMessage={setSnackbarMessage}
            title="savedExports"
            refreshState={state.refreshSavedExports}
            url={ep.exportsTableAPI}
            deleteAPI={ep.exportsIdAPI}
            expandableRows={false}
            autosaveControl={true}
            selectableRows="multiple"
            controls={true}
            deleteControl={true}
            initialSortOrder={'-favorite,-modified'}
            getHumanRowIdentifier={(row) => row['name']}
            columnOrderMap={{
              favorite: 'favorite',
              name: 'name',
              created_by: ['created_by__last_name', 'created_by__first_name'],
              modified: 'modified',
            }}
            columns={[
              {
                name: 'favorite',
                label: ' ',
                options: {
                  setCellProps: () => ({
                    style: { width: FAVORITES_CELL_WIDTH },
                  }),
                },
              },
              {
                name: 'icon',
                label: ' ',
                options: {
                  setCellProps: () => ({
                    style: { width: FAVORITES_CELL_WIDTH },
                  }),
                },
              },
              {
                name: 'name',
                label: 'Name',
              },
              {
                name: 'created_by',
                label: 'Created By',
                options: {
                  setCellProps: () => ({
                    style: { width: RUN_BY_CELL_WIDTH },
                  }),
                },
              },
              {
                name: 'modified',
                label: 'Last Modified',
                options: {
                  setCellProps: () => ({
                    style: { width: DATE_CELL_WIDTH },
                  }),
                },
              },
              {
                name: 'edit',
                label: ' ',
                options: {
                  sort: false,
                  setCellProps: () => ({
                    style: { width: ACTION_CELL_WIDTH },
                  }),
                },
              },
              {
                name: 'run',
                label: ' ',
                options: {
                  sort: false,
                  setCellProps: () => ({
                    style: { width: ACTION_CELL_WIDTH },
                  }),
                },
              },
              {
                name: 'clone',
                label: ' ',
                options: {
                  sort: false,
                  setCellProps: () => ({
                    style: { width: ACTION_CELL_WIDTH },
                  }),
                },
              },
            ]}
            rowsOnPage={5}
            customRowRender={(rowData: any) => {
              const exportsTableData = rowData?.map((item: any) => {
                const updatedItem = { ...item }

                updatedItem.favorite = (
                  <FavoriteButton
                    status={item.favorite}
                    updateFavoriteStatus={() => updateFavoriteStatus(item)}
                    data-cy="favoriteExport"
                  />
                )

                let icon
                const input = item.name.toLowerCase()
                if (input.match(/facebook/g)) {
                  icon = <FacebookIcon height={'42px'} width={'42px'} />
                } else if (input.match(/liveramp/g)) {
                  icon = (
                    <img
                      src={LiveRampLogo}
                      height={'42px'}
                      width={'42px'}
                      alt="LiveRamp Logo"
                    />
                  )
                } else if (input.match(/linkedin/g)) {
                  icon = (
                    <img
                      src={LinkedInLogo}
                      height={'42px'}
                      width={'42px'}
                      alt="LinkedIn Logo"
                    />
                  )
                } else {
                  icon = <NetWiseIcon height={'42px'} width={'42px'} />
                }

                updatedItem.icon = (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {icon}
                  </div>
                )

                updatedItem.name = (
                  <ConditionalTooltip
                    text={item.name}
                    maxLength={NAME_CELL_WIDTH}
                    render={(text: any) => (
                      <LinkButton
                        text={text}
                        onClick={() => viewExport(item.hashed)}
                        data-cy="viewExport"
                      />
                    )}
                  />
                )
                updatedItem.created_by = `${item.created.user.last_name}, ${item.created.user.first_name}`

                updatedItem.modified = new Date(
                  item.modified.timestamp.raw
                ).toLocaleString('en-US')

                updatedItem.edit = (
                  <SpecialTooltip
                    text="Your account doesn't have credits and/or permission to export audiences. Please use the chat to upgrade your account."
                    condition={needsUpgrade}
                  >
                    <Button
                      text="Edit"
                      disabled={!item.editable}
                      size="medium"
                      variant="secondary"
                      onClick={() => editExport(item.hashed)}
                      textStyle={{ fontWeight: 'bold' }}
                      testId="editExport"
                    />
                  </SpecialTooltip>
                )

                updatedItem.run = (
                  <SpecialTooltip
                    text="Your account doesn't have credits and/or permission to export audiences. Please use the chat to upgrade your account."
                    condition={needsUpgrade}
                  >
                    <Button
                      text={<PlayButton />}
                      disabled={needsUpgrade}
                      size="medium"
                      variant="primary"
                      onClick={() => runExport(item.hashed)}
                      textStyle={{ fontWeight: 'bold' }}
                      testId="runExport"
                    />
                  </SpecialTooltip>
                )

                updatedItem.clone = (
                  <SpecialTooltip
                    text="Your account doesn't have credits and/or permission to export audiences. Please use the chat to upgrade your account."
                    condition={needsUpgrade}
                  >
                    <Button
                      disabled={needsUpgrade}
                      text="Clone"
                      size="medium"
                      variant="secondary"
                      onClick={() => cloneExport(item.hashed)}
                      textStyle={{ fontWeight: 'bold' }}
                      testId="cloneExport"
                    />
                  </SpecialTooltip>
                )
                return updatedItem
              })
              return exportsTableData
            }}
          />
        </div>
      </Accordion>

      <BaseExportDialog
        isOpen={state.isOpen}
        chosenExport={state.chosenExport}
        setRenderExportAudience={savedExportsRefreshHandler}
        disabled={state.dialogType === 'edit' ? false : true}
        handleExit={closeExportDialog}
        calculateCreditUsage={calculateCreditUsage}
        submissionCallback={
          state.dialogType === 'run'
            ? () => setSnackbarMessage({ text: 'Export started' })
            : null
        }
        dialogType={state.dialogType as DialogType}
        credits={userData.credits}
        appliedFilters={state.appliedFilters}
        creditsToBeUsed={creditsToBeUsed}
      />
    </div>
  )
}
