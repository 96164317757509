import Navigation from 'App/SmartHub/Navigation'
import Banner from 'components/Banner'
import Snackbar from 'components/Snackbar'
import Routing from 'App/SmartHub/Routing'
import AssumedUserInfo from 'App/SmartHub/AssumedUserInfo'
import { useSnackbar } from 'context/snackbar-context'
import { useAuth } from 'context/auth-context'

function SmartHub() {
  const { message } = useSnackbar()
  const { userData, unassumeUser } = useAuth()
  const isVisible = !!userData?.assumer_id

  return (
    <>
      <Banner
        isVisible={isVisible}
        Component={
          <AssumedUserInfo unassumeUser={unassumeUser} userData={userData} />
        }
      />
      <Navigation />
      <div style={{ overflowY: 'scroll' }}>
        <Routing />
      </div>
      <Snackbar {...message} />
    </>
  )
}

export default SmartHub
