import ListAccordion from '../Accordion/ListAccordion'
import styled from 'styled-components'
import SimpleInput from '../SimpleInput'
import { colors } from '../../context/theme-context'
import StandardAutocomplete from '../StandardAutocomplete'

type Props = {
  exportState?: any
  setExportState?: any
  prioritizeFields?: any
  partitionFields?: any
  chosenExport?: any
  disabled?: any
}

export default function LimitsAndOrderingSection(props: Props) {
  return (
    <ListAccordion title="Limits & Ordering" data-cy="limitsAndOrdering">
      <ContentDiv>
        <RowDiv>
          <FlexStartDiv>
            <SimpleInput
              disabled={props.disabled}
              label="File Wide Record Limit"
              value={props.exportState.record_limit}
              placeholder="Enter Record Limit"
              onChange={(e: any) =>
                props.setExportState((state: any) => ({
                  ...state,
                  record_limit: e.target.value,
                }))
              }
              data-cy="fileWideRecordLimit"
            />
          </FlexStartDiv>
          <StandardAutocomplete
            disabled={props.disabled}
            label="Prioritize By"
            placeholder={'Select Fields'}
            value={props.exportState.prioritize_by}
            tagSuggestions={props.prioritizeFields}
            multiple={true}
            onChange={(_: any, value: any) => {
              props.setExportState((state: any) => ({
                ...state,
                prioritize_by: value,
              }))
            }}
            testId="prioritizeBy"
          />
        </RowDiv>
        <Container>
          <RowDiv>
            <FlexStartDiv>
              <SimpleInput
                disabled={props.disabled}
                label="Group Record Limit"
                value={props.exportState.record_limit_per_company}
                placeholder="Enter Record Limit"
                onChange={(e: any) =>
                  props.setExportState((state: any) => ({
                    ...state,
                    record_limit_per_company: e.target.value,
                  }))
                }
                data-cy="groupRecordLimit"
              />
            </FlexStartDiv>
            <StandardAutocomplete
              disabled={props.disabled}
              label="Group By"
              value={props.exportState.partition_by}
              placeholder={'Select Fields'}
              tagSuggestions={props.partitionFields}
              multiple={true}
              onChange={(_: any, value: any) => {
                props.setExportState((state: any) => ({
                  ...state,
                  partition_by: value,
                }))
              }}
              testId="groupBy"
            />
          </RowDiv>
        </Container>
      </ContentDiv>
    </ListAccordion>
  )
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  font-weight: 400;
`
const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 1rem;
`

const Container = styled.div`
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
`

const FlexStartDiv = styled.div`
  align-self: flex-start;
`
