import styled from 'styled-components'
import { ReactComponent as PlayIcon } from '../../../../../../icons/24px/Play.svg'
import { colors } from '../../../../../../context/theme-context/'

export default function PlayButton({ text = 'Run' }) {
  return (
    <span>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledPlayIcon />
        <span>{text}</span>
      </div>
    </span>
  )
}

const StyledPlayIcon = styled(PlayIcon)`
  stroke: ${colors.gray1};
  stroke-width: 4px;
  margin-right: 0.25rem;
`
