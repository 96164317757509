import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { colors } from 'context/theme-context/'
import { ReactComponent as UploadIcon } from '../icons/24px/Upload.svg'

interface Props {
  disabled?: boolean | null
  onDropAccepted:
    | (<T extends File>(files: T[], event: DropEvent) => void)
    | undefined
  onDropRejected:
    | ((fileRejections: FileRejection[], event: DropEvent) => void)
    | undefined
  sizeLimitMb?: number
  subtitle?: string
}

const UploadInterface: React.FC<Props> = ({
  disabled = false,
  onDropAccepted = () => null,
  onDropRejected = () => null,
  sizeLimitMb = 1000,
  subtitle = 'CSV or Excel files only',
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
    multiple: false,
    maxFiles: 1,
    maxSize: 1_048_576 * sizeLimitMb,
  })
  const rootProps = disabled ? {} : getRootProps()

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        margin: '0.25em',
      }}
    >
      <div style={{ margin: '0.5em 0em' }}>
        <div data-cy="dropzone" style={{ display: 'flex', flex: 1 }} {...rootProps}>
          {!disabled && <input {...getInputProps()} />}
          <BorderedDiv>
            <StyledUploadIcon />
            <FirstText>
              <span style={{ color: colors.blue7 }}>Click to Upload </span>
              or drag file here
            </FirstText>
            <SecondText>{subtitle}</SecondText>
          </BorderedDiv>
        </div>
      </div>
    </div>
  )
}

const StyledUploadIcon = styled(UploadIcon)`
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  width: 4rem;
  height: 4rem;
  path {
    fill: ${colors.gray6};
  }
`
const BorderedDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${colors.gray5};
  border-radius: 10px;
  height: 15.625rem;
  width: 100%;
`
const FirstText = styled.div`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`
const SecondText = styled.div`
  font-size: 0.875rem;
  color: ${colors.gray7};
`

export default UploadInterface
