function toTitleCase(name: any) {
  if (!name) {
    return name
  }
  const titledName = name
    .replaceAll('_', ' ')
    .split(' ')
    .map((word: any) => {
      if (!word) {
        return null
      } else if (word.toLowerCase() === 'b2b') {
        return 'B2B'
      } else if (word.toLowerCase() === 'and') {
        return 'and'
      }
      const firstChar = word[0].toUpperCase()
      const restChars = word.slice(1, word.length)
      return firstChar + restChars
    })
    .join(' ')
  return titledName
}

function toSnakeCase(name: any) {
  const titledName = name.replaceAll(' ', '_').toLowerCase()
  return titledName
}

export { toTitleCase, toSnakeCase }
