import Chip from '../Chip/'
import { NumberCount } from './FilterLabel'

const ChipLabel = ({ text, count }: any) => (
  <div style={{ display: 'flex' }}>
    <span style={{ flex: 1 }}>{text}</span>
    {count > 1 && (
      <NumberCount style={{ flex: 1 }} count={count}>
        {count}
      </NumberCount>
    )}
  </div>
)

export default function AggregateChip({
  value,
  setSelectedTags,
  setDialogTitle,
  setDialogIsOpen,
  ...rest
}: any) {
  const values = value.split(/[,:]/)
  const fmtValue = values.shift()
  return (
    <Chip
      clickable={true}
      onClick={() => {
        setDialogTitle(fmtValue)
        setSelectedTags(values)
        setDialogIsOpen(true)
      }}
      value={fmtValue}
      label={<ChipLabel text={fmtValue} count={values.length} />}
      {...rest}
    />
  )
}
