import React from 'react'
import { InputContainer, StyledInput } from './TagInput/Css'
import { AutocompleteLabel } from './StandardAutocomplete/Css'
import styled from 'styled-components'
import { debounce } from '../utils/debounce'
import Skeleton from 'react-loading-skeleton'
import isUndefined from 'utils/isUndefined'

interface Props {
  label?: string
  labelStyles?: any
  helperText?: string
  LeftAdornment?: any
  RightAdornment?: any
  containerProps?: any
  ApplyButton?: any
  disabled?: boolean
  placeholder?: string
  rest?: any
  onChange: any
  value?: string | number
  defaultValue?: string | number
  type?: string
  size?: string
  error?: any
  onClick?: any
  labelProps?: any
  multiple?: any
  name?: any
  autoComplete?: string
  autoFocus?: boolean
  inputProps?: any
  'data-cy'?: string
  debounced?: boolean
  id?: any
}

export default function SimpleInput(props: Props) {
  const [focused, setFocused] = React.useState(false)
  const { debounced = true } = props
  let onChange = props.onChange

  if (props.value && props.debounced) {
    throw Error('Cannot use debounced with value prop')
  }

  if (props.defaultValue) {
    onChange = debounce(props.onChange, debounced ? 250 : 0)
  }

  const testId = props['data-cy'] ?? ''

  const UI = (
    <div
      style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
      id={props?.id}
    >
      {(!!props.label || !!props.helperText) && (
        <AutocompleteLabel data-cy={testId + '-label'} style={props.labelStyles}>
          {props.label}&nbsp;
          <HelperSpan data-cy={testId + '-helper'}>{props.helperText}</HelperSpan>
        </AutocompleteLabel>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: '0.5rem',
        }}
      >
        <InputContainer
          disabled={props.disabled}
          focused={focused}
          {...props.containerProps}
        >
          {props.LeftAdornment && (
            <div style={{ marginLeft: '0.5rem', display: 'flex' }}>
              <props.LeftAdornment />
            </div>
          )}
          <StyledInput
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            data-cy={testId}
            placeholder={props.placeholder}
            onChange={onChange}
            value={props.value}
            defaultValue={props.defaultValue}
            disabled={props.disabled}
            type={props.type}
            onClick={props.onClick}
            multiple={props.multiple}
            name={props.name || props.label}
            autoComplete={props.autoComplete || 'off'}
            autoFocus={props.autoFocus}
            {...props.inputProps}
          />
          {props.RightAdornment && (
            <span data-cy={testId + '-adornment'}>
              <props.RightAdornment />
            </span>
          )}
        </InputContainer>
        {props.ApplyButton && (
          <span data-cy={testId + '-apply'}>
            <props.ApplyButton />
          </span>
        )}
      </div>
    </div>
  )

  const isLoading = isUndefined(props.value) && isUndefined(props.defaultValue)
  return isLoading ? (
    <span data-cy={testId + '-skeleton'}>
      <Skeleton height={40} />
    </span>
  ) : (
    UI
  )
}

const HelperSpan = styled.span`
  color: red;
`
