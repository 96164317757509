import filterDiff from './filterDiff'
import printPdf from './printPdf'
import {
  filterDefaults,
  infoDefaults,
  locationDefaults,
  initialState,
} from './defaults'

const defaults = {
  filters: filterDefaults,
  info: infoDefaults,
  location: locationDefaults,
  initialState,
}

export { filterDiff, printPdf, defaults }
