//@ts-nocheck
import Checkbox from '@material-ui/core/Checkbox'
import TreeItem from '@material-ui/lab/TreeItem'
import { colors } from '../../context/theme-context/'
import { ReactComponent as CheckBoxIconOn } from '../../icons/16px/Checkbox/On.svg'
import { ReactComponent as CheckBoxIconOff } from '../../icons/16px/Checkbox/Off.svg'
import IndeterminateCheckBoxIcon from './CheckboxTreeItem/IndeterminateCheckBoxIcon'

export default function CheckboxTreeItem({
  node,
  toggleExpansion,
  toggleSelection,
  children,
  ...rest
}) {
  const { id, isSelected, isHidden, functionName: text } = node
  const getIcon = () => {
    let mixed = false

    function anyMixed(node) {
      if (node.isRoot) {
        const { subFunctions } = node
        const size = new Set(subFunctions.map((x) => x.isSelected)).size
        if (size > 1) {
          mixed = true
        }

        subFunctions.map((x) => anyMixed(x))
      }
    }

    anyMixed(node)

    if (mixed) {
      return <IndeterminateCheckBoxIcon />
    }

    return <CheckBoxIconOn />
  }

  const label = (
    <>
      <Checkbox
        checked={!!isSelected}
        onClick={(e) => {
          e.stopPropagation()
          toggleSelection(id)
        }}
        icon={<CheckBoxIconOff />}
        checkedIcon={getIcon()}
        style={{ color: colors.blue7, padding: 0, margin: '0.5rem' }}
      />
      <span style={{ fontSize: '0.875rem' }}>{text}</span>
    </>
  )

  return (
    <TreeItem
      hidden={isHidden}
      onClick={() => toggleExpansion(id)}
      onIconClick={() => toggleExpansion(id)}
      label={label}
      id={label.props.children[1].props.children}
      {...rest}
    >
      {children}
    </TreeItem>
  )
}
