// This component:
// 1) Centers content vertically and horizontally in the page
// 2) Dims content behind dialog

import Button from './Button'
import styles from './Dialog/Dialog.module.css'

type Props = {
  isOpen: boolean
  closeHandler: () => void
  Body: JSX.Element
  Header?: JSX.Element
  Footer?: JSX.Element
  surfaceStyle?: React.CSSProperties
  animated?: boolean
}
export default function Dialog(props: Props) {
  return props.isOpen ? (
    <>
      <div className={styles.translucentCenterLayout}>
        <div
          className={`${styles.surface} ${props.animated && styles.animated}`}
          style={{ ...props.surfaceStyle }}
        >
          <div className={styles.header}>
            {props.Header || <div />}
            <span>
              <Button
                size="small"
                variant="warning"
                text={<div>&nbsp;x&nbsp;</div>}
                onClick={props.closeHandler}
                testId="closeDialog"
              />
            </span>
          </div>
          <div className={styles.body}>{props.Body}</div>
          {props.Footer && <div className={styles.footer}>{props.Footer}</div>}
        </div>
      </div>
    </>
  ) : null
}
