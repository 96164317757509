import { colors } from 'context/theme-context'
import {
  Compliance,
  hasAlphabeticCharacters,
  hasNumericCharacters,
  hasSpecialCharacters,
  hasTenCharactersMinimum,
  hasUpperAndLowerCase,
} from 'utils/passwordIsCompliant'

// Paragraph of text showing which compliance checks are passing
type Props = {
  password: string
  hasAttemptedPassword: boolean
}
export default function PasswordComplianceMessage(props: Props) {
  const { password, hasAttemptedPassword } = props

  const ErrorMessage = ({ check }: { check: Compliance }) => {
    let color = check.compliant ? colors.green7 : colors.red7
    if (!hasAttemptedPassword) color = colors.gray7

    return <div style={{ color: color }}>{check.reason}</div>
  }
  return (
    <div>
      <ErrorMessage check={hasTenCharactersMinimum(password)} />
      <br />
      <div style={{ fontWeight: 'bold', color: colors.gray9 }}>
        And comply with 3 of the following 4 rules:
      </div>
      <ErrorMessage check={hasNumericCharacters(password)} />
      <ErrorMessage check={hasSpecialCharacters(password)} />
      <ErrorMessage check={hasUpperAndLowerCase(password)} />
      <ErrorMessage check={hasAlphabeticCharacters(password)} />
    </div>
  )
}
