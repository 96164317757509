import React from 'react'
import SimpleInput from 'components/SimpleInput'
import AuthPage, { SubmitButton } from './AuthPage'
import { useAuth } from 'context/auth-context'
import { useAlerts } from 'context/alert-context'
import recaptcha from 'utils/recaptcha'
import { RecaptchaDisclaimer } from 'utils/recaptcha'

export default function ResetPasswordScreen() {
  const [email, setEmail] = React.useState('')
  const [emailError, setEmailError] = React.useState(false)
  const [helperText, setHelperText] = React.useState('')
  const [hasRequested, setHasRequested] = React.useState(false)
  const { client, requestReset } = useAuth()
  const { sendAlert } = useAlerts()

  const stateHandler = (stateFn: any, e: any) => {
    const value = e.target.value.trim()
    stateFn(value.trim())
  }

  const requestHandler = async () => {
    if (!email.trim()) {
      setHelperText('Please include email address.')
      return
    }

    await recaptcha.evaluateScore({
      client: client,
      action: 'reset',
      onSuccess: async () => {
        const { ok = false, responseData } = await requestReset(email)
        if (!ok) {
          setEmailError(true)
          setHelperText(responseData['email'])
        } else {
          setHasRequested(true)
        }
        return
      },
      onFailure: () => {
        recaptcha.promptCustomerSupport(sendAlert)
      },
    })
  }

  return (
    <>
      {hasRequested ? (
        <AuthPage title={'Check your inbox.'}>
          <br />
          <div>
            We've emailed you instructions for setting your password. You should
            receive the email shortly!
          </div>
        </AuthPage>
      ) : (
        <AuthPage title={'Forgot Your Password?'}>
          <br />
          <div>
            Enter your email address below, and we'll email instructions for setting
            a new one.
          </div>
          <SimpleInput
            error={emailError}
            helperText={helperText}
            placeholder="Email Address"
            name="email"
            value={email}
            autoComplete="email"
            onChange={(e: any) => stateHandler(setEmail, e)}
            inputProps={{ style: { padding: '1rem' } }}
            data-cy="emailAddress"
          />
          <SubmitButton
            text="SEND INSTRUCTIONS"
            variant="primary"
            size="medium"
            onClick={() => requestHandler()}
            data-cy="sendInstructions"
          >
            Send Instructions
          </SubmitButton>
          <RecaptchaDisclaimer />
        </AuthPage>
      )}
    </>
  )
}
