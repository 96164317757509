import styled from 'styled-components'
import Header from './PrintableContent/Header'
import Summary from './PrintableContent/Summary'
import Body from './PrintableContent/Body'

export default function PrintableContent({
  printingUser,
  audienceInfo,
  appliedFilters,
  audienceSize,
  printableMetrics,
  dataReady,
  buildVersion,
}: any) {
  return (
    <div style={{ display: 'hidden' }}>
      <Container id="audienceReport">
        <Header />
        <Summary
          dataReady={dataReady}
          buildVersion={buildVersion}
          audienceInfo={audienceInfo}
          printingUser={printingUser}
          audienceSize={audienceSize}
        />
        <Body
          audienceSize={audienceSize}
          appliedFilters={appliedFilters}
          printableMetrics={printableMetrics}
        />
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: none;
  flex-direction: column;
  width: 1050px;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: white;
`
