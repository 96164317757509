import { useState } from 'react'
import { useAuth } from '../../../context/auth-context'
import Accordion from '../../../components/Accordion'
import RemoteDataTable from '../../../components/RemoteDataTable'
import Button from '../../../components/Button'
import { Link } from 'react-router-dom'
import MatchContent from './ExactMatch/ExactMatchDialog/MatchContent'
import StandardDialog from '../../../components/StandardDialog'
import UploadButton from './ExactMatch/UploadButton'
import { BiListUl } from 'react-icons/bi'
import { ConditionalTooltip } from '../../../components/Tooltip'
import makeThemes from '../../../components/DataTable/theme'
import { useAlerts } from 'context/alert-context'
import { useSnackbar } from 'context/snackbar-context'

const MAX_CELL_WIDTH = 50
const [theme, disabledTheme] = makeThemes(MAX_CELL_WIDTH)
export default function MatchedFiles({
  makeInitialMatchState,
  matchesExpanded,
}: any) {
  const { client, adminMode } = useAuth()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage } = useSnackbar()
  const { matchedFilesAPI } = client.endpoints
  const [isOpen, setIsOpen] = useState(false)
  const [matchStats, setMatchStats] = useState([])

  const customRowRender = (data: any) => {
    const customData = data.map((match: any) => {
      const {
        stats: match_stats,
        created: {
          timestamp: { raw: created_time },
          user: { first_name, last_name },
        },
        file: { formatted_name: file_formatted_name },
        audience: { hashed: audience_hash, name: audience_name },
      } = match

      match.input_file = (
        <ConditionalTooltip
          text={file_formatted_name}
          maxLength={MAX_CELL_WIDTH}
          render={() => file_formatted_name}
        />
      )

      match.date_matched = new Date(created_time).toLocaleString('en-US')
      match.last_modified_by = `${last_name}, ${first_name}`

      match.matched_audience = (
        <ConditionalTooltip
          text={audience_name}
          maxLength={MAX_CELL_WIDTH}
          render={(text: any) => (
            <Link to={`audiences/${audience_hash}`}>{text}</Link>
          )}
        />
      )

      match.styledStats = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <Button
              text=""
              size="medium"
              onClick={() => showMatchModal(match_stats)}
              icon={() => <BiListUl size={16} />}
              iconProps={{ style: { margin: 0 } }}
              variant="secondary"
            />
          </div>
        </div>
      )
      return match
    })
    return customData
  }

  function showMatchModal(stats: any) {
    const parsedStats = JSON.parse(stats)
    setMatchStats(parsedStats.rows)
    setIsOpen(true)
  }

  const columns = [
    { name: 'input_file', label: 'Name' },
    { name: 'matched_audience', label: 'Matched Audience' },
    { name: 'date_matched', label: 'Date Matched' },
    { name: 'last_modified_by', label: 'Last Modified By' },
    { name: 'styledStats', label: 'Match Stats', options: { sort: false } },
  ]

  const columnOrderMap = {
    input_file: 'file__formatted_name',
    type: 'match_type',
    matched_audience: 'audience__name',
    last_modified_by: ['created_by__last_name', 'created_by__first_name'],
    date_matched: 'created',
  }

  return (
    <div>
      <Accordion
        title="Matched Files"
        expanded={matchesExpanded}
        Widgets={
          <UploadButton
            id="upload_button_matched_files"
            testId="uploadButtonMatchedFiles"
            clickHandler={() => makeInitialMatchState({ dialogIsOpen: true })}
          />
        }
      >
        <div style={{ paddingTop: '1rem' }}>
          <RemoteDataTable
            client={client}
            adminMode={adminMode}
            sendAlert={sendAlert}
            setSnackbarMessage={setSnackbarMessage}
            initialSortOrder={'-modified'}
            title="matchedFileTable"
            copyControl={false}
            expandableRows={false}
            csvControl={false}
            excelControl={false}
            customRowRender={customRowRender}
            columnOrderMap={columnOrderMap}
            url={matchedFilesAPI}
            columns={columns}
            theme={theme}
            disabledTheme={disabledTheme}
          />
        </div>
      </Accordion>
      <StandardDialog
        isOpen={isOpen}
        title="Match Statistics"
        handleClose={() => setIsOpen(false)}
        Content={<MatchContent matchStats={matchStats} />}
      />
    </div>
  )
}
