import StandardAutocomplete from 'components/StandardAutocomplete'
import Checkbox from 'components/Checkbox'
import format from 'date-fns/format'
import SimpleInput from 'components/SimpleInput'
import React from 'react'
import { CreditSchedule } from '../adminTypes'
import InputSubtitle from './InputSubtitle'
import Button from 'components/Button'
import { toTitleCase } from 'utils/caseHelpers'

const months: Array<{ text: string; value: any }> = []
for (let i = 0; i < 12; i++) {
  const newDate = new Date(2023, i)
  months.push({ text: format(newDate, 'MMMM'), value: i + 1 })
}
months.push({ text: '', value: '' })

interface Props {
  creditSchedule: CreditSchedule
  updateInfo: (update: Record<'creditSchedule', any>) => void
  hasCreditSchedule: boolean
  removeCreditSchedule: () => void
  addCreditSchedule: () => void
}

const AccountsCreditsSchedule: React.FC<Props> = ({
  creditSchedule,
  updateInfo,
  hasCreditSchedule,
  removeCreditSchedule,
  addCreditSchedule,
}) => {
  // Wraps update function with proper key
  const wrapper = (update: Record<string, any>) => {
    updateInfo({ creditSchedule: { ...creditSchedule, ...update } })
  }

  return (
    <>
      <h3>Accounts Credits Schedule</h3>
      {hasCreditSchedule ? (
        <>
          <div>
            <Button
              variant="delete"
              size="small"
              text={'Remove Credit Schedule'}
              onClick={removeCreditSchedule}
              style={{ maxWidth: 300 }}
              data-cy="removeCreditSchedule"
            />
          </div>
          <div>
            <Checkbox
              label="Active"
              status={creditSchedule.active}
              data-cy="creditScheduleActive"
              onClick={() =>
                wrapper({
                  active: !creditSchedule.active,
                })
              }
            />
            <InputSubtitle>
              Whether credit schedule is currently active
            </InputSubtitle>
          </div>

          <div>
            <StandardAutocomplete
              label="Credit Schedule Start Year"
              value={String(creditSchedule.scheduleStartYear || '')}
              testId="creditScheduleStartYear"
              tagSuggestions={['', '2021', '2022', '2023']}
              multiple={false}
              onChange={(_: any, value: string) => {
                wrapper({
                  scheduleStartYear: Number(value),
                })
              }}
            />
            <InputSubtitle>Year to start credit schedule</InputSubtitle>
          </div>

          <div>
            <StandardAutocomplete
              label="Credit Schedule Start Month"
              value={creditSchedule.scheduleStartMonth || ''}
              testId="creditScheduleStartMonth"
              tagSuggestions={months}
              multiple={false}
              onChange={(_: any, opt) => {
                wrapper({
                  scheduleStartMonth: opt.value,
                })
              }}
              autocompleteProps={{
                getOptionLabel: (value: any) => {
                  let label = value.text
                  if (typeof value.text === 'undefined') {
                    label = months.find((month) => month.value === value)?.text
                  }
                  return label
                },
                getOptionSelected: (option: any, value: any) => {
                  const result = option.value === value
                  return result
                },
                renderOption: (option: any) => option.text,
              }}
            />
            <InputSubtitle>Month to start credit schedule</InputSubtitle>
          </div>
          <SimpleInput
            label="Credits"
            type={'number'}
            value={creditSchedule.credits || ''}
            data-cy="credits"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              wrapper({
                credits: e.target.value,
              })
            }
          />

          <div>
            <StandardAutocomplete
              label="Credit Schedule"
              value={creditSchedule.creditSchedule || ''}
              testId="creditSchedule"
              tagSuggestions={['', 'monthly', 'quarterly', 'biannually', 'annually']}
              multiple={false}
              onChange={(_: any, value: string) =>
                wrapper({
                  creditSchedule: value,
                })
              }
              autocompleteProps={{
                getOptionLabel: (option: string) => toTitleCase(option),
              }}
            />
            <InputSubtitle>
              Cadence to grant credits automatically, will always be granted on first
              of the month
            </InputSubtitle>
          </div>

          <div>
            <SimpleInput
              label="Credit Limit"
              value={creditSchedule.creditLimit ?? 0}
              data-cy="creditLimit"
              type={'number'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                wrapper({
                  creditLimit: e.target.value,
                })
              }
            />
            <InputSubtitle>
              Maximum number of credits to result from scheduled credits (i.e. if set
              to 5,000 credits, and account already has 4,500 credits, and they are
              scheduled for 1,000 more credits, they will only be given 500 credits
              on schedule)
            </InputSubtitle>
          </div>
        </>
      ) : (
        <>
          <div>
            <Button
              size="small"
              variant="primary"
              text={'Add Credit Schedule'}
              onClick={addCreditSchedule}
              style={{ maxWidth: 300, marginBottom: '2rem' }}
              data-cy="addCreditSchedule"
            />
          </div>
        </>
      )}
    </>
  )
}

export default AccountsCreditsSchedule
