import { ChangeEvent } from 'react'
import styled from 'styled-components'
import StandardDialog from 'components/StandardDialog'
import SimpleInput from 'components/SimpleInput'
import { ReactComponent as SearchIcon } from 'icons/16px/Search.svg'
import ExportAudienceDialogItem from './ExportAudienceDialogItem'
import { DialogExport } from '../../../NameAudience'
import { debounce } from 'utils/debounce'

interface ExportDialogInterface {
  isOpen: boolean
  exports: Array<DialogExport>
  updatedDTCache: any
  searchText: string | undefined
  handleClose: () => void
  calculate: (hash: number, name?: string) => void
  setSearchText: any
  userCredits: number | null
}

const ExportAudienceDialog: React.FC<ExportDialogInterface> = (props) => {
  const searchDebounced = debounce(props.setSearchText, 500)

  const Title = (
    <TitleContainer>
      <TitleSpan>Export Audience</TitleSpan>
      <div>
        <SimpleInput
          defaultValue={''}
          LeftAdornment={SearchIcon}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            searchDebounced(e.target.value)
          }}
          placeholder="Search Recent Exports"
          data-cy="searchRecentExports"
        />
      </div>
    </TitleContainer>
  )

  const Content = (
    <ContentContainer>
      {props.exports.map((obj, i) => (
        <ExportAudienceDialogItem
          key={i}
          index={i}
          itemData={obj}
          calculateCounts={() => {
            props.calculate(obj.hashed, obj.name)
          }}
          userCredits={props.userCredits}
          counts={props.updatedDTCache[obj.hashed]}
          fieldCount={obj.layout?.fields?.available_count}
          bottomBorder={!(i === props.exports.length - 1)}
        />
      ))}
    </ContentContainer>
  )

  return (
    <StandardDialog
      isOpen={props.isOpen}
      title={Title}
      Content={Content}
      handleClose={() => {
        props.handleClose()
        props.setSearchText(null)
      }}
      titleStyle={{ margin: '0.5rem 1rem', marginRight: 0 }}
      dialogStyle={{ padding: '0rem 1rem' }}
    />
  )
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TitleSpan = styled.span`
  font-size: 1rem;
  font-weight: 500;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
`

export default ExportAudienceDialog
