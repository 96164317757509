import endpoints from './endpoints'
import cacheController from './cache/cacheController'
import Worker from 'context/auth-context/worker'
import { Endpoints, ClientRequest } from 'types'
import { decodeToken, getURLPrefix } from 'utils'
import { AlertContextType } from 'context/alert-context'

export default class NetWiseClient {
  logUserOut: () => void
  worker: typeof Worker
  endpoints: Endpoints
  abortController: AbortController
  isNetWiseAccount: boolean
  jwt?: string
  showAlertMessage?: AlertContextType['sendAlert']

  constructor(worker?: any) {
    this.logUserOut = () => null
    this.jwt = undefined
    this.worker = worker
    this.endpoints = endpoints
    this.abortController = new AbortController()
    this.isNetWiseAccount = false
    this.showAlertMessage = undefined
  }

  setIsNetWiseAccount = (isNetWiseAccount: boolean) => {
    this.isNetWiseAccount = isNetWiseAccount
  }

  abortFetch = () => {
    this.abortController.abort()
    this.abortController = new AbortController()
  }

  makeFetch = async (args: ClientRequest) => {
    const { auth = true, showAlert = false } = args
    const credentials: RequestCredentials = args.endpointIsUrl ? 'omit' : 'include'

    type Template = {
      body: FormData | string | null
      method: string
      credentials: RequestCredentials
      signal: AbortSignal
      headers: Headers
    }

    let template: Template = {
      body: null,
      method: args.method,
      credentials: credentials,
      signal: this.abortController.signal,
      headers: new Headers(),
    }

    let queryString = ''
    if (args.data instanceof FormData) {
      template = { ...template, body: args.data }
    } else if (!!args.data) {
      if (args.method === 'GET') {
        queryString = '?' + new URLSearchParams(args.data).toString()
      } else {
        template['body'] = JSON.stringify(args.data)
        template.headers.append('accept', 'application/json')
        template.headers.append('Content-Type', 'application/json')
      }
    }

    if (auth) {
      if (this.worker) this.jwt = await this.worker.getJwt()
      const decoded: { exp: number } = decodeToken(this.jwt || '')
      const expirationDate = new Date(decoded.exp * 1000)
      const now = new Date()

      if (!this.jwt || expirationDate < now) {
        await this.logUserOut()
        throw new Error('JWT is expired, logging out.')
      }
      template.headers.append('Authorization', `Bearer ${this.jwt}`)
    }

    let url: string
    if (args.endpointIsUrl) url = args.endpoint
    else {
      const prefix = getURLPrefix()
      url = prefix + '/api/' + args.endpoint + '/' + queryString
    }

    const response = await fetch(url, template)
    const { ok, redirected, status, statusText } = response

    let serverData: any
    let errorMessage: string
    const statusCode = status.toString()
    const is200Status = statusCode.match(/2\d{2}/)

    try {
      serverData = await response.json()
      errorMessage = serverData.message
    } catch (e: any) {
      throw new Error(e)
    }

    /* Messages to be surfaced to the user for common errors */
    if (!errorMessage && !is200Status) {
      switch (status) {
        case 401:
          errorMessage = 'Unauthorized. Please try to re-authenticate.'
          break
        case 403:
          errorMessage = 'You are not permitted to perform this action.'
          break
        case 409:
          errorMessage = 'Object of this name already exists.'
          break
        case 500:
          errorMessage = 'The server has encountered an error.'
          break
        default:
          break
      }
    }

    /* Commonly NetWise staff will attempt to access a customer auidence,
     * but will forget to toggle the admin mode to 'on'. If the user is not a
     * netwise employee, we give them a customer-centric message */
    if (statusCode === '206') {
      const msg = this.isNetWiseAccount
        ? 'NetWise Users: make sure admin mode is on if ' +
          'they are trying to access a customer audience.'
        : 'You do not have access to this resource, ' +
          'please contact your company administrator ' +
          'or contact NetWise Customer Success.' +
          '\n\n You will be redirected after clicking OK.'

      alert(msg)

      const rootURL = new URL(document.location.href)
      if (rootURL.pathname !== '/login') {
        this.sendToRootPageURL(rootURL)
      }
    }

    if (errorMessage && showAlert) {
      this.showAlertMessage?.({ message: errorMessage })
    }

    return {
      responseData: serverData,
      ok,
      redirected,
      status,
      statusText,
      errorMessage,
    }
  }

  sendToRootPageURL = (rootURL: any) => {
    window.location.assign(rootURL.origin + '/' + rootURL.pathname.split('/')[0])
  }

  makeCachedFetch = async (args: ClientRequest) => {
    const { useCache = true } = args

    if (useCache) {
      const res = await cacheController({ makeFetch: this.makeFetch, args })
      return res
    }
    const res = await this.makeFetch(args)
    return res
  }

  get = async (args: Omit<ClientRequest, 'method'>) => {
    const res = await this.makeCachedFetch({ method: 'GET', ...args })
    return res
  }

  post = async (args: Omit<ClientRequest, 'method'>) => {
    const res = await this.makeCachedFetch({ method: 'POST', ...args })
    return res
  }

  put = async (args: Omit<ClientRequest, 'method'>) => {
    const res = await this.makeFetch({ method: 'PUT', ...args })
    return res
  }

  patch = async (args: Omit<ClientRequest, 'method'>) => {
    const res = await this.makeFetch({ method: 'PATCH', ...args })
    return res
  }

  delete = async (args: Omit<ClientRequest, 'method'>) => {
    const res = await this.makeFetch({
      method: 'DELETE',
      ...args,
    })
    return res
  }

  undelete = async (args: Omit<ClientRequest, 'method'>) => {
    const res = await this.makeFetch({ method: 'PUT', ...args })
    return res
  }
}
