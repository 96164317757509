import { useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as StarIconOn } from '../../../../../../icons/24px/StarOn.svg'
import { ReactComponent as StarIconOff } from '../../../../../../icons/24px/StarOff.svg'
import { colors } from '../../../../../../context/theme-context/'
import React from 'react'

interface Button {
  status: any
  updateFavoriteStatus?: any
  disabled?: any
  onClick?: any
}

const FavoriteButton: React.FC<Button> = ({
  status,
  updateFavoriteStatus,
  ...rest
}) => {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <ContainerDiv>
      <ButtonDiv
        status={status}
        onClick={updateFavoriteStatus}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        {...rest}
      >
        {isHovering || status ? <StarIconOn /> : <StarIconOff />}
      </ButtonDiv>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
`

const ButtonDiv = styled.div<{ status: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${colors.gray2};
  border: 1px solid ${colors.gray5};
  svg > path {
    fill: ${colors.gray7};
  }
  ${(props) =>
    props.status
      ? css`
          :hover {
            background-color: ${colors.orange2};
            border: 1px solid ${colors.orange4};
            svg > path {
              stroke: ${colors.orange6};
              fill: ${colors.orange5};
            }
          }
        `
      : css`
          :hover {
            background-color: ${colors.orange2};
            border: 1px solid ${colors.orange4};
            svg > path {
              stroke: ${colors.gray7};
              fill: ${colors.gray2};
            }
          }
        `}
  ${(props) =>
    props.status &&
    css`
      svg > path {
        fill: ${colors.orange5};
        stroke: ${colors.orange6};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      svg > path {
        fill: ${colors.gray7};
        stroke: ${colors.gray7};
      }
      :hover {
        background-color: ${colors.gray2};
        border: 1px solid ${colors.gray5};
        svg > path {
          stroke: ${colors.gray7};
          fill: ${colors.gray7};
        }
      }
    `}
`

export default FavoriteButton
