import {
  ContentDiv,
  TextDiv,
  NumberDiv,
  DeleteDiv,
  StyledDelete,
  ProgressIndicator,
} from './CSS'

export default function Chip({
  text = 'Chip Text',
  size = 'large',
  number = null,
  progress = false,
  onDelete = null,
  onClick = null,
  ...rest
}) {
  const onDeleteFn = (e: any) => {
    // @ts-expect-error ts-migrate(2721) FIXME: Cannot invoke an object which is possibly 'null'.
    onDelete()
    e.stopPropagation()
  }

  const Content = (
    <>
      {progress && <ProgressIndicator size="1rem" />}
      <TextDiv>{text}</TextDiv>
      {number && <NumberDiv>{number}</NumberDiv>}
      {onDelete && (
        <DeleteDiv text={text} onClick={onDeleteFn}>
          <StyledDelete />
        </DeleteDiv>
      )}
    </>
  )

  return (
    <div style={{ display: 'flex' }}>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <ContentDiv text={text} onClick={onClick} size={size} {...rest}>
        {Content}
      </ContentDiv>
    </div>
  )
}
